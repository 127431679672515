export const DELETE_MEETING = 'events/DELETE_MEETING';

/**
 * @param meeting
 * @param deleteType
 * @return {{type: boolean}}
 */
export const deleteMeeting = (meeting, deleteType) => ({
  type: DELETE_MEETING,
  payload: {
    meeting,
    deleteType,
  },
});
