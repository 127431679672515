import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  sliderBtn: {
    backgroundColor: 'rgba(0, 0, 0, 0.54)',
    borderRadius: 24,
    color: '#ffffff',
    top: 48,
    left: -(48 + theme.spacing(1)),
    position: 'absolute !important',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
  },
  count: {
    fontSize: '1rem',
  },
}));

const PrevArrow = ({
  currentSlide,
  displayed,
  onClick,
  slideCount,
}) => {
  const classes = useStyles();

  // handle tray opening and closing
  // it was easier to put hear so the the onClick can be used
  const nextCount = slideCount - currentSlide - displayed;
  useEffect(() => {
    if (nextCount < 0) {
      for (let i = 0; i < Math.abs(nextCount); i += 1) {
        onClick();
      }
    }
  }, [nextCount]);

  if (!currentSlide) {
    return null;
  }

  return (
    <IconButton
      id="VideoTrayPrevArrow"
      className={classes.sliderBtn}
      onClick={onClick}
    >
      <div>
        <div>
          <ArrowLeft />
        </div>
        <div className={classes.count}>
          {currentSlide}
        </div>
      </div>
    </IconButton>
  );
};

PrevArrow.defaultProps = {
  currentSlide: 0,
  displayed: 0,
  onClick: () => {},
  slideCount: 0,
};

PrevArrow.propTypes = {
  currentSlide: PropTypes.number,
  displayed: PropTypes.number,
  onClick: PropTypes.func,
  slideCount: PropTypes.number,
};

export default PrevArrow;
