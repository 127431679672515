import React from 'react';
import PropTypes from 'prop-types';

import AudioControl from './AudioControl';
import EnlargeControl from './EnlargeControl';
import MoveUpControl from './MoveUpControl';
import StopScreenShareControl from './StopScreenShareControl';
import VideoControl from './VideoControl';

import attendeesShape from '../../../../shapes/attendees';
import participantShape from '../../../../shapes/participant';

const ControlsIcons = ({
  attendee,
  participant,
  variant,
  centered,
  hideEnlarge,
}) => (
  <>
    <AudioControl
      type={centered ? 'iconButton' : 'text'}
      variant={variant}
      attendee={attendee}
    />

    <VideoControl
      type={centered ? 'iconButton' : 'text'}
      variant={variant}
      attendee={attendee}
    />

    {participant && participant.type === 'screenShare' && (
      <StopScreenShareControl
        type={centered ? 'iconButton' : 'text'}
        variant={variant}
        attendee={attendee}
        handleClose={() => {}}
      />
    )}

    {!hideEnlarge && (
      <EnlargeControl
        type={centered ? 'iconButton' : 'text'}
        variant={variant}
        participant={participant}
      />
    )}

    {!hideEnlarge && (
      <MoveUpControl
        type={centered ? 'iconButton' : 'text'}
        variant={variant}
        participant={participant}
      />
    )}
  </>
);

ControlsIcons.defaultProps = {
  variant: 'text',
  centered: false,
  hideEnlarge: false,
  participant: null,
};

ControlsIcons.propTypes = {
  attendee: attendeesShape.propTypesShape.isRequired,
  participant: participantShape.propTypesShape,
  variant: PropTypes.string,
  centered: PropTypes.bool,
  hideEnlarge: PropTypes.bool,
};

export default ControlsIcons;
