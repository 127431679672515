import React, { useState } from 'react';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { makeStyles } from '@material-ui/core';

import CallEnd from '@material-ui/icons/CallEnd';

import attendeesShape from '../../../../../shapes/attendees';
import * as events from '../../../../../events';
import * as selectors from '../../../../../selectors';

const messages = defineMessages({
  END_CALL: {
    id: 'END_CALL',
    defaultMessage: 'End call',
  },
  END_MEETING: {
    id: 'END_MEETING',
    defaultMessage: 'End Meeting',
  },
  END_MEETING_BODY: {
    id: 'END_MEETING_BODY',
    defaultMessage: 'Leave and end this meeting for all remaining attendees?',
  },
  CANCEL: {
    id: 'CANCEL',
    defaultMessage: 'Cancel',
  },
  DISCONNECT: {
    id: 'DISCONNECT',
    defaultMessage: 'Just Leave',
  },
});

const useStyles = makeStyles(() => ({
  redBtn: {
    margin: '0 4px',
    backgroundColor: '#F44336 !important',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: 'rgba(244, 67, 54, .74) !important',
    },
  },
  actionsContainer: {
  },
}));

const EndSession = ({
  // inherited
  classes,

  // stateful
  myAttendee,

  // dispatch
  endCallEvent,
  endMeetingEvent,
}) => {
  const localClasses = useStyles();

  const [dialogOpen, setDialogOpen] = useState(false);

  const endSession = () => {
    if (myAttendee.role === 'host') {
      setDialogOpen(true);
    } else {
      endCallEvent();
    }
  };

  const endCall = () => {
    setDialogOpen(false);
    endCallEvent();
  };

  const endMeeting = () => {
    setDialogOpen(false);
    endMeetingEvent();
  };

  return (
    <>
      <Tooltip title={<FormattedMessage {...messages.END_CALL} />}>
        <IconButton
          id="EndSession"
          className={classnames(classes.icon, localClasses.redBtn)}
          onClick={endSession}
        >
          <CallEnd />
        </IconButton>
      </Tooltip>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle>
          <FormattedMessage {...messages.END_MEETING} />
        </DialogTitle>

        <DialogContent style={{ width: 222 }}>
          <DialogContentText>
            <FormattedMessage {...messages.END_MEETING_BODY} />
          </DialogContentText>
        </DialogContent>

        <DialogActions style={{ textAlign: 'left', flex: 'none', display: 'block' }}>
          <div style={{ paddingLeft: 8 }}>
            <Button id="MeetingListDelete" onClick={endCall} color="primary" autoFocus>
              <FormattedMessage {...messages.DISCONNECT} />
            </Button>
          </div>
          <div>
            <Button id="MeetingListDeleteAll" onClick={endMeeting} color="primary" autoFocus>
              <FormattedMessage {...messages.END_MEETING} />
            </Button>
          </div>
          <div>
            <Button id="MeetingListCancel" onClick={() => setDialogOpen(false)} color="primary">
              <FormattedMessage {...messages.CANCEL} />
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

EndSession.propTypes = {
  classes: PropTypes.shape({
    icon: PropTypes.string,
  }).isRequired,
  myAttendee: attendeesShape.propTypesShape.isRequired,
  endCallEvent: PropTypes.func.isRequired,
  endMeetingEvent: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  myAttendee: selectors.selectMyAttendee(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  endCallEvent: events.endCall,
  endMeetingEvent: events.endMeeting,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EndSession);
