export const SEND_CHAT_ATTACHMENTS = 'events/SEND_CHAT_ATTACHMENTS';

/**
 * @param info
 * @return {{type: string, payload: *}}
 */
export const sendChatAttachments = (info) => ({
  type: SEND_CHAT_ATTACHMENTS,
  payload: info,
});
