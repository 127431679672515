export default (message) => {
  let filtered = message;
  switch (filtered) {
    case '/happy':
      filtered = 'ᕕ( ᐛ )ᕗ';
      break;
    case '/tableflip':
      filtered = '(╯°□°)╯︵ ┻━┻';
      break;
    case '/tableback':
      filtered = '┬─┬﻿ ノ( ゜-゜ノ)';
      break;
    case '/sunglasses':
      filtered = '( •_•) \n ( •_•)>⌐■-■ \n (⌐■_■)';
      break;
    case '/shrug':
      filtered = '¯\\_(ツ)_/¯';
      break;
    case '/yuno':
      filtered = 'ლ(ಠ益ಠლ)';
      break;
    case '/facepalm':
      filtered = '(－‸ლ)';
      break;
    case '/disapprove':
      filtered = 'ಠ_ಠ';
      break;
    case '/this':
      filtered = '☜(ﾟヮﾟ☜)';
      break;
    case '/that':
      filtered = '(☞ﾟヮﾟ)☞';
      break;
    case '/crying':
      filtered = '(ಥ﹏ಥ)';
      break;
    case '/flex':
      filtered = 'ᕙ(⇀‸↼‶)ᕗ';
      break;
    case '/sparkles':
      filtered = '(ﾉ◕ヮ◕)ﾉ*:･ﾟ✧';
      break;
    case '/owo':
      filtered = '(•ω•)';
      break;
    default:
  }
  return filtered;
};
