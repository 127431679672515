import _ from 'lodash';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import { put, select } from 'redux-saga/effects';

import fetchMessages from '../../services/fetchMessages';
import { selectChatSessions, selectMeeting, selectMyAttendee } from '../../selectors';
import {
  setChatInFocus, setChatSessions, setUcIsOpen, setUcTabValue,
} from '../../actions';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* newPrivateChat({ payload }) {
  try {
    const sessions = yield select(selectChatSessions);

    // check if session already exists with that attendee_id, if so set that to focus
    let foundId;
    Object.keys(sessions).forEach((id) => {
      if (sessions[id].remoteAttendeeId === payload.attendee_id) {
        foundId = id;
      }
    });
    if (foundId) {
      yield put(setChatInFocus(foundId));
      yield put(setUcIsOpen(true));
      yield put(setUcTabValue('chat'));
      return;
    }

    const decodedToken = nsToken.getDecoded();
    const meeting = yield select(selectMeeting);
    const myAttendee = yield select(selectMyAttendee);

    const sessionId = nsUtils.generateChatId({
      domain: decodedToken.domain,
      fromUID: myAttendee.attendee_id, // use attendee_id instead because guests are not unique
      termUID: payload.attendee_id, // use attendee_id instead because guests are not unique
    });

    const messages = yield fetchMessages({
      domain: decodedToken.domain,
      user: decodedToken.user,
      sessionid: `${meeting.chat_sessionid}_private${sessionId}`,
      session_name: meeting.chat_sessionid,
      order: 'timestamp desc',
    });

    const clonedSessions = _.cloneDeep(sessions);
    clonedSessions[`${meeting.chat_sessionid}_private${sessionId}`] = {
      id: `${meeting.chat_sessionid}_private${sessionId}`,
      type: 'private',
      messages,
      remoteAttendeeId: payload.attendee_id,
    };

    yield put(setChatSessions(clonedSessions));
    yield put(setChatInFocus(`${meeting.chat_sessionid}_private${sessionId}`));
    yield put(setUcIsOpen(true));
    yield put(setUcTabValue('chat'));
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: newPrivateChat';
      event.addMetadata('payload', payload);
    });
  }
}
