import { put, select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import bugsnagClient from '../../bugsnag';

import store from '../../store';
import * as actions from '../../actions';
import * as events from '../../events';
import * as selectors from '../../selectors';

// eslint-disable-next-line import/prefer-default-export
export function* endMeeting() {
  try {
    yield put(actions.showLoaderPage());

    const user = yield select((state) => selectors.selectUser(state));
    const meeting = yield select((state) => selectors.selectMeeting(state));

    yield nsApi.post({
      conference_match: `sip:${meeting.id}.video.bridge@conference-bridge`,
      user: user.userId || '',
      domain: user.domain,
      object: 'participant',
      action: 'disc',
      participant_match: 'all',
    });

    yield nsApi.post({
      id: meeting.id,
      user: user.userId || '',
      domain: user.domain,
      object: 'meeting',
      action: 'update',
      status: 'ended',
    });

    store.dispatch(events.endCall());
    const room = yield select((state) => selectors.selectVideoRoom(state));
    room.forceMeetingClose();
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: endMeeting';
    });
  }
}
