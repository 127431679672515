export const UPDATE_SESSION_TO_READ = 'events/UPDATE_SESSION_TO_READ';

/**
 * @param messagesession
 * @return {{type: string, payload: *}}
 */
export const updateSessionToRead = (sessionId) => ({
  type: UPDATE_SESSION_TO_READ,
  payload: sessionId,
});
