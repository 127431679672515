export const RESTRICT_ATTENDEE = 'events/RESTRICT_ATTENDEE';

/**
 * @param attendee
 * @param restriction
 * @param successCallback
 * @returns {{payload: *, type: string}}
 */
export const restrictAttendee = (attendee, restriction, successCallback) => ({
  type: RESTRICT_ATTENDEE,
  payload: {
    attendee,
    restriction,
    successCallback,
  },
});
