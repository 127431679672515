export const UPDATE_ATTENDEE = 'events/UPDATE_ATTENDEE';

/**
 *
 * @param attendee
 * @return {{type: string, payload: *}}
 */
export const updateAttendee = (attendee) => ({
  type: UPDATE_ATTENDEE,
  payload: attendee,
});
