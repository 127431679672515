import { put, select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* logoutUser({ payload }) {
  try {
    yield put(actions.showLoaderPage());

    // if bg image is showing, fade it out
    const showBgImage = yield select((state) => selectors.showBgImage(state));
    if (showBgImage) {
      yield put(actions.showBgImage(false));
    } else {
      yield put(actions.showBgImage(null));
    }

    const apiHash = yield select((state) => state.configs.apiHash);

    yield nsApi.logout(apiHash);

    if (payload === undefined || payload) {
      window.location.reload();
    }
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: logoutUser';
      event.addMetadata('payload', payload);
    });
    window.location.reload();
  }
}
