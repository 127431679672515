export const DELETE_CHAT_MESSAGE = 'events/DELETE_CHAT_MESSAGE';

/**
 * @param message
 * @return {{type: string, payload: *}}
 */
export const deleteChatMessage = (message) => ({
  type: DELETE_CHAT_MESSAGE,
  payload: message,
});
