import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import bugsnagClient from '../bugsnag';

export default ({
  meetingId,
  attendeeId,
  apiHash,
  attendeeEmail,
}) => new Promise((resolve, reject) => {
  nsApi.videoGuestLogin({
    guest: attendeeId,
    confId: meetingId,
    apiHash,
    scope: 'Meeting Guest',
    guestEmail: attendeeEmail || attendeeId,
  }).then(() => {
    const token = nsToken.getDecoded();
    nsApi.get({
      id: meetingId,
      object: 'meeting',
      action: 'read',
      attendee_id: token.attendee_id,
    }).then((res) => {
      const [meeting] = res;
      if (!meeting) {
        // eslint-disable-next-line no-throw-literal
        throw 'NO_MEETING';
      }
      resolve(meeting);
    }).catch((e) => {
      console.error(e);
      bugsnagClient.notify(e);
      reject(e);
    });
  }).catch((e) => {
    console.error(e);
    bugsnagClient.notify(e);
  });
});
