import { USER } from '../constants';

const defaultState = {
  user: null,
};

const reducers = {
  [USER]: (state, { payload }) => ({ user: payload }),
};

export default {
  defaultState,
  reducers,
};
