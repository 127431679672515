import { put, select } from 'redux-saga/effects';

import * as actions from '../../actions';
import * as selectors from '../../selectors';
import * as services from '../../services';

import { setMeeting } from '../../events';
import { setRoute } from './setRoute';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* showMeetingsScrollPage({ payload }) {
  const { tense, noRender, instanceId } = payload;

  let { meetingId } = payload;

  try {
    if (!meetingId || meetingId === 'undefined') {
      meetingId = '';
    }

    let path = tense === 'future'
      ? `upcoming/${meetingId}`
      : `past/${meetingId}`;

    if (instanceId) {
      path += `/${instanceId}`;
    }

    yield setRoute({ title: null, path });

    // noRender will suppress page load animations
    if (!noRender) {
      yield put(actions.showLoaderPage());
    }

    // clear meetings and set loading flag
    yield put(actions.meetingsLoading(true));
    yield put(actions.setMeetings([]));

    const user = yield select((state) => selectors.selectUser(state));

    if (tense === 'future') {
      const params = {
        user: user.userId,
        domain: user.domain,
        limit: 20,
      };

      if (meetingId) {
        params.id = meetingId;
      }

      const res = yield services.getMeetings(params);

      yield put(actions.meetingsLoading(false));

      const meeting = meetingId && res && res.length === 1 && res[0];

      if (meeting) {
        if (instanceId) {
          meeting.start_instance = instanceId;
        }

        yield put(setMeeting(meeting));
      } else {
        yield put(actions.setMeetings(res));
      }

      if (!noRender) {
        yield put(actions.showMeetingsScrollPage({ tense }));
      }
    } else if (tense === 'past') {
      // if a specific meeting/instance is requested, load it directly
      if (meetingId) {
        const meetingParams = {
          user: user.userId,
          domain: user.domain,
          group_by_instance: 1,
          limit: 20,
          id: meetingId,
        };

        if (instanceId) {
          meetingParams.instanceId = instanceId;
        }

        const res = yield services.getMeetingEvents(meetingParams);

        yield put(actions.meetingsLoading(false));

        const meeting = meetingId && res && res.length === 1 && res[0];
        if (meeting) {
          yield put(setMeeting(meeting));
        }

        if (!noRender) {
          yield put(actions.showMeetingsScrollPage({ tense }));
        }
      } else {
        const listParams = {
          user: user.userId,
          domain: user.domain,
          group_by_instance: 1,
          limit: 20,
        };

        const res = yield services.getMeetingEvents(listParams);

        yield put(actions.meetingsLoading(false));
        yield put(actions.setMeetings(res));

        if (!noRender && !meetingId) {
          yield put(actions.showMeetingsScrollPage({ tense }));
        }
      }
    }
  } catch (err) {
    if (meetingId) {
      yield put(actions.snackBarError('COULD_NOT_LOAD_PAGE'));
      yield put(actions.showMeetingsScrollPage({ tense }));
    } else {
      yield put(actions.snackBarError('COULD_NOT_LOAD_PAGE'));
      yield put(actions.showMeetingHubPage());
    }

    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: showMeetingsScrollPage';
      event.addMetadata('payload', payload);
    });
  }
}
