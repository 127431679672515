import _ from 'lodash';
import { put, select } from 'redux-saga/effects';

import { selectParticipants, selectVideoGridIds, selectLayoutPreScreenshare } from '../../selectors';
import {
  isScreenShareSelf,
  isScreenShareSharing,
  removeMediaStatus,
  setVideoGrid,
  setVideoParticipants,
} from '../../actions';
import { setLayout } from '../../events';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* removeParticipant({ payload: participant }) {
  try {
    const participants = yield select(selectParticipants);
    let gridIds = yield select(selectVideoGridIds);

    const filtered = {};
    let deletedParticipant;
    Object.keys(participants).forEach((id) => {
      if (participant && participant.id && id !== participant.id) {
        filtered[id] = participants[id];
        // if video participant of the audio only hangs up, keep the on audio only participant
        if (filtered[id].videoPart != null && filtered[id].videoPart.id === participant.id) {
          filtered[id].videoPart = null;
          // insert audio only back into grid
          gridIds = [...gridIds, id];
        }
      } else {
        deletedParticipant = participants[id];
      }
    });

    yield put(setVideoParticipants(filtered));

    const filteredGrid = _.filter(gridIds, (partId) => partId !== participant.id);
    yield put(setVideoGrid(_.uniq(filteredGrid)));

    // do some other cleanup
    if (deletedParticipant && deletedParticipant.type === 'screenShare') {
      yield put(removeMediaStatus(deletedParticipant.id));
      yield put(isScreenShareSelf(false));
      yield put(isScreenShareSharing(false));
      const layout = yield select(selectLayoutPreScreenshare);
      yield put(setLayout({ layout, updateMeeting: false }));
    }
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: removeParticipant';
      event.addMetadata('payload', participant);
    });
  }
}
