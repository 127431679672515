export default (theme) => ({
  cardButtonPrimary: {
    margin: 0,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    float: 'right',
    minWidth: 88,
  },
  cardButtonSecondary: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingLeft: 13,
    paddingRight: 13,
    float: 'left',
  },
  cardButtonSecondaryRight: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    float: 'right',
  },
});
