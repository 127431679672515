import React, { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import nsToken from '@netsapiens/netsapiens-js/dist/token';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { FilePicker } from 'react-file-picker';

import {
  makeStyles, Grid, IconButton, InputBase,
} from '@material-ui/core';
import AttachFile from '@material-ui/icons/AttachFile';
import InsertEmoticon from '@material-ui/icons/InsertEmoticon';

import { ALLOWED_WEBINAR_ROLES } from '../../../../../constants';
import attendeesShape from '../../../../../shapes/attendees';
import * as actions from '../../../../../actions';
import * as events from '../../../../../events';
import * as selectors from '../../../../../selectors';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    height: 'calc(100vh - 90px)',
  },
  subNavigationButtons: {
    margin: theme.spacing(0, 0, 1, 1),
  },
  subNavigationButtonTitle: {
    margin: theme.spacing(0.4, 0, 1.4, 1),
    color: 'rgba(0, 0, 0, 0.54)',
  },
  chip: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  chipLabel: {
    paddingLeft: theme.spacing(1),
  },
  chipSelected: {
    backgroundColor: '#E1F4FE !important',
    color: '#0083D0 !important',
  },
  headerContainer: {
    marginTop: 3,
    width: '100%',
    paddingTop: theme.spacing(1),
    backgroundColor: '#FAFAFA',
    boxShadow: theme.shadows[1],
  },
  messagesContainer: {
    backgroundColor: '#F0F0F0',
    overflow: 'auto',
    padding: theme.spacing(1, 2, 1, 2),
  },
  messageInputContainer: {
    padding: theme.spacing(1),
  },
  messageInput: {
    paddingTop: '2px',
  },
  chips: {
    padding: theme.spacing(0, 0, 1, 1),
  },
  emojiPicker: {
    display: 'none',
  },
  emojiToggleOn: {
    color: '#0083D0 !important',
    backgroundColor: '#E1F4FE !important',
  },
  searchInput: {
    margin: theme.spacing(2, 1, 0, 1),
  },
  searchAdornmentRoot: {
    marginTop: '0px !important',
  },
  listContainer: {
    paddingBottom: theme.spacing(1),
    height: 'calc(100vh - 264px)',
    overflowY: 'auto',
  },
}));

const MessageInput = ({
  // inherit
  onMessage,
  onAttachment,

  // state
  chatSession,
  myAttendee,
  meetingType,
  instanceId,
  chatAttachments,

  // dispatch
  snackBarError,
  sendChatMessage,
  sendChatAttachments,
}) => {
  const classes = useStyles();

  function retrieveImageFromClipboardAsBlob(pasteEvent, callback) {
    if (pasteEvent.clipboardData === false) {
      if (typeof callback === 'function') {
        callback(undefined);
      }
    }

    const { items } = pasteEvent.clipboardData;

    if (items === undefined) {
      if (typeof callback === 'function') {
        callback(undefined);
      }
    }

    for (let i = 0; i < items.length; i += 1) {
      // Skip content if not image
      if (items[i].type.indexOf('image') !== -1) {
        // Retrieve image on clipboard as blob
        const blob = items[i].getAsFile();

        if (typeof callback === 'function') {
          callback(blob);
        }
      }
    }
  }

  const handlePasteEvent = (thePasteEvent) => {
    retrieveImageFromClipboardAsBlob(thePasteEvent, onAttachment);
  };

  useEffect(() => {
    window.addEventListener('paste', handlePasteEvent, false);

    return () => {
      window.removeEventListener('paste', handlePasteEvent, false);
    };
  });

  const [message, setMessage] = useState('');
  const [emojiToggle, setEmojiToggle] = useState('');

  const hasRightsToMeeting = meetingType === 'conference'
    || ALLOWED_WEBINAR_ROLES.includes(myAttendee.role);

  // emoji work
  // toggle on and off the emoji picker, and change a few css attributes from the imported module
  const toggleEmojiSelector = () => {
    const emojiPicker = document.getElementById('emojiPicker');
    if (emojiPicker.style.display === 'none' || emojiPicker.style.display === '') {
      emojiPicker.style.display = 'block';

      // change css elements
      const emojiScroll = document.getElementsByClassName('emoji-mart-scroll');
      if (typeof emojiScroll !== 'undefined') {
        emojiScroll[0].style.height = '180px';
        setEmojiToggle(true);
      }
    } else {
      emojiPicker.style.display = 'none';
      setEmojiToggle(false);
    }
  };

  // on clicking an emoji, append and set that as new message to send
  const onEmojiClick = useCallback((em) => {
    const oldValue = document.getElementById('messageInputField').value;
    setMessage(oldValue + em.native);
  }, []);

  // error on faulty attachment
  const notifyRestriction = (errMsg) => {
    snackBarError(errMsg || 'MESSAGE_NOT_SENT');
  };

  // on key up of the input field
  const handleOnKeyUp = (e) => {
    if (e.keyCode === 13) {
      if (Object.keys(chatAttachments).length > 0) {
        sendChatAttachments({
          sessionId: chatSession.id,
        });
      }
      if (message.trim()) {
        const decodedToken = nsToken.getDecoded();

        // hide emoji picker after sending message
        const emojiPicker = document.getElementById('emojiPicker');
        if (emojiPicker.style.display !== 'none' && emojiPicker.style.display !== '') {
          emojiPicker.style.display = 'none';
          setEmojiToggle(false);
        }

        sendChatMessage({
          user: decodedToken.user,
          domain: decodedToken.domain,
          type: 'chat',
          fromAttendeeId: myAttendee.attendee_id,
          message: message.trim(),
          sessionId: chatSession.id,
          destination: chatSession.remoteAttendeeId,
          instanceId,
        });
      }

      onMessage();
      setTimeout(() => { setMessage(''); }, 0);
    }
  };

  // on mouse down of the input field
  const handleOnMouseDown = () => {
    // hide emoji picker when input base is clicked
    const emojiPicker = document.getElementById('emojiPicker');
    if (emojiPicker.style.display !== 'none' && emojiPicker.style.display !== '') {
      emojiPicker.style.display = 'none';
      setEmojiToggle(false);
    }
  };

  return (
    <Grid item={false} className={classes.messageInputContainer}>
      <span id="emojiPicker" className={classes.emojiPicker}>
        <Picker showSkinTones={false} showPreview={false} onSelect={onEmojiClick} />
      </span>
      <InputBase
        id="messageInputField"
        classes={{ multiline: classes.messageInput }}
        multiline
        fullWidth
        margin="dense"
        rows={2}
        value={message}
        onChange={(e) => { setMessage(e.target.value); }}
        onKeyDown={handleOnKeyUp}
        placeholder="Type a message"
        disabled={!chatSession}
        onMouseDown={handleOnMouseDown}
      />
      <Grid container justifyContent="flex-end">
        {hasRightsToMeeting && (
          <FilePicker
            maxSize="20"
            onChange={(FileObject) => (onAttachment(FileObject))}
            onError={(errMsg) => (notifyRestriction(errMsg))}
          >
            <IconButton size="small">
              <AttachFile />
            </IconButton>
          </FilePicker>
        )}
        <IconButton
          size="small"
          className={classnames(emojiToggle && classes.emojiToggleOn)}
          onClick={toggleEmojiSelector}
        >
          <InsertEmoticon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

MessageInput.propTypes = {
  onMessage: PropTypes.func.isRequired,
  onAttachment: PropTypes.func.isRequired,
  chatSession: PropTypes.shape({
    id: PropTypes.string.isRequired,
    remoteAttendeeId: PropTypes.string.isRequired,
  }).isRequired,
  myAttendee: attendeesShape.propTypesShape.isRequired,
  meetingType: PropTypes.string.isRequired,
  instanceId: PropTypes.string.isRequired,
  snackBarError: PropTypes.func.isRequired,
  sendChatMessage: PropTypes.func.isRequired,
  sendChatAttachments: PropTypes.func.isRequired,
  chatAttachments: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
  const chatInFocus = selectors.selectChatInFocus(state);
  const meeting = selectors.selectMeeting(state);

  return {
    chatSession: selectors.selectChatSession(state, chatInFocus),
    myAttendee: selectors.selectMyAttendee(state),
    meetingType: meeting.type,
    instanceId: meeting.instance_id,
    chatAttachments: selectors.selectChatAttachments(state),
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  snackBarError: actions.snackBarError,
  sendChatMessage: events.sendChatMessage,
  sendChatAttachments: events.sendChatAttachments,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MessageInput);
