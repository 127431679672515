export const SSO_LOGIN_USER = 'events/SSO_LOGIN_USER';

/**
 * @param idToken
 * @param vendor
 * @param username
 * @return {{type: string, payload: {idtoken: *,vendor: *}}}
 */
export const ssoLoginUser = (idtoken, vendor, username) => ({
  type: SSO_LOGIN_USER,
  payload: { idtoken, vendor, username },
});
