import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';
import { call, put, select } from 'redux-saga/effects';
import * as actions from '../../actions';
import { selectUser, selectContacts } from '../../selectors';
import { setMeeting } from '../../events';
import * as services from '../../services';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* showMeetingConfig({ payload }) {
  yield put(actions.showLoaderPage());

  let meeting;
  const { configMode, configType } = payload;

  const user = yield select(selectUser);

  try {
    if (configMode === 'new') {
      const maxMeeting = yield select((state) => state.configs.videoConferenceMaxAttendees);
      const maxPresentation = yield select((state) => state.configs.videoPresentationMaxAttendees);
      const defaultPassword = (yield select((state) => state.configs.PORTAL_VIDEO_DEFAULT_PASSCODE)) === 'yes';
      const contacts = yield select((state) => selectContacts(state));
      const requested = nsBrowser.getQuery('users');

      meeting = yield call(services.getNewMeetingInfo, {
        user,
        maxMeeting: parseInt(maxMeeting, 10),
        maxPresentation: parseInt(maxPresentation, 10),
        configType,
        defaultPassword,
        contacts,
        requested,
      });

      if (nsBrowser.getQuery('s') === '1') {
        meeting.layout = 'spotlight';
      }

      if (configType === 'room') {
        meeting.is_room = 1;
      }
    } else if (configMode === 'edit') {
      /* eslint-disable prefer-destructuring */
      meeting = payload.meeting;
      const startInstance = meeting.start_instance;

      meeting = yield call(services.getMeetingById, {
        user: user.userId,
        domain: user.domain,
        meetingId: meeting.id,
      });

      meeting.start_instance = startInstance; // can be null
    }

    yield put(setMeeting(meeting));
    yield put(actions.showMeetingConfig({ configMode, configType }));
  } catch (err) {
    yield put(actions.snackBarError('UNABLE_TO_CONFIG'));
    yield put(actions.showMeetingHubPage());

    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: showMeetingConfig';
      event.addMetadata('payload', payload);
    });
  }
}
