/* eslint-disable react/prop-types */
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';
import nsPortal from '@netsapiens/netsapiens-js/dist/portal';
import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment/min/locales';// import various locals
import { bindActionCreators } from 'redux';
import { IntlProvider } from 'react-intl';

import { darken, createTheme } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import AddUserModal from './pages/video/add-user-modal/AddUserModal';
import AlertDialog from './components/AlertDialog';
import RejectDialog from './components/RejectDialog';
import AppMenu from './components/AppMenu';
import Loader from './pages/loader/Loader';
import LoginTheme from './components/LoginTheme';
import PendingPermissionsDialog from './components/PendingPermissionsDialog';
import PermissionsDialog from './components/PermissionsDialog';
import ScreenshareRequestDialog from './components/ScreenshareRequestDialog';
import SnackBar from './components/SnackBar';
import UserInfo from './components/UserInfo';
import Video from './pages/video/Video';
import DeviceReconfigDialog from './components/DeviceReconfigDialog';
import MivInterface from './components/MivInterface';

import bugsnagClient from '../bugsnag';
import appStyles from '../styles.css';

import * as events from '../events';
import * as selectors from '../selectors';
import { StateProvider as CallMeProvider } from './providers/CallMeProvider';
import getContrastColor from '../utils/getContrastColor';

class App extends React.Component {
  constructor(props) {
    super(props);

    if (_.isNil(localStorage.getItem('videoRenderedLang')) || localStorage.getItem('videoRenderedLang') === 'null' || localStorage.getItem('videoRenderedLang') === 'undefined') {
      localStorage.setItem(
        'videoRenderedLang',
        navigator.language.toLowerCase().replace('-', '_') || 'en_us',
      );
    }

    this.state = {
      translations: null,
      usersLocale: localStorage.getItem('videoRenderedLang'),
      usersLang: localStorage.getItem('videoRenderedLang').split('_')[0],
    };
  }

  componentDidMount() {
    // Initialize moment pool timer
    Moment.startPooledTimer();

    const { processRoute } = this.props;
    window.onpopstate = (inbound) => {
      processRoute(inbound);
    };

    const { usersLocale } = this.state;
    if (usersLocale !== 'en_us' && usersLocale !== 'tbd') {
      nsPortal.getTranslation(usersLocale).then((res) => {
        if (typeof res === 'object') {
          this.setState({ translations: res });
        } else {
          const msg = `getTranslation('${usersLocale}') request did not return an appropriate localized dataset.`;
          // eslint-disable-next-line
          console.error(msg);
          bugsnagClient.notify(msg);
        }
      }).catch((err) => {
        // eslint-disable-next-line
        console.error(err);
        bugsnagClient.notify(err);
      });
    }
  }

  componentDidUpdate() {
    const { user } = this.props;
    if (user
      && user.language
      && user.language !== localStorage.getItem('videoRenderedLang')
      && user.language !== 'undefined'
      && user.language !== 'null'
    ) {
      localStorage.setItem('videoRenderedLang', user.language);

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        usersLocale: localStorage.getItem('videoRenderedLang'),
        usersLang: localStorage.getItem('videoRenderedLang').split('_')[0],
      });
    }
  }

  setUpMoment() {
    const { configs } = this.props;

    // date configs
    // delimiter between month/day/year, defaults to /
    const dateDelimiter = (typeof configs.PORTAL_LOCALIZATION_DATE_DELIMITER !== 'undefined') ? configs.PORTAL_LOCALIZATION_DATE_DELIMITER : '/';

    // set MMM (default) or MM or M for month format
    const monthFormat = (typeof configs.PORTAL_LOCALIZATION_DATE_MONTH_FORMAT !== 'undefined') ? (configs.PORTAL_LOCALIZATION_DATE_MONTH_FORMAT).toUpperCase() : 'MMM';

    // set YYYY (default) or yy for year format
    const yearFormat = (typeof configs.PORTAL_LOCALIZATION_DATE_YEAR_FORMAT !== 'undefined') ? configs.PORTAL_LOCALIZATION_DATE_YEAR_FORMAT : 'YYYY';

    let dayFormat = (typeof configs.PORTAL_LOCALIZATION_DATE_DAY_FORMAT !== 'undefined') ? configs.PORTAL_LOCALIZATION_DATE_DAY_FORMAT.toUpperCase() : 'Do';
    if (dayFormat === 'DO' || dayFormat === 'DTH') {
      dayFormat = 'Do';
    }
    // only supported values
    if (!['D', 'DD', 'DDD', 'Do', ''].includes(dayFormat)) {
      dayFormat = 'D';
    }

    // time configs
    // set HH or hh, and AM/PM
    const hourFormat = (configs.PORTAL_LOCALIZATION_TIME_12_HOUR_FORMAT === 'no') ? 'HH' : 'h';
    const amPMFormat = (configs.PORTAL_LOCALIZATION_TIME_12_HOUR_FORMAT === 'no') ? '' : ' a';

    // delimiter between hours/minutes and between date and time
    const timeDelimiter = (typeof configs.PORTAL_LOCALIZATION_TIME_DELIMITER !== 'undefined') ? `[${configs.PORTAL_LOCALIZATION_TIME_DELIMITER}]` : ':';
    const dateTimeDelimiter = (typeof configs.PORTAL_LOCALIZATION_DATETIME_DELIMITER !== 'undefined') ? `[${configs.PORTAL_LOCALIZATION_DATETIME_DELIMITER}]` : ',';

    // get user's language (before first underscore "fr_CA" => "fr" ) default en
    const { user } = this.props;
    let momentLang = (user !== null && typeof user.language !== 'undefined' && user.language !== null) ? user.language.substring(0, user.language.indexOf('_')) : 'en';

    // if momentLang was invalid, setting to default english
    if (moment.locale(momentLang) === undefined) {
      momentLang = 'en';
    }

    // date format configs
    // PORTAL_LOCALIZATION_DATE_FORMAT YMD, MDY(default), DMY
    if (configs.PORTAL_LOCALIZATION_DATE_FORMAT === 'DMY') {
      moment.updateLocale(momentLang, {
        longDateFormat: {
          LT: `${hourFormat}${timeDelimiter}mm${amPMFormat}`, // h:mm a // regular time
          LTS: `${hourFormat}${timeDelimiter}mm${timeDelimiter}ss`,
          L: `DD${dateDelimiter}${monthFormat}${dateDelimiter}${yearFormat}`, // day/month/year
          LL: `D ${monthFormat} ${yearFormat}`,
          LLL: `D ${monthFormat} ${yearFormat} ${hourFormat}${timeDelimiter}mm`,
          LLLL: `dddd D ${monthFormat} ${yearFormat}${dateTimeDelimiter} ${hourFormat}${timeDelimiter}mm`,
          l: `ddd${dateTimeDelimiter} ${hourFormat}${timeDelimiter}mm${amPMFormat}`, // ddd, h:mm a; day of the week and time
          ll: `${dayFormat} ${monthFormat}${dateTimeDelimiter} ${hourFormat}${timeDelimiter}mm${amPMFormat}`, // MMM Do, h:mm a
          lll: `${dayFormat} ${monthFormat} ${yearFormat}${dateTimeDelimiter} ${hourFormat}${timeDelimiter}mm${amPMFormat}`, // MMM Do YYYY, h:mm a, only used for past so far
        },
      });
    } else if (configs.PORTAL_LOCALIZATION_DATE_FORMAT === 'YMD') {
      moment.updateLocale(momentLang, {
        longDateFormat: {
          LT: `${hourFormat}${timeDelimiter}mm${amPMFormat}`, // h:mm a // regular time
          LTS: `${hourFormat}${timeDelimiter}mm${timeDelimiter}ss`,
          L: `${yearFormat}${dateDelimiter}${monthFormat}${dateDelimiter}DD`, // year/month/day
          LL: `${yearFormat}${monthFormat} D'`,
          LLL: `${yearFormat} ${monthFormat} D ${hourFormat}${timeDelimiter}mm`,
          LLLL: `${yearFormat} ${monthFormat} dddd D ${dateTimeDelimiter} ${hourFormat}${timeDelimiter}mm`,
          l: `ddd${dateTimeDelimiter} ${hourFormat}${timeDelimiter}mm${amPMFormat}`, // ddd, h:mm a; day of the week and time
          ll: `${monthFormat} ${dayFormat}${dateTimeDelimiter} ${hourFormat}${timeDelimiter}mm${amPMFormat}`, // MMM Do, h:mm a
          lll: `${yearFormat} ${monthFormat} ${dayFormat}${dateTimeDelimiter} ${hourFormat}${timeDelimiter}mm${amPMFormat}`, // MMM Do YYYY, h:mm a, only used for past so far
        },
      });
    } else { // default to MDY
      moment.updateLocale(momentLang, {
        longDateFormat: {
          LT: `${hourFormat}${timeDelimiter}mm${amPMFormat}`, // h:mm a // regular time
          LTS: `${hourFormat}${timeDelimiter}mm${timeDelimiter}ss`,
          L: `${monthFormat}${dateDelimiter}DD${dateDelimiter}${yearFormat}`, // year/month/day
          LL: `${monthFormat}${yearFormat} D`,
          LLL: `${monthFormat} D ${yearFormat} ${hourFormat}${timeDelimiter}mm`,
          LLLL: `${monthFormat} dddd D ${yearFormat}${dateTimeDelimiter}${hourFormat}${timeDelimiter}mm`,
          l: `ddd${dateTimeDelimiter} ${hourFormat}${timeDelimiter}mm${amPMFormat}`, // ddd, h:mm a; day of the week and time
          ll: `${monthFormat} ${dayFormat}${dateTimeDelimiter} ${hourFormat}${timeDelimiter}mm${amPMFormat}`, // MMM Do, h:mm a
          lll: `${monthFormat} ${dayFormat} ${yearFormat}${dateTimeDelimiter} ${hourFormat}${timeDelimiter}mm${amPMFormat}`, // MMM Do YYYY, h:mm a, only used for past so far
        },
      });
    }

    return null;
  }

  themeSettings() {
    const { configs } = this.props;

    const primary = configs.PORTAL_CSS_PRIMARY_1 || '#0072BC';
    const accent = configs.PORTAL_CSS_SECONDARY_1 || '#F49F0A';

    return createTheme({
      palette: {
        type: 'light',
        primary: {
          main: primary,
          contrastColor: getContrastColor(primary),
        },
        secondary: {
          main: accent,
          contrastColor: getContrastColor(accent),
        },
      },
      typography: {
        caption: {
          color: 'rgba(0,0,0,.54)',
          lineHeight: 1.25,
        },
        overline: {
          color: 'rgba(0,0,0,.60)',
        },
      },
      overrides: {
        MuiAutocomplete: {
          inputRoot: {
            padding: '7px !important',
          },
          paper: {
            position: 'relative',
            top: -2,
          },
          listbox: {
            position: 'relative',
            padding: '0px !important',
          },
          groupUl: {
            border: '1px solid red',
          },
        },
        MuiButton: {
          text: {
            color: 'inherit',
          },
          contained: {
            color: 'rgba(0,0,0,.60)',
          },
          textPrimary: {
            color: getContrastColor(primary, true) ? darken(primary, 0.5) : primary,
          },
        },
        MuiCard: {
          root: {
            boxShadow: '0 15px 12px 0 rgba(0,0,0,0.22), 0 19px 38px 0 rgba(0,0,0,0.3)',
            padding: 24,
          },
        },
        MuiContainer: {
          root: {
            boxShadow: '0 15px 12px 0 rgba(0,0,0,0.22), 0 19px 38px 0 rgba(0,0,0,0.3)',
          },
        },
        MuiCardContent: {
          root: {
            textAlign: 'left',
            padding: 0,
            '&:last-child': {
              paddingBottom: 0,
            },
          },
        },
        MuiCardHeader: {
          root: {
            textAlign: 'left',
            padding: 0,
          },
          subheader: {
            paddingTop: 16,
          },
        },
        MuiCircularProgress: {
          root: {
            transition: 'color 500ms',
          },
        },
        MuiFilledInput: {
          root: {
            paddingTop: 2,
            color: '#555',
            fontSize: 14,
          },
          input: {
            paddingTop: 15,
            paddingBottom: 15,
          },
          multiline: {
            paddingTop: 15,
            paddingBottom: 15,
          },
          inputMarginDense: {
            paddingTop: '10px !important',
            paddingBottom: '10px !important',
          },
        },
        MuiFormControl: {
          fullWidth: {
            marginTop: 8,
            marginBottom: 14,
          },
        },
        MuiFormHelperText: {
          contained: {
            margin: '2px 0px -18px 14px',
            lineHeight: '16px',
          },
        },
        MuiMenu: {
          list: {
            paddingRight: '0 !important',
          },
        },
        MuiSelect: {
          filled: {
            marginTop: 1,
            marginBottom: 3,
          },
          selectMenu: {
            paddingBottom: 5,
            paddingTop: 20,
          },
        },
        MuiTypography: {
          caption: {
            color: 'inherit',
            opacity: 0.5,
          },
        },
      },
    });
  }

  addUserModal() {
    const { layout } = this.props;

    if (layout.showVideoGridPage) {
      return <AddUserModal />;
    }
    return null;
  }

  browserSupportMessage() {
    const { layout } = this.props;

    if (layout.showBrowserSupportPage) {
      const BrowserSupportMsg = loadable(() => import('./pages/browser-support-msg/BrowserSupportMsg'));
      return <BrowserSupportMsg />;
    }
    return null;
  }

  confIdForm() {
    const { layout } = this.props;

    if (layout.showConfIdFormPage) {
      const ConfIdForm = loadable(() => import('./pages/conf-id-form/ConfIdForm'));
      return <ConfIdForm />;
    }
    return null;
  }

  confSettings() {
    const { layout } = this.props;

    if (layout.showConfSettingsPage) {
      const ConfSettings = loadable(() => import('./pages/conf-settings/ConfSettings'));
      return (
        <CallMeProvider>
          <ConfSettings />
        </CallMeProvider>
      );
    }
    return null;
  }

  deviceSettings() {
    const { layout } = this.props;

    if (layout.showDeviceSettingsPage) {
      const DeviceSettingsPage = loadable(() => import('./pages/device-settings/DeviceSettingsPage'));
      return <DeviceSettingsPage />;
    }
    return null;
  }

  loader() {
    const { layout } = this.props;

    if (layout.showLoaderPage) {
      return <Loader />;
    }
    return null;
  }

  loginForm() {
    const { layout } = this.props;

    if (layout.showLoginFormPage) {
      const LoginForm = loadable(() => import('./pages/login-form/Login'));
      return <LoginForm />;
    }
    return null;
  }

  landingForm() {
    const { layout } = this.props;

    if (layout.showLandingFormPage) {
      const LandingForm = loadable(() => import('./pages/login-form/Landing'));
      return <LandingForm />;
    }
    return null;
  }

  registerForm() {
    const { layout } = this.props;

    if (layout.showRegisterFormPage) {
      const RegisterForm = loadable(() => import('./pages/register-form/Register'));
      return <RegisterForm />;
    }
    return null;
  }

  registerSuccessForm() {
    const { layout } = this.props;
    if (layout.showRegisterSuccessPage) {
      const RegisterSuccess = loadable(() => import('./pages/register-form/Success'));
      return <RegisterSuccess />;
    }
    return null;
  }

  subscriberSelect() {
    const { layout } = this.props;

    if (layout.showSubscriberSelectPage) {
      const SubscriberSelect = loadable(() => import('./pages/login-form/SubscriberSelect'));
      return <SubscriberSelect {...layout.showSubscriberSelectPage} />;
    }
    return null;
  }

  ssoEnroll() {
    const { layout } = this.props;

    if (layout.showSsoEnrollPage) {
      const SsoEnroll = loadable(() => import('./pages/login-form/SsoEnroll'));
      return <SsoEnroll {...layout.showSsoEnrollPage} />;
    }
    return null;
  }

  meetingPassword() {
    const { layout } = this.props;

    if (layout.showMeetingPasswordPage) {
      const MeetingPassword = loadable(() => import('./pages/meeting-password/MeetingPassword'));
      return <MeetingPassword />;
    }
    return null;
  }

  meetingConfig() {
    const { layout } = this.props;

    if (layout.showMeetingConfig) {
      const MeetingConfig = loadable(() => import('./pages/meeting-config/MeetingConfig'));
      return <MeetingConfig {...layout.showMeetingConfig} />;
    }
    return null;
  }

  meetingEnded() {
    const { layout } = this.props;

    if (layout.showMeetingEndedPage) {
      const MeetingEnded = loadable(() => import('./pages/meeting-ended/MeetingEnded'));
      return <MeetingEnded />;
    }
    return null;
  }

  meetingError() {
    const { layout } = this.props;

    if (layout.showMeetingErrorPage) {
      const MeetingError = loadable(() => import('./pages/meeting-error/MeetingError'));
      return <MeetingError errorId={layout.showMeetingErrorPage} />;
    }
    return null;
  }

  meetingTimeError() {
    const { layout } = this.props;

    if (layout.showMeetingTimeErrorPage) {
      const MeetingTimeError = loadable(() => import('./pages/meeting-time-error/MeetingTimeError'));
      return <MeetingTimeError {...layout.showMeetingTimeErrorPage} />;
    }
    return null;
  }

  meetingHub() {
    const { layout } = this.props;

    if (layout.showMeetingHubPage) {
      const MeetingHub = loadable(() => import('./pages/meeting-hub/MeetingHub'));
      return <MeetingHub tab={layout.showMeetingHubPage || 'meeting'} />;
    }
    return null;
  }

  meetingsScroll() {
    const { layout } = this.props;

    if (layout.showMeetingsScrollPage) {
      const MeetingsScroll = loadable(() => import('./pages/meetings-scroll/MeetingsScroll'));
      return <MeetingsScroll {...layout.showMeetingsScrollPage} />;
    }
    return null;
  }

  mfaForm() {
    const { layout } = this.props;

    if (layout.showMfaFormPage) {
      const MfaForm = loadable(() => import('./pages/login-form/MfaForm'));
      return <MfaForm {...layout.showMfaFormPage} />;
    }
    return null;
  }

  meetingNotFoundPage() {
    const { layout } = this.props;

    if (layout.showMeetingNotFoundPage) {
      const MeetingNotFound = loadable(() => import('./pages/meeting-hub/MeetingNotFound'));
      return <MeetingNotFound />;
    }
    return null;
  }

  ucLicenseMsg() {
    const { layout } = this.props;

    if (layout.showUCLicensePage) {
      const UCLicenseMsg = loadable(() => import('./pages/uc-license-msg/UCLicenseMsg'));
      return <UCLicenseMsg message={layout.showUCLicensePage} />;
    }
    return null;
  }

  videoPage() {
    const { layout } = this.props;

    if (layout.showVideoGridPage) {
      return <Video />;
    }
    return null;
  }

  render() {
    const { translations, usersLang } = this.state;
    const {
      layout, user, configs, miv,
    } = this.props;

    const showTheme = !layout.showVideoGridPage && !layout.showMeetingEndedPage;

    // todo temp solution for header
    const showUserInfo = user && !layout.showVideoGridPage && !layout.showMeetingEndedPage;
    const showAppMenu = configs.PORTAL_VIDEO_APP_MENU || 'no';

    // check for browser notification support and permissions
    const browserSupportsBroNo = 'Notification' in window;
    const needPermission = browserSupportsBroNo && ['granted', 'denied'].includes(Notification.permission) === false;

    if (needPermission && !nsBrowser.getQuery('register')) {
      Notification.requestPermission();
    }

    return (
      <IntlProvider locale={usersLang} messages={translations} onError={(err) => console.warn(err)}>
        <MuiThemeProvider theme={this.themeSettings()}>
          <div className={appStyles.fullHeight}>
            {showTheme && <LoginTheme />}
            {showAppMenu && showUserInfo && <AppMenu />}
            {showUserInfo && <UserInfo />}
            { this.setUpMoment() }
            { this.addUserModal() }
            { this.browserSupportMessage() }
            { this.meetingEnded() }
            { this.meetingError() }
            { this.meetingTimeError() }
            { this.meetingPassword() }
            { this.meetingConfig() }
            { this.meetingsScroll() }
            { this.meetingHub() }
            { this.confIdForm() }
            { this.confSettings() }
            { this.deviceSettings() }
            { this.loader() }
            { this.subscriberSelect() }
            { this.ssoEnroll() }
            { this.mfaForm() }
            { this.loginForm() }
            { this.landingForm() }
            { this.registerForm() }
            { this.registerSuccessForm() }
            { this.meetingNotFoundPage() }
            { this.ucLicenseMsg() }
            { this.videoPage() }
            <SnackBar />
            <AlertDialog />
            <RejectDialog />
            <PendingPermissionsDialog />
            <PermissionsDialog />
            <DeviceReconfigDialog />
            <ScreenshareRequestDialog />
            { miv === 1 && <MivInterface />}
          </div>
        </MuiThemeProvider>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  configs: state.configs,
  layout: state.layout,
  screenShare: state.screenShare,
  user: selectors.selectUser(state),
  miv: selectors.selectMIV(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  processRoute: events.processRoute,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
