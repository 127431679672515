import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import useWindowSize from 'react-use/lib/useWindowSize';
import classnames from 'classnames';
import Grid from '@material-ui/core/Grid';

import VideoItem from '../../video-item/VideoItem';

const useStyles = makeStyles(() => ({
  grid: {
    minWidth: 360,
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'hidden',
    textAlign: 'center',
    transition: 'all 0.3s ease-in-out',
  },
  col5: {
    maxWidth: '20% !important',
  },
  col7: {
    maxWidth: '14.28% !important',
  },
  col8: {
    maxWidth: '12.5% !important',
  },
  col9: {
    maxWidth: '11.11% !important',
  },
  col10: {
    maxWidth: '10% !important',
  },
  col11: {
    maxWidth: '9.09% !important',
  },
  offsetItem: {
    '&:first-of-type': {
      marginLeft: 1,
    },
  },
}));

const ITEM_RATIO = 1.732585;
const gridRectCalcTimeout = [];

const ParticipantsGrid = ({
  alignContent,
  layout,
  participantIds,
  minCols,
}) => {
  const classes = useStyles();
  const { width, height } = useWindowSize();
  const [gridWidth, setGridWidth] = useState(width);
  const [gridHeight, setGridHeight] = useState(height);

  let colCount = Math.sqrt(participantIds.length); // we want a square in most cases

  // prefer rows if grid is too wide
  if (gridWidth > gridHeight * 2) {
    colCount *= gridWidth / (gridHeight * 2);
    if (colCount > participantIds.length) {
      colCount = participantIds.length;
    }
  }

  // prefer cols if grid is too tall
  if (gridHeight > gridWidth / 0.85) {
    colCount = Math.floor(colCount * 0.85);
  }

  // need at least one col
  if (colCount < 1 || participantIds.length === 1) {
    colCount = 1;
  }

  // minCols overrides calculated
  if (minCols > colCount) {
    colCount = minCols;
  }

  colCount = Math.ceil(colCount);

  const rowCount = Math.ceil(participantIds.length / colCount);
  const breakpoint = Math.ceil(12 / colCount); // measure breakpoints out of 12 columns
  const unevenItemCount = (participantIds.length / colCount) % 1 !== 0;

  // don't exceed vertical space -- START
  const itemWidth = gridWidth / colCount;
  const stackHeight = (rowCount * (itemWidth / ITEM_RATIO)) + 8;

  // horizontal padding to apply as grid constraint
  let wPadding = 0;

  // eased curve for squaring reduction based on height ratio
  const paddingCurve = (ratio) => {
    let r = ratio;
    const b = 1; // minimum padding
    const c = 40; // maximum padding
    const d = 3.5; // ratio cap
    return c * ((r = r / d - 1) * r * r + 1) + b; // eslint-disable-line no-return-assign
  };

  // apply wPadding constraining where grid height will exceed stack height
  if (stackHeight > gridHeight) {
    const heightRatio = stackHeight / gridHeight;
    wPadding = paddingCurve(heightRatio - 1);
  }
  // don't exceed vertical space -- END

  const refCallback = (element) => {
    if (element) {
      // there can be multiple grids so we want different timers for different grid sizes
      const calcTimerForGridSize = gridRectCalcTimeout[participantIds.length];

      // clear active timer before setting new one
      if (calcTimerForGridSize) {
        clearTimeout(calcTimerForGridSize);
      }

      gridRectCalcTimeout[participantIds.length] = setTimeout(() => {
        const elementRect = element.getBoundingClientRect();
        setGridWidth(elementRect.width);
        setGridHeight(elementRect.height);
      }, 225); // this timer waits for the sidetray animation before reporting box size
    }
  };

  return (
    <Grid
      container
      wrap="wrap"
      justifyContent="center"
      alignContent={alignContent}
      className={classes.grid}
      ref={refCallback}
      style={{ padding: `0 ${wPadding}%` }}
    >
      {participantIds.map((id) => (
        <Grid
          item
          key={id}
          xs={participantIds.length > 1 ? 6 : 12}
          sm={breakpoint}
          className={
            classnames(
              colCount === 5 && classes.col5,
              colCount === 7 && classes.col7,
              colCount === 8 && classes.col8,
              colCount === 9 && classes.col9,
              colCount === 10 && classes.col10,
              colCount === 11 && classes.col11,
              unevenItemCount && classes.offsetItem,
            )
          }
        >
          <VideoItem participantId={id} layout={layout} />
        </Grid>
      ))}
    </Grid>
  );
};

ParticipantsGrid.defaultProps = {
  alignContent: 'center',
  layout: undefined,
  minCols: 1,
};

ParticipantsGrid.propTypes = {
  alignContent: PropTypes.string,
  layout: PropTypes.string,
  participantIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  minCols: PropTypes.number,
};

export default ParticipantsGrid;
