import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';

import DetectRTC from '@netsapiens/netsapiens-js/dist/web-rtc';
import { ALLOWED_WEBINAR_ROLES } from '../../../constants';
import attendeesShape from '../../../shapes/attendees';
import meetingShape from '../../../shapes/meeting';

import {
  selectMyAttendee,
  selectMeeting,
} from '../../../selectors';

// cam components
import CamDevice from './components/CamDevice';
import CamPreview from './components/CamPreview';

// microphone components
import MicDevice from './components/MicDevice';
import MicSetting from './components/MicSetting';

// output components
import OutputDevice from './components/OutputDevice';
import MicOutputSetting from './components/MicOutputSetting';
import MicInputSetting from './components/MicInputSetting';
import FormMicDevice from './components/FormMicDevice';
import FormCamDevice from './components/FormCamDevice';

const useStyles = makeStyles((theme) => ({
  selectContainer: {
    padding: theme.spacing(2),
    maxWidth: '70% !important',
  },
  mediaRow: {
    marginBottom: theme.spacing(2),
  },
  soloSelectSpeaker: {
    padding: theme.spacing(2),
    maxWidth: '100% !important',
  },
  speakerOnly: {
    marginBottom: theme.spacing(2),
    justifyContent: 'center',
    padding: '5.4px',
  },
}));

const DeviceSettings = ({
  variant,
  myAttendee,
  meeting,
}) => {
  const classes = useStyles();

  return (
    <>
      {variant === 'normal' && (
        <>
          {(meeting.type === 'conference' || ALLOWED_WEBINAR_ROLES.includes(myAttendee.role)) && (
            <>
              <Grid className={classes.mediaRow} container alignItems="center">
                <Grid className={classes.selectContainer} item xs>
                  <CamDevice />
                </Grid>
                <Grid item={false}>
                  <CamPreview />
                </Grid>
              </Grid>
              <Grid className={classes.mediaRow} container alignItems="center">
                <Grid className={classes.selectContainer} item xs>
                  <MicDevice />
                </Grid>
                <Grid item={false}>
                  <MicSetting />
                </Grid>
              </Grid>
            </>
          )}
          {DetectRTC.isSetSinkIdSupported && (
            <Grid className={classes.mediaRow} container alignItems="center">
              <Grid className={classes.selectContainer} item xs>
                <OutputDevice />
              </Grid>
              <Grid item={false}>
                <MicOutputSetting />
              </Grid>
            </Grid>
          )}
        </>
      )}
      {variant === 'form' && (
        <Grid container alignItems="flex-start" justifyContent="center" spacing={2}>
          <Grid item xs={4}>
            <FormMicDevice />
            <MicInputSetting />
          </Grid>
          {DetectRTC.isSetSinkIdSupported && (
            <Grid item xs={4}>
              <OutputDevice />
              <MicOutputSetting variant="text" />
            </Grid>
          )}
          <Grid item xs={4}>
            <FormCamDevice />
          </Grid>
        </Grid>
      )}
      {variant === 'stacked' && (
        <>

          {DetectRTC.isSetSinkIdSupported && (
            <Grid container alignItems="flex-start" justifyContent="center" spacing={2}>
              <Grid className={classes.speakerOnly} container alignItems="center">
                <Grid className={classes.soloSelectSpeaker} item xs>
                  <OutputDevice />
                </Grid>
              </Grid>
              <Grid className={classes.speakerOnly} container alignItems="center">
                <Grid item={false}>
                  <MicOutputSetting />
                </Grid>
              </Grid>
            </Grid>
          )}
          {!DetectRTC.isSetSinkIdSupported && (
            <Grid container alignItems="flex-start" justify="center" spacing={2}>

              <Grid className={classes.speakerOnly} container alignItems="center">
                <Grid item={false}>
                  <MicOutputSetting />
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

DeviceSettings.defaultProps = {
  variant: 'normal',
};

DeviceSettings.propTypes = {
  variant: PropTypes.string,
  myAttendee: attendeesShape.propTypesShape.isRequired,
  meeting: meetingShape.propTypesShape.isRequired,
};

const mapStateToProps = (state) => ({
  myAttendee: selectMyAttendee(state),
  meeting: selectMeeting(state),
});

export default connect(mapStateToProps)(DeviceSettings);
