import checkConf from './checkConf';
import createConf from './createConf';
import getSfu from './getSfu';

export const MISSING_CONF_ID = 'MISSING_CONF_ID';
export const INVALID_CONF_ID = 'INVALID_CONF_ID';

export default ({
  confId,
  domain,
  sub,
  user,
  confInstance,
  meetingType,
  attendeeId,
}) => {
  if (!confId) { return Promise.reject(MISSING_CONF_ID); }

  if (confId.length > 9) { return Promise.reject(INVALID_CONF_ID); }

  const videoBridgeId = `sip:${confId}.video.bridge@conference-bridge`;

  return new Promise((resolve, reject) => {
    checkConf({
      domain,
      videoBridgeId,
    }).then((res) => {
      if (res) {
        getSfu({
          confId,
          confInstance,
          uid: sub,
          attendeeId,
          action: 'create',
        }).then((sfuRes) => {
          resolve(sfuRes);
        }).catch(() => {
          reject(INVALID_CONF_ID);
        });
      } else {
        createConf({
          domain,
          videoBridgeId,
          uid: sub,
          user,
          meetingType,
        }).then(() => {
          getSfu({
            confId,
            confInstance,
            uid: sub,
            attendeeId,
            action: 'create',
          }).then((sfuRes) => {
            resolve(sfuRes);
          }).catch(() => {
            reject(INVALID_CONF_ID);
          });
        }).catch(() => {
          reject(MISSING_CONF_ID);
        });
      }
    });
  });
};
