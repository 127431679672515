import PropTypes from 'prop-types';

const shape = {
  audioInputDevice: PropTypes.shape({}),
  audioOutputDevice: PropTypes.shape({}),
  audioMuted: PropTypes.bool,
  audioStream: PropTypes.shape({}),
  hasCamDevice: PropTypes.bool,
  hasCamPermissions: PropTypes.bool,
  hasMicDevice: PropTypes.bool,
  hasMicPermissions: PropTypes.bool,
  screenShareMuted: PropTypes.bool,
  screenShareStream: PropTypes.shape({}),
  userId: PropTypes.string,
  videoAspectRatio: PropTypes.string, // "4:3" or "16:9"
  videoDevice: PropTypes.shape({}),
  videoMuted: PropTypes.bool,
  videoStream: PropTypes.shape({}),
};

const propTypesShape = PropTypes.shape(shape);

export default {
  shape,
  propTypesShape,
  objectOf: PropTypes.objectOf(propTypesShape),
};
