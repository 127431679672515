export const PARTIAL_MEETING_UPDATE = 'events/PARTIAL_MEETING_UPDATE';

/**
 * @param payload
 * @returns {{payload: *, type: string}}
 */
export const partialMeetingUpdate = (payload) => ({
  type: PARTIAL_MEETING_UPDATE,
  payload,
});
