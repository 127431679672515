import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useWindowSize from 'react-use/lib/useWindowSize';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { gravatarSessionId } from '../../../../../utils';

import * as selectors from '../../../../../selectors';
import store from '../../../../../store';
import getInitials from '../../../../../utils/getInitials';
import { LAYOUT_TYPE_CONVERSATION, LAYOUT_TYPE_PICTURE } from '../../../../../constants';

const useStyles = makeStyles(() => ({
  centerContainer: {
    margin: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gravatarImg: {
    borderRadius: '50%',
    width: '100%',
    height: '100%',
  },
  gravatarInitials: {
    display: 'table',
    position: 'absolute',
    backgroundColor: '#b9b9b9',
    borderRadius: '50%',
    zIndex: -1,
    '& span': {
      display: 'table-cell',
      verticalAlign: 'middle',
      textAlign: 'center',
    },
  },
  isAudible: {
    transition: 'box-shadow 1s ease-in-out',
    borderRadius: '50%',
    boxShadow: '0 0 4px 0 #999, 0 0 4px 2px #999',
  },
  isActive: {
    borderRadius: '50%',
    boxShadow: '0 0 4px 0 #1A73E8, 0 0 4px 4px #1A73E8',
  },
}));

const GravatarItem = ({
  layout,
  participantId,
}) => {
  const classes = useStyles();
  const ref = useRef();
  const { width } = useWindowSize();

  const element = ref?.current?.getBoundingClientRect();
  const itemWidth = element?.width || width;
  const gravatarSize = layout === LAYOUT_TYPE_CONVERSATION ? itemWidth * 0.04 : itemWidth * 0.1;

  const [attendee, setAttendee] = useState({});

  const participant = useSelector((state) => selectors.selectParticipant(state, participantId));
  const speakerActivity = useSelector((state) => selectors.selectSpeakerActivity(
    state,
    participantId,
  ));

  if (!participant) { return null; }

  const mediaStatus = participant.direction === 'outbound'
    ? useSelector((state) => selectors.selectUserMediaStatus(state))
    : useSelector((state) => selectors.selectMediaStatus(state, _.get(participant, 'userId')));

  let audioMuted = false;
  ({ audioMuted } = mediaStatus || {
    // default value if the media status isn't set for the participant yet
    audioMuted: true,
  });

  useEffect(() => {
    const attendees = selectors.selectAttendeesList(store.getState());
    let match = _.find(attendees, { uid: participant.uid });
    if (!match && participant.userId) {
      match = _.find(attendees, (a) => `guest_${a.attendee_id}` === participant.userId);
    }
    setAttendee(match);
  }, [participant.id]);

  const gravatar = participant.gravatar || _.get(attendee, 'gravatar');
  const initials = participant.initials || getInitials(_.get(attendee, 'name', ''));
  const isActive = layout !== LAYOUT_TYPE_PICTURE && !audioMuted && (_.get(speakerActivity, 'isActive'));
  const isAudible = layout !== LAYOUT_TYPE_PICTURE && !audioMuted && (_.get(speakerActivity, 'isAudible'));

  return (
    <div className={classes.centerContainer}>
      <div
        style={{ width: gravatarSize, height: gravatarSize, fontSize: gravatarSize / 2 }}
        className={
          classnames(
            classes.gravatarInitials,
            isAudible && !isActive && classes.isAudible,
            isActive && classes.isActive,
          )
        }
      >
        <span>{initials}</span>
      </div>
      <div>
        {gravatar && (
        <img
          id="gravatarImg"
          src={`https://www.gravatar.com/avatar/${gravatar}?s=150&d=blank&hash=${gravatarSessionId}`}
          style={{ width: gravatarSize, height: gravatarSize, fontSize: gravatarSize / 2 }}
          className={
            classnames(
              classes.gravatarImg,
              isAudible && !isActive && classes.isAudible,
              isActive && classes.isActive,
            )
          }
          alt=""
        />
        )}
      </div>
    </div>
  );
};

React.memo(GravatarItem);

GravatarItem.defaultProps = {
  layout: null,
};

GravatarItem.propTypes = {
  layout: PropTypes.string,
  participantId: PropTypes.string.isRequired,
};

export default GravatarItem;
