import {
  LAYOUT_TYPE_GRID,
  VIDEO_DYNAMIC_SPEAKER,
  VIDEO_TEMP_DYNAMIC_SPEAKER,
  VIDEO_DYNAMIC_SPEAKER_COOLDOWN,
  VIDEO_DYNAMIC_SPEAKER_COOLDOWN_SFU,
  MUTED_NOTIFY,
  MUTED_HINTS,
  VIDEO_GRID,
  TEMP_VIDEO_GRID,
  VIDEO_LAYOUT,
  ROTATE_ORIENTATION,
  LAYOUT_ROTATE_VERT,
  VIDEO_PARTICIPANTS,
  VIDEO_TRAY_OPEN,
  VIDEO_MUTE_HIDE,
  VIDEO_HIDE_SELF,
  VIDEO_ALL_STATS,
} from '../constants';

const defaultState = {
  dynamicSpeaker: true,
  dynamicTempSpeaker: '',
  dynamicSpeakerCooldown: '',
  dynamicSpeakerCooldownSFU: '',
  mutedNotify: false,
  grid: [],
  tempGrid: [],
  layout: LAYOUT_TYPE_GRID,
  participants: {},
  orientation: LAYOUT_ROTATE_VERT,
  trayOpen: true,
  muteHide: false,
  hideSelf: false,
  allStats: false,
};

const reducers = {
  [VIDEO_DYNAMIC_SPEAKER]: (state, { payload }) => ({ dynamicSpeaker: payload }),
  [VIDEO_TEMP_DYNAMIC_SPEAKER]: (state, { payload }) => ({ dynamicTempSpeaker: payload }),
  [VIDEO_DYNAMIC_SPEAKER_COOLDOWN]: (state, { payload }) => ({ dynamicSpeakerCooldown: payload }),
  [VIDEO_DYNAMIC_SPEAKER_COOLDOWN_SFU]: (state, { payload }) => ({ dynamicSpeakerCooldownSFU: payload }), // eslint-disable-line max-len
  [MUTED_NOTIFY]: (state, { payload }) => ({ mutedNotify: payload }), // eslint-disable-line max-len
  [MUTED_HINTS]: (state, { payload }) => ({ mutedHints: payload }), // eslint-disable-line max-len
  [VIDEO_GRID]: (state, { payload }) => ({ grid: payload }),
  [TEMP_VIDEO_GRID]: (state, { payload }) => ({ tempGrid: payload }),
  [VIDEO_LAYOUT]: (state, { payload }) => ({ layout: payload }),
  [ROTATE_ORIENTATION]: (state, { payload }) => ({ orientation: payload }),
  [VIDEO_PARTICIPANTS]: (state, { payload }) => ({ participants: payload }),
  [VIDEO_TRAY_OPEN]: (state, { payload }) => ({ trayOpen: payload }),
  [VIDEO_MUTE_HIDE]: (state, { payload }) => ({ muteHide: payload }),
  [VIDEO_HIDE_SELF]: (state, { payload }) => ({ hideSelf: payload }),
  [VIDEO_ALL_STATS]: (state, { payload }) => ({ allStats: payload }),
};

export default {
  defaultState,
  reducers,
};
