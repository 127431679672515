import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/index';
import classnames from 'classnames';

import { LAYOUT_TYPE_CONVERSATION } from '../../../../constants';

const RATIO_H2W_PERCENTAGE = (9 / 16) * 100;
const RATIO_W2H_PERCENTAGE = (17.77 / 9) * 100;

const useStyles = makeStyles(() => ({
  box: (props) => ({
    margin: props.isMiv && props.isSelf ? '6px 3px 6px 6px' : '6px;',
    flex: '1 0 auto',
    position: 'relative',
    overflow: 'hidden',
    transition: 'box-shadow 0.2s ease-in-out',
    paddingTop: props.isMiv ? `calc(${props.isSelf ? RATIO_W2H_PERCENTAGE : '100'}% - 8px)` : `calc(${RATIO_H2W_PERCENTAGE}% - 8px)`,
    backgroundColor: props.backgroundColor,
    borderRadius: props.isMiv ? '16px' : '8px',
    // boxShadow: '0 0 6px 0 #212121, 0 0 6px 6px #212121',
    '&::after': {
      content: '',
      float: 'left',
      display: 'block',
      paddingTop: '100%',
    },
  }),
  conversationBox: {
    paddingTop: `calc(${RATIO_H2W_PERCENTAGE}% - 16px) !important`,
  },
  mobileCropBox: {
    paddingTop: 'calc(100% - 8px) !important',
  },
  mobileSelfBox: {
    paddingTop: 'calc(100% - 8px) !important',
  },
  isAudible: {
    transition: 'box-shadow 1s ease-in-out',
    boxShadow: '0 0 6px 0 #999, 0 0 6px 2px #999',
  },
  isActive: {
    boxShadow: '0 0 6px 0 #1A73E8, 0 0 6px 6px #1A73E8',
  },
  isScreenShare: {
    boxShadow: '0 0 6px 0 #00C853, 0 0 6px 6px #00C853',
  },
}));

const BorderContainer = ({
  children,
  isActive,
  isAudible,
  isShare,
  isMiv,
  isSelf,
  bgColor,
  layout,
  videoMuted,
  type,
}) => {
  // This should help avoid the color poppup up during quick flashes.
  if (!videoMuted) bgColor = '#212121'; // eslint-disable-line no-param-reassign
  const classes = useStyles({ backgroundColor: bgColor, isMiv, isSelf });
  if (!type) {
    return null;
  }

  return (
    <div
      className={
        classnames(
          classes.box,
          isAudible && !isActive && classes.isAudible,
          isActive && classes.isActive,
          isShare && classes.isScreenShare,
          layout === LAYOUT_TYPE_CONVERSATION && classes.conversationBox,
        )
      }
    >
      {children}
    </div>
  );
};

BorderContainer.defaultProps = {
  isActive: false,
  isAudible: false,
  isShare: false,
  isMiv: false,
  isSelf: false,
  bgColor: '#212121',
  layout: null,
  videoMuted: false,
  type: null,
};

BorderContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
    PropTypes.bool,
  ]).isRequired,
  isActive: PropTypes.bool,
  isAudible: PropTypes.bool,
  isShare: PropTypes.bool,
  isMiv: PropTypes.bool,
  isSelf: PropTypes.bool,
  layout: PropTypes.string,
  videoMuted: PropTypes.bool,
  bgColor: PropTypes.string,
  type: PropTypes.string,
};

export default React.memo(BorderContainer);
