import { put, select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import * as actions from '../../actions';
import { userAuthenticated } from '../../events';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* authenticateGuest({ payload }) {
  try {
    yield put(actions.showLoaderPage());
    const apiHash = yield select((state) => state.configs.apiHash);

    const guestPost = {
      guest: payload.guest,
      confId: payload.confId,
      apiHash,
      scope: 'video',
      guestEmail: payload.guestEmail,
    };

    if (payload.attId) {
      guestPost.scope = 'video_attid';
      guestPost.guest = `${payload.attId}|||${payload.guest}`;
    }

    yield nsApi.videoGuestLogin(guestPost);
    const decodedToken = nsToken.getDecoded();

    yield put(actions.loginFormErrorMsg(null));
    yield put(actions.setConfig({ guest: true }));

    if (guestPost.guestEmail) {
      yield put(actions.setConfig({ guestEmail: guestPost.guestEmail }));
    }

    if (guestPost.guest) {
      yield put(actions.setConfig({ guestName: guestPost.guest }));
    }

    const confId = yield select((state) => state.configs.confId);
    if (!confId) {
      yield put(actions.setConfig({ confId: payload.confId }));
    }

    yield put(actions.setConfig({ attendeeId: decodedToken.attendee_id }));

    if (!nsBrowser.getQuery('id')) {
      nsBrowser.setQuery({ name: 'id', value: payload.confId });
    }

    yield put(userAuthenticated());
  } catch (err) {
    if (nsApi.NO_CONF === err) {
      yield put(actions.loginFormErrorMsg('CONFERENCE_ID_INVALID'));
    }
    yield put(actions.showLoginFormPage());

    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.severity = 'info';
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: authenticateGuest';
      event.addMetadata('payload', payload);
    });
  }
}
