import React from 'react';
import { connect } from 'react-redux';
import { put } from 'redux-saga/effects';
import { defineMessages, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core';

import * as actions from '../../../../actions';
import * as events from '../../../../events';
import * as selectors from '../../../../selectors';
import meetingShape from '../../../../shapes/meeting';
import attendeesShape from '../../../../shapes/attendees';

import cardButtonsStyle from '../../../styles/cardButtons';
import CountdownTimer from './CountdownTimer';

const messages = defineMessages({
  MEETING_NOT_STARTED_TITLE: {
    id: 'MEETING_NOT_STARTED_TITLE',
    defaultMessage: 'The meeting has not started yet',
  },
  MEETING_NOT_STARTED_TEXT: {
    id: 'MEETING_NOT_STARTED_TEXT',
    defaultMessage: 'Only Hosts and Presenters can see each other.',
  },
  MEETING_START_RECORDING_START: {
    id: 'MEETING_START_RECORDING_START',
    defaultMessage: 'Recording will start with the meeting.',
  },
  START_NOW: {
    id: 'START_NOW',
    defaultMessage: 'Start Now',
  },
});

const useStyles = makeStyles((theme) => ({
  ...cardButtonsStyle(theme),
  cardContainer: {
    position: 'absolute',
    textAlign: 'center',
    margin: '100px auto',
    width: '100%',
    zIndex: 3,
  },
  header: {
    marginBottom: 1,
  },
  startControlCard: {
    width: 344,
    display: 'inline-block',
  },
  counterTimer: {

  },
}));

const StartControl = ({
  meeting,
  postMeetingStatusEvent,
  updateNcsParticipantEvent,
  attendees,
}) => {
  const classes = useStyles();

  const startMeeting = () => {
    updateNcsParticipantEvent('leader', () => {
      postMeetingStatusEvent('started');
      put(actions.snackBarMessage('MEETING_STARTED'));
    });

    attendees.forEach((attendee) => {
      if (attendee.audio_id && (attendee.role === 'host' || attendee.role === 'presenter')) {
        updateNcsParticipantEvent('leader', () => {}, attendee.audio_id);
      }
    });
  };

  return (
    <div className={classes.cardContainer}>
      <Card className={classes.startControlCard}>
        <CardHeader
          title={<FormattedMessage {...messages.MEETING_NOT_STARTED_TITLE} />}
          className={classes.header}
        />

        <CardContent>
          <Typography variant="body2">
            <FormattedMessage {...messages.MEETING_NOT_STARTED_TEXT} />
          </Typography>

          {
            meeting.record_enabled
              ? (
                <Typography variant="body2">
                  <FormattedMessage {...messages.MEETING_START_RECORDING_START} />
                </Typography>
              ) : null
          }

          <div className={classes.counterTimer}>
            <CountdownTimer variant="small" startTime={meeting.start_instance || meeting.start} meeting={meeting} />
          </div>

          <Button
            id="StartControlStartNew"
            type="submit"
            color="primary"
            variant="contained"
            className={classes.cardButtonPrimary}
            onClick={startMeeting}
          >
            <FormattedMessage {...messages.START_NOW} />
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

StartControl.propTypes = {
  postMeetingStatusEvent: PropTypes.func.isRequired,
  updateNcsParticipantEvent: PropTypes.func.isRequired,
  meeting: meetingShape.propTypesShape.isRequired,
  attendees: attendeesShape.arrayOfShapes.isRequired,
};

const mapStateToProps = (state) => ({
  meeting: selectors.selectMeeting(state),
  attendees: selectors.selectAttendeesList(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  postMeetingStatusEvent: events.postMeetingStatus,
  updateNcsParticipantEvent: events.updateNcsParticipant,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StartControl);
