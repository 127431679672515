import nsApi from '@netsapiens/netsapiens-js/dist/api';
import bugsnagClient from '../bugsnag';

export default ({
  domain,
  user,
  videoBridgeId,
  participantMatch,
}) => {
  nsApi.post({
    object: 'conference',
    action: 'disc',
    domain,
    user,
    conference_match: `sip:${videoBridgeId}.video.bridge@conference-bridge`,
    participant_match: participantMatch,
  }).catch((err) => {
    console.error(err);
    bugsnagClient.notify(err);
  });
};
