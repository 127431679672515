import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core';

import ScreenShare from '@material-ui/icons/ScreenShare';
import StopScreenShare from '@material-ui/icons/StopScreenShare';

import { ALLOWED_WEBINAR_ROLES } from '../../../../../constants';
import * as actions from '../../../../../actions';
import * as events from '../../../../../events';
import * as selectors from '../../../../../selectors';

import attendeesShape from '../../../../../shapes/attendees';
import meetingShape from '../../../../../shapes/meeting';
import mediaStatusShape from '../../../../../shapes/mediaStatus';
import screenShareShape from '../../../../../shapes/screenShare';

const messages = defineMessages({
  SCREEN_SHARE: {
    id: 'SCREEN_SHARE',
    defaultMessage: 'Screen Share',
  },
  STOP_SCREEN_SHARE: {
    id: 'STOP_SCREEN_SHARE',
    defaultMessage: 'Stop sharing',
  },
  SCREEN_SHARING_DISABLED_FOR_DEVICE: {
    id: 'SCREEN_SHARING_DISABLED_FOR_DEVICE',
    defaultMessage: 'Screen sharing is disabled on this Browser',
  },
  SCREEN_SHARING_DISABLED_BY_HOST: {
    id: 'SCREEN_SHARING_DISABLED_BY_HOST',
    defaultMessage: 'Screen share disabled by host',
  },
  SCREEN_SHARING_DISABLED_ALERT_CONTENT: {
    id: 'SCREEN_SHARING_DISABLED_ALERT_CONTENT',
    defaultMessage: 'You won\'t be able to share your screen until a host enables your screen share.',
  },
  SCREEN_SHARING_IN_USE: {
    id: 'SCREEN_SHARING_IN_USE',
    defaultMessage: 'Screenshare is already in use',
  },
  SCREEN_SHARING_BLOCKED: {
    id: 'SCREEN_SHARING_BLOCKED',
    defaultMessage: 'Screenshare is blocked',
  },
  SCREEN_SHARING_BLOCKED_CONTENT: {
    id: 'SCREEN_SHARING_BLOCKED_CONTENT',
    defaultMessage: 'Screenshare is blocked by your browser. Click the blocked media icon in your browser\'s address bar to allow screenshare use and then refresh',
  },
  SCREEN_SHARING_IN_USE_ALERT_CONTENT: {
    id: 'SCREEN_SHARING_IN_USE_ALERT_CONTENT',
    defaultMessage: 'Someone else has control of screenshare. Please wait until they\'re finished before sharing screen.',
  },
});

const useStyles = makeStyles(() => ({
  activeShare: {
    color: '#00C853 !important',
    backgroundColor: '#fff !important',
    border: '2px solid #00c853',
    margin: '-1px 3px',
  },
}));

const ScreenShareToggle = ({
  // inherited
  variant,
  classes,

  // stateful
  configs,
  meeting,
  myAttendee,
  screenShare,
  mediaStatus,

  // dispatch
  endScreenShareAction,
  openAlertDialog,
  startScreenShareAction,
}) => {
  const localClasses = useStyles();

  const { videoPhoneNumber } = configs;
  const { isSharing, isSelf, isBlocked } = screenShare;
  const screenShareBlocked = isBlocked || (isSharing && !isSelf);
  const screenShareActive = mediaStatus.screenShareStream
    && !mediaStatus.screenShareMuted
    && mediaStatus.screenShareStream.active;

  let screenShareStyle = classes.btn;
  if (screenShareActive || isSharing) {
    if (screenShareActive || isSelf) {
      screenShareStyle = localClasses.activeShare;
    } else {
      screenShareStyle = classes.activeBtn;
    }
  }

  const screenShareIconStyle = !isSharing ? classes.icon : classes.activeIcon;

  let isVideoPermitted = true;
  switch (meeting.video_share) {
    case 'host':
      isVideoPermitted = myAttendee && myAttendee.role === 'host';
      break;
    case 'presenters':
      isVideoPermitted = myAttendee && ALLOWED_WEBINAR_ROLES.includes(myAttendee.role);
      break;
    default:
      break;
  }

  const isScreenShareRestricted = !myAttendee
    || !isVideoPermitted
    || Boolean(myAttendee.screenshare_restricted);

  const isBrowserSupported = nsBrowser.name === 'Chrome' || nsBrowser.name === 'Firefox';

  const getTooltip = () => {
    let tooltipText = <FormattedMessage {...messages.SCREEN_SHARE} />;

    if (screenShareActive || (isSharing && isSelf)) {
      tooltipText = <FormattedMessage {...messages.STOP_SCREEN_SHARE} />;
    }

    if (!isBrowserSupported) {
      tooltipText = <FormattedMessage {...messages.SCREEN_SHARING_DISABLED_FOR_DEVICE} />;
    }

    if (isScreenShareRestricted) {
      tooltipText = <FormattedMessage {...messages.SCREEN_SHARING_DISABLED_BY_HOST} />;
    }

    if (screenShareBlocked) {
      tooltipText = <FormattedMessage {...messages.SCREEN_SHARING_BLOCKED_CONTENT} />;
    }

    return tooltipText;
  };

  const notifyRestriction = () => {
    openAlertDialog({
      title: <FormattedMessage {...messages.SCREEN_SHARING_DISABLED_BY_HOST} />,
      content: <FormattedMessage {...messages.SCREEN_SHARING_DISABLED_ALERT_CONTENT} />,
    });
  };

  const notifyScreenShareBlocked = () => {
    openAlertDialog({
      title: <FormattedMessage {...messages.SCREEN_SHARING_BLOCKED} />,
      content: <FormattedMessage {...messages.SCREEN_SHARING_BLOCKED_CONTENT} />,
    });
  };

  let onClickAction = () => {
    if (screenShareActive || (screenShare.isSharing && screenShare.isSelf)) {
      endScreenShareAction();
    } else if (!screenShareBlocked) {
      startScreenShareAction();
    }
  };

  if (screenShareBlocked) {
    onClickAction = notifyScreenShareBlocked;
  } else if (!isBrowserSupported || isScreenShareRestricted) {
    onClickAction = notifyRestriction;
  }

  return videoPhoneNumber !== 'no' ? (
    <Tooltip title={getTooltip()}>
      <IconButton
        id="ScreenShareToggle"
        data-restricted={isScreenShareRestricted || screenShareBlocked}
        data-sharing={isSharing}
        onClick={onClickAction}
        className={
          classnames(
            screenShareIconStyle,
            screenShareStyle,
            (!isBrowserSupported || isScreenShareRestricted) && classes.restrictedBtn,
            variant === 'small' && classes.btnSmall,
          )
        }
      >
        {isSharing ? <StopScreenShare /> : <ScreenShare />}
      </IconButton>
    </Tooltip>
  ) : '';
};

ScreenShareToggle.defaultProps = {
  variant: null,
};

ScreenShareToggle.propTypes = {
  variant: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  classes: PropTypes.shape({
    activeBtn: PropTypes.string,
    activeIcon: PropTypes.string,
    btn: PropTypes.string,
    btnSmall: PropTypes.string,
    icon: PropTypes.string,
    restrictedBtn: PropTypes.string,
  }).isRequired,
  myAttendee: attendeesShape.propTypesShape.isRequired,
  configs: PropTypes.shape({
    videoPhoneNumber: PropTypes.string,
  }).isRequired,
  mediaStatus: mediaStatusShape.propTypesShape.isRequired,
  meeting: meetingShape.propTypesShape.isRequired,
  screenShare: screenShareShape.propTypesShape.isRequired,
  endScreenShareAction: PropTypes.func.isRequired,
  openAlertDialog: PropTypes.func.isRequired,
  startScreenShareAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  configs: state.configs,
  mediaStatus: selectors.selectUserMediaStatus(state),
  meeting: selectors.selectMeeting(state),
  myAttendee: selectors.selectMyAttendee(state),
  screenShare: state.screenShare,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  endScreenShareAction: events.endScreenShare,
  openAlertDialog: actions.openAlertDialog,
  startScreenShareAction: events.startScreenShare,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ScreenShareToggle);
