export const END_AUDIO_BRIDGE = 'events/END_AUDIO_BRIDGE';

/**
 * @param participantMatch
 * @return {{type: string}}
 */
export const endAudioBridge = (participantMatch) => ({
  type: END_AUDIO_BRIDGE,
  payload: participantMatch,
});
