import _ from 'lodash';
import { put, select, call } from 'redux-saga/effects';

import {
  selectParticipants,
  selectVideoGridIds,
  selectAttendeesList,
  selectMeeting,
  selectMIV,
} from '../../selectors';
import { setVideoGrid, setVideoParticipants } from '../../actions';
import fetchGravatarAvg from '../../models/services/fetchGravatarAvg';
import { ALLOWED_WEBINAR_ROLES } from '../../constants';
import makeParticipant from '../../models/makeParticipant';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* addParticipant({ payload }) {
  try {
    const participant = makeParticipant(payload);
    const meeting = yield select(selectMeeting);
    const isMobileView = yield select(selectMIV);

    // get proper displayName from attendees
    if ((typeof participant.displayName === 'undefined' || participant.displayName === '')
      && typeof existingAttendee !== 'undefined'
    ) {
      const attendees = yield select(selectAttendeesList);
      const existingAttendee = _.find(attendees, { uid: participant.uid });
      participant.displayName = existingAttendee.name; // eslint-disable-line no-param-reassign
    }

    if (typeof participant.audioId === 'undefined' || participant.audioId === null) {
      const attendees = yield select(selectAttendeesList);
      const existingAttendee = _.find(attendees, { uid: participant.uid });
      if (typeof existingAttendee !== 'undefined'
        && typeof existingAttendee.audio_id !== 'undefined') {
        participant.audioId = existingAttendee.audio_id; // eslint-disable-line no-param-reassign
        if (existingAttendee.gravatar) {
          participant.gravatar = existingAttendee.gravatar; // eslint-disable-line no-param-reassign
        }
      }

      // check for guest, needs audio_id for themselves
      if (participant.id && participant.id.includes('video_guest_')) {
        const guestId = participant.id.replace('video_guest_', '');
        const existingGAttendee = _.find(attendees, { attendee_id: guestId });
        if (typeof existingGAttendee !== 'undefined'
          && typeof existingGAttendee.audio_id !== 'undefined') {
          participant.audioId = existingGAttendee.audio_id; // eslint-disable-line no-param-reassign
          if (existingGAttendee.gravatar) {
            participant.gravatar = existingGAttendee.gravatar; // eslint-disable-line no-param-reassign, max-len
          }
        }
        participant.bgColor = yield call(fetchGravatarAvg, { gravatar: participant.gravatar || null }); // eslint-disable-line no-param-reassign, max-len
      }
    }

    if (participant.type !== 'screenShare' && (typeof participant.bgColor === 'undefined' || participant.bgColor === '' || participant.bgColor === '#212121')) {
      participant.bgColor = yield call(fetchGravatarAvg, { gravatar: participant.gravatar }); // eslint-disable-line no-param-reassign, max-len
    }

    let participants = yield select(selectParticipants);
    // match video and audio participants to each other if the userid's match
    Object.keys(participants).forEach((id) => {
      if (participants[id].userId === participant.userId
        && participant.type === 'call'
      ) {
        participants[id].audioPart = participant;
        participant.videoPart = participants[id]; // eslint-disable-line no-param-reassign
      } else if (participants[id].userId === participant.userId
        && participant.type === 'video'
      ) {
        // should remove this participant from grid as well
        participants[id].videoPart = participant;
        participant.audioPart = participants[id]; // eslint-disable-line no-param-reassign
      }
    });

    participants = {
      ...participants,
      [participant.id]: participant,
    };
    yield put(setVideoParticipants(participants));

    const attendees = yield select(selectAttendeesList);
    if (participant.type !== 'call') {
      let attendee = _.find(attendees, { uid: participant.uid });
      if (!attendee && participant.userId) {
        attendee = _.find(attendees, (a) => `guest_${a.attendee_id}` === participant.userId);
      }

      const gridIds = yield select(selectVideoGridIds);

      // only show the grid if the participants do not have a videopart
      // (no duplicate audio only participants in the grid)
      let array;

      // if new participant is a screenshare, put it at the front of the grid array
      // and put the participant's video second
      if (participant.type === 'screenShare') {
        const userIdMatch = participant.userId;
        // eslint-disable-next-line max-len
        const participantVideo = gridIds.find((partId) => participants[partId].userId === userIdMatch);

        array = [
          participant.id,
          ...(participantVideo ? [participantVideo] : []),
          ...gridIds.filter((partId) => participants[partId].userId !== userIdMatch),
        ];
      } else if (meeting.type === 'presentation' && _.get(attendee, 'role')
        && ALLOWED_WEBINAR_ROLES.includes(_.get(attendee, 'role'))) {
        // if the participant is the presenter put them first
        if (meeting.presenter === _.get(attendee, 'attendee_id')) {
          // keep the screenshare first if someone is sharing
          const firstId = _.get(gridIds, '0');
          const type = _.get(participants[firstId], 'type');
          if (type === 'screenShare') {
            array = [
              ...gridIds.slice(0, 1),
              participant.id,
              ...gridIds.slice(1),
            ];
          } else {
            // add the participant first
            array = [participant.id, ...gridIds];
          }
        } else {
          // add them after the presenter
          array = [
            ...gridIds.slice(0, 1),
            participant.id,
            ...gridIds.slice(1),
          ];
        }
      } else {
        array = [...gridIds, participant.id];
      }

      let filteredGrid = _.filter(array, (partId) => participants[partId].type !== 'call');

      if (isMobileView === 1) {
        filteredGrid = _.filter(filteredGrid, (partId) => !participants[partId].isSelf);
      }
      yield put(setVideoGrid(_.uniq(filteredGrid)));
    }
  } catch (e) {
    console.error(e);
    bugsnagClient.notify(e, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: addParticipant';
      event.addMetadata('payload', payload);
    });
  }
}
