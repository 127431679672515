import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import { CONTACTS } from '../constants';

const defaultState = {};

const reducers = {
  [CONTACTS]: (state, { payload = [] }) => {
    const contacts = nsUtils.normalizeList(
      'id',
      payload,
      [
        { name: 'names', path: 'name', toLower: true },
        { name: 'users', path: 'user' },
        { name: 'phones', path: 'user' },
        { name: 'phones', path: 'cell_phone' },
        { name: 'phones', path: 'home_phone' },
        { name: 'phones', path: 'work_phone' },
      ],
    );
    return { ...contacts, contacts: payload };
  },
};

export default {
  defaultState,
  reducers,
};
