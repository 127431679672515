import nsApi from '@netsapiens/netsapiens-js/dist/api';

import bugsnagClient from '../bugsnag';

export default ({
  domain,
  user,
  sessionName,
  attendeeId,
}) => new Promise((resolve) => {
  nsApi.get({
    object: 'messagesession',
    action: 'read',
    domain,
    user,
    session_name: sessionName,
    attendee_id: attendeeId,
  }).then((messagesessions) => {
    resolve(messagesessions);
  }).catch((err) => {
    console.error(err);
    bugsnagClient.notify(err);
    resolve([]);
  });
});
