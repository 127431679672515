import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { createTheme, makeStyles } from '@material-ui/core';

import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';

import { LAYOUT_TYPE_CONVERSATION, LAYOUT_TYPE_GRID, LAYOUT_TYPE_SPOTLIGHT } from '../../../../../constants';
import {
  selectDynamicSpeaker,
  selectVideoLayout,
} from '../../../../../selectors';

import * as actions from '../../../../../actions';
import * as events from '../../../../../events';

import conversationImage from '../../../../../assets/images/conversation-layout-sm.svg';
import gridImage from '../../../../../assets/images/grid-layout-sm.svg';
import spotlightImage from '../../../../../assets/images/spotlight-layout-sm.svg';

const messages = defineMessages({
  LAYOUT_SPOTLIGHT: {
    id: 'LAYOUT_SPOTLIGHT',
    defaultMessage: 'Spotlight layout',
  },
  LAYOUT_CONVERSATION: {
    id: 'LAYOUT_CONVERSATION',
    defaultMessage: 'Conversation layout',
  },
  LAYOUT_GRID: {
    id: 'LAYOUT_GRID',
    defaultMessage: 'Grid layout',
  },
  LAYOUT_SPEAKER_DETECTION_TOGGLE: {
    id: 'LAYOUT_SPEAKER_DETECTION_TOGGLE',
    defaultMessage: 'Automatically move and enlarge speaking people',
  },
});

const useStyles = makeStyles(() => ({
  selectContainer: {
    marginLeft: 2,
    display: 'inline-block',
    backgroundColor: 'rgba(0, 0, 0, 0.54) !important',
    borderRadius: 50,
    '& img': {
      top: 7,
      position: 'relative',
      filter: 'invert(.9)',
    },
  },
  selectSubContainer: {
    backgroundColor: 'rgba(255, 255, 255, 0.2) !important',
    overflow: 'visible',
    borderRadius: 50,
    height: 42,
    top: 4,
    marginLeft: 4,
    position: 'relative',
    display: 'inline-block',
    marginRight: 4,
  },
  subBtn: {
    top: -4,
    position: 'relative',
    display: 'inline-block',
    '&:hover': {
      backgroundColor: 'rgba(140, 140, 140, 1) !important',
    },
  },
  layoutSelected: {
    backgroundColor: '#fff !important',
    '& img': {
      filter: 'invert(0)',
    },
    '&:hover': {
      backgroundColor: '#aaa !important',
    },
  },
  dynamicSpeakerBtn: {
    width: 24,
    height: 24,
    top: -2,
    left: -2,
    position: 'relative',
    backgroundColor: 'rgba(20, 20, 20, 1)',
    '& svg': {
      fontSize: '1rem !important',
    },
    '&:hover': {
      backgroundColor: 'rgba(140, 140, 140, 1) !important',
    },
  },
  detectionActive: {
    backgroundColor: '#5699FF !important',
    '&:hover': {
      backgroundColor: '#5699FF !important',
    },
    '& img': {
      filter: 'invert(.9)',
    },
  },
}));

const btnOverride = createTheme({
  overrides: {
    MuiButton: {
      root: {
        minWidth: 50,
        margin: '0 !important',
        marginRight: '4px !important',
        backgroundColor: 'rgba(0, 0, 0, 0) !important',
        border: '1px solid transparent !important',
        transition: '500ms all ease-in-out',
        borderRadius: '50%',
        '&:hover': {
          backgroundColor: 'rgba(40, 40, 40, 1) !important',
        },
      },
      label: {
        display: 'inline-block',
        width: 30,
        height: 36,
      },
    },
  },
});

const LayoutSelect = ({
  // inherited
  variant,
  classes,

  // dispatch
  setLayoutAction,
  setVideoDynamicSpeakerAction,

  // stateful
  dynamicSpeaker,
  activeLayout,
}) => {
  const localClasses = useStyles();
  const intl = useIntl();

  const toggleSpeakerDetection = () => {
    if (!dynamicSpeaker && activeLayout === LAYOUT_TYPE_GRID) {
      setLayoutAction({ layout: LAYOUT_TYPE_CONVERSATION });
    }

    setVideoDynamicSpeakerAction(!dynamicSpeaker);
  };

  return (
    <div className={localClasses.selectContainer}>
      <ThemeProvider theme={btnOverride}>
        <Tooltip title={<FormattedMessage {...messages.LAYOUT_GRID} />}>
          <Button
            id="LayoutSelectGrid"
            type="button"
            size={variant !== 'small' ? 'medium' : 'small'}
            onClick={() => setLayoutAction({ layout: LAYOUT_TYPE_GRID })}
            className={classnames(
              classes.icon,
              { [localClasses.layoutSelected]: activeLayout === LAYOUT_TYPE_GRID },
            )}
          >
            <img
              src={gridImage}
              alt={intl.formatMessage(messages.LAYOUT_GRID)}
            />
          </Button>
        </Tooltip>

        <div className={localClasses.selectSubContainer}>
          <Tooltip title={<FormattedMessage {...messages.LAYOUT_SPOTLIGHT} />}>
            <Button
              id="LayoutSelectSpotlight"
              type="button"
              size={variant !== 'small' ? 'medium' : 'small'}
              onClick={() => setLayoutAction({ layout: LAYOUT_TYPE_SPOTLIGHT })}
              className={classnames(
                classes.icon,
                localClasses.subBtn,
                variant === 'small' && classes.btnSmall,
                { [localClasses.layoutSelected]: activeLayout === LAYOUT_TYPE_SPOTLIGHT },
              )}
            >
              <img
                src={spotlightImage}
                alt={intl.formatMessage(messages.LAYOUT_SPOTLIGHT)}
              />
            </Button>
          </Tooltip>

          <Tooltip title={<FormattedMessage {...messages.LAYOUT_CONVERSATION} />}>
            <Button
              id="LayoutSelectConversation"
              type="button"
              size={variant !== 'small' ? 'medium' : 'small'}
              onClick={() => setLayoutAction({ layout: LAYOUT_TYPE_CONVERSATION })}
              className={classnames(
                classes.icon,
                localClasses.subBtn,
                variant === 'small' && classes.btnSmall,
                { [localClasses.layoutSelected]: activeLayout === LAYOUT_TYPE_CONVERSATION },
              )}
            >
              <img
                src={conversationImage}
                alt={intl.formatMessage(messages.LAYOUT_CONVERSATION)}
              />
            </Button>
          </Tooltip>

          <Tooltip title={<FormattedMessage {...messages.LAYOUT_SPEAKER_DETECTION_TOGGLE} />}>
            <IconButton
              id="LayoutSelect"
              className={classnames(
                classes.icon,
                classes.btnSmall,
                localClasses.dynamicSpeakerBtn, {
                  [localClasses.detectionActive]:
                  dynamicSpeaker && activeLayout !== LAYOUT_TYPE_GRID,
                },
              )}
              onClick={() => toggleSpeakerDetection()}
            >
              <RecordVoiceOverIcon />
            </IconButton>
          </Tooltip>
        </div>
      </ThemeProvider>
    </div>
  );
};

LayoutSelect.defaultProps = {
  variant: null,
};

LayoutSelect.propTypes = {
  variant: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  classes: PropTypes.shape({
    btn: PropTypes.string,
    btnSmall: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
  setLayoutAction: PropTypes.func.isRequired,
  setVideoDynamicSpeakerAction: PropTypes.func.isRequired,
  dynamicSpeaker: PropTypes.bool.isRequired,
  activeLayout: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  dynamicSpeaker: selectDynamicSpeaker(state),
  activeLayout: selectVideoLayout(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setLayoutAction: events.setLayout,
  setVideoDynamicSpeakerAction: actions.setVideoDynamicSpeaker,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LayoutSelect);
