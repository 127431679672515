/* eslint-disable */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';

import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { makeStyles } from '@material-ui/core';

import ChangeRoleControl from './ChangeRoleControl';
import KickControl from './KickControl';
import MessageControl from './MessageControl';
import ScreenShareControl from './ScreenShareControl';
import StatsControl from './StatsControl';
import HideSelfControl from './HideSelfControl';
import StopScreenShareControl from './StopScreenShareControl';
import TransferPresentationControl from './TransferPresentationControl';

import attendeesShape from '../../../../shapes/attendees';
import meetingShape from '../../../../shapes/meeting';
import participantShape from '../../../../shapes/participant';

import * as events from '../../../../events';
import * as selectors from '../../../../selectors';

const useStyles = makeStyles(() => ({
  centered: {
    top: '50%',
    transform: 'translate(0, -50%)',
    color: 'inherit',
  },
  contained: {
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 0, 0, 0.54) !important',
  },
  right: {
    textAlign: 'right',
    display: 'block',
  },
}));

const ControlsMenu = ({
  attendee,
  centered,
  variant,
  meeting,
  myAttendee,
  participant,
  configs,
}) => {
  if (!attendee) return null; // I have no idea how attendee is ever null here, but it is

  // hide menu for attendees in presentations
  if (meeting.type === 'presentation' && myAttendee.role === 'attendee') {
    return '';
  }

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const iconButtonVariant = variant === 'horizontal' ? 'contained' : 'text';

  const statsOverlayAllowed = configs.PORTAL_VIDEO_ALLOW_STAT_OVERLAY === 'yes';

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <IconButton
        id="ControlsMenuToggleMenu"
        className={
          classnames(
            centered && classes.centered,
            iconButtonVariant === 'contained' && classes.contained,
          )
        }
        onClick={handleClick}
        onClose={handleClose}
      >
        {variant === 'horizontal' ? <MoreHorizIcon /> : <MoreVertIcon />}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MessageControl
          type="menuItem"
          variant={iconButtonVariant}
          attendee={attendee}
          handleClose={handleClose}
        />

        <TransferPresentationControl
          type="menuItem"
          variant={iconButtonVariant}
          attendee={attendee}
          handleClose={handleClose}
        />

        {participant && participant.type === 'screenShare' && (
          <StopScreenShareControl
            type="menuItem"
            variant={iconButtonVariant}
            attendee={attendee}
            handleClose={handleClose}
          />
        )}

        <ScreenShareControl
          type="menuItem"
          variant={iconButtonVariant}
          attendee={attendee}
          handleClose={handleClose}
        />

        {statsOverlayAllowed && participant && (
          <StatsControl
            type="menuItem"
            variant={iconButtonVariant}
            participant={participant}
            handleClose={handleClose}
          />
        )}

        {participant && participant.isSelf && (
          <HideSelfControl
            type="menuItem"
            variant={iconButtonVariant}
            handleClose={handleClose}
          />
        )}

        {myAttendee && myAttendee.role === 'host' && (
          <ChangeRoleControl
            attendee={attendee}
            handleClose={handleClose}
          />
        )}

        <KickControl
          type="menuItem"
          variant={iconButtonVariant}
          attendee={attendee}
          handleClose={handleClose}
        />
      </Menu>
    </Fragment>
  );
};

ControlsMenu.defaultProps = {
  centered: false,
  participant: null,
  variant: 'vertical',
};

ControlsMenu.propTypes = {
  attendee: attendeesShape.propTypesShape.isRequired,
  centered: PropTypes.bool,
  variant: PropTypes.string,
  meeting: meetingShape.propTypesShape.isRequired,
  myAttendee: attendeesShape.propTypesShape.isRequired,
  participant: participantShape.propTypesShape,
};

const mapStateToProps = state => ({
  meeting: selectors.selectMeeting(state),
  myAttendee: selectors.selectMyAttendee(state),
  configs: state.configs,
});

export default connect(mapStateToProps)(ControlsMenu);
