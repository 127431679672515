import { select, put } from 'redux-saga/effects';
import * as actions from '../../actions';
import * as selectors from '../../selectors';

import store from '../../store';

// eslint-disable-next-line import/prefer-default-export
export function* startScreenShare() {
  const room = yield select((state) => state.videoRoom.room);

  if (room) {
    room.shareScreen();
  } else {
    try {
      const stream = yield navigator.mediaDevices.getDisplayMedia({
        audio: false,
        video: {
          displaySurface: 'monitor',
          logicalSurface: true,
          cursor: true,
          width: { max: 1920 },
          height: { max: 1080 },
          frame: { max: 5 },
        },
      });

      if (!stream) {
        console.error('enableShare() | Stream is null');
        return;
      }

      const mediaStatus = yield select(selectors.selectUserMediaStatus);
      mediaStatus.screenShareMuted = false;
      mediaStatus.screenShareStream = stream;
      yield put(actions.updateMediaStatus(mediaStatus));
    } catch (error) {
      console.error(`enableShare() | failed: ${error.code} ${error.name} ${error.message}`);
      if (error.name === 'NotAllowedError' && error.message !== 'Permission denied') {
        store.dispatch(actions.isScreenShareBlocked(true));
        yield put(actions.openAlertDialog({
          title: 'SCREEN_SHARING_BLOCKED',
          content: 'SCREEN_SHARING_BLOCKED_CONTENT',
          caption: '',
        }));
      }
    }
  }
}
