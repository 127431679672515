import { select } from 'redux-saga/effects';
import * as selectors from '../../selectors';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* postWebkitAction({ payload }) {
  try {
    const isMobileView = yield select(selectors.selectMIV);
    console.log('postWebkitAction', payload);

    if (isMobileView !== 1) {
      return;
    }

    if (!window.webkit) {
      console.error('Missing WEBKIT');
      return;
    }

    const message = { action: payload.action, data: payload.data };
    window.webkit.messageHandlers.nsWebkitAction.postMessage(JSON.stringify(message));
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: postWebkitAction';
      event.addMetadata('payload', payload);
    });
  }
}
