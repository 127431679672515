// import React from 'react';
import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import useMousetrap from 'react-hook-mousetrap';
import classnames from 'classnames';

import { makeStyles } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import InfoIcon from '@material-ui/icons/Info';

import * as actions from '../../../../../actions';
import * as events from '../../../../../events';
import * as selectors from '../../../../../selectors';
import store from '../../../../../store';

import { ALLOWED_WEBINAR_ROLES } from '../../../../../constants';

const useStyles = makeStyles(() => ({
  info: {
    backgroundColor: '#1976d2 !important',
  },
  hidden: {
    display: 'none',
  },
  show: {
    display: 'block',
  },
}));

const messages = defineMessages({
  MIC_DISABLED_BY_HOST: {
    id: 'MIC_DISABLED_BY_HOST',
    defaultMessage: 'Microphone disabled by host',
  },
  MIC_DISABLED_ALERT_CONTENT: {
    id: 'MIC_DISABLED_ALERT_CONTENT',
    defaultMessage: "You won't be able to speak until a host enables your microphone.",
  },
  MIC_NOT_DETECTED_TITLE: {
    id: 'MIC_NOT_DETECTED_TITLE',
    defaultMessage: 'We could not detect a microphone',
  },
  MIC_NOT_DETECTED_BODY: {
    id: 'MIC_NOT_DETECTED_BODY',
    defaultMessage: 'Make sure you have a microphone plugged into your computer and rejoin the meeting.',
  },
  TEMPORARILY_MUTED: {
    id: 'TEMPORARILY_MUTED',
    defaultMessage: 'Temporarily muted.',
  },
  TEMPORARILY_UNMUTED: {
    id: 'TEMPORARILY_UNMUTED',
    defaultMessage: 'Temporarily unmuted.',
  },
});

const HotKeyPage = ({
  // dispatch
  openAlertDialog,
  toggleAudioMuteAction,
  snackBar,
  setMutedHintsAction,
}) => {
  const classes = useStyles();

  const onToggleAudioMute = () => {
    const mediaStatus2 = selectors.selectUserMediaStatus(store.getState());

    if (!mediaStatus2.hasMicDevice) {
      const title = <FormattedMessage {...messages.MIC_NOT_DETECTED_TITLE} />;
      const content = <FormattedMessage {...messages.MIC_NOT_DETECTED_BODY} />;
      openAlertDialog({ title, content });
    } else {
      toggleAudioMuteAction();
    }
  };

  const notifyRestriction = () => {
    const title = <FormattedMessage {...messages.MIC_DISABLED_BY_HOST} />;
    const content = <FormattedMessage {...messages.MIC_DISABLED_ALERT_CONTENT} />;
    openAlertDialog({ title, content });
  };

  const notifyTempMuted = () => {
    snackBar({
      message: 'MIC_TURN_OFF',
      type: 'info',
    });
  };

  const notifyTempUnmuted = () => {
    snackBar({
      message: 'MIC_TURN_ON',
      type: 'info',
    });
  };

  const showMuteToggleHint = () => {
    const alreadyHinted = selectors.selectMutedHints(store.getState());
    if (typeof alreadyHinted === 'undefined' || !alreadyHinted) {
      snackBar({
        message: 'MUTE_HINT',
        type: null,
        action: {
          label: 'DO_NOT_SHOW_AGAIN',
          action: () => {
            // set local storage for dismiss mute hint
            localStorage.setItem('dismissMuteHint', true);
          },
        },
      });
      setMutedHintsAction(true);
    }
  };

  const getIsAudiorestricted = () => {
    const myAttendee = selectors.selectMyAttendee(store.getState());
    const meeting = selectors.selectMeeting(store.getState());

    let isAudioPermitted = true;
    switch (meeting.audio_share) {
      case 'host':
        isAudioPermitted = myAttendee && myAttendee.role === 'host';
        break;
      case 'presenters':
        isAudioPermitted = myAttendee && ALLOWED_WEBINAR_ROLES.includes(myAttendee.role);
        break;
      default:
        break;
    }

    const isAudioRestricted = !myAttendee
    || !isAudioPermitted
    || Boolean(myAttendee.audio_restricted);

    return isAudioRestricted;
  };

  let isPressingSpace = false;
  let toggledOnce = false;
  // if the boolean isPressingSpace = true, then do nothing (the user is just holding space)
  useMousetrap('space space', // any mousetrap combo, or array of combo
    () => { // triggered on key combo
      if (!isPressingSpace) {
        const isAudioRestricted = getIsAudiorestricted();
        if (!isAudioRestricted) {
          // display temporary muted or unmuted notification
          onToggleAudioMute();
          const mediaStatusAfter = selectors.selectUserMediaStatus(store.getState());
          if (mediaStatusAfter.audioMuted
              || !mediaStatusAfter.hasMicDevice
              || !mediaStatusAfter.hasMicPermissions) {
            // new mediaStatus is muted, show temp muted message
            notifyTempMuted();
          } else {
            // new mediaStatus is unmuted, show temp muted message
            notifyTempUnmuted();
          }
        } else {
          notifyRestriction();
        }
      }
    });

  const keyPressHandler = (event) => {
    // do nothing if the focus is on the messageInputField (chat)
    const focused = document.activeElement;
    if (focused.id === 'messageInputField') {
      return;
    }

    if (event.code === 'Space') {
      event.preventDefault();

      if (isPressingSpace) {
        // is holding space already, should unmute
        if (!toggledOnce) {
          toggledOnce = true;
          const isAudioRestricted = getIsAudiorestricted();
          if (!isAudioRestricted) {
            // show that the muted state notification here
            onToggleAudioMute();
            const mediaStatusAfter = selectors.selectUserMediaStatus(store.getState());
            if (mediaStatusAfter.audioMuted
                || !mediaStatusAfter.hasMicDevice
                || !mediaStatusAfter.hasMicPermissions) {
              // new mediaStatus is muted, show temp muted message
              const mutedSnackBar = document.getElementById('mutedSnackBar');
              mutedSnackBar.style.display = 'block';
            } else {
              // new mediaStatus is unmuted, show temp muted message
              const unMutedSnackBar = document.getElementById('unMutedSnackBar');
              unMutedSnackBar.style.display = 'block';
            }
          } else {
            notifyRestriction();
          }
        }
      } else {
        isPressingSpace = true;
      }
    }
  };

  // set a boolean isPressingSpace back to false when releasing space
  const keyUpHandler = (event) => {
    // do nothing if the focus is on the messageInputField (chat)
    const focused = document.activeElement;
    if (focused.id === 'messageInputField') {
      return;
    }
    const isAudioRestricted = getIsAudiorestricted();

    if (event.code === 'Space') {
      event.preventDefault();
      if (isPressingSpace && toggledOnce) {
        // was holding space, should unmute
        if (!isAudioRestricted) {
          // hide the temporarily muted state card
          onToggleAudioMute();
        } else {
          notifyRestriction();
        }
      }
      isPressingSpace = false;
      toggledOnce = false;

      // hide all holding snackbars
      const mutedSnackBar = document.getElementById('mutedSnackBar');
      const unMutedSnackBar = document.getElementById('unMutedSnackBar');
      mutedSnackBar.style.display = 'none';
      unMutedSnackBar.style.display = 'none';
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', keyPressHandler);
    window.addEventListener('keyup', keyUpHandler);

    // check local storage if dismiss notification hint is set, then do not show
    if (localStorage.getItem('dismissMuteHint') !== 'true') {
      showMuteToggleHint();
    }

    return () => {
      window.removeEventListener('keydown', keyPressHandler);
      window.removeEventListener('keyup', keyUpHandler);
    };
  });

  const snackBarOpen = true;

  return (
    <>
      <Snackbar
        id="mutedSnackBar"
        open={snackBarOpen}
        className={classnames(classes.hidden)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <SnackbarContent
          className={classes.info}
          message={(
            <span>
              <InfoIcon style={{ float: 'left', marginRight: 12 }} />
              <FormattedMessage {...messages.TEMPORARILY_MUTED} />
            </span>
          )}
        />
      </Snackbar>

      <Snackbar
        id="unMutedSnackBar"
        open={snackBarOpen}
        className={classnames(classes.hidden)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <SnackbarContent
          className={classes.info}
          message={(
            <span>
              <InfoIcon style={{ float: 'left', marginRight: 12 }} />
              <FormattedMessage {...messages.TEMPORARILY_UNMUTED} />
            </span>
          )}
        />
      </Snackbar>
    </>
  );
};

HotKeyPage.defaultProps = {
};

HotKeyPage.propTypes = {
  openAlertDialog: PropTypes.func.isRequired,
  toggleAudioMuteAction: PropTypes.func.isRequired,
  snackBar: PropTypes.func.isRequired,
  setMutedHintsAction: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  openAlertDialog: actions.openAlertDialog,
  toggleAudioMuteAction: events.toggleAudioMute,
  snackBar: actions.snackBar,
  setMutedHintsAction: actions.setMutedHints,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HotKeyPage);
