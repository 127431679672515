import _ from 'lodash';
import { put, select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';

import * as actions from '../../actions';
import {
  ssoLoginUser,
} from '../../events';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* ssoEnrollSubmit({ payload }) {
  const apiHash = yield select((state) => state.configs.apiHash);

  const {
    idtoken, vendor, username, password,
  } = payload;

  try {
    const params = {
      username,
      password,
      idtoken,
      vendor,
      apiHash,
    };

    yield nsApi.ssoEnroll(params);
    yield put(actions.loginFormErrorMsg(null));
    // nothing exploded so we should be able to shunt over to the ssoLogin
    yield put(ssoLoginUser(idtoken, vendor, username));
  } catch (err) {
    const errorMsg = err.message && err.message.includes('409') ? 'DOMAIN_SSO_DISABLED' : 'ENROLLMENT_FAILED';
    yield put(actions.loginFormErrorMsg(errorMsg));

    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: ssoEnrollSubmit';
      event.addMetadata('payload', _.omit(payload, 'password'));
      event.addMetadata('errorMsg', errorMsg);
    });
  }
}
