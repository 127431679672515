import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';

import * as events from '../../../../events';
import * as selectors from '../../../../selectors';
import meetingShape from '../../../../shapes/meeting';

import CountdownTimer from './CountdownTimer';

const messages = defineMessages({
  LAYOUT_MODAL_TITLE: {
    id: 'LAYOUT_MODAL_TITLE',
    defaultMessage: 'Choose a layout',
  },
  CONFERENCE: {
    id: 'CONFERENCE',
    defaultMessage: 'Conference',
  },
  THE_CONFERENCE: {
    id: 'THE_CONFERENCE',
    defaultMessage: 'The conference',
  },
  WEBINAR: {
    id: 'WEBINAR',
    defaultMessage: 'Webinar',
  },
  PRESENTATION: { // neds to stay here.
    id: 'PRESENTATION',
    defaultMessage: 'Webinar',
  },
  THE_WEBINAR: {
    id: 'THE_WEBINAR',
    defaultMessage: 'The webinar',
  },
  WAITING_TO_START_TEXT: {
    id: 'WAITING_TO_START_TEXT',
    defaultMessage: 'has not started yet. Please wait until a host starts the meeting.',
  },
  LEAVE: {
    id: 'LEAVE',
    defaultMessage: 'Leave',
  },
});

const WaitingToStartModal = ({
  meeting,
  showMeetingHubPageEvent,
}) => {
  const meetingName = meeting.name
    ? meeting.name
    : <FormattedMessage {...messages[meeting.type.toUpperCase()]} />;

  return (
    <Dialog open>
      <DialogTitle style={{ width: 344 }}>
        {meetingName}
      </DialogTitle>

      <DialogContent style={{ width: 344 }}>
        <DialogContentText>
          <Typography variant="body2">
            {meeting.type === 'presentation'
              ? <FormattedMessage {...messages.THE_WEBINAR} />
              : <FormattedMessage {...messages.THE_CONFERENCE} />}
            &nbsp;
            <FormattedMessage {...messages.WAITING_TO_START_TEXT} />
          </Typography>

          <div style={{ textAlign: 'center' }}>
            <CountdownTimer
              variant="big"
              startTime={meeting.start_instance || meeting.start}
              meeting={meeting}
            />
          </div>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button id="WaitingToStartModalLeave" onClick={() => showMeetingHubPageEvent()} color="primary" autoFocus>
          <FormattedMessage {...messages.LEAVE} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

WaitingToStartModal.propTypes = {
  meeting: meetingShape.propTypesShape.isRequired,
  showMeetingHubPageEvent: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  meeting: selectors.selectMeeting(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showMeetingHubPageEvent: events.showMeetingHubPage,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WaitingToStartModal);
