export const UPDATE_ACTIVE_STATUS = 'events/UPDATE_ACTIVE_STATUS';

/**
 *
 * @param res
 * @return {{type: string, payload: *}}
 */
export const updateActiveStatus = (res) => ({
  type: UPDATE_ACTIVE_STATUS,
  payload: res,
});
