export default (theme) => ({
  card: {
    maxWidth: 548,
    backgroundColor: '#FAFAFA',
  },
  cardHeader: {
    textAlign: 'left',
    padding: theme.spacing(0, 3, 3, 3),
  },
  cardActions: {
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 0, 0, 0),
  },
});
