export const SUBSCRIBER_SELECT = 'events/SUBSCRIBER_SELECT';

/**
 * @param subscriberList
 * @return {{type: string}}
 */
export const subscriberSelect = (subscriberList) => ({
  type: SUBSCRIBER_SELECT,
  payload: { subscriberList },
});
