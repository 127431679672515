import { put, select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import * as selectors from '../../selectors';
import bugsnagClient from '../../bugsnag';
import { setMeeting } from '../../actions';

// eslint-disable-next-line import/prefer-default-export
export function* postMeetingStatus({ payload }) {
  try {
    const user = yield select(selectors.selectUser);
    const meeting = yield select(selectors.selectMeeting);

    yield nsApi.post({
      id: meeting.id,
      user: user.userId || '',
      domain: user.domain,
      object: 'meeting',
      action: 'update',
      status: payload,
    });

    yield put(setMeeting({
      ...meeting,
      status: payload,
    }));
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: postMeetingStatus';
      event.addMetadata('payload', payload);
    });
  }
}
