import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';

import useMediaDevices from 'react-use/lib/useMediaDevices';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import mediaStatusShape from '../../../../shapes/mediaStatus';

import { updateMediaStatus } from '../../../../actions';
import {
  getAudioStream,
  getDefaultAudioInput,
  getFilteredAudioInput,
  stopStream,
} from '../../../../utils/devices';
import {
  selectUserMediaStatus,
} from '../../../../selectors';

const messages = defineMessages({
  DEVICE_SETTINGS_TITLE: {
    id: 'DEVICE_SETTINGS_TITLE',
    defaultMessage: 'Device settings',
  },
  DONE: {
    id: 'DONE',
    defaultMessage: 'done',
  },
  MIC_INPUT_LABEL: {
    id: 'MIC_INPUT_LABEL',
    defaultMessage: 'Microphone input',
  },
  NO_DEVICES: {
    id: 'NO_DEVICES',
    defaultMessage: 'No devices detected',
  },
  BLOCKED_BY_BROWSER: {
    id: 'BLOCKED_BY_BROWSER',
    defaultMessage: 'Blocked by browser',
  },
  MIC_DISABLED_TOOLTIP: {
    id: 'MIC_DISABLED_TOOLTIP',
    defaultMessage: 'Your microphone is blocked by your browser. Click the blocked media icon in your browser\'s address bar to allow microphone use and then refresh',
  },
});

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth: 240,
  },
}));

const MicDevice = ({
  mediaStatus,
  updateMediaStatusAction,
}) => {
  const { devices } = useMediaDevices();
  const classes = useStyles();

  const {
    audioInputDevice,
    audioInputDeviceSelect,
    audioStreamSelect,
    hasMicDevice,
    hasMicPermissions,
  } = mediaStatus;

  const [devicesInit, setDevicesInit] = useState(false);

  const [defaultAudioInput, setDefaultAudioInput] = useState(null);
  const [audioInputDevices, setAudioInputDevices] = useState(null);

  const selectedAudioInput = audioInputDeviceSelect || audioInputDevice;
  const setSelectedAudioInput = (selectedDevice) => {
    updateMediaStatusAction({
      userId: mediaStatus.userId,
      audioInputDeviceSelect: selectedDevice,
    });
  };

  // initialize devices
  useEffect(() => {
    if (devicesInit && devices) {
      const filteredDefaultAudioInput = getDefaultAudioInput(devices);
      const filteredAudioInputs = getFilteredAudioInput(devices);
      setDefaultAudioInput(filteredDefaultAudioInput);
      setAudioInputDevices(filteredAudioInputs);
      setSelectedAudioInput(audioInputDevice);
    } else if (devices) {
      setDevicesInit(true);
    }
  }, [devices, devicesInit]);

  // check if selected audio input device is still connected
  useEffect(() => {
    if (audioInputDevices && selectedAudioInput) {
      const filter = {};
      if (selectedAudioInput.groupId) {
        filter.groupId = selectedAudioInput.groupId;
      } else {
        filter.deviceId = selectedAudioInput.deviceId;
      }
      const stillExists = !!_.filter(audioInputDevices, filter).length;

      if (!stillExists) {
        setSelectedAudioInput(null);
      }
    }
  }, [devices, audioInputDevices, selectedAudioInput]);

  // initialize and handle audio device changes
  useEffect(() => {
    if (devices && selectedAudioInput && hasMicPermissions !== false) {
      if (audioStreamSelect) {
        stopStream(audioStreamSelect);
        updateMediaStatusAction({
          userId: mediaStatus.userId,
          audioStreamSelect: null,
        });
      }

      getAudioStream(_.get(selectedAudioInput, 'deviceId')).then((stream) => {
        updateMediaStatusAction({
          userId: mediaStatus.userId,
          audioStreamSelect: stream,
        });
      });
    }
  }, [selectedAudioInput, devices]);

  const handleChange = (setter, filter, localDevices) => (e) => {
    const id = e.target.value;
    const match = _.find(filter(localDevices), { groupId: id });
    if (match) {
      setter(match);
    } else {
      setter(_.find(filter(localDevices), { deviceId: id }));
    }
  };

  const selectedInput = _.get(selectedAudioInput, 'groupId')
    || _.get(selectedAudioInput, 'deviceId')
    || _.get(defaultAudioInput, 'groupId')
    || _.get(defaultAudioInput, 'deviceId')
    || '';
  const [openTT, setOpen] = useState(false);
  const handleClick = () => { setOpen((prev) => !prev); };
  const handleClickAway = () => { setOpen(false); };

  return (
    <>
      <InputLabel id="mic_input">
        <FormattedMessage {...messages.MIC_INPUT_LABEL} />
      </InputLabel>
      {hasMicDevice && hasMicPermissions !== false && (
        <TextField
          select
          id="MicDeviceMicInput"
          labelid="mic_input"
          variant="filled"
          margin="dense"
          value={selectedInput}
          onChange={handleChange(
            setSelectedAudioInput,
            getFilteredAudioInput,
            audioInputDevices,
          )}
          fullWidth
        >
          {!audioInputDevices && (
            <MenuItem value={1}>
              <FormattedMessage {...messages.NO_DEVICES} />
            </MenuItem>
          )}
          {audioInputDevices && audioInputDevices.map((device) => (
            <MenuItem
              key={device.groupId || device.deviceId}
              value={device.groupId || device.deviceId}
            >
              {device.label}
            </MenuItem>
          ))}
        </TextField>
      )}
      {(!hasMicDevice || hasMicPermissions === false) && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Tooltip
            title={<FormattedMessage {...messages.MIC_DISABLED_TOOLTIP} />}
            classes={{ tooltip: classes.customWidth }}
            open={openTT}
          >
            <TextField
              select
              onClick={handleClick}
              labelid="mic_input"
              variant="filled"
              margin="dense"
              value={1}
              fullWidth
              disabled
            >
              {!hasMicDevice && (
              <MenuItem value={1}>
                <FormattedMessage {...messages.NO_DEVICES} />
              </MenuItem>
              )}
              {!hasMicPermissions && (
              <MenuItem value={1}>
                <FormattedMessage {...messages.BLOCKED_BY_BROWSER} />
              </MenuItem>
              )}
            </TextField>
          </Tooltip>
        </ClickAwayListener>
      )}
    </>
  );
};

MicDevice.propTypes = {
  mediaStatus: mediaStatusShape.propTypesShape.isRequired,
  updateMediaStatusAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  mediaStatus: selectUserMediaStatus(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateMediaStatusAction: updateMediaStatus,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MicDevice);
