import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core';

import FiberManualRecord from '@material-ui/icons/FiberManualRecord';

import * as actions from '../../../../../actions';
import * as events from '../../../../../events';
import * as selectors from '../../../../../selectors';
import meetingShape from '../../../../../shapes/meeting';

const messages = defineMessages({
  NOT_RECORDING: {
    id: 'NOT_RECORDING',
    defaultMessage: 'Not Recording',
  },
  IS_RECORDING: {
    id: 'IS_RECORDING',
    defaultMessage: 'Recording',
  },
  IS_RECORDING_LABEL: {
    id: 'IS_RECORDING_LABEL',
    defaultMessage: 'This meeting is being recorded.',
  },
  STOP_RECORDING: {
    id: 'STOP_RECORDING',
    defaultMessage: 'Stop Recording',
  },
  STOP_RECORDING_BODY: {
    id: 'STOP_RECORDING_BODY',
    defaultMessage: 'Are you you want to stop recording this meeting?',
  },
  CANCEL: {
    id: 'CANCEL',
    defaultMessage: 'Cancel',
  },
  STOP: {
    id: 'STOP',
    defaultMessage: 'Stop',
  },
});

const useStyles = makeStyles(() => ({
  dialogContent: {
    width: 222,
  },
  recordingBadge: {
    transition: '500ms all ease-in-out',
    color: '#232323',
    margin: '0 4px 0 0',
    padding: '0 8px',
    backgroundColor: 'rgba(0, 0, 0, 0.54) !important',
    borderRadius: '30px',
    maxWidth: 200,
    height: 46,
  },
  recordingText: {
    fontSize: 13,
    margin: '-2px 6px 0 2px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  noEditStyle: {
    cursor: 'default',
  },
  recordingDot: {
    animation: '$dot-pulse 5s infinite',
  },
  '@keyframes dot-pulse': {
    '0%': {
      color: '#f00',
    },
    '50%': {
      color: '#a00',
    },
    '100%': {
      color: '#f00',
    },
  },
}));

const RecordingIndicator = ({
  // inherited
  variant,
  classes,

  // stateful
  configs,
  meeting,

  // dispatch
  snackBarMessage,
  partialMeetingUpdateAction,
}) => {
  const localClasses = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  const stopRecording = () => {
    snackBarMessage('HOST_STOPPED_RECORD');
    partialMeetingUpdateAction({ record_enabled: 0 });
    setDialogOpen(false);
  };

  const { videoAllowRecording } = configs;
  const isRecording = meeting.record_enabled && videoAllowRecording === 'yes';

  if (!isRecording) {
    return '';
  }

  return (
    <>
      <Tooltip title={meeting.record_enabled
        ? <FormattedMessage {...messages.IS_RECORDING_LABEL} />
        : <FormattedMessage {...messages.NOT_RECORDING} />}
      >
        <IconButton
          id="RecordingIndicator"
          onClick={() => meeting.iCanEdit && setDialogOpen(true)}
          className={classnames(
            classes.icon,
            variant === 'small' && classes.btn,
            variant === 'small' && classes.btnSmall,
            variant !== 'small' && localClasses.recordingBadge,
            !meeting.iCanEdit && localClasses.noEditStyle,
          )}
        >
          <FiberManualRecord className={localClasses.recordingDot} />
          { variant !== 'small' && (
          <span className={localClasses.recordingText}>
            {meeting.record_enabled
              ? <FormattedMessage {...messages.IS_RECORDING} />
              : <FormattedMessage {...messages.NOT_RECORDING} />}
          </span>
          )}
        </IconButton>
      </Tooltip>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle>
          <FormattedMessage {...messages.STOP_RECORDING} />
        </DialogTitle>

        <DialogContent className={localClasses.dialogContent}>
          <DialogContentText>
            <FormattedMessage {...messages.STOP_RECORDING_BODY} />
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button id="StopRecordingDialogCancel" onClick={() => setDialogOpen(false)} color="primary">
            <FormattedMessage {...messages.CANCEL} />
          </Button>

          <Button id="StopRecordingDialogConfirm" onClick={stopRecording} color="primary" autoFocus>
            <FormattedMessage {...messages.STOP} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

RecordingIndicator.defaultProps = {
  variant: null,
};

RecordingIndicator.propTypes = {
  variant: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  classes: PropTypes.shape({
    icon: PropTypes.string,
    btn: PropTypes.string,
    btnSmall: PropTypes.string,
  }).isRequired,
  configs: PropTypes.shape({
    videoAllowRecording: PropTypes.string,
  }).isRequired,
  meeting: meetingShape.propTypesShape.isRequired,
  snackBarMessage: PropTypes.func.isRequired,
  partialMeetingUpdateAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  configs: state.configs,
  meeting: selectors.selectMeeting(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  snackBarMessage: actions.snackBarMessage,
  partialMeetingUpdateAction: events.partialMeetingUpdate,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RecordingIndicator);
