import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import * as selectors from '../../selectors';

import LoginImage from './LoginImage';
import Logo from './Logo';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
}));

const LoginTheme = ({
  showBgImage,
}) => {
  const classes = useStyles();
  const [animState, setAnimState] = useState();

  useEffect(() => {
    if (showBgImage === true && animState !== 'animate__fadeIn') {
      setAnimState('animate__fadeIn');
    }
    if (showBgImage === false && animState === 'animate__fadeIn') {
      setAnimState('animate__fadeOut');
    }
  });

  return (
    <div
      className={
        classnames(
          `animate__animated ${animState}`,
          classes.root,
        )
      }
      style={{
        animationDuration: '2s',
        opacity: 0,
      }}
    >
      <LoginImage />
      <Logo />
    </div>
  );
};

LoginTheme.defaultProps = {
  showBgImage: null,
};

LoginTheme.propTypes = {
  showBgImage: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  showBgImage: selectors.showBgImage(state),
});

export default connect(mapStateToProps)(LoginTheme);
