import { put, select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';

import * as actions from '../../actions';
import { userAuthenticated, showMfaFormPage } from '../../events';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* authenticateUser({ payload }) {
  try {
    yield put(actions.showLoaderPage());

    const apiHash = yield select((state) => state.configs.apiHash);

    yield nsApi.authenticate({
      username: payload.username,
      password: payload.password,
      scope: 'video',
      apiHash,
    });

    yield put(actions.loginFormErrorMsg(null));
    yield put(userAuthenticated());
  } catch (err) {
    if (err.reason === 'MFA_REQUIRED') {
      yield put(showMfaFormPage(
        payload.username,
        err.mfa_type,
        err.mfa_vendor,
      ));
    } else {
      yield put(actions.loginFormErrorMsg('CREDENTIALS_INVALID'));
      yield put(actions.showLoginFormPage());

      console.debug(err);
      bugsnagClient.notify(err, (event) => {
        // eslint-disable-next-line no-param-reassign
        event.severity = 'info';
        // eslint-disable-next-line no-param-reassign
        event.context = 'saga: authenticateUser';
        event.addMetadata('payload', payload);
      });
    }
  }
}
