import { put, select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import _ from 'lodash';

import { snackBarError } from '../../actions';
import * as selectors from '../../selectors';
import * as services from '../../services';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* restrictAttendee({ payload }) {
  try {
    const { attendee, restriction, successCallback } = payload;

    // eslint-disable-next-line camelcase
    const { meeting_id, attendee_id, id } = attendee;

    const user = yield select((state) => selectors.selectUser(state));

    const data = {
      object: 'attendee',
      action: 'update',
      user: user.userId,
      domain: user.domain,
      meeting_id,
      attendee_id,
    };

    switch (restriction) {
      case 'access':
        data.access_restricted = Number(!attendee.access_restricted);

        if (data.access_restricted) {
          data.status = 'disconnect';
          data.status_video = '';

          if (attendee.audio_participant_match) {
            services.toggleNcsAccess({
              user: user.userId,
              domain: user.domain,
              videoBridgeId: meeting_id,
              participantMatch: attendee.audio_participant_match,
            });
          }
        }
        break;
      case 'audio':
        data.audio_restricted = Number(!attendee.audio_restricted);

        if (attendee.audio_participant_match) {
          services.toggleNcsMute({
            user: user.userId,
            domain: user.domain,
            videoBridgeId: meeting_id,
            participantMatch: attendee.audio_participant_match,
            mute: data.audio_restricted ? 'mute' : 'unmute',
          });
        }

        break;
      case 'screenshare':
        data.screenshare_restricted = Number(!attendee.screenshare_restricted);
        break;
      case 'video':
        data.video_restricted = Number(!attendee.video_restricted);
        break;
      default:
        console.warn('Restriction type not supported');
        break;
    }

    yield nsApi.get(data)
      .then(() => _.isFunction(successCallback) && successCallback())
      .catch((err) => {
        console.warn(err);
        put(snackBarError('ACTION_FAILED'));
      });

    if (restriction === 'access' && id) {
      const room = yield select((state) => selectors.selectVideoRoom(state));
      let rejectedUser = id;
      if (id === 'guest') {
        // eslint-disable-next-line camelcase
        rejectedUser = `${id}_${attendee_id}`;
      }
      room.forcePeerClose(rejectedUser);
    }
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: restrictAttendee';
      event.addMetadata('payload', payload);
    });
  }
}
