import _ from 'lodash';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import crypto from 'crypto-browserify';
import bugsnagClient from '../bugsnag';

export default ({
  user,
  domain,
  meetingId,
  attendeeId,
  join,
  isInit,
}) => new Promise((resolve) => {
  const decodedToken = nsToken.getDecoded();
  const lookupObj = {
    user,
    domain,
    id: meetingId,
    object: 'meeting',
    action: 'read',
    include_attendees: 'yes',
    isInit,
  };

  if (attendeeId) {
    lookupObj.attendee_id = attendeeId;
  } else if (nsBrowser.getQuery('a')) {
    lookupObj.attendee_id = nsBrowser.getQuery('a');
    delete lookupObj.user;
  } else if (decodedToken.attendee_id) {
    lookupObj.attendee_id = decodedToken.attendee_id;
    delete lookupObj.user;
  }

  if (join === true) {
    lookupObj.join = 'yes';
  }

  nsApi.get(lookupObj).then((res) => {
    const [meeting] = res;

    if (!meeting) {
      // eslint-disable-next-line no-throw-literal
      throw 'NO_MEETING';
    }

    meeting.user = String(meeting.user); // API sends this as number but we want it to be string

    if (!meeting.attendees) {
      meeting.attendees = [];
    } else if (_.isString(meeting.attendees)) {
      try {
        meeting.attendees = JSON.parse(meeting.attendees);

        if (!_.isArray(meeting.attendees)) {
          console.error(`Meeting attendees should be an array. Got ${typeof meeting.attendees}`);
          bugsnagClient.notify(`Meeting attendees should be an array. Got ${typeof meeting.attendees}`);
          meeting.attendees = [];
        }
      } catch (e) {
        console.error(e);
        bugsnagClient.notify(e);
        meeting.attendees = [];
      }
    }

    let userFound = false;
    meeting.attendees = meeting.attendees.map((attendee) => {
      const clonedAttendee = _.cloneDeep(attendee);
      const attendeeEmail = attendee.email.split(',')[0];

      if (attendeeEmail) {
        clonedAttendee.gravatar = crypto.createHash('md5').update(clonedAttendee.email.split(',')[0]).digest('hex');
      }

      clonedAttendee.access_restricted = Number(clonedAttendee.access_restricted);
      clonedAttendee.audio_restricted = Number(clonedAttendee.audio_restricted);
      clonedAttendee.video_restricted = Number(clonedAttendee.video_restricted);
      clonedAttendee.screenshare_restricted = Number(clonedAttendee.screenshare_restricted);
      if (decodedToken.attendee_id && attendee.attendee_id === decodedToken.attendee_id && decodedToken.attendee_id !== '') {
        meeting.attendee_id = clonedAttendee.attendee_id;
        meeting.my_audio_id = clonedAttendee.audio_id;
        userFound = true;
      } else if (attendee.uid === `${user}@${domain}`) {
        if (!userFound) {
          meeting.attendee_id = clonedAttendee.attendee_id;
          meeting.my_audio_id = clonedAttendee.audio_id;
        }
      }

      return clonedAttendee;
    });

    // determine if user has write access to meeting
    const uid = `${user}@${domain}`;
    meeting.iCanEdit = false;
    if (`${meeting.user}@${meeting.domain}` === uid) {
      meeting.iCanEdit = true;
    } else {
      meeting.attendees.forEach((attendee) => {
        if (attendee.uid === uid && attendee.role === 'host') {
          meeting.iCanEdit = true;
        }
      });
    }

    bugsnagClient.metaData = {
      meeting,
      decodedToken,
    };

    if (!meeting.attendee_id) {
      bugsnagClient.notify('Attendee ID NOT set!');
      console.error('Attendee ID NOT set!');
      console.error(meeting);
      console.error(meeting.attendees);
      console.error(decodedToken);
    }

    if (typeof meeting.dial_in_numbers === 'number') {
      meeting.dial_in_numbers = meeting.dial_in_numbers.toString();
    }

    resolve(meeting);
  }).catch((err) => {
    console.error(err);
    bugsnagClient.notify(err);
    resolve(null);
  });
});
