import { put, select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import { updateNcsParticipant } from '../../events';

import * as selectors from '../../selectors';
import { snackBarMessage } from '../../actions';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* changeAttendeeRole({ payload }) {
  try {
    const { attendee, role } = payload;

    // eslint-disable-next-line camelcase
    const { meeting_id, attendee_id } = attendee;

    const user = yield select(selectors.selectUser);
    const meeting = yield select(selectors.selectMeeting);

    yield nsApi.get({
      object: 'attendee',
      action: 'update',
      user: user.userId,
      domain: user.domain,
      meeting_id,
      attendee_id,
      role,
    }).catch((err) => {
      console.warn(err);
      put(snackBarMessage('ACTION_FAILED'));
    });

    if (meeting && meeting.type === 'presentation' && meeting.status === 'started') {
      if (attendee && role && (role === 'host' || role === 'presenter')) {
        // this will make sure they are unmuted on the NCS as a leader
        yield put(updateNcsParticipant('leader', () => {}, attendee.audio_id));
      }
    }
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: changeAttendeeRole';
      event.addMetadata('payload', payload);
    });
  }
}
