import * as constants from './constants';

function actionCreator(type) {
  return (payload) => ({
    type,
    payload,
  });
}

/* attendees */
export const setSpeakerActivity = actionCreator(constants.SPEAKER_ACTIVITY);
export const setAttendeesList = actionCreator(constants.ATTENDEES_LIST);
export const setConnectedAttendees = actionCreator(constants.CONNECTED_ATTENDEES);
export const setMyAttendee = actionCreator(constants.MY_ATTENDEE);

/* audio */
export const setAudioDevice = actionCreator(constants.AUDIO_DEVICE);
export const setAudioSession = actionCreator(constants.AUDIO_SESSION);
export const setAudioUA = actionCreator(constants.UA);
export const setAudioConfiguration = actionCreator(constants.CONFIGURATION);
export const setRemoteAudio = actionCreator(constants.REMOTE_AUDIO);
export const setParticipantMatch = actionCreator(constants.PARTICIPANT_MATCH);

/* chat */
export const setChatInFocus = actionCreator(constants.CHAT_SESSION_IN_FOCUS);
export const setChatSessions = actionCreator(constants.CHAT_SESSIONS);
export const setRecentMessages = actionCreator(constants.RECENT_MESSAGES);

/* chat attachments */
export const addChatAttachment = actionCreator(constants.ADD_CHAT_ATTACHMENT);
export const setChatAttachments = actionCreator(constants.CHAT_ATTACHMENTS);
export const removeChatAttachment = actionCreator(constants.REMOVE_CHAT_ATTACHMENT);
export const sendChatAttachments = actionCreator(constants.SEND_CHAT_ATTACHMENTS);

/* confIdForm */
export const confIdFormErrorId = actionCreator(constants.CONF_ID_ERROR_ID);

/* configs */
export const setConfig = actionCreator(constants.CONFIG);

/* contacts */
export const setContacts = actionCreator(constants.CONTACTS);

/* layout */
export const showAddUser = actionCreator(constants.SHOW_ADD_USER);
export const showBgImage = actionCreator(constants.SHOW_BG_IMAGE);
export const showBrowserSupportPage = actionCreator(constants.SHOW_BROWSER_SUPPORT_PAGE);
export const showPendingPermissionsDialog = actionCreator(
  constants.SHOW_PENDING_PERMISSIONS_DIALOG,
);
export const showPermissionsDialog = actionCreator(constants.SHOW_PERMISSIONS_DIALOG);
export const showConfIdFormPage = actionCreator(constants.SHOW_CONF_ID_FORM_PAGE);
export const showConfSettingsPage = actionCreator(constants.SHOW_CONF_SETTINGS_PAGE);
export const showDeviceSettingsPage = actionCreator(constants.SHOW_DEVICE_SETTINGS_PAGE);
export const showLoaderPage = actionCreator(constants.SHOW_LOADER_PAGE);
export const showLoginFormPage = actionCreator(constants.SHOW_LOGIN_FORM_PAGE);
export const showLandingFormPage = actionCreator(constants.SHOW_LANDING_FORM_PAGE);
export const showMeetingPasswordPage = actionCreator(constants.SHOW_MEETING_PASSWORD_PAGE);
export const showDeviceReconfigModal = actionCreator(constants.SHOW_DEVICE_RECONFIG_MODAL);
export const showRegisterFormPage = actionCreator(constants.SHOW_REGISTER_FORM_PAGE);
export const showRegisterSuccessPage = actionCreator(constants.SHOW_REGISTER_SUCCESS_PAGE);
export const showMeetingConfig = actionCreator(constants.SHOW_MEETING_CONFIG);
export const showMeetingEnded = actionCreator(constants.SHOW_MEETING_ENDED);
export const showMeetingConfigBypass = actionCreator(constants.SHOW_MEETING_CONFIG_BYPASS);
export const showMeetingErrorPage = actionCreator(constants.SHOW_MEETING_ERROR_PAGE);
export const showMeetingTimeErrorPage = actionCreator(constants.SHOW_MEETING_TIME_ERROR_PAGE);
export const showMeetingHubPage = actionCreator(constants.SHOW_MEETING_HUB_PAGE);
export const showMeetingNotFoundPage = actionCreator(constants.SHOW_MEETING_NOT_FOUND_PAGE);
export const showMeetingsScrollPage = actionCreator(constants.SHOW_MEETINGS_SCROLL_PAGE);
export const showMfaFormPage = actionCreator(constants.SHOW_MFA_FORM_PAGE);
export const showScreenshareRequestModal = actionCreator(constants.SHOW_SCREENSHARE_REQUEST_MODAL);
export const showSsoEnrollPage = actionCreator(constants.SHOW_SSO_ENROLL_PAGE);
export const showSubscriberSelectPage = actionCreator(constants.SHOW_SUBSCRIBER_SELECT_PAGE);
export const showUCLicenseMsgPage = actionCreator(constants.SHOW_UC_LICENSE_PAGE);
export const showVideoGridPage = actionCreator(constants.SHOW_VIDEO_GRID_PAGE);
export const setLogo = actionCreator(constants.LOGO);
export const setMIV = actionCreator(constants.SET_MIV);

/* loginForm */
export const setLoginImage = actionCreator(constants.LOGIN_IMAGE);
export const loginFormErrorMsg = actionCreator(constants.LOGIN_ERROR_MSG);

/* mediaStatus */
export const updateMediaStatus = actionCreator(constants.UPDATE_MEDIA_STATUS);
export const removeMediaStatus = actionCreator(constants.REMOVE_MEDIA_STATUS);

/* meetings */
export const setMeetings = actionCreator(constants.MEETINGS);
export const setMeeting = actionCreator(constants.MEETING_CONFIG);
export const setRooms = actionCreator(constants.ROOMS);
export const meetingsLoading = actionCreator(constants.MEETINGS_LOADING);

/** application alerts and notices */
export const snackBar = actionCreator(constants.SNACK_BAR);
export const snackBarMessage = actionCreator(constants.SNACK_BAR_MESSAGE);
export const snackBarError = actionCreator(constants.SNACK_BAR_ERROR);
export const snackBarSuccess = actionCreator(constants.SNACK_BAR_SUCCESS);
export const closeAlertDialog = actionCreator(constants.CLOSE_ALERT_DIALOG);
export const openAlertDialog = actionCreator(constants.OPEN_ALERT_DIALOG);
export const closeRejectDialog = actionCreator(constants.CLOSE_REJECT_DIALOG);
export const openRejectDialog = actionCreator(constants.OPEN_REJECT_DIALOG);

/* screenShare */
export const isScreenShareSelf = actionCreator(constants.SCREENSHARE_IS_SELF);
export const isScreenShareSharing = actionCreator(constants.SCREENSHARE_IS_SHARING);
export const isScreenShareBlocked = actionCreator(constants.SCREENSHARE_BLOCKED);
export const isScreenShareOverlayDismissed = actionCreator(constants.SCREENSHARE_OVERLAY_DISMISSED);

/* socket */
export const setConnected = actionCreator(constants.IS_CONNECTED);
export const setSocket = actionCreator(constants.SOCKET);

/* ucInbox */
export const setUcIsOpen = actionCreator(constants.UC_IS_OPEN);
export const setUcItems = actionCreator(constants.UC_ITEMS);
export const setUcTabValue = actionCreator(constants.UC_TAB_VALUE);
export const setUcUnreadCount = actionCreator(constants.UC_UNREAD_COUNT);

/* user */
export const logoutUser = actionCreator(constants.LOGOUT_USER);
export const setUser = actionCreator(constants.USER);

/* video */
export const setVideoDynamicSpeaker = actionCreator(constants.VIDEO_DYNAMIC_SPEAKER);
export const setVideoDynamicSpeakerCooldown = actionCreator(constants.VIDEO_DYNAMIC_SPEAKER_COOLDOWN); // eslint-disable-line max-len
export const setVideoDynamicSpeakerCooldownSFU = actionCreator(constants.VIDEO_DYNAMIC_SPEAKER_COOLDOWN_SFU); // eslint-disable-line max-len
export const setMutedNotify = actionCreator(constants.MUTED_NOTIFY); // eslint-disable-line max-len
export const setMutedHints = actionCreator(constants.MUTED_HINTS);
export const setVideoTempDynamicSpeaker = actionCreator(constants.VIDEO_TEMP_DYNAMIC_SPEAKER); // eslint-disable-line max-len
export const setVideoParticipants = actionCreator(constants.VIDEO_PARTICIPANTS);
export const setVideoGrid = actionCreator(constants.VIDEO_GRID);
export const setTempVideoGrid = actionCreator(constants.TEMP_VIDEO_GRID);
export const setVideoLayout = actionCreator(constants.VIDEO_LAYOUT);
export const setVideoTrayOpen = actionCreator(constants.VIDEO_TRAY_OPEN);
export const setVideoMuteHide = actionCreator(constants.VIDEO_MUTE_HIDE);
export const setVideoHideSelf = actionCreator(constants.VIDEO_HIDE_SELF);
export const setVideoAllStats = actionCreator(constants.VIDEO_ALL_STATS);
export const setOrientation = actionCreator(constants.ROTATE_ORIENTATION);

/* videoRoom */
export const setRoom = actionCreator(constants.ROOM);
export const setLayoutPreScreenshare = actionCreator(constants.LAYOUT_PRE_SCREENSHARE);
export const setSnapHDVolley = actionCreator(constants.SNAPHD_VOLLEY);
export const setConsumers = actionCreator(constants.CONSUMERS);
export const setCameraMap = actionCreator(constants.CAMERA_MAP);
