import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React, {
  useRef, useEffect, useState, useCallback, useMemo,
} from 'react';
import _ from 'lodash';
import useWindowSize from 'react-use/lib/useWindowSize';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ArrowUp from '@material-ui/icons/KeyboardArrowUp';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Slider from 'react-slick';

import { UC_DRAWER_WIDTH } from '../../../../constants';
import * as actions from '../../../../actions';
import * as selectors from '../../../../selectors';

import NextArrow from './NextArrow';
import PrevArrow from './PrevArrow';
import VideoItem from '../video-item/VideoItem';

const VIDEO_HEIGHT = 135;
const VIDEO_WIDTH = VIDEO_HEIGHT / 0.5625;

const useStyles = makeStyles((theme) => ({
  trayContainer: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    zIndex: 3,
  },
  verticalContainer: {
    width: '25%',
    height: '100%',
    right: 0,
    bottom: 0,
    position: 'absolute',
    flexDirection: 'column-reverse',
    display: 'flex',
  },
  root: {
    margin: '0 auto',
    position: 'relative',
  },
  sliderContainer: {
    overflow: 'hidden',
  },
  slider: {
    margin: '0 auto',
  },
  hideSlider: {
    height: 0,
    transition: 'height .2s',
  },
  showSlider: {
    height: 150,
    transition: 'height .2s',
  },
  showSliderVertical: {
    height: '100%',
  },
  trayBtn: {
    backgroundColor: 'rgba(0, 0, 0, 0.54)',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
  },
  box: {
    margin: theme.spacing(1),
    position: 'relative',
    overflow: 'hidden',
    transition: 'box-shadow 0.3s ease-in-out',
    '&.active': {
      boxShadow: '0 0 6px 0 #1A73E8, 0 0 6px 6px #1A73E8',
    },
  },
  grid: {
    position: 'absolute',
    top: -64,
  },
}));

const getDisplayed = (width, count) => {
  const totalWidth = width - 176; // 176 is the combined button widths and padding

  for (let i = 1; i <= 25; i += 1) {
    if (totalWidth <= VIDEO_WIDTH * (i + 1)) {
      return _.min([i, count]);
    }
  }

  return 1;
};

const VideoTray = ({ vertical }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { width } = useWindowSize();

  const videoGridIds = useSelector(selectors.selectVideoGridIds);
  const participants = useSelector(selectors.selectParticipants);
  const muteHide = useSelector(selectors.selectVideoMuteHide);
  const hideSelf = useSelector(selectors.selectVideoHideSelf);

  const videoItemIds = useMemo(() => {
    let filtered = videoGridIds;

    if (muteHide) {
      filtered = filtered.filter(
        (id) => _.get(participants, [id, 'videoTrackMuted']) !== true,
      );
    }

    if (hideSelf) {
      filtered = filtered.filter(
        (id) => _.get(participants, [id, 'isSelf']) !== true,
      );
    }
    return filtered;
  }, [videoGridIds, muteHide, hideSelf]);

  const [open, setOpen] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const ucInboxIsOpen = useSelector(selectors.selectUcIsOpen);

  const [settings, setSettings] = useState(null);
  const [dynamicStyles, setDynamicStyles] = useState(null);

  const trayCount = useRef(_.size(videoItemIds));
  const sliderRef = useRef();

  useEffect(() => {
    if (!videoItemIds) {
      setDynamicStyles(null);
      setSettings(null);
      return;
    }

    const ucInboxWidth = ucInboxIsOpen ? UC_DRAWER_WIDTH : 0;
    const vw = width - ucInboxWidth;
    const displayed = getDisplayed(vw, videoItemIds.length);

    let trayItemsWidth = (displayed * (VIDEO_WIDTH + 16));
    if (vertical) {
      trayItemsWidth = VIDEO_WIDTH + 16;
    }

    setDynamicStyles({
      trayItemsWidth,
    });

    setSettings({
      infinite: false,
      nextArrow: <NextArrow displayed={displayed} />,
      prevArrow: <PrevArrow displayed={displayed} />,
      slidesToScroll: 1,
      speed: 100,
      slidesToShow: vertical ? 5 : displayed,
      vertical: vertical || false,
    });
  }, [videoGridIds, width, ucInboxIsOpen, muteHide]);

  useEffect(() => {
    const show = _.size(videoItemIds) > 1;
    setOpen(show);
    setShowButton(show);
    // dispatch(actions.setVideoTrayOpen(show));
  }, []);

  useEffect(() => {
    if (_.size(videoItemIds) === 1) {
      setOpen(false);
      setShowButton(false);
      // dispatch(actions.setVideoTrayOpen(false));
    } else if (_.size(videoItemIds) > trayCount.current && _.size(videoItemIds) === 2) {
      setOpen(true);
      setShowButton(true);
      // dispatch(actions.setVideoTrayOpen(true));
    }

    trayCount.current = _.size(videoItemIds);
  }, [_.size(videoItemIds)]);

  const toggleVideoTray = useCallback(() => {
    setOpen(!open);
    dispatch(actions.setVideoTrayOpen(!open));
  }, [open]);

  if (!videoItemIds || !settings) {
    return null;
  }

  return (
    <div className={classnames(classes.trayContainer, vertical && classes.verticalContainer)}>
      {showButton && !vertical && (
        <Grid
          className={classes.grid}
          container
          direction="row"
          justifyContent="center"
        >
          <Grid item={false}>
            <IconButton
              id="VideoTrayToggleTray"
              className={classes.trayBtn}
              color="inherit"
              onClick={toggleVideoTray}
            >
              {open ? <ArrowDown /> : <ArrowUp />}
            </IconButton>
          </Grid>
        </Grid>
      )}
      <div
        className={classnames(classes.sliderContainer, {
          [classes.showSlider]: open,
          [classes.hideSlider]: !open,
          [classes.showSliderVertical]: vertical,
        })}
      >
        <div
          style={{
            width: dynamicStyles.trayItemsWidth,
            margin: '0 auto',
          }}
        >
          <Slider
            ref={sliderRef}
            className={classes.slider}
            {...settings}
          >
            {videoItemIds.slice(1)
              .map((id) => (
                <VideoItem key={id} participantId={id} isTrayItem />
              ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

VideoTray.defaultProps = {
  vertical: false,
};

VideoTray.propTypes = {
  vertical: PropTypes.bool,
};

export default VideoTray;
