import { put } from 'redux-saga/effects';
import * as actions from '../../actions';
import { initConference } from '../../events';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* newConference() {
  yield put(actions.showLoaderPage());
  const confId = `${Math.floor(100000000 + Math.random() * 900000000)}`;
  yield put(actions.setConfig({ confId }));

  try {
    yield put(initConference());
    yield put(actions.showLoaderPage());
  } catch (err) {
    yield put(actions.confIdFormErrorId('CONF_NOT_FOUND'));

    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: newConference';
      event.addMetadata('confId', confId);
    });
  }
}
