import _ from 'lodash';
import nsApi from '@netsapiens/netsapiens-js/dist/api';

export default ({
  user,
  domain,
  limit,
  offset,
  start,
  type,
  id,
}) => {
  const params = {
    user,
    domain,
    object: 'meeting',
    action: 'read',
    format: 'json',
    include_attendees: 'yes',
    sort: 'start asc',
    limit: limit || 5,
    offset: offset || 0,
  };

  if (start) {
    params.start = start;
  }

  if (type) {
    params.type = type;
  }

  if (id) {
    params.id = id;
  }

  return nsApi.get(params).then((res) => {
    const uid = `${user}@${domain}`;

    return _.compact(res).map((meeting) => {
      const parsedMeeting = meeting;

      // if attendees is a JSON string, parse to an object
      if (_.isString(parsedMeeting.attendees)) {
        parsedMeeting.attendees = JSON.parse(parsedMeeting.attendees);
      }

      // if user is a number (extension), typecast to string
      if (_.isNumber(parsedMeeting.user)) {
        parsedMeeting.user = `${parsedMeeting.user}`;
      }

      // if dial_in_numbers is a number, typecast to string
      if (_.isNumber(parsedMeeting.dial_in_numbers)) {
        parsedMeeting.dial_in_numbers = `${parsedMeeting.dial_in_numbers}`;
      }

      // determine if user has write access to meeting
      parsedMeeting.iCanEdit = false;
      if (`${meeting.user}@${meeting.domain}` === uid) {
        parsedMeeting.iCanEdit = true;
      } else {
        meeting.attendees.forEach((attendee) => {
          if (attendee.uid === uid && attendee.role === 'host') {
            parsedMeeting.iCanEdit = true;
          }
        });
      }

      return parsedMeeting;
    }).filter((meeting) => {
      if (String(meeting.user) === String(user) && meeting.domain === domain) {
        return true;
      }

      const iAmInvited = meeting.attendees.filter((attendee) => attendee.uid === uid).length;
      return Boolean(iAmInvited);
    });
  });
};
