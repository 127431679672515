import _ from 'lodash';

/* attendees */
export const selectAllSpeakerActivity = (state) => state.speakerActivity;
export const selectSpeakerActivity = (state, id) => state.speakerActivity[id];
export const selectAttendeesList = (state) => state.attendees.attendeesList;
export const selectAttendee = (state, id) => _.find(
  selectAttendeesList(state), { attendee_id: id },
);
export const selectConnectedAttendees = (state) => state.attendees.connectedAttendees;
export const selectMyAttendee = (state) => state.attendees.myAttendee;
export const selectAttendeeAudioId = (state, audioId) => _.find(
  selectAttendeesList(state), { audio_id: parseInt(audioId, 10) },
);

/* audio */
export const selectAudioConfiguration = (state) => state.audio.configuration;
export const selectAudioDevice = (state) => state.audio.device;
export const selectAudioElement = (state) => state.audio.audioElement;
export const selectAudioSession = (state) => state.audio.session;
export const selectAudioUA = (state) => state.audio.ua;
export const selectRemoteAudio = (state) => state.audio.remoteAudio;
export const selectParticipantMatch = (state) => state.audio.participantMatch;

/* chat */
export const selectChatInFocus = (state) => state.chat.inFocus;
export const selectChatSession = (state, key) => _.get(state, ['chat', 'sessions', key]);
export const selectChatSessionMessages = (state, key) => _.get(state, ['chat', 'sessions', key, 'messages'], []);
export const selectChatSessions = (state) => state.chat.sessions;
export const selectRecentMessages = (state) => state.chat.recentMessages;

/* chat attachments */
export const selectChatAttachments = (state) => state.chat.attachments;

/* conf id form */
export const selectConfIdFormError = (state) => state.confIdForm.errorMsg;

/* configs */
export const selectBrowsers = (state) => {
  const browsers = [
    'videoChromeSupport',
    'videoFirefoxSupport',
    'videoIESupport',
    'videoEdgeSupport',
    'videoSafariSupport',
  ];

  const tmp = [];
  for (let i = 0; i < browsers.length; i += 1) {
    let val = state.configs[browsers[i]];
    if (val) {
      val = Number(val);
      if (!Number.isNaN(val)) {
        tmp.push({ configName: browsers[i], val });
      }
    }
  }

  return tmp;
};
export const selectConfig = (state, name) => state.configs[name] || null;

/* contacts */
export const selectContact = (state, id) => state.contacts.entities[id] || null;
export const selectContacts = (state) => state.contacts;

/* layout */
export const selectLayout = (state) => state.layout;
export const selectMIV = (state) => state.layout.MIV;
// eslint-disable-next-line max-len
export const selectShowScreenshareRequestModal = (state) => state.layout.showScreenshareRequestModal;
export const showBgImage = (state) => state.layout.showBgImage;

/* loginForm */
export const selectLoginFormErrorMsg = (state) => state.loginForm.errorMsg;
export const selectLoginImage = (state) => state.loginForm.loginImage;

/* mediaStatus */
export const selectMediaStatus = (state, userId) => {
  if (userId) return state.mediaStatus[userId];
  return state.mediaStatus;
};
export const selectUserMediaStatus = (state) => {
  const { user } = state.user;

  let userId;
  if (user) {
    ({ userId } = user);
  } else {
    userId = `${state.configs.userId}${state.configs.guestId}`;
  }
  return selectMediaStatus(state, userId);
};

/* meeting */
export const selectMeetings = (state) => state.meetings.meetings;
export const selectMeeting = (state) => state.meetingConfig.meeting;

/* notices */
export const selectAlertDialog = (state) => state.notices.alertDialog;
export const selectRejectDialog = (state) => state.notices.rejectDialog;

/* socket */
export const selectSocket = (state) => state.socket.socket;
export const selectSocketConnected = (state) => state.socket.isConnected;

/* ucInbox */
export const selectUcIsOpen = (state) => state.ucInbox.isOpen;
export const selectUcItems = (state) => state.ucInbox.items;
export const selectUcTabValue = (state) => state.ucInbox.tabValue;
export const selectUcUnReadCount = (state) => state.ucInbox.unreadCount;

/* user */
export const selectUser = (state) => state.user.user;

/* video */
export const selectDynamicSpeaker = (state) => state.video.dynamicSpeaker;
export const selectTempDynamicSpeaker = (state) => state.video.dynamicTempSpeaker;
export const selectDynamicSpeakerCooldown = (state) => state.video.dynamicSpeakerCooldown;
export const selectDynamicSpeakerCooldownSFU = (state) => state.video.dynamicSpeakerCooldownSFU;
export const selectMutedNotify = (state) => state.video.mutedNotify;
export const selectMutedHints = (state) => state.video.mutedHints;
export const selectParticipant = (state, id) => state.video.participants[id];
export const selectParticipants = (state) => state.video.participants;
export const selectUserParticipant = (state) => state.video.participants[`video_${state.user.user.userId}`]
  || state.video.participants[`video_guest_${state.user.user.userId}`]
  || null;
export const selectOrientaion = (state) => state.video.orientation;
export const selectVideoGridIds = (state) => state.video.grid;
export const selectTempVideoGrid = (state) => state.video.tempGrid;

export const selectVideoLayout = (state) => state.video.layout;
export const selectVideoTrayOpen = (state) => state.video.trayOpen;
export const selectVideoMuteHide = (state) => state.video.muteHide;
export const selectVideoHideSelf = (state) => state.video.hideSelf;
export const selectVideoAllStats = (state) => state.video.allStats;

/* videoRoom */
export const selectVideoRoom = (state) => state.videoRoom.room;
export const selectLayoutPreScreenshare = (state) => state.videoRoom.prelayout;
export const selectSnapHDVolley = (state) => state.videoRoom.snaphdvolley;
export const selectConsumers = (state) => state.videoRoom.consumers;
export const selectConsumer = (state, id) => state.videoRoom.consumers[id];
export const selectCameraMap = (state) => state.videoRoom.cameraMap;
