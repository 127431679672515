import React from 'react';
import crypto from 'crypto-browserify';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import classnames from 'classnames';
import userShape from '../../shapes/user';
import gravatarSessionId from '../../utils/gravatarSessionId';

const useStyles = makeStyles(() => ({
  avatarContainer: {
    display: 'inline-block',
  },
  listGravatar: {
    opacity: 1,
    marginRight: 4,
    float: 'left',
  },
  speaking: {
    opacity: 1,
    boxShadow: '0 0 2px 0 rgba(0,0,0,0.5), 0 0 4px 2px #1A73E8',
    transition: 'box-shadow 0.3s ease-in-out',
  },
  listInitials: {
    display: 'block',
    // padding: '12px 9px 8px 7px',
    width: '36px',
    height: '36px',
    opacity: 1,
    marginRight: 4,
    borderRadius: 50,
    color: '#fff',
    lineHeight: '38px',
    whiteSpace: 'nowrap',
    backgroundColor: '#b9b9b9',
    position: 'absolute',
    textAlign: 'center',
    overflow: 'hidden',
    '& span': {
      width: '100%',
      fontSize: '0.9em',
    },
  },
  listFiller: {
    marginTop: -4,
  },
}));

const UserAvatar = ({ user }) => {
  const classes = useStyles();

  let {
    email,
    gravatar,
  } = user;

  const {
    initials, name, isActive,
  } = user;

  if (!gravatar && email) {
    if (_.isArray(email)) {
      email = email.shift();
    }
    if (email) {
      gravatar = crypto.createHash('md5').update(email).digest('hex');
    }
  }

  let userInitials = '';
  if (initials) {
    userInitials = initials;
    userInitials = userInitials.slice(0, 3);
  } else if (name && name.trim()) {
    const userNameSplit = name.match(/\b\w/g);

    if (userNameSplit) {
      userInitials = userNameSplit.join('');
    }
    userInitials = userInitials.slice(0, 3);
  } else {
    userInitials = <AccountCircle className={classes.listFiller} />;
  }

  return (
    <div className={classnames('animate__animated animate__fadeIn', classes.avatarContainer)}>
      <span className={classnames(isActive && classes.speaking, classes.listInitials)}>
        {userInitials}
      </span>

      <Avatar
        className={classnames(isActive && classes.speaking, classes.listGravatar)}
        src={`https://www.gravatar.com/avatar/${gravatar}?s=42&d=blank&hash=${gravatarSessionId}`}
      />
    </div>
  );
};

UserAvatar.propTypes = {
  user: userShape.propTypesShape.isRequired,
};

export default UserAvatar;
