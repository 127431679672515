import {
  IS_CONNECTED,
  SOCKET,
} from '../constants';

const defaultState = {
  isConnected: null,
  socket: null,
};

const reducers = {
  [IS_CONNECTED]: (state, { payload }) => ({ isConnected: payload }),
  [SOCKET]: (state, { payload }) => ({ socket: payload }),
};

export default {
  defaultState,
  reducers,
};
