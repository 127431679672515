import React, { useMemo } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classnames from 'classnames';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import PeopleIcon from '@material-ui/icons/People';

import LayoutContainer from '../components/LayoutContainer';
import VideoItem from '../../video-item/VideoItem';
import GravatarItem from './GravatarItem';

import { postWebkitAction } from '../../../../../events';

import {
  selectOrientaion,
  selectVideoGridIds,
  selectParticipants,
  selectUserParticipant,
  selectVideoHideSelf,
  selectConfig,
} from '../../../../../selectors';

import {
  LAYOUT_TYPE_CONVERSATION,
  LAYOUT_ROTATE_LAND,
} from '../../../../../constants';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
    flexWrap: 'nowrap',
  },
  mivCont: {
    overflowX: 'hidden',
    overflowY: 'hidden',
    textAlign: 'center',
    transition: 'all 0.3s ease-in-out',
    padding: '4%',
  },
  focusWidth: {
    width: '75%',
  },
  trayWidth: {
    width: '25%',
    textAlign: 'center',
    transition: 'all 0.3s ease-in-out',
  },
  self: {
    height: '90px',
    margin: '4px',
    padding: '0 2px 0 2px',
    flex: '1 0 auto',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '16px',
    backgroundColor: '#717171',
    zIndex: -3,
  },
  bottomButton: {
    width: '100%',
    position: 'absolute',
    bottom: '2px',
  },
  halfWidth: {
    margin: '2px',
    padding: '4px',
    display: 'flex',
    borderRadius: '100px',
    left: '-50%',
    zIndex: 4,
  },
}));

const MobileConversationLayout = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const orientation = useSelector(selectOrientaion);
  const videoGridIds = useSelector(selectVideoGridIds);
  const participants = useSelector(selectParticipants);
  const participantSelf = useSelector(selectUserParticipant);
  const videoHideSelf = useSelector(selectVideoHideSelf);
  const videoGridLimit = useSelector((state) => selectConfig(state, 'PORTAL_MIV_VIDEO_LIMIT')) || 7;

  const landscape = useMemo(() => orientation === LAYOUT_ROTATE_LAND, [orientation]);
  console.log(`Orientation:${orientation} landscape:${landscape}`);

  const participantIds = useMemo(() => {
    const filtered = videoGridIds.filter((id) => _.get(participants, [id, 'videoTrackMuted']) !== true);
    return [filtered.slice(0, 1), filtered.slice(1, Number(videoGridLimit) / 2)];
  }, [videoGridIds]);

  const gravatarsParticipants = useMemo(() => {
    const filtered = videoGridIds.filter((id) => _.get(participants, [id, 'videoTrackMuted']) === true);
    return filtered;
  });

  const showChat = () => {
    console.log('showChat clicked');
    dispatch(postWebkitAction({ action: 'SHOW_CHAT' }));
  };

  const showPeople = () => {
    console.log('showPeople clicked');
    dispatch(postWebkitAction({ action: 'SHOW_PEOPLE' }));
  };

  return (
    <LayoutContainer>
      <Grid container direction="row" className={classnames(classes.fullWidth, classes.mivCont)}>
        { participantIds[0] && (
        <Grid
          item
          xs={12}
          className={
          classnames(
            participantIds[1].length > 0 && classes.focusWidth,
            participantIds[1].length === 0 && classes.fullWidth,
          )
        }
        >
          <VideoItem
            participantId={participantIds[0]}
            isMobileCrop
            layout={LAYOUT_TYPE_CONVERSATION}
          />
        </Grid>
        )}
        <Grid item xs={3} classname={classes.trayWidth}>
          <Grid container wrap="nowrap" direction="column">
            {participantIds[1].map((id) => (
              <Grid
                item
                key={id}
              >
                <VideoItem
                  participantId={id}
                  isMobileCrop
                  layout={LAYOUT_TYPE_CONVERSATION}
                />
              </Grid>
            ))}
            { !videoHideSelf && (
            <Grid
              item
              key={participantSelf.id}
            >
              <VideoItem
                participantId={participantSelf.id}
                layout={LAYOUT_TYPE_CONVERSATION}
                isMobileCrop
              />
            </Grid>
            )}
            <Grid
              item
              style={{ zIndex: 2 }}
            >
              <div
                className={classes.self}
              >
                <Grid
                  container
                  justifyContent="space-around"
                  alignItems="center"
                >
                  { gravatarsParticipants.map((id) => (
                    <Grid
                      item
                      key={id}
                      xs={2}
                    >
                      <GravatarItem
                        participantId={id}
                        layout={LAYOUT_TYPE_CONVERSATION}
                      />
                    </Grid>
                  ))}
                </Grid>
                <div className={classes.bottomButton}>
                  <Grid
                    container
                    justifyContent="space-around"
                    alignItems="center"
                  >
                    <Grid item key="MIVChat" xs={2}>
                      <Button
                        id="MIVChatToggle"
                        variant="contained"
                        className={classes.halfWidth}
                        onClick={showChat}
                      >
                        <QuestionAnswerIcon />
                      </Button>
                    </Grid>
                    <Grid item key="MIVPeople" xs={2}>
                      <Button
                        id="MIVPeopleToggle"
                        variant="contained"
                        className={classes.halfWidth}
                        onClick={showPeople}
                      >
                        <PeopleIcon />
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </LayoutContainer>
  );
};

export default MobileConversationLayout;
