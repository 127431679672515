import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';

import { saveSelectedDevices } from '../../../events';
import DeviceSettings from './DeviceSettings';
import PageContainer from '../../containers/PageContainer';
import largeSplashCard from '../../styles/largeSplashCard';

const messages = defineMessages({
  CAM_INPUT_LABEL: {
    id: 'CAM_INPUT_LABEL',
    defaultMessage: 'Camera',
  },
  DEVICE_SETTINGS_TITLE: {
    id: 'DEVICE_SETTINGS_TITLE',
    defaultMessage: 'Device settings',
  },
  DONE: {
    id: 'DONE',
    defaultMessage: 'done',
  },
  MIC_INPUT_LABEL: {
    id: 'MIC_INPUT_LABEL',
    defaultMessage: 'Microphone input',
  },
  MIC_OUTPUT_LABEL: {
    id: 'MIC_OUTPUT_LABEL',
    defaultMessage: 'Audio output',
  },
  NO_MIC_OUTPUT_MESSAGE: {
    id: 'NO_MIC_OUTPUT_MESSAGE',
    defaultMessage: 'Default audio output device',
  },
  NO_DEVICES: {
    id: 'NO_DEVICES',
    defaultMessage: 'No devices detected',
  },
  BLOCKED_BY_BROWSER: {
    id: 'BLOCKED_BY_BROWSER',
    defaultMessage: 'Blocked by browser',
  },
});

const useStyles = makeStyles((theme) => ({
  ...largeSplashCard(theme),
  card: {
    ...largeSplashCard(theme).card,
    width: 548,
  },
  selectContainer: {
    padding: theme.spacing(2),
    maxWidth: '70% !important',
  },
  mediaRow: {
    marginBottom: theme.spacing(2),
  },
}));

const DeviceSettingsPage = ({
  closeCallback,
  saveSelectedDevicesAction,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    saveSelectedDevicesAction();
    closeCallback();
  };

  return (
    <PageContainer>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          title={<FormattedMessage {...messages.DEVICE_SETTINGS_TITLE} />}
        />
        <CardContent className={classes.cardContent}>
          <DeviceSettings />
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button id="DeviceSettingsDone" variant="contained" color="primary" onClick={() => handleClose()}>
            <FormattedMessage {...messages.DONE} />
          </Button>
        </CardActions>
      </Card>
    </PageContainer>
  );
};

DeviceSettingsPage.propTypes = {
  saveSelectedDevicesAction: PropTypes.func.isRequired,
  closeCallback: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  saveSelectedDevicesAction: saveSelectedDevices,
}, dispatch);

export default connect(undefined, mapDispatchToProps)(DeviceSettingsPage);
