import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import copy from 'copy-to-clipboard';
import _ from 'lodash';

import Autocomplete from '@material-ui/lab/Autocomplete';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';

import * as constants from './constants';
import * as actions from '../../../../actions';
import * as events from '../../../../events';
import * as selectors from '../../../../selectors';
import { getMeetingById } from '../../../../services';
import { formatMeetingId } from '../../../../utils';
import { ALLOWED_WEBINAR_ROLES } from '../../../../constants';

import meetingShape from '../../../../shapes/meeting';
import attendeesShape from '../../../../shapes/attendees';
import processSelectItems from './utils/processSelectItems';

import ListOption from './components/ListOption';
import UserAvatar from '../../../components/UserAvatar';

const messages = defineMessages({
  DIAL_IN_NUMBER: {
    id: 'DIAL_IN_NUMBER',
    defaultMessage: 'Dial-In Number',
  },
  CALL: {
    id: 'CALL',
    defaultMessage: 'call',
  },
  COPY_MEETING_INFO: {
    id: 'COPY_MEETING_INFO',
    defaultMessage: 'copy meeting info',
  },
  DONE: {
    id: 'DONE',
    defaultMessage: 'done',
  },
  HOST: {
    id: 'HOST',
    defaultMessage: 'Host',
  },
  INVITE: {
    id: 'INVITE',
    defaultMessage: 'invite',
  },
  JOIN_INFO: {
    id: 'JOIN_INFO',
    defaultMessage: 'join info',
  },
  MEETING_ID: {
    id: 'MEETING_ID',
    defaultMessage: 'Meeting ID',
  },
  MEETING_URL: {
    id: 'MEETING_URL',
    defaultMessage: 'Meeting URL',
  },
  NEW_EMAIL: {
    id: 'NEW_EMAIL',
    defaultMessage: 'New email',
  },
  NEW_NUMBER: {
    id: 'NEW_NUMBER',
    defaultMessage: 'New number',
  },
  SEND_INVITE: {
    id: 'SEND_INVITE',
    defaultMessage: 'send invite',
  },
  INVITE_SEARCH_PLACEHOLDER: {
    id: 'INVITE_SEARCH_PLACEHOLDER',
    defaultMessage: 'Search by name, extension, or email',
  },
  CALL_SEARCH_PLACEHOLDER: {
    id: 'CALL_SEARCH_PLACEHOLDER',
    defaultMessage: 'Search by name, extension, or number',
  },
  PRESENTER: {
    id: 'PRESENTER',
    defaultMessage: 'Presenter',
  },
  ATTENDEE: {
    id: 'ATTENDEE',
    defaultMessage: 'Attendee',
  },
  AUDIO_ID: {
    id: 'AUDIO_ID',
    defaultMessage: 'Audio ID',
  },
  CONFERENCE: {
    id: 'CONFERENCE',
    defaultMessage: 'Conference',
  },
  PRESENTATION: {
    id: 'PRESENTATION',
    defaultMessage: 'Webinar',
  },
  MORE: {
    id: 'MORE',
    defaultMessage: 'more',
  },
  LESS: {
    id: 'LESS',
    defaultMessage: 'less',
  },
});

const useStyles = makeStyles((theme) => ({
  inviteTabRoot: {
    padding: '0 12px',
  },
  inviteTabAutoComplete: {
    padding: 7,
  },
  contentBox: {
    marginLeft: 0,
    marginRight: 0,
    width: 480,
    height: 530,
    paddingBottom: 48,
    overflow: 'hidden',
  },
  selectedOptionList: {
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'auto',
    maxHeight: '40vh',
  },
  tabs: {
    width: '100%',
    maxWidth: 600,
    padding: theme.spacing(1),
  },
  title: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  descBtn: {
    display: 'block',
    margin: 'auto',
  },
  infoTab: {
    maxHeight: '60vh',
    overflowY: 'auto',
    padding: '0 24px',
    minWidth: 380,
  },
}));

const AddUserModal = ({
  attendees,
  chatSetupAction,
  configs,
  contacts,
  emitVideoInviteAction,
  meeting,
  setAttendeesAction,
  setMeetingAction,
  showAddUser,
  showAddUserAction,
  myAttendee,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const [autoCompleteValue, setAutoCompleteValue] = useState(null);
  const [customEmail, setCustomEmail] = useState(null);
  const [customNumber, setCustomNumber] = useState(null);
  const [showFullDesc, setShowFullDesc] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [tab, setTab] = useState(showAddUser.tab);

  useEffect(() => {
    setTab(showAddUser.tab);
  }, [showAddUser.tab]);

  const convertOptionToAttendee = (option) => {
    const email = (option.type === constants.ITEM_TYPE_EMAIL_GUEST
    || option.type === constants.ITEM_TYPE_EMAIL_OFF_NET
    || option.type === constants.ITEM_TYPE_EMAIL_ON_NET)
      ? option.label
      : null;

    const phoneNumber = (option.type === constants.ITEM_TYPE_PHONE_CELL
      || option.type === constants.ITEM_TYPE_PHONE_GUEST
      || option.type === constants.ITEM_TYPE_PHONE_HOME
      || option.type === constants.ITEM_TYPE_PHONE_WORK)
      ? option.label
      : null;

    return {
      meeting_id: meeting.id,
      domain: meeting.domain,
      name: option.name,
      email,
      role: option.role,
      uid: option.uid,
      phonenumber: phoneNumber,
      contact_id: option.id,
      gravatar: option.gravatar,
    };
  };

  const handleTabChange = (event, newValue) => {
    setAutoCompleteValue('');
    setSelectedOptions([]);
    setTab(newValue);
  };

  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      showAddUserAction({ show: false, tab: 'info' });
    }
  };

  const handleCopyInfo = () => {
    const meetingName = meeting.name
      ? meeting.name
      : intl.formatMessage(messages[meeting.type.toUpperCase()]);

    let infoText = meetingName;

    if (meeting.description) {
      infoText += `\n\n${meeting.description}`;
    }

    infoText += `\n\n${intl.formatMessage(messages.MEETING_ID)}`;
    infoText += `\n${formatMeetingId(configs.confId)}`;

    infoText += `\n\n${intl.formatMessage(messages.MEETING_URL)}`;
    infoText += `\nhttps://${meeting.hostname}/video/?id=${meeting.id}${meeting.password_required ? `&p=${meeting.password}` : ''}`;

    // eslint-disable-next-line max-len, react/prop-types
    let dialInNumbers = nsUtils.formatPhoneNumber(meeting.dial_in_numbers, configs.PORTAL_LOCALIZATION_NUMBER_FORMAT);
    // eslint-disable-next-line react/prop-types
    if (configs.PORTAL_PHONENUMBER_HIDE_DOMESTIC_ONE === 'no' && (meeting.dial_in_numbers.startsWith('1') && !dialInNumbers.startsWith('1'))) {
      dialInNumbers = `1 ${dialInNumbers}`;
    }
    infoText += `\n\n${intl.formatMessage(messages.DIAL_IN_NUMBER)}`;
    infoText += `\n${dialInNumbers}`;

    let audioId = '';
    if (meeting && meeting.my_audio_id) {
      audioId = meeting.my_audio_id;
    }
    if (myAttendee && myAttendee.audio_id) {
      audioId = myAttendee.audio_id;
    }
    if (audioId) {
      infoText += `\n\n${intl.formatMessage(messages.AUDIO_ID)}`;
      infoText += `\n${audioId}`;
    }

    copy(infoText);
  };

  const handleInvite = async () => {
    setFormDisabled(true);

    const decodedToken = nsToken.getDecoded();

    const clonedMeeting = _.cloneDeep(meeting);
    let clonedAttendees = _.cloneDeep(attendees);

    selectedOptions.forEach((option) => {
      const index = _.findIndex(clonedAttendees, (attendee) => attendee.id === option.user);

      if (index === -1) {
        clonedAttendees.push(convertOptionToAttendee(option));
      }
    });

    clonedMeeting.attendees = JSON.stringify(clonedAttendees);

    await nsApi.post({
      object: 'meeting',
      action: 'update',
    }, {
      ...clonedMeeting,
      user: decodedToken.user || decodedToken.userId,
      domain: decodedToken.domain,
    });

    await new Promise((res) => setTimeout(res, 300));

    const updatedMeeting = await getMeetingById({
      user: decodedToken.user,
      domain: decodedToken.domain,
      meetingId: meeting.id,
    });

    clonedAttendees = _.cloneDeep(updatedMeeting.attendees);
    delete updatedMeeting.attendees;
    setAttendeesAction(clonedAttendees);
    setMeetingAction(updatedMeeting);

    selectedOptions.forEach((option) => {
      if (option.type === constants.ITEM_TYPE_ON_NET) {
        chatSetupAction(option.user);
        emitVideoInviteAction(option.user);
      } else if (option.type === constants.ITEM_TYPE_PHONE_GUEST
        || option.type === constants.ITEM_TYPE_PHONE_WORK
        || option.type === constants.ITEM_TYPE_PHONE_CELL
        || option.type === constants.ITEM_TYPE_PHONE_HOME
      ) {
        const index = _.findIndex(clonedAttendees, (attendee) => attendee.contact_id === option.id
            || attendee.email === option.label
            || attendee.phonenumber === option.label);

        const randomNumber = Math.floor(100000000 + Math.random() * 900000000);
        const callId = `VideoInvite_${decodedToken.sub}_${randomNumber}`;
        nsApi.post({
          action: 'call',
          object: 'call',
          uid: decodedToken.sub,
          callid: callId,
          ...(index === -1 ? {} : { destHdr: `P-Served-Parameters: audioId=${clonedAttendees[index].audio_id}` }),
          destination: configs.videoBridgeId,
          origination: `sip:${option.label}@${decodedToken.domain}`,
        });
      }
    });

    setFormDisabled(false);
    setSelectedOptions([]);
  };

  const infoTab = () => {
    const meetingName = meeting.name
      ? meeting.name
      : <FormattedMessage {...messages[meeting.type.toUpperCase()]} />;

    let audioId = '';
    if (meeting && meeting.my_audio_id) {
      audioId = meeting.my_audio_id;
    }
    if (myAttendee && myAttendee.audio_id) {
      audioId = myAttendee.audio_id;
    }
    // eslint-disable-next-line max-len, react/prop-types
    let dialInNumbers = nsUtils.formatPhoneNumber(meeting.dial_in_numbers, configs.PORTAL_LOCALIZATION_NUMBER_FORMAT);
    // eslint-disable-next-line react/prop-types
    if (configs.PORTAL_PHONENUMBER_HIDE_DOMESTIC_ONE === 'no' && (meeting.dial_in_numbers.startsWith('1') && !dialInNumbers.startsWith('1'))) {
      dialInNumbers = `1 ${dialInNumbers}`;
    }

    const toggleDesc = (e) => {
      e.preventDefault();
      setShowFullDesc(!showFullDesc);
    };

    const meetingDesc = () => {
      const MAX_CHAR_BREAKPOINT = 120;

      if (!meeting.description) {
        return '';
      }

      if (meeting.description.length < MAX_CHAR_BREAKPOINT) {
        return (
          <Typography className={classes.title} variant="body2" align="center">
            {meeting.description}
          </Typography>
        );
      }

      return showFullDesc ? (
        <Typography className={classes.title} variant="body2" align="center">
          {meeting.description}
          {' '}
          <div className={classes.descBtn}>
            <Button size="small" onClick={toggleDesc}>
              <FormattedMessage {...messages.LESS} />
            </Button>
          </div>
        </Typography>
      ) : (
        <Typography className={classes.title} variant="body2" align="center">
          {meeting.description.slice(0, MAX_CHAR_BREAKPOINT)}
          {'... '}
          <div className={classes.descBtn}>
            <Button size="small" onClick={toggleDesc}>
              <FormattedMessage {...messages.MORE} />
            </Button>
          </div>
        </Typography>
      );
    };

    return (
      <div className={classes.infoTab}>
        <Box mt={6}>
          <Typography className={classes.title} variant="subtitle1" align="center">
            {meetingName}
          </Typography>

          {meetingDesc()}
        </Box>
        <Box my={4}>
          <Typography className={classes.title} variant="subtitle2" align="center">
            <FormattedMessage {...messages.MEETING_ID} />
          </Typography>
          <Typography variant="subtitle1" align="center">
            {formatMeetingId(configs.confId)}
          </Typography>
        </Box>
        <Box my={4}>
          <Typography className={classes.title} variant="subtitle2" align="center">
            <FormattedMessage {...messages.MEETING_URL} />
          </Typography>
          <Typography variant="subtitle1" align="center">
            {`${meeting.hostname}/video/?id=${meeting.id}${meeting.password_required ? `&p=${meeting.password}` : ''}`}
          </Typography>
        </Box>
        <Box my={4}>
          <Typography className={classes.title} variant="subtitle2" align="center">
            <FormattedMessage {...messages.DIAL_IN_NUMBER} />
          </Typography>
          <Typography variant="subtitle1" align="center">
            {dialInNumbers}
          </Typography>
        </Box>
        {audioId && (
          <Box my={4}>
            <Typography className={classes.title} variant="subtitle2" align="center">
              <FormattedMessage {...messages.AUDIO_ID} />
            </Typography>
            <Typography variant="subtitle1" align="center">{audioId}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const inviteTab = () => {
    let optionsList = [];
    if (tab === 'invite' || tab === 'call') {
      optionsList = processSelectItems(meeting.user, contacts.contacts);
      if (tab === 'invite') {
        optionsList = _.compact(optionsList.map((option) => {
          if (option.type === constants.ITEM_TYPE_ON_NET
            || option.type === constants.ITEM_TYPE_EMAIL_ON_NET
            || option.type === constants.ITEM_TYPE_EMAIL_OFF_NET
          ) {
            return option;
          }

          return null;
        }));

        if (customEmail) {
          optionsList.push({
            type: constants.ITEM_TYPE_EMAIL_GUEST,
            label: customEmail,
            value: `${constants.ITEM_TYPE_EMAIL_GUEST}_${customEmail}`,
            role: 'attendee',
          });
        }
      }

      if (tab === 'call') {
        optionsList = _.compact(optionsList.map((option) => {
          if (option.type === constants.ITEM_TYPE_ON_NET
            || option.type === constants.ITEM_TYPE_PHONE_WORK
            || option.type === constants.ITEM_TYPE_PHONE_CELL
            || option.type === constants.ITEM_TYPE_PHONE_HOME
          ) {
            return option;
          }

          return null;
        }));

        if (customNumber) {
          optionsList.push({
            type: constants.ITEM_TYPE_PHONE_GUEST,
            label: customNumber,
            value: `${constants.ITEM_TYPE_PHONE_GUEST}_${customNumber}`,
            role: 'attendee',
          });
        }
      }
    }

    const filterOptions = (options, { inputValue }) => options.filter((option) => {
      const alreadySelected = selectedOptions.some((o) => o.id === option.id);
      let matchesSearch = false;

      if (option.name && option.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1) {
        matchesSearch = true;
      } else if (option.extension && option.extension.indexOf(inputValue) !== -1) {
        matchesSearch = true;
      } else if (option.email) {
        matchesSearch = option.email.some((e) => e.indexOf(inputValue.toLowerCase()) !== -1);
      } else if (option.label === inputValue.toLowerCase()) {
        matchesSearch = true;
      }

      return !alreadySelected && (!inputValue.trim().length || matchesSearch);
    });

    const getPrimaryText = (option, showRole) => {
      let text;
      switch (option.type) {
        case constants.ITEM_TYPE_ON_NET:
          text = option.name.trim() ? option.name : option.user;
          break;
        case constants.ITEM_TYPE_PHONE_GUEST:
          text = <FormattedMessage {...messages.NEW_NUMBER} />;
          break;
        case constants.ITEM_TYPE_PHONE_WORK:
          text = `${option.name} (Work)`;
          break;
        case constants.ITEM_TYPE_PHONE_CELL:
          text = `${option.name} (Mobile)`;
          break;
        case constants.ITEM_TYPE_PHONE_HOME:
          text = `${option.name} (Home)`;
          break;
        case constants.ITEM_TYPE_EMAIL_ON_NET:
        case constants.ITEM_TYPE_EMAIL_OFF_NET:
          text = `${option.name} (Email)`;
          break;
        case constants.ITEM_TYPE_EMAIL_GUEST:
          text = <FormattedMessage {...messages.NEW_EMAIL} />;
          break;
        default:
          text = '';
      }

      return (
        <>
          {text}
          {' '}
          { showRole && option.role === 'host' && (
            <Typography variant="caption" display="inline">
              <FormattedMessage {...messages.HOST} />
            </Typography>
          )}
          { showRole && option.role === 'presenter' && (
            <Typography variant="caption" display="inline">
              <FormattedMessage {...messages.PRESENTER} />
            </Typography>
          )}
          { showRole && option.role === 'attendee' && (
            <Typography variant="caption" display="inline">
              <FormattedMessage {...messages.ATTENDEE} />
            </Typography>
          )}
        </>
      );
    };

    const getSecondaryText = (option) => {
      switch (option.type) {
        case constants.ITEM_TYPE_ON_NET:
          return option.name.trim() ? option.user : option.uid;
        case constants.ITEM_TYPE_PHONE_GUEST:
        case constants.ITEM_TYPE_PHONE_WORK:
        case constants.ITEM_TYPE_PHONE_CELL:
        case constants.ITEM_TYPE_PHONE_HOME:
          // eslint-disable-next-line react/prop-types
          return nsUtils.formatPhoneNumber(option.value, configs.PORTAL_LOCALIZATION_NUMBER_FORMAT);
        case constants.ITEM_TYPE_EMAIL_ON_NET:
        case constants.ITEM_TYPE_EMAIL_OFF_NET:
          return `${_.get(option, ['email', 0], '')}`;
        case constants.ITEM_TYPE_EMAIL_GUEST:
          return option.label;
        default:
          return '';
      }
    };

    const handleOptionSelect = (e, option) => {
      if (!option) return;

      // handle when user presses enter
      if (!_.isPlainObject(option)
        // eslint-disable-next-line max-len, react/prop-types
        && (nsUtils.isPhoneNumber(option, configs.PORTAL_LOCALIZATION_NUMBER_FORMAT) || nsUtils.isEmailValid(option))
      ) {
        if (nsUtils.isEmailValid(option)) {
          setSelectedOptions([{
            type: constants.ITEM_TYPE_EMAIL_GUEST,
            label: option,
            value: `${constants.ITEM_TYPE_EMAIL_GUEST}_${option}`,
            role: 'attendee',
          }, ...selectedOptions]);
        }

        // eslint-disable-next-line react/prop-types
        if (nsUtils.isPhoneNumber(option, configs.PORTAL_LOCALIZATION_NUMBER_FORMAT)) {
          setSelectedOptions([{
            type: constants.ITEM_TYPE_PHONE_GUEST,
            label: nsUtils.cleanPhoneNumber(option),
            value: `${constants.ITEM_TYPE_PHONE_GUEST}_${nsUtils.cleanPhoneNumber(option)}`,
            role: 'attendee',
          }, ...selectedOptions]);
        }

        setCustomEmail(null);
        setCustomNumber(null);
        setAutoCompleteValue('');
      }

      if (_.isPlainObject(option)) {
        setSelectedOptions([option, ...selectedOptions]);
      }

      setCustomEmail(null);
      setCustomNumber(null);
      setAutoCompleteValue('');
    };

    const handleInputChange = (e) => {
      setCustomNumber(null);
      setCustomEmail(null);

      if (e) {
        // eslint-disable-next-line react/prop-types
        if (tab === 'call' && nsUtils.isPhoneNumber(e.target.value, configs.PORTAL_LOCALIZATION_NUMBER_FORMAT)) {
          const cleanNumber = nsUtils.cleanPhoneNumber(e.target.value);
          setCustomNumber(cleanNumber);
        } else if (tab === 'invite' && nsUtils.isEmailValid(e.target.value)) {
          setCustomEmail(e.target.value);
        }
      }
    };

    const handleRemoveSelected = (value) => {
      setSelectedOptions(_.filter(selectedOptions, (opt) => opt.value !== value));
    };

    const supportedRoles = [{
      id: 'host',
      name: <FormattedMessage {...messages.HOST} />,
    }, {
      id: 'presenter',
      name: <FormattedMessage {...messages.PRESENTER} />,
    }, {
      id: 'attendee',
      name: <FormattedMessage {...messages.ATTENDEE} />,
    }];

    const handleRoleChange = (value, id) => {
      const updatedOptions = selectedOptions.map((o) => {
        if (o.value === value) {
          const clonedOption = _.cloneDeep(o);
          clonedOption.role = id;
          return clonedOption;
        }
        return o;
      });
      setSelectedOptions(updatedOptions);
    };

    const renderListOption = (option) => (
      <ListOption
        key={option.value}
        onRemove={handleRemoveSelected}
        option={option}
        onChangeRole={handleRoleChange}
        primaryText={getPrimaryText(option, true)}
        secondaryText={getSecondaryText(option)}
        supportedRoles={supportedRoles}
        disabled={formDisabled}
      />
    );

    return (
      <>
        <Box mt={2} className={classes.inviteTabRoot}>
          <Autocomplete
            className={classes.inviteTabAutoComplete}
            autoComplete
            autoHighlight
            blurOnSelect
            freeSolo
            forcePopupIcon
            onChange={handleOptionSelect}
            value={autoCompleteValue}
            filterOptions={filterOptions}
            options={optionsList}
            getOptionLabel={() => ''}
            onInputChange={handleInputChange}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={intl.formatMessage(tab === 'invite'
                  ? messages.INVITE_SEARCH_PLACEHOLDER
                  : messages.CALL_SEARCH_PLACEHOLDER)}
                variant="filled"
                margin="dense"
                fullWidth
                required
              />
            )}
            renderOption={(params) => {
              if (params.type === constants.ITEM_TYPE_EMAIL_GUEST) {
                return (
                  <ListItem key={params.value} component="div" style={{ margin: 0 }} dense>
                    <ListItemAvatar>
                      <Avatar>
                        <EmailIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<FormattedMessage {...messages.NEW_EMAIL} />}
                      secondary={params.label}
                    />
                  </ListItem>
                );
              }

              if (params.type === constants.ITEM_TYPE_PHONE_GUEST) {
                return (
                  <ListItem key={params.value} component="div" style={{ margin: 0 }} dense>
                    <ListItemAvatar>
                      <Avatar>
                        <PhoneIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<FormattedMessage {...messages.NEW_NUMBER} />}
                      // eslint-disable-next-line max-len, react/prop-types
                      secondary={nsUtils.formatPhoneNumber(params.label, configs.PORTAL_LOCALIZATION_NUMBER_FORMAT)}
                    />
                  </ListItem>
                );
              }

              return (
                <ListItem key={params.value} component="div" style={{ margin: 0 }} dense>
                  <ListItemAvatar>
                    <UserAvatar user={params} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={getPrimaryText(params)}
                    secondary={getSecondaryText(params)}
                  />
                </ListItem>
              );
            }}
            disabled={formDisabled}
          />
        </Box>
        <Box mt={2}>
          <List className={classes.selectedOptionList}>
            {selectedOptions.map((option) => renderListOption(option))}
          </List>
        </Box>
      </>
    );
  };

  const canInvite = ALLOWED_WEBINAR_ROLES.includes(myAttendee.role);

  return (
    <Dialog
      open={showAddUser.show}
      onClose={handleClose}
      maxWidth="sm"
    >
      <Box m={1} mx={2} className={classes.contentBox}>
        {canInvite && (
          <Tabs
            indicatorColor="primary"
            value={tab}
            onChange={handleTabChange}
            centered
          >
            <Tab
              label={<FormattedMessage {...messages.JOIN_INFO} />}
              value="info"
            />
            <Tab
              label={<FormattedMessage {...messages.INVITE} />}
              value="invite"
            />
            <Tab
              label={<FormattedMessage {...messages.CALL} />}
              value="call"
            />
          </Tabs>
        )}
        {tab === 'info' && infoTab()}
        {(tab === 'invite' || tab === 'call') && inviteTab()}
      </Box>
      <DialogActions>
        {tab === 'info' && (
          <Button onClick={handleCopyInfo} color="primary">
            <FormattedMessage {...messages.COPY_MEETING_INFO} />
          </Button>
        )}
        {tab === 'invite' && (
          <Button onClick={handleInvite} color="primary" disabled={!selectedOptions.length || formDisabled}>
            <FormattedMessage {...messages.SEND_INVITE} />
          </Button>
        )}
        {tab === 'call' && (
          <Button onClick={handleInvite} color="primary" disabled={!selectedOptions.length || formDisabled}>
            <FormattedMessage {...messages.CALL} />
          </Button>
        )}
        <Button onClick={handleClose} color="primary" disabled={formDisabled}>
          <FormattedMessage {...messages.DONE} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddUserModal.propTypes = {
  attendees: attendeesShape.arrayOfShapes.isRequired,
  chatSetupAction: PropTypes.func.isRequired,
  configs: PropTypes.shape({
    confId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    guest: PropTypes.bool,
    videoBridgeId: PropTypes.string,
  }).isRequired,
  contacts: PropTypes.shape({
    contacts: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  emitVideoInviteAction: PropTypes.func.isRequired,
  meeting: meetingShape.propTypesShape.isRequired,
  setAttendeesAction: PropTypes.func.isRequired,
  setMeetingAction: PropTypes.func.isRequired,
  showAddUser: PropTypes.shape({
    show: PropTypes.bool,
    tab: PropTypes.string,
  }).isRequired,
  showAddUserAction: PropTypes.func.isRequired,
  myAttendee: attendeesShape.propTypesShape.isRequired,
};

const mapStateToProps = (state) => ({
  attendees: selectors.selectAttendeesList(state),
  configs: state.configs,
  contacts: selectors.selectContacts(state),
  meeting: selectors.selectMeeting(state),
  showAddUser: selectors.selectLayout(state).showAddUser,
  myAttendee: selectors.selectMyAttendee(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  chatSetupAction: events.chatSetup,
  emitVideoInviteAction: events.emitVideoInvite,
  setAttendeesAction: events.setAttendees,
  setMeetingAction: actions.setMeeting,
  showAddUserAction: actions.showAddUser,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal);
