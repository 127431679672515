import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  centered: {
    top: '50%',
    transform: 'translate(0, -50%)',
    color: 'inherit',
  },
  contained: {
    margin: '0 4px',
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 0, 0, 0.54) !important',
  },
}));

const ControlItem = ({
  type,
  variant,
  isToggled,
  onClick,
  enabledIcon,
  disabledIcon,
  enabledTooltip,
  disabledTooltip,
}) => {
  const classes = useStyles();

  let controlItem = '';

  if (type === 'menuItem') {
    controlItem = isToggled ? (
      <MenuItem onClick={onClick}>
        <ListItemIcon>
          {disabledIcon}
        </ListItemIcon>
        <Typography variant="inherit">
          {disabledTooltip}
        </Typography>
      </MenuItem>
    ) : (
      <MenuItem onClick={onClick}>
        <ListItemIcon>
          {enabledIcon}
        </ListItemIcon>
        <Typography variant="inherit">
          {enabledTooltip}
        </Typography>
      </MenuItem>
    );
  }

  if (type === 'iconButton' || type === 'text') {
    controlItem = isToggled ? (
      <Tooltip
        onClick={onClick}
        title={disabledTooltip}
        placement="bottom-start"
      >
        <IconButton
          className={
            classnames(
              type === 'iconButton' && classes.centered,
              variant === 'contained' && classes.contained,
            )
          }
        >
          {disabledIcon}
        </IconButton>
      </Tooltip>
    ) : (
      <Tooltip
        onClick={onClick}
        title={enabledTooltip}
        placement="bottom-start"
      >
        <IconButton
          className={
            classnames(
              type === 'iconButton' && classes.centered,
              variant === 'contained' && classes.contained,
            )
          }
        >
          {enabledIcon}
        </IconButton>
      </Tooltip>
    );
  }

  return controlItem;
};

ControlItem.defaultProps = {
  type: 'text',
  variant: 'vertical',
  isToggled: false,
  disabledIcon: {},
  disabledTooltip: '',
};

ControlItem.propTypes = {
  variant: PropTypes.string,
  isToggled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  enabledIcon: PropTypes.shape({}).isRequired,
  enabledTooltip: PropTypes.string.isRequired,
  disabledIcon: PropTypes.shape({}),
  disabledTooltip: PropTypes.string,
};

export default ControlItem;
