export const NEW_PRIVATE_CHAT = 'events/NEW_PRIVATE_CHAT';

/**
 *
 * @param payload
 * @return {{type: string, payload: *}}
 */
export const newPrivateChat = (payload) => ({
  type: NEW_PRIVATE_CHAT,
  payload,
});
