export const SHOW_SSO_ENROLL_PAGE = 'events/SHOW_SSO_ENROLL_PAGE';

/**
 * @param idtoken
 * @param vendor
 * @return {{type: string, payload: {idtoken: *,vendor: *}}}
 */
export const showSsoEnrollPage = (idtoken, vendor) => ({
  type: SHOW_SSO_ENROLL_PAGE,
  payload: {
    idtoken,
    vendor,
  },
});
