import getMeetings from './getMeetings';
import store from '../store';
import * as actions from '../actions';

export default ({
  user,
  domain,
}) => {
  const dataRequests = [
    getMeetings({
      user,
      domain,
      include_attendees: 'yes',
      sort: 'name asc',
      start: Math.floor(Date.now() / 1000),
      limit: 5,
      type: 'room',
    }),
    getMeetings({
      user,
      domain,
      start: Math.floor(Date.now() / 1000),
      limit: 5,
      type: 'meeting',
    }),
  ];

  return Promise.all(dataRequests).then((res) => {
    if (res[0]) {
      store.dispatch(actions.setRooms(res[0]));
    }

    if (res[1]) {
      store.dispatch(actions.setMeetings(res[1]));
    }
  });
};
