import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '@material-ui/core/Modal';

import * as actions from '../../actions';
import * as selectors from '../../selectors';
import DeviceSettingsPage from '../pages/device-settings/DeviceSettingsPage';

const DeviceReconfigDialog = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => selectors.selectLayout(state).showDeviceReconfigModal);

  const handleDismiss = () => {
    dispatch(actions.showDeviceReconfigModal(false));
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleDismiss}
    >
      <DeviceSettingsPage variant="modal" closeCallback={handleDismiss} />
    </Modal>
  );
};

export default DeviceReconfigDialog;
