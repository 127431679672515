export const RESTRICT_ATTENDEE_MULTI = 'events/RESTRICT_ATTENDEE_MULTI';

/**
 * @param attendeesList
 * @param restriction
 * @param successCallback
 * @returns {{payload: *, type: string}}
 */
export const restrictAttendeeMulti = (attendeesList, restriction, successCallback) => ({
  type: RESTRICT_ATTENDEE_MULTI,
  payload: {
    attendeesList,
    restriction,
    successCallback,
  },
});
