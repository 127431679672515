export const SEND_CHAT_MESSAGE = 'events/SEND_CHAT_MESSAGE';

/**
 * @param message
 * @return {{type: string, payload: *}}
 */
export const sendChatMessage = (message) => ({
  type: SEND_CHAT_MESSAGE,
  payload: message,
});
