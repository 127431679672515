import {
  UC_IS_OPEN,
  UC_ITEMS,
  UC_TAB_VALUE,
  UC_UNREAD_COUNT,
} from '../constants';

const defaultState = {
  isOpen: false,
  items: [],
  tabValue: 'chat',
  unreadCount: 0,
};

const reducers = {
  [UC_IS_OPEN]: (state, { payload }) => ({ isOpen: payload }),
  [UC_ITEMS]: (state, { payload }) => ({ items: payload }),
  [UC_TAB_VALUE]: (state, { payload }) => ({ tabValue: payload }),
  [UC_UNREAD_COUNT]: (state, { payload }) => ({ unreadCount: payload }),
};

export default {
  defaultState,
  reducers,
};
