import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';
import Drawer from '@material-ui/core/Drawer';

// layouts
import ConversationLayout from './video-layouts/ConversationLayout';
import GridLayout from './video-layouts/GridLayout';
import SpotlightLayout from './video-layouts/SpotlightLayout';

import MobileConversationLayout from './video-layouts/mobile/MobileConversationLayout';
import MobileGridLayout from './video-layouts/mobile/MobileGridLayout';
import MobileSoloLayout from './video-layouts/mobile/MobileSoloLayout';

// components
import Header from './header/Header';
import ChatBubbles from './header/ChatBubbles';
import SideTray from './side-tray/SideTray';
// import SpeakerNotify from './video-item/SpeakerNotify';
import HotKeyPage from './header/buttons/HotKeyPage';
import StartControl from './waiting-to-start-modal/StartControl';
import WaitingToStartModal from './waiting-to-start-modal/WaitingToStartModal';

import {
  ALLOWED_WEBINAR_ROLES,
  LAYOUT_TYPE_CONVERSATION,
  LAYOUT_TYPE_GRID,
  LAYOUT_TYPE_SPOTLIGHT,
  LAYOUT_TYPE_PICTURE,
  UC_DRAWER_WIDTH,
} from '../../../constants';
import * as selectors from '../../../selectors';

import meetingShape from '../../../shapes/meeting';
import videoShape from '../../../shapes/video';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
  },
  drawer: {
    width: UC_DRAWER_WIDTH,
  },
  drawerPaper: {
    width: UC_DRAWER_WIDTH,
    overflow: 'hidden',
  },
  content: {
    height: '100%',
    overflow: 'hidden',
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentTrayShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: UC_DRAWER_WIDTH,
  },
  headerContainer: {
    position: 'relative',
    margin: '12px 4px',
  },
  headerContainerMobile: {
    display: 'none',
  },
  headerContainerFullscreen: {
    position: 'absolute',
    width: '99%',
    zIndex: 9,
    opacity: 1,
  },
  inactive: {
    transition: 'opacity 2s',
    opacity: 0,
  },
  gridSizing: {
    height: '100%',
  },
  mobileLand: {
    height: '100%',
  },
  mobileGrid: {
    paddingTop: '8%',
    height: 'calc(100% - 8%)',
  },
  mobileSpot: {
    paddingTop: '8%',
    height: 'calc(100% - 8%)',
  },
  mobilePict: {
    height: '100%',
  },
}));

const Video = ({
  meeting,
  myAttendeeRole,
  ucInboxIsOpen,
  videoLayout,
  isMobileView,
}) => {
  const classes = useStyles();

  const [lastClick, setClick] = useState(0);
  const [mouseTimer, setMouseTimer] = useState(0);
  const [activityInterval, setActivityInterval] = useState(0);
  const [fullscreenRequested] = useState(false);
  const ACTIVITY_TIMEOUT = 2000;

  const isFullscreen = document.fullscreenElement
    || document.mozFullScreenElement
    || document.webkitFullscreenElement
    || document.msFullscreenElement;

  const setFromEvent = () => setMouseTimer(Date.now());

  useEffect(() => {
    if (isFullscreen) window.addEventListener('mousemove', setFromEvent);
    else window.removeEventListener('mousemove', setFromEvent);

    return () => {
      if (isFullscreen) window.removeEventListener('mousemove', setFromEvent);
    };
  }, [fullscreenRequested, isFullscreen]);

  useEffect(() => {
    if (isFullscreen) setTimeout(() => setActivityInterval(Date.now()), 1000);
  }, [activityInterval, fullscreenRequested, isFullscreen]);

  const lastActive = activityInterval - mouseTimer;

  const isWaitingToStartP = meeting.wait_for_host === 1
    && meeting.type === 'presentation'
    && !['started', 'complete', 'ended'].includes(meeting.status);

  const isWaitingToStartC = meeting.wait_for_host === 1 && !['started', 'complete', 'ended'].includes(meeting.status);

  const audioAllow = meeting.type === 'conference' || ALLOWED_WEBINAR_ROLES.includes(myAttendeeRole);

  const videoContent = () => {
    if (isMobileView === 1) {
      return (
        <div className={classes.content}>
          <div
            className={
              classnames(
                videoLayout === LAYOUT_TYPE_GRID && classes.mobileGrid,
                videoLayout === LAYOUT_TYPE_CONVERSATION && classes.mobileLand,
                videoLayout === LAYOUT_TYPE_SPOTLIGHT && classes.mobileSpot,
                videoLayout === LAYOUT_TYPE_PICTURE && classes.mobilePict,
              )
            }
          >
            { videoLayout !== LAYOUT_TYPE_PICTURE && <ChatBubbles /> }
            { videoLayout === LAYOUT_TYPE_GRID && <MobileGridLayout /> }
            { videoLayout === LAYOUT_TYPE_CONVERSATION && <MobileConversationLayout /> }
            { (videoLayout === LAYOUT_TYPE_PICTURE || videoLayout === LAYOUT_TYPE_SPOTLIGHT)
              && <MobileSoloLayout layout={videoLayout} /> }
          </div>
        </div>
      );
    }

    // default
    return (
      <div
        className={classnames(classes.content, { [classes.contentTrayShift]: ucInboxIsOpen })}
      >
        <div className={classes.gridSizing}>
          <div
            className={
              classnames(
                classes.headerContainer,
                { [classes.contentTrayShift]: ucInboxIsOpen },
                { [classes.headerContainerFullscreen]: isFullscreen },
                { [classes.inactive]: isFullscreen && lastActive > ACTIVITY_TIMEOUT },
              )
            }
          >
            <Header />
          </div>
          {audioAllow && <HotKeyPage />}
          <ChatBubbles />
          { videoLayout === LAYOUT_TYPE_SPOTLIGHT && <SpotlightLayout /> }
          { videoLayout === LAYOUT_TYPE_GRID && <GridLayout /> }
          { videoLayout === LAYOUT_TYPE_CONVERSATION && <ConversationLayout /> }

        </div>
      </div>
    );
  };

  /* removed <SpeakerNotify /> for now from right below videw layouts */

  /* thewebflash.com/toggling-fullscreen-mode-using-the-html5-fullscreen-api */
  const toggleFullScreen = () => {
    const elem = document.documentElement;

    if (!isFullscreen) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  };

  const onVideoClick = (event) => {
    // removing for now VID-822
    if (typeof notdefined === 'undefined') return;
    if (event.type === 'click') {
      if (Date.now() - lastClick < 400) {
        setClick(0);
        toggleFullScreen();
      } else {
        setClick(Date.now());
      }
    }
  };

  return (
    <div className={classes.root}>
      <div onClick={onVideoClick} onKeyDown={onVideoClick} role="button" tabIndex={-1}>
        {videoContent()}
      </div>

      <Drawer
        open={ucInboxIsOpen}
        variant="persistent"
        anchor="right"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {ucInboxIsOpen && (<SideTray />)}
      </Drawer>

      {(isWaitingToStartP || isWaitingToStartC) && (
        ALLOWED_WEBINAR_ROLES.includes(myAttendeeRole)
          ? <StartControl />
          : <WaitingToStartModal />
      )}
    </div>
  );
};

Video.propTypes = {
  meeting: meetingShape.propTypesShape.isRequired,
  myAttendeeRole: PropTypes.string.isRequired,
  ucInboxIsOpen: PropTypes.bool.isRequired,
  videoLayout: videoShape.shape.layout.isRequired,
  isMobileView: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  meeting: selectors.selectMeeting(state),
  myAttendeeRole: selectors.selectMyAttendee(state).role,
  ucInboxIsOpen: selectors.selectUcIsOpen(state),
  videoLayout: selectors.selectVideoLayout(state),
  isMobileView: selectors.selectMIV(state),
});

export default connect(mapStateToProps)(Video);
