import PropTypes from 'prop-types';

const shape = {
  carrier: PropTypes.string,
  dialed: PropTypes.string,
  direction: PropTypes.string,
  domain: PropTypes.string,
  from_num: PropTypes.string,
  from_ua: PropTypes.string,
  from_uid: PropTypes.string,
  id: PropTypes.number,
  participants: PropTypes.string,
  media_size: PropTypes.string,
  media_type: PropTypes.string,
  remotepath: PropTypes.string,
  session_id: PropTypes.string,
  status: PropTypes.string,
  rx_hostname: PropTypes.string,
  term_num: PropTypes.string,
  term_uid: PropTypes.string,
  text: PropTypes.string,
  timestamp: PropTypes.string,
  type: PropTypes.string,
};

const propTypesShape = PropTypes.shape(shape);

export default {
  shape,
  propTypesShape,
  arrayOfShapes: PropTypes.arrayOf(propTypesShape),
};
