import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import PeopleIcon from '@material-ui/icons/People';

import LayoutContainer from '../components/LayoutContainer';
import VideoItem from '../../video-item/VideoItem';
import GravatarItem from './GravatarItem';

import { postWebkitAction } from '../../../../../events';

import {
  selectOrientaion,
  selectVideoGridIds,
  selectParticipants,
  selectUserParticipant,
  selectVideoHideSelf,
  selectConfig,
} from '../../../../../selectors';

import {
  LAYOUT_TYPE_GRID,
  LAYOUT_ROTATE_LAND,
  LAYOUT_ROTATE_VERT,
} from '../../../../../constants';

const useStyles = makeStyles(() => ({
  mivGrid: {
    overflowX: 'hidden',
    overflowY: 'hidden',
    textAlign: 'center',
    transition: 'all 0.3s ease-in-out',
    padding: '4%',
  },
  selfVideoV: {
    right: '1%',
    bottom: '-3%',
    width: '30%',
    position: 'absolute',
  },
  self: {
    height: 'calc(100% - 12px)',
    margin: '4px',
    padding: '0 2px 0 2px',
    flex: '1 0 auto',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '16px',
    backgroundColor: '#717171',
    zIndex: -3,
  },
  bottomButton: {
    width: '100%',
    position: 'absolute',
    bottom: '2px',
  },
  halfWidth: {
    margin: '2px',
    padding: '4px',
    display: 'flex',
    borderRadius: '100px',
    zIndex: 4,
  },
}));

const MobileGridLayout = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const orientation = useSelector(selectOrientaion);
  const videoGridIds = useSelector(selectVideoGridIds);
  const participants = useSelector(selectParticipants);
  const participantSelf = useSelector(selectUserParticipant);
  const videoHideSelf = useSelector(selectVideoHideSelf);
  const videoGridLimit = useSelector((state) => selectConfig(state, 'PORTAL_MIV_VIDEO_LIMIT')) || 7;

  const landscape = useMemo(() => orientation === LAYOUT_ROTATE_LAND, [orientation]);
  console.log(`Orientation:${orientation} landscape:${landscape}`);

  const [expandFirst, setExpandFirst] = useState(true);

  const participantIds = useMemo(() => {
    const filtered = videoGridIds.filter((id) => _.get(participants, [id, 'videoTrackMuted']) !== true);
    const count = Math.min(filtered.length, Number(videoGridLimit));
    setExpandFirst(count <= 2 || (count + (videoHideSelf ? 0 : 1)) % 2);
    // if (filtered.length % 2 !== 0) {
    //   return [filtered.slice(0, 1), filtered.slice(1, Number(videoGridLimit))];
    // }
    return _.take(filtered, Number(videoGridLimit));
  }, [videoGridIds]);

  const gravatarsParticipants = useMemo(() => {
    const filtered = videoGridIds.filter((id) => _.get(participants, [id, 'videoTrackMuted']) === true);
    return filtered;
  });

  const showChat = () => {
    console.log('showChat clicked');
    dispatch(postWebkitAction({ action: 'SHOW_CHAT' }));
  };

  const showPeople = () => {
    console.log('showPeople clicked');
    dispatch(postWebkitAction({ action: 'SHOW_PEOPLE' }));
  };

  return (
    <LayoutContainer>
      <Grid
        container
        wrap="wrap"
        justifyContent="center"
        alignContent="center"
        columns={orientation === LAYOUT_ROTATE_VERT ? 12 : 48}
        className={classes.mivGrid}
      >
        { participantIds.map((id, i) => (
          <Grid
            item
            key={id}
            xs={i === 0 && expandFirst ? 10 : 6}
            sm={i === 0 && expandFirst ? 10 : 6}
          >
            <VideoItem
              participantId={id}
              isMobileCrop
              layout={LAYOUT_TYPE_GRID}
            />
          </Grid>
        ))}
        { !videoHideSelf && (
          <Grid
            item
            xs={participantIds.length === 1 ? 10 : 6}
            sm={participantIds.length === 1 ? 10 : 6}
          >
            <Grid
              container
            >
              <Grid
                item
                key={participantSelf.id}
                xs={6}
                sm={6}
              >
                <VideoItem
                  participantId={participantSelf.id}
                  layout={LAYOUT_TYPE_GRID}
                  isTrayItem
                  isMobileCrop
                />
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                style={{ zIndex: 2 }}
              >
                <div
                  className={classes.self}
                >
                  <Grid container>
                    { gravatarsParticipants.map((id) => (
                      <Grid
                        item
                        key={id}
                        xs={6}
                        sm={6}
                      >
                        <GravatarItem
                          participantId={id}
                          layout={LAYOUT_TYPE_GRID}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <div className={classes.bottomButton}>
                    <Grid
                      container
                      justify="center"
                      align="center"
                    >
                      <Grid item key="MIVChat" xs={6} sm={6}>
                        <Button
                          id="MIVChatToggle"
                          variant="contained"
                          className={classes.halfWidth}
                          onClick={showChat}
                        >
                          <QuestionAnswerIcon />
                        </Button>
                      </Grid>
                      <Grid item key="MIVPeople" xs={6} sm={6}>

                        <Button
                          id="MIVPeopleToggle"
                          variant="contained"
                          className={classes.halfWidth}
                          onClick={showPeople}
                        >
                          <PeopleIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </LayoutContainer>
  );
};

export default MobileGridLayout;
