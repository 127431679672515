import _ from 'lodash';
import { put, select } from 'redux-saga/effects';

import {
  selectAudioSession,
  selectUserMediaStatus,
  selectVideoRoom,
} from '../../selectors';
import { stopStream } from '../../utils/devices';
import * as actions from '../../actions';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* saveSelectedDevices() {
  try {
    const status = yield select(selectUserMediaStatus);
    const audioSession = yield select(selectAudioSession);
    const newStatus = { ...status };

    // update audio input device
    if (status.audioInputDeviceSelect) {
      newStatus.audioInputDevice = status.audioInputDeviceSelect;
      newStatus.audioInputDeviceSelect = null;
      localStorage.setItem('myLastAudioInput', _.get(status.audioInputDevice, 'deviceId'));

      // check if the audio bridge has already started
      // this case handles the device setting modal
      if (audioSession) {
        if (newStatus.audioMuted) {
          if (newStatus.audioStreamSelect) {
            stopStream(newStatus.audioStreamSelect);
          }
          newStatus.audioStreamSelect = null;
        } else {
          newStatus.audioStream = newStatus.audioStreamSelect;
          newStatus.audioStreamSelect = null;

          const pc = audioSession.sessionDescriptionHandler.peerConnection;
          if (pc) {
            pc.getLocalStreams().forEach((stream) => {
              stopStream(stream);
            });
          }

          const sd = audioSession.sessionDescriptionHandler;
          sd.setLocalMediaStream(newStatus.audioStream);
        }
      } else {
        // this means the audio session hasn't been started yet and stream should be ended
        // no need to do anything with streams since joinAudioBridge handles it during meeting start
        if (status.audioStream) {
          stopStream(status.audioStream);
          newStatus.audioStream = null;
        }

        if (status.audioStreamSelect) {
          stopStream(status.audioStreamSelect);
          newStatus.audioStreamSelect = null;
        }
      }
    }

    // update audio output device
    // no need to do anything with the output here since joinAudioBridge handles it
    if (status.audioOutputDeviceSelect) {
      newStatus.audioOutputDevice = status.audioOutputDeviceSelect;
      newStatus.audioOutputDeviceSelect = null;
      localStorage.setItem('myLastAudioOutput', _.get(status.audioOutputDevice, 'deviceId'));
    }

    // update video input device
    if (status.videoDeviceSelect) {
      newStatus.videoDevice = status.videoDeviceSelect;
      newStatus.videoDeviceSelect = null;
      localStorage.setItem('myLastVideoInput', _.get(status.videoDevice, 'deviceId'));

      if (!status.videoMuted) {
        // if a previous stream existed, stop it

        if (status.videoStreamSelect) {
          if (status.videoStream) {
            stopStream(status.videoStream);
          }
          newStatus.videoStream = status.videoStreamSelect;
        }
        newStatus.videoStreamSelect = null;

        newStatus.videoAspectRatio = status.videoAspectRatioSelect;
        newStatus.videoAspectRatioSelect = null;

        const track = newStatus.videoStream.getTracks()[0];
        const room = yield select(selectVideoRoom);
        if (room) {
          room.updateWebcamTrack(track);
        }
      } else {
        if (newStatus.videoStreamSelect) {
          stopStream(newStatus.videoStreamSelect);
        }
        newStatus.videoStreamSelect = null;
        newStatus.videoAspectRatioSelect = null;
      }
    }

    yield put(actions.updateMediaStatus(newStatus));
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: saveSelectedDevices';
    });
  }
}
