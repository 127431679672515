export const UPDATE_CONSUMER = 'events/UPDATE_CONSUMER';

/**
 *
 * @param consumer
 * @return {{type: string, payload: *}}
 */
export const updateConsumer = (consumer) => ({
  type: UPDATE_CONSUMER,
  payload: consumer,
});
