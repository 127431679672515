import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

import CamOn from '@material-ui/icons/Videocam';
import CamOff from '@material-ui/icons/VideocamOff';

import ControlItem from './ControlItem';

import attendeesShape from '../../../../shapes/attendees';
import * as events from '../../../../events';
import * as selectors from '../../../../selectors';

const messages = defineMessages({
  ENABLE_VIDEO: {
    id: 'ENABLE_SCREEN_SHARE',
    defaultMessage: 'Enable video',
  },
  DISABLE_VIDEO: {
    id: 'DISABLE_SCREEN_SHARE',
    defaultMessage: 'Disable video',
  },
});

const VideoControl = ({
  type,
  variant,
  attendee,
  restrictAttendee,
  attendees,
  myAttendee,
}) => {
  // I have no idea how attendee is ever null here, but it is
  if (!attendee || _.isEmpty(attendee)) return null;

  if (!myAttendee) return null; // myAttendee shouldn't ever be null either, but it is

  if (attendee.isSelf) return null;

  // eslint-disable-next-line max-len
  const stateAttendee = attendees.find((sAttendee) => sAttendee.attendee_id === attendee.attendee_id);
  if (!stateAttendee || _.isEmpty(stateAttendee)) return null;

  // access restrictions based on roles
  switch (myAttendee.role) {
    case 'host':
      if (['host'].includes(attendee.role) && !myAttendee.isOwner) {
        return null;
      }
      break;
    case 'presenter':
      if (['host', 'presenter'].includes(attendee.role)) {
        return null;
      }
      break;
    case 'attendee':
      return null;
    default:
      return null;
  }

  const intl = useIntl();

  const isToggled = Boolean(stateAttendee.video_restricted);

  const onToggleVideo = () => restrictAttendee(stateAttendee, 'video');

  return (
    <ControlItem
      type={type}
      variant={variant}
      isToggled={isToggled}
      onClick={onToggleVideo}
      enabledIcon={<CamOn />}
      disabledIcon={<CamOff />}
      enabledTooltip={intl.formatMessage(messages.DISABLE_VIDEO)}
      disabledTooltip={intl.formatMessage(messages.ENABLE_VIDEO)}
    />
  );
};

VideoControl.propTypes = {
  type: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  attendee: attendeesShape.propTypesShape.isRequired,
  myAttendee: attendeesShape.propTypesShape.isRequired,
  attendees: attendeesShape.arrayOfShapes.isRequired,
  restrictAttendee: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  attendees: selectors.selectConnectedAttendees(state),
  myAttendee: selectors.selectMyAttendee(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  restrictAttendee: events.restrictAttendee,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VideoControl);
