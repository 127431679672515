export const UPDATE_SPEAKER_ORDER = 'events/UPDATE_SPEAKER_ORDER';

/**
 *
 * @param res
 * @return {{type: string, payload: *}}
 */
export const updateSpeakerOrder = (res) => ({
  type: UPDATE_SPEAKER_ORDER,
  payload: res,
});
