import nsUiConfig from '@netsapiens/netsapiens-js/dist/ui-config';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';

export default ({
  domain,
  user,
}) => {
  const configsToRead = [
    nsUiConfig.get({
      configName: 'WS_SERVERS',
      domain,
      user,
      filter: ['wsSockets'],
      returnFirst: true,
    }),
    nsUiConfig.get({
      configName: 'PORTAL_*',
      domain,
      user,
      // filter: configFilter,
    }),
  ];

  return Promise.all(configsToRead).then((res) => {
    const configs = {};

    // process socket response
    if (res[0] && res[0].config_value && res[0].config_value.trim()) {
      const tempWs = res[0].config_value.split(',');

      if (tempWs) {
        const usSockets = [];
        for (let i = 0; i < tempWs.length; i += 1) {
          if (tempWs[i] && tempWs[i].trim()) {
            tempWs[i] = tempWs[i].trim();
            if (tempWs[i].indexOf(':') === -1) {
              usSockets.push(`wss://${tempWs[i]}:9002`);
            } else {
              usSockets.push(`wss://${tempWs[i]}`);
            }
          }
        }
        if (usSockets) {
          configs.uaSocketURLs = usSockets;
        }
      }
    }

    if (res[1] && Array.isArray(res[1])) {
      res[1].forEach((config) => {
        if (config.config_name === 'nsSocketHostName'
          && config.config_value
          && config.config_value.trim()
        ) {
          const tempWs = config.config_value.split(',');
          if (tempWs) {
            const nsSockets = [];
            for (let i = 0; i < tempWs.length; i += 1) {
              if (tempWs[i] && tempWs[i].trim()) {
                tempWs[i] = tempWs[i].trim();
                if (tempWs[i].indexOf(':') === -1) {
                  nsSockets.push(`wss://${tempWs[i]}:8001`);
                } else {
                  nsSockets.push(`wss://${tempWs[i]}`);
                }
              }
            }
            if (nsSockets) {
              configs.nsSocketURLs = nsSockets;
            }
          }
        } else {
          configs[config.config_name] = config.config_value;
        }
      });
    }

    if (configs.PORTAL_LOCALIZATION_NUMBER_FORMAT_MASTER !== 'yes' && configs.PORTAL_LOCALIZATION_NUMBER_FORMAT) {
      configs.PORTAL_LOCALIZATION_NUMBER_FORMAT = null;
    }

    // format video number
    if (configs.videoPhoneNumber) {
      // eslint-disable-next-line max-len
      configs.videoPhoneNumber = nsUtils.formatPhoneNumber(configs.videoPhoneNumber, configs.PORTAL_LOCALIZATION_NUMBER_FORMAT);
    }

    return configs;
  });
};
