import nsApi from '@netsapiens/netsapiens-js/dist/api';

/**
 *
 * @param videoBridgeId
 * @return {Promise}
 */
export default (videoBridgeId) => nsApi.get({
  object: 'participant',
  action: 'read',
  conference_match: videoBridgeId,
  format: 'json',
}).then((res) => res || []);
