import { select, put } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import _ from 'lodash';

import bugsnagClient from '../../bugsnag';
import { selectChatSessions, selectMyAttendee } from '../../selectors';
import { setChatSessions } from '../../actions';

// eslint-disable-next-line import/prefer-default-export, no-unused-vars, require-yield
export function* deleteChatSession({ payload: sessionId }) {
  try {
    const decodedToken = nsToken.getDecoded();
    const myAttendee = yield select(selectMyAttendee);
    const sessions = yield select(selectChatSessions);
    const updatedSessions = _.omit(sessions, [sessionId]);
    yield put(setChatSessions(updatedSessions));

    yield nsApi.post({
      object: 'messagesession',
      action: 'delete',
      domain: decodedToken.domain,
      user: myAttendee.attendee_id,
      session_id: sessionId,
    }, {
      object: 'messagesession',
      action: 'delete',
      domain: decodedToken.domain,
      user: myAttendee.attendee_id,
      session_id: sessionId,
    });
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: deleteChatSession';
      event.addMetadata('payload', sessionId);
    });
  }
}
