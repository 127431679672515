import nsToken from '@netsapiens/netsapiens-js/dist/token';
import { select } from 'redux-saga/effects';

import { selectSocket } from '../../selectors';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* chatSetup({ payload }) {
  try {
    const decodedToken = nsToken.getDecoded();
    const data = {
      domain: decodedToken.domain, // domain for both users
      a: decodedToken.user, // user doing the invite
      b: payload, // user being invited
    };

    const socket = yield select((state) => selectSocket(state));
    socket.emit('chatsetup', data);
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: chatSetup';
      event.addMetadata('payload', payload);
    });
  }
}
