import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';

import config from '../../../../config.json';

export const BROWSER_CHECK_FAIL = 'BROWSER_CHECK_FAIL';

/**
 * Checks if the browser is compatible with the app
 * Supported browsers are configured in the app package.json file
 * @return {Promise} promise returns null or rejects with BROWSER_CHECK_FAIL
 * @private
 */
export default (configBrowsers) => {
  const configToBrowserMapping = {
    videoChromeSupport: 'Chrome',
    videoFirefoxSupport: 'Firefox',
    videoIESupport: 'MSIE',
    videoEdgeSupport: 'Edge',
    videoSafariSupport: 'Safari',
  };

  const supportedBrowsers = [];
  for (let i = 0; i < configBrowsers.length; i += 1) {
    supportedBrowsers.push({
      name: configToBrowserMapping[configBrowsers[i].configName],
      lowestVersion: configBrowsers[i].val,
      excludedVersions: [],
      lowestRenderVersion: null,
    });
  }

  const browserList = [...supportedBrowsers, ...config.supportedBrowsers];

  if (nsBrowser.isSupported(browserList)) {
    return Promise.resolve();
  }
  return Promise.reject(BROWSER_CHECK_FAIL);
};
