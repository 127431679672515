import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Person from '@material-ui/icons/Person';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Delete from '@material-ui/icons/Delete';
import EmailIcon from '@material-ui/icons/Email';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import PhoneIcon from '@material-ui/icons/Phone';

import UserAvatar from '../../../../components/UserAvatar';
import { ITEM_TYPE_EMAIL_GUEST, ITEM_TYPE_PHONE_GUEST } from '../constants';

const useStyles = makeStyles(() => ({
  buttonsContainer: {
    right: 0,
    float: 'right',
    position: 'absolute',
    whiteSpace: 'nowrap',
    display: 'none',
  },
  showHButtons: {
    display: 'inline-block',
  },
}));

const ListOption = ({
  primaryText,
  onChangeRole,
  onRemove,
  option,
  secondaryText,
  supportedRoles,
  disabled,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [hovering, setHovering] = useState(false);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handelChangeRole = (id) => {
    handleClose();
    onChangeRole(option.value, id);
  };

  const handleMouseEnter = () => {
    setHovering(true);
  };

  const handleMouseLeave = () => {
    setHovering(false);
  };

  return (
    <ListItem
      key={option.value}
      style={{ margin: 0 }}
      onMouseMove={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      dense
    >
      <ListItemAvatar>
        {(option.type === ITEM_TYPE_EMAIL_GUEST && (
          <Avatar>
            <EmailIcon />
          </Avatar>
        ))}
        {(option.type === ITEM_TYPE_PHONE_GUEST && (
          <Avatar>
            <PhoneIcon />
          </Avatar>
        ))}
        {(option.type !== ITEM_TYPE_EMAIL_GUEST && option.type !== ITEM_TYPE_PHONE_GUEST && (
          <UserAvatar user={option} />
        ))}
      </ListItemAvatar>
      <ListItemText
        primary={primaryText}
        secondary={secondaryText}
      />
      <div className={classnames(classes.buttonsContainer, 'animate__animated animate__fadeIn', { [classes.showHButtons]: hovering })}>
        <Button
          id="OptionChangeRole"
          variant="text"
          onClick={handleOpen}
          disabled={disabled}
        >
          <Person />
          <ArrowDropDown />
        </Button>
        <Button
          id="DeleteOption"
          variant="text"
          onClick={() => onRemove(option.value)}
          disabled={disabled}
        >
          <Delete />
        </Button>
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {supportedRoles.map((role) => (
          <MenuItem
            key={role.id}
            selected={role.id === option.role}
            onClick={() => handelChangeRole(role.id)}
          >
            {role.name}
          </MenuItem>
        ))}
      </Menu>
    </ListItem>
  );
};

ListOption.defaultProps = {
  primaryText: '',
  secondaryText: '',
};

ListOption.propTypes = {
  primaryText: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  onChangeRole: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  option: PropTypes.shape({
    id: PropTypes.string,
    role: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  secondaryText: PropTypes.string,
  supportedRoles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.shape({}),
  })).isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ListOption;
