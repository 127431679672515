import { SPEAKER_ACTIVITY } from '../constants';

const defaultState = {};

const reducers = {
  // eslint-disable-next-line max-len
  [SPEAKER_ACTIVITY]: (state, { payload }) => ({
    ...state,
    [payload.id]: {
      isActive: payload.isActive,
      isAudible: payload.isAudible,
    },
  }),
};

export default {
  defaultState,
  reducers,
};
