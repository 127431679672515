import _ from 'lodash';
import { put, select } from 'redux-saga/effects';

import nsApi from '@netsapiens/netsapiens-js/dist/api';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { LAYOUT_TYPE_CONVERSATION } from '../../constants';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* saveMeeting({ payload }) {
  try {
    const { saveMode, successCallback, suppressLoader } = payload;

    const attendees = yield select(selectors.selectAttendeesList);
    const meeting = yield select(selectors.selectMeeting);

    // check if the socket is connected
    const isConnected = yield select(selectors.selectSocketConnected);
    if (!isConnected) {
      yield put(actions.openAlertDialog({
        title: 'SOCKET_CONNECTION_ERROR_TITLE',
        content: 'SOCKET_CONNECTION_ERROR_BODY',
        caption: 'SOCKET_CONNECTION_ERROR_CAPTION',
      }));
      return;
    }

    if (!suppressLoader) {
      yield put(actions.showLoaderPage());
    }

    const clonedMeeting = _.cloneDeep(meeting);

    if (attendees && attendees.length > 0) {
      clonedMeeting.attendees = JSON.stringify(attendees);
    } else if (meeting.attendees && saveMode === 'new') {
      clonedMeeting.attendees = JSON.stringify(meeting.attendees);
    }

    const user = yield select(selectors.selectUser);

    if (saveMode === 'new' && clonedMeeting.type === 'presentation') {
      clonedMeeting.layout = LAYOUT_TYPE_CONVERSATION;
    }

    const toSave = _.omit(clonedMeeting, ['update_ts']);
    toSave.user = user.userId || null;
    toSave.domain = user.domain || null;

    yield nsApi.post({
      object: 'meeting',
      action: saveMode === 'new' ? 'create' : 'update',
    }, toSave);

    if (_.isFunction(successCallback)) {
      successCallback();
    }
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: saveMeeting';
      event.addMetadata('payload', saveMeeting);
    });
    yield put(actions.snackBarError('SAVE_FAILED'));
    yield put(actions.showMeetingConfig('new', 'start'));
  }
}
