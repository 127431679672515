export const ADD_PARTICIPANT = 'events/ADD_PARTICIPANT';

/**
 *
 * @param participant
 * @return {{type: string, payload: *}}
 */
export const addParticipant = (participant) => ({
  type: ADD_PARTICIPANT,
  payload: participant,
});
