import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

import ScreenShare from '@material-ui/icons/ScreenShare';
import StopScreenShare from '@material-ui/icons/StopScreenShare';

import ControlItem from './ControlItem';

import * as events from '../../../../events';
import * as selectors from '../../../../selectors';
import attendeesShape from '../../../../shapes/attendees';
import meetingShape from '../../../../shapes/meeting';

const messages = defineMessages({
  ENABLE_SCREEN_SHARE: {
    id: 'ENABLE_SCREEN_SHARE',
    defaultMessage: 'Enable screen share',
  },
  DISABLE_SCREEN_SHARE: {
    id: 'DISABLE_SCREEN_SHARE',
    defaultMessage: 'Disable screen share',
  },
});

const ScreenShareControl = ({
  type,
  variant,
  attendee,
  restrictAttendee,
  myAttendee,
  attendees,
  meeting,
  handleClose,
}) => {
  // I have no idea how attendee is ever null here, but it is
  if (!attendee || _.isEmpty(attendee)) return null;

  if (!myAttendee) return null; // myAttendee shouldn't ever be null either, but it is

  if (attendee.isSelf) return null;

  if (meeting.type === 'presentation') return null;

  // eslint-disable-next-line max-len
  const stateAttendee = attendees.find((sAttendee) => sAttendee.attendee_id === attendee.attendee_id);
  if (!stateAttendee || _.isEmpty(stateAttendee)) return null;

  // access restrictions based on roles
  switch (myAttendee.role) {
    case 'host':
      if (['host'].includes(stateAttendee.role) && !myAttendee.isOwner) {
        return null;
      }
      break;
    case 'presenter':
      if (['host', 'presenter'].includes(stateAttendee.role)) {
        return null;
      }
      break;
    case 'attendee':
      return null;
    default:
      return null;
  }

  const intl = useIntl();
  const isToggled = Boolean(stateAttendee.screenshare_restricted);

  const onToggle = () => {
    restrictAttendee(stateAttendee, 'screenshare');
    handleClose();
  };

  return (
    <ControlItem
      type={type}
      variant={variant}
      onClick={onToggle}
      isToggled={isToggled}
      enabledIcon={<ScreenShare />}
      disabledIcon={<StopScreenShare />}
      enabledTooltip={intl.formatMessage(messages.DISABLE_SCREEN_SHARE)}
      disabledTooltip={intl.formatMessage(messages.ENABLE_SCREEN_SHARE)}
    />
  );
};

ScreenShareControl.propTypes = {
  type: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  attendee: attendeesShape.propTypesShape.isRequired,
  meeting: meetingShape.propTypesShape.isRequired,
  myAttendee: attendeesShape.propTypesShape.isRequired,
  restrictAttendee: PropTypes.func.isRequired,
  attendees: attendeesShape.arrayOfShapes.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  meeting: selectors.selectMeeting(state),
  myAttendee: selectors.selectMyAttendee(state),
  attendees: selectors.selectConnectedAttendees(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  restrictAttendee: events.restrictAttendee,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ScreenShareControl);
