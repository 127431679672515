import React, {
  useCallback, useState, useRef, useEffect,
} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import _ from 'lodash';
import classnames from 'classnames';
import moment from 'moment';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useDropzone } from 'react-dropzone';
import 'emoji-mart/css/emoji-mart.css';

import {
  makeStyles,
  Avatar,
  Button,
  Chip,
  Collapse,
  Grid,
  IconButton,
  TextField,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import AttachFile from '@material-ui/icons/AttachFile';
import CreateIcon from '@material-ui/icons/Create';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Search from '@material-ui/icons/Search';

import toBase64 from '../../../../../services/toBase64';

import { ALLOWED_WEBINAR_ROLES } from '../../../../../constants';
import * as actions from '../../../../../actions';
import * as events from '../../../../../events';
import * as selectors from '../../../../../selectors';
import attendeesShape from '../../../../../shapes/attendees';
import store from '../../../../../store';

import BubbleInbound from './BubbleInbound';
import BubbleOutbound from './BubbleOutbound';
import BubbleUnreadCount from './BubbleUnreadCount';
import MeetingStatusChange from './MeetingStatusChange';
import PeopleList from '../components/PeopleList';
import PrivateSessionsList from '../components/PrivateSessionsList';
import MessageInput from './MessageInput';

const SEARCH_TYPE_ATTENDEES = 'attendees';

const messages = defineMessages({
  ALL: {
    id: 'ALL',
    defaultMessage: 'All',
  },
  HOSTS: {
    id: 'HOSTS',
    defaultMessage: 'Hosts',
  },
  PRIVATE: {
    id: 'PRIVATE',
    defaultMessage: 'Private',
  },
  NEW_PRIVATE_MESSAGE: {
    id: 'NEW_PRIVATE_MESSAGE',
    defaultMessage: 'New Private Message',
  },
  ROOM: {
    id: 'ROOM',
    defaultMessage: 'Room',
  },
  Q_AND_A: {
    id: 'Q_AND_A',
    defaultMessage: 'Q&A',
  },
  SEARCH: {
    id: 'SEARCH',
    defaultMessage: 'Search',
  },
  DROP_HELPTEXT: {
    id: 'DROP_HELPTEXT',
    defaultMessage: 'Drop images or files here',
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    height: 'calc(100vh - 91px)',
  },
  subNavigationButtons: {
    margin: theme.spacing(0, 0, 1, 1),
  },
  subNavigationButtonTitle: {
    margin: theme.spacing(0.4, 0, 1.4, 1),
    color: 'rgba(0, 0, 0, 0.54)',
  },
  chip: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  chipLabel: {
    paddingLeft: theme.spacing(1),
  },
  chipSelected: {
    backgroundColor: '#E1F4FE !important',
    color: '#0083D0 !important',
  },
  headerContainer: {
    marginTop: 3,
    width: '100%',
    paddingTop: theme.spacing(1),
    backgroundColor: '#FAFAFA',
    boxShadow: theme.shadows[1],
  },
  messagesContainer: {
    backgroundColor: '#F0F0F0',
    overflow: 'auto',
    padding: theme.spacing(1, 1, 1, 1),
  },
  messageInputContainer: {
    padding: theme.spacing(1),
  },
  messageInput: {
    paddingTop: '2px',
  },
  chips: {
    padding: theme.spacing(0, 0, 1, 1),
  },
  chatAttachmentChips: {
    padding: theme.spacing(1),
  },
  emojiPicker: {
    display: 'none',
  },
  emojiToggleOn: {
    color: '#0083D0 !important',
    backgroundColor: '#E1F4FE !important',
  },
  searchInput: {
    margin: theme.spacing(2, 1, 0, 1),
  },
  searchAdornmentRoot: {
    marginTop: '0px !important',
  },
  listContainer: {
    padding: theme.spacing(1),
    height: 'calc(100vh - 264px)',
    overflowY: 'auto',
  },
  unreadBadge: {
    backgroundColor: '#e91e63 !important',
  },
  badge: {
    marginBottom: '0px !important',
    backgroundColor: '#00bcd4',
    color: '#fff',
    right: -4,
    top: -4,
    zIndex: 9,
    height: 18,
    fontSize: 12,
    lineHeight: 1,
    borderRadius: 9,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 5px',
    position: 'absolute',
  },
  backArrow: {
    marginRight: '16px',
  },
  createIcon: {
    paddingRight: '4px',
  },
  dragOverlay: {
    transition: 'all 150ms ease-in-out',
    opacity: 0,
    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, .7)',
    zIndex: 1,
    textAlign: 'center',
  },
  dragActive: {
    opacity: 1,
  },
  attachIcon: {
    transform: 'rotate(90deg)',
    width: 150,
    height: 150,
  },
  dropHelper: {
    color: '#666',
    width: 300,
    height: 300,
    margin: 'auto',
    top: '50%',
    marginTop: '-45%',
    display: 'block',
  },
}));

/* onDrop can't reference stateful chat sessions so they get dumped into this global
 * TODO: move all chat message manipulation out of this component and into events so
 * this hack isn't needed */
// let globalChatSessions = [];
// let globalChatSession = {};

const ChatTab = ({
  // inherited
  attendees,

  // state
  chatSessions,
  chatSession,
  chatSessionInFocus,
  chatSessionMessages,
  myAttendee,
  fullAttendees,
  meetingType,
  instanceId,
  chatAttachments,

  // dispatch
  setChatInFocus,
  setChatSessions,
  setUcUnreadCount,
  deleteChatSession,
  newPrivateChat,
  updateSessionToRead,
  addChatAttachment,
  removeChatAttachment,
}) => {
  const classes = useStyles();
  const messagesEndRef = useRef(null);
  const intl = useIntl();

  const [privateChipToggled, setPrivateChipToggle] = useState(_.get(chatSession, 'type') === 'private');
  const [searchString, setSearchString] = useState('');

  const [filteredAttendees, setFilteredAttendees] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState([]);

  const [showSessionSearch, setShowSessionSearch] = useState(false);
  const [showAttendeeSearch, setShowAttendeeSearch] = useState(false);

  const [meetingSession, setMeetingSession] = useState(null);
  const [hostSession, setHostSession] = useState(null);
  const [qaSession, setQaSession] = useState(null);
  const [privateSessions, setPrivateSessions] = useState([]);
  const [isNearBottom, setIsNearBottom] = useState(false);

  const hasRightsToMeeting = meetingType === 'conference'
    || ALLOWED_WEBINAR_ROLES.includes(myAttendee.role);

  // calculate total unread count of private sessions
  let privateSessionsUnreadCount = 0;
  if (typeof privateSessions !== 'undefined' && privateSessions !== []) {
    for (let i = 0; i < privateSessions.length; i += 1) {
      if (typeof privateSessions[i].unreadMessageCount !== 'undefined'
        && privateSessions[i].unreadMessageCount !== 0) {
        privateSessionsUnreadCount += privateSessions[i].unreadMessageCount;
      }
    }
  }

  // for scroll down effect
  // unread count bubble logic
  const unreadCount = _.get(chatSession, 'unreadMessageCount');

  let lastMessage = null;

  const filterAttendees = (str) => _.filter(attendees, (attendee) => {
    // omit self
    if (attendee.isSelf) return false;

    // return true if the search string is empty
    if (!str) {
      return true;
    }

    const loweredSearchString = str.toLowerCase();

    // check the attendee name
    if (attendee.name && attendee.name.toLowerCase().includes(loweredSearchString)) {
      return true;
    }

    // check the attendee uid
    if (attendee.uid && attendee.uid.includes(loweredSearchString)) {
      return true;
    }

    // check the attendee email
    return attendee.email && attendee.email.includes(loweredSearchString);
  });

  const filterSortSessions = (str) => _.orderBy(_.filter(privateSessions, (session) => {
    // return true if the search string is empty
    if (!str) {
      return true;
    }

    const loweredSearchString = str.toLowerCase();
    const attendee = _.find(attendees, { attendee_id: session.remoteAttendeeId });

    // check the attendee name
    if (attendee.name && attendee.name.toLowerCase().includes(loweredSearchString)) {
      return true;
    }

    // check the attendee uid
    if (attendee.uid && attendee.uid.includes(loweredSearchString)) {
      return true;
    }

    // check the attendee email
    return attendee.email && attendee.email.includes(loweredSearchString);
  }), ['lastMessageTimestamp'], ['desc']);

  const setChatInputFocus = () => {
    setTimeout(() => {
      if (document.getElementById('messageInputField') !== null) {
        document.getElementById('messageInputField').focus();
      }
    }, 1000);
  };

  // only update sessions when chat sessions have changed
  useEffect(() => {
    const sessionsArr = Object.values(chatSessions);
    // globalChatSessions = chatSessions;
    // globalChatSession = chatSession;
    setMeetingSession(_.find(sessionsArr, { type: 'meeting' }));
    setHostSession(_.find(sessionsArr, { type: 'host' }));
    setQaSession(_.find(sessionsArr, { type: 'qa' }));
    setPrivateSessions(_.filter(sessionsArr, { type: 'private' }));
  }, [chatSessions]);

  // update the filtered attendees list when
  // attendees array changes or attendee search is displayed
  useEffect(() => {
    if (showAttendeeSearch) {
      setFilteredAttendees(filterAttendees(searchString));
    }
  }, [attendees, showAttendeeSearch]);

  // update the filtered private sessions list when
  // attendees array changes or attendee search is displayed
  useEffect(() => {
    if (showSessionSearch) {
      setFilteredSessions(filterSortSessions(searchString));
    }
  }, [privateSessions, showSessionSearch]);

  // handles the cases for starting a new chat from this tab or people tab
  // also defaults if no chat session in focus
  useEffect(() => {
    if (chatSessionInFocus && !showSessionSearch) {
      setSearchString('');
      setShowSessionSearch(false);
      setShowAttendeeSearch(false);
    } else if (!showSessionSearch && !showAttendeeSearch) {
      if (meetingSession) {
        setChatInFocus(meetingSession.id);
      } else if (hostSession) {
        setChatInFocus(hostSession.id);
      } else if (qaSession) {
        setChatInFocus(qaSession.id);
      } else if (privateSessions.length) {
        setChatInFocus(privateSessions[0].id);
      }
    }
    setChatInputFocus();
  }, [chatSessionInFocus, meetingSession, hostSession, qaSession, privateSessions]);

  const scrollToBottom = () => {
    // check for scroll to bottom not being able to be called
    if (!chatSession
      || typeof messagesEndRef.current === 'undefined'
      || messagesEndRef.current === null
    ) {
      return;
    }

    messagesEndRef.current.scrollIntoView({ behavior: 'instant' });
    setIsNearBottom(true);
    // set session's unread to 0
    const clonedChatSessions = _.cloneDeep(selectors.selectChatSessions(store.getState()));
    if (clonedChatSessions[chatSession.id].unreadMessageCount !== 0) {
      // set total unread count down by that much...
      const ucUnReadCountOriginal = selectors.selectUcUnReadCount(store.getState());
      let diff = ucUnReadCountOriginal - clonedChatSessions[chatSession.id].unreadMessageCount;
      if (diff < 0) {
        diff = 0;
      }
      if (!Number.isNaN(diff)) {
        setUcUnreadCount(diff);
      }

      clonedChatSessions[chatSession.id].unreadMessageCount = 0;
      setChatSessions(clonedChatSessions);

      // set session as read
      updateSessionToRead({
        sessionId: chatSession.id,
        fromAttendeeId: myAttendee.attendee_id,
      });
    }
  };

  const scrollToBottomOnSend = () => {
    // check for scroll to bottom not being able to be called
    if (!chatSession
      || typeof messagesEndRef.current === 'undefined'
      || messagesEndRef.current === null
    ) {
      return;
    }

    messagesEndRef.current.scrollIntoView({ behavior: 'instant' });
    setIsNearBottom(true);
  };

  // if already on bottom, set unread to 0, scroll to bottom again after render
  const scrollFunction = (event) => {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      setIsNearBottom(true);

      // set chatsession unread to 0
      const clonedChatSessions = _.cloneDeep(selectors.selectChatSessions(store.getState()));
      if (clonedChatSessions[chatSession.id].unreadMessageCount !== 0) {
        // set total unread count down by that much...
        const ucUnReadCountOriginal = selectors.selectUcUnReadCount(store.getState());
        let diff = ucUnReadCountOriginal - clonedChatSessions[chatSession.id].unreadMessageCount;
        if (diff < 0) {
          diff = 0;
        }
        if (!Number.isNaN(diff)) {
          setUcUnreadCount(diff);
        }

        clonedChatSessions[chatSession.id].unreadMessageCount = 0;
        setChatSessions(clonedChatSessions);

        // set session as read
        updateSessionToRead({
          sessionId: chatSession.id,
          fromAttendeeId: myAttendee.attendee_id,
        });
      }
    }

    // set is near bottom threshold to 90% as well
    if (chatSession === null || typeof chatSession === 'undefined') {
      return;
    }
    const sCont = document.getElementById(`${chatSession.id}_scrollContainer`);
    if (typeof sCont === 'undefined' || sCont === null) {
      return;
    }
    const scrollPerc = ((sCont.offsetHeight + sCont.scrollTop) / sCont.scrollHeight) * 100;
    if (scrollPerc > 90) {
      setIsNearBottom(true);
    } else {
      setIsNearBottom(false);
    }
  };

  // check if it is on the bottom, so incoming messages will push the column down more
  const checkIfScrollToBottom = () => {
    if (chatSession === null || typeof chatSession === 'undefined') {
      return;
    }
    const sCont = document.getElementById(`${chatSession.id}_scrollContainer`);
    if (typeof sCont === 'undefined' || sCont === null) {
      return;
    }
    const scrollPerc = ((sCont.offsetHeight + sCont.scrollTop) / sCont.scrollHeight) * 100;

    if ((scrollPerc > 90 && scrollPerc !== 100) || isNearBottom) {
      scrollToBottom();
    }
  };

  const addAttachment = async (FileObject) => {
    const base64 = await toBase64(FileObject);
    const base64ToSend = base64.split(',')[1];
    addChatAttachment({
      file: FileObject,
      fromAttendeeId: myAttendee.attendee_id,
      destination: chatSession.remoteAttendeeId,
      instanceId,
      base64ToSend,
    });
    setChatInputFocus();
  };

  const removeAttachment = async (attachmentId) => {
    removeChatAttachment(attachmentId);
  };

  const chatAttachmentChips = () => {
    const content = [];
    Object.keys(chatAttachments).forEach((key) => {
      const attachment = chatAttachments[key];
      const name = (attachment.file.name.length > 20)
        ? `${attachment.file.name.substring(0, 35)}...`
        : attachment.file.name;
      content.push(
        <Chip
          className={classes.chip}
          label={name}
          variant="outlined"
          key={`chatAttachment_${key}`}
          onDelete={() => removeAttachment(key)}
        />,
      );
    });
    return content;
  };

  useEffect(checkIfScrollToBottom, [chatSessionMessages]);
  useEffect(scrollToBottom, [chatSessionInFocus]);
  useEffect(setChatInputFocus, [chatSessionInFocus]);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      addAttachment(file);
    });
  }, []);

  const { getRootProps, isDragActive } = useDropzone({ onDrop });

  // handles room, hosts or qa chip clicks
  const handleChipClick = (sessionId) => () => {
    if (privateChipToggled) {
      setSearchString('');
      setShowAttendeeSearch(false);
      setShowSessionSearch(false);
      setPrivateChipToggle(false);
    }

    setChatInFocus(sessionId);
    scrollToBottom();
    setChatInputFocus();
  };

  // handles room, hosts or qa chip clicks
  const handlePrivateChipClick = () => {
    if (!privateChipToggled) {
      setSearchString('');
      setPrivateChipToggle(true);
      setShowSessionSearch(true);
      setChatInputFocus();
    }
  };

  // handles private chip clicks
  const handleNewPrivateMessageClick = () => {
    setSearchString('');
    setShowSessionSearch(false);
    setShowAttendeeSearch(true);
  };

  // handles the back button in the session search view
  const handlePrivateSessionSearchBackClick = () => {
    setSearchString('');
    setShowSessionSearch(true);
    setShowAttendeeSearch(false);
  };

  // handles search input changes and updates filtered arrays
  const handleSearchChange = (type, str) => {
    setSearchString(str);
    if (type === SEARCH_TYPE_ATTENDEES) {
      setFilteredAttendees(filterAttendees(str));
    } else {
      setFilteredSessions(filterSortSessions(str));
    }
  };

  // handles starting a new private chat session
  // when an attendee is clicked in the new session view
  const handleAttendeeClick = useCallback((attendee) => {
    setChatInFocus(null);
    newPrivateChat(attendee);
  }, []);

  // handles the back button in the session search view
  const handlePrivateSessionClick = (sessionId) => {
    setSearchString('');
    setShowAttendeeSearch(false);
    setShowSessionSearch(false);
    setChatInFocus(sessionId);
    scrollToBottom();
  };

  // handles the back button in the private chat session view
  const handlePrivateSessionBackClick = () => {
    setChatInFocus(null);
    setSearchString('');
    setShowSessionSearch(true);
    setShowAttendeeSearch(false);
  };

  const deleteSession = (sessionId) => {
    deleteChatSession(sessionId);
  };

  const scrollToBottomCallback = useCallback(scrollToBottom, []);
  const scrollToBottomOnSendCallback = useCallback(scrollToBottomOnSend, []);
  return (
    <>
      <div
        className={classnames(
          classes.dragOverlay,
          isDragActive && classes.dragActive,
        )}
      >
        <Avatar
          className={classes.dropHelper}
        >
          <div style={{ marginTop: 50 }}>
            <AttachFile className={classes.attachIcon} />
          </div>
          <Typography variant="body2">
            <FormattedMessage {...messages.DROP_HELPTEXT} />
          </Typography>
        </Avatar>
      </div>
      <Grid
        className={classes.root}
        container
        wrap="nowrap"
        direction="column"
        {...getRootProps()}
      >
        <Grid item={false} className={classes.headerContainer}>
          <div className={classes.chips}>
            {meetingSession && (
              <Chip
                className={classnames(
                  classes.chip,
                  { [classes.chipSelected]: _.get(chatSession, 'type') === 'meeting' && !privateChipToggled },
                )}
                variant="outlined"
                onClick={handleChipClick(_.get(meetingSession, 'id'))}
                label={(
                  <>
                    <FormattedMessage {...messages.ROOM} />
                    {(typeof meetingSession.unreadMessageCount !== 'undefined'
                      && meetingSession.unreadMessageCount !== 0) && (
                      <div className={classnames('animate__animated animate__bounce', classes.unreadBadge, classes.badge)}>
                        {` ${meetingSession.unreadMessageCount}`}
                      </div>
                    )}
                  </>
                )}
              />
            )}
            {hostSession && (
              <Chip
                className={classnames(
                  classes.chip,
                  { [classes.chipSelected]: _.get(chatSession, 'type') === 'host' && !privateChipToggled },
                )}
                variant="outlined"
                onClick={handleChipClick(_.get(hostSession, 'id'))}
                label={(
                  <>
                    <FormattedMessage {...messages.HOSTS} />
                    {(typeof hostSession.unreadMessageCount !== 'undefined' && hostSession.unreadMessageCount !== 0) && (
                      <div className={classnames('animate__animated animate__bounce', classes.unreadBadge, classes.badge)}>
                        {` ${hostSession.unreadMessageCount}`}
                      </div>
                    )}
                  </>
                )}
              />
            )}
            {qaSession && (
              <Chip
                className={classnames(
                  classes.chip,
                  { [classes.chipSelected]: _.get(chatSession, 'type') === 'qa' && !privateChipToggled },
                )}
                variant="outlined"
                onClick={handleChipClick(false, _.get(qaSession, 'id'))}
                label={(
                  <>
                    <FormattedMessage {...messages.Q_AND_A} />
                    {(typeof qaSession.unreadMessageCount !== 'undefined'
                      && qaSession.unreadMessageCount !== 0) && (
                      <div className={classnames('animate__animated animate__bounce', classes.unreadBadge, classes.badge)}>
                        {` ${qaSession.unreadMessageCount}`}
                      </div>
                    )}
                  </>
                )}
              />
            )}
            <Chip
              className={classnames(classes.chip, { [classes.chipSelected]: privateChipToggled })}
              variant="outlined"
              label={(
                <>
                  <FormattedMessage {...messages.PRIVATE} />
                  {(!_.isEmpty(privateSessions) && privateSessionsUnreadCount !== 0) && (
                    <div className={classnames('animate__animated animate__bounce', classes.unreadBadge, classes.badge)}>
                      {` ${privateSessionsUnreadCount}`}
                    </div>
                  )}
                </>
              )}
              onClick={handlePrivateChipClick}
            />
          </div>
          <Collapse in={privateChipToggled}>
            {showSessionSearch && (
              <Button
                id="ChatTabNewPrivateMessage"
                className={classnames('animate__animated animate__fadeIn', classes.subNavigationButtons)}
                color="primary"
                onClick={handleNewPrivateMessageClick}
              >
                <CreateIcon className={classnames(classes.createIcon)} />
                <FormattedMessage {...messages.NEW_PRIVATE_MESSAGE} />
              </Button>
            )}
            {showAttendeeSearch && (
              <div
                className={classnames('animate__animated animate__fadeIn', classes.subNavigationButtonTitle)}
              >
                <IconButton onClick={handlePrivateSessionSearchBackClick} size="small" className={classnames(classes.backArrow)}>
                  <ArrowBack />
                </IconButton>
                {' '}
                <FormattedMessage {...messages.NEW_PRIVATE_MESSAGE} />
              </div>
            )}
            {!showSessionSearch && !showAttendeeSearch && _.get(chatSession, 'type') === 'private' && (
              <div
                className={classnames('animate__animated animate__fadeIn', classes.subNavigationButtonTitle)}
              >
                <IconButton onClick={handlePrivateSessionBackClick} size="small" className={classnames(classes.backArrow)}>
                  <ArrowBack />
                </IconButton>
                {_.get(_.find(fullAttendees, { attendee_id: chatSession.remoteAttendeeId }), 'name', '')}
              </div>
            )}
          </Collapse>
        </Grid>
        {!showSessionSearch && !showAttendeeSearch && !!chatSession && (
          <>
            <Grid item xs>
              <Grid
                container
                direction="column"
                onScroll={scrollFunction}
                style={{ height: '100%', padding: '0,8px' }}
              >
                <Grid item xs className={classes.messagesContainer} id={`${chatSession.id}_scrollContainer`}>
                  {chatSessionMessages.map((m) => {
                    if (typeof m.event_id !== 'undefined') {
                      let detailDef = '';
                      if (m.details) {
                        detailDef = m.details;
                      }
                      if (!hasRightsToMeeting) return <div />;
                      return (
                        <MeetingStatusChange
                          key={m.event_id}
                          event={m.event}
                          details={detailDef}
                          timestamp={m.timestamp}
                          myAttendeeId={myAttendee.attendee_id}
                          scrollToBottom={scrollToBottomCallback}
                        />
                      );
                    }
                    const lastInChain = !lastMessage
                      || m.type !== lastMessage.type
                      || m.direction !== lastMessage.direction
                      || m.from_attendee_id !== lastMessage.from_attendee_id
                      || moment(m.timestamp).fromNow() !== lastMessage.fromNow;
                    lastMessage = m;
                    // if attendee does not exist, use default name of from_uid
                    const attendee = _.find(fullAttendees, { attendee_id: m.from_attendee_id })
                      || { name: m.from_uid };
                    if (m.from_attendee_id === myAttendee.attendee_id) {
                      return (
                        <BubbleOutbound
                          key={`${m.type}${m.id}${m.direction}${m.from_attendee_id}${m.timestamp}`}
                          lastInChain={lastInChain}
                          text={m.text}
                          timestamp={m.timestamp}
                          remotepath={m.remotepath}
                          mediatype={m.media_type}
                          sending={m.sending}
                          errorSending={m.errorSending}
                          scrollToBottom={scrollToBottomCallback}
                          deleted={m.deleted}
                          id={m.id}
                          sessionId={m.session_id}
                        />
                      );
                    }
                    return (
                      <>
                        <BubbleInbound
                          key={`${m.type}${m.id}${m.direction}${m.from_attendee_id}${m.timestamp}`}
                          gravatar={_.get(attendee, 'gravatar')}
                          lastInChain={lastInChain}
                          initials={_.get(attendee, 'initials')}
                          text={m.text}
                          timestamp={m.timestamp}
                          remotepath={m.remotepath}
                          mediatype={m.media_type}
                          fromName={attendee.name}
                          scrollToBottom={scrollToBottom}
                          deleted={m.deleted}
                          id={m.id}
                          sessionId={m.session_id}
                        />
                      </>
                    );
                  })}
                  <div ref={messagesEndRef} />
                </Grid>
                <BubbleUnreadCount
                  unreadCount={unreadCount}
                  scrollToBottom={scrollToBottomCallback}
                  chatSessionId={chatSession.id}
                  isAtEnd={isNearBottom}
                />
              </Grid>
            </Grid>
            <div className={classes.chatAttachmentChips}>
              {chatAttachmentChips()}
            </div>
            <MessageInput
              onMessage={scrollToBottomOnSendCallback}
              onAttachment={addAttachment}
            />
          </>
        )}
        {showSessionSearch && (
          <>
            <div className={classes.listContainer}>
              <PrivateSessionsList
                sessions={filteredSessions}
                onDeleteSession={deleteSession}
                onSessionClick={handlePrivateSessionClick}
              />
            </div>
          </>
        )}
        {showAttendeeSearch && (
          <>
            <div className={classes.searchInput}>
              <TextField
                id="SideTraySearch"
                variant="filled"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" classes={{ root: classes.searchAdornmentRoot }}>
                      <Search />
                    </InputAdornment>
                  ),
                }}
                value={searchString}
                onChange={(e) => handleSearchChange(SEARCH_TYPE_ATTENDEES, e.target.value)}
                placeholder={intl.formatMessage(messages.SEARCH)}
                fullWidth
              />
            </div>
            <div className={classes.listContainer}>
              <PeopleList
                attendees={filteredAttendees}
                onAttendeeClick={handleAttendeeClick}
                hideOptions
                titleMargin
              />
            </div>
          </>
        )}
      </Grid>
    </>
  );
};

ChatTab.propTypes = {
  attendees: attendeesShape.arrayOfShapes.isRequired,
  myAttendee: attendeesShape.propTypesShape.isRequired,
  fullAttendees: attendeesShape.arrayOfShapes.isRequired,

  meetingType: PropTypes.string.isRequired,
  instanceId: PropTypes.string.isRequired,

  chatSessionMessages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  chatSessions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  chatSessionInFocus: PropTypes.shape({}).isRequired,
  chatSession: PropTypes.shape({
    id: PropTypes.string.isRequired,
    remoteAttendeeId: PropTypes.string.isRequired,
  }).isRequired,

  addChatAttachment: PropTypes.func.isRequired,
  removeChatAttachment: PropTypes.func.isRequired,
  chatAttachments: PropTypes.shape({}).isRequired,

  deleteChatSession: PropTypes.func.isRequired,
  newPrivateChat: PropTypes.func.isRequired,
  setChatInFocus: PropTypes.func.isRequired,
  setChatSessions: PropTypes.func.isRequired,
  setUcUnreadCount: PropTypes.func.isRequired,
  updateSessionToRead: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const chatSessionInFocus = selectors.selectChatInFocus(state);
  const meeting = selectors.selectMeeting(state);

  return {
    chatSessions: selectors.selectChatSessions(state),
    chatSession: selectors.selectChatSession(state, chatSessionInFocus),
    chatSessionInFocus,
    chatSessionMessages: selectors.selectChatSessionMessages(state, chatSessionInFocus),
    chatAttachments: selectors.selectChatAttachments(state),
    myAttendee: selectors.selectMyAttendee(state),
    fullAttendees: selectors.selectAttendeesList(state),
    meetingType: meeting.type,
    instanceId: meeting.instance_id,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setChatInFocus: actions.setChatInFocus,
  setChatSessions: actions.setChatSessions,
  setUcUnreadCount: actions.setUcUnreadCount,
  deleteChatSession: events.deleteChatSession,
  newPrivateChat: events.newPrivateChat,
  updateSessionToRead: events.updateSessionToRead,
  addChatAttachment: events.addChatAttachment,
  removeChatAttachment: events.removeChatAttachment,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChatTab);
