import * as constants from '../constants';

/**
 * Splits out contacts, by contact's info, creates an item for each info type
 * Note: The item values need to be unique or
 * it will break dropdown hover effects and causes multiple select issues
 * @param userId
 * @param contacts
 * @return {Array}
 */
export default (userId, contacts) => {
  const items = [];
  if (contacts) {
    for (let i = 0; i < contacts.length; i += 1) {
      if (contacts[i].user !== userId) {
        if (contacts[i].extension) {
          items.push({
            ...contacts[i],
            itemGroupType: constants.ITEM_GROUP_TYPE_NET,
            label: contacts[i].extension,
            value: `${constants.ITEM_TYPE_ON_NET}_${contacts[i].extension}`,
            type: constants.ITEM_TYPE_ON_NET,
            role: 'attendee',
          });
        }

        if (contacts[i].work_phone) {
          items.push({
            ...contacts[i],
            itemGroupType: constants.ITEM_GROUP_TYPE_PHONE,
            label: contacts[i].work_phone,
            value: `${constants.ITEM_TYPE_PHONE_WORK}_${contacts[i].work_phone}`,
            type: constants.ITEM_TYPE_PHONE_WORK,
            role: 'attendee',
          });
        }

        if (contacts[i].cell_phone) {
          items.push({
            ...contacts[i],
            itemGroupType: constants.ITEM_GROUP_TYPE_PHONE,
            label: contacts[i].cell_phone,
            value: `${constants.ITEM_TYPE_PHONE_CELL}_${contacts[i].cell_phone}`,
            type: constants.ITEM_TYPE_PHONE_CELL,
            role: 'attendee',
          });
        }

        if (contacts[i].home_phone) {
          items.push({
            ...contacts[i],
            itemGroupType: constants.ITEM_GROUP_TYPE_PHONE,
            label: contacts[i].home_phone,
            value: `${constants.ITEM_TYPE_PHONE_HOME}_${contacts[i].home_phone}`,
            type: constants.ITEM_TYPE_PHONE_HOME,
            role: 'attendee',
          });
        }

        if (contacts[i].email) {
          for (let emailIndex = 0; emailIndex < contacts[i].email.length; emailIndex += 1) {
            const itemType = contacts[i].user
              ? constants.ITEM_TYPE_EMAIL_ON_NET
              : constants.ITEM_TYPE_EMAIL_OFF_NET;

            items.push({
              ...contacts[i],
              itemGroupType: constants.ITEM_GROUP_TYPE_EMAIL,
              label: contacts[i].email[emailIndex],
              value: `${itemType}_${contacts[i].email[emailIndex]}_${emailIndex}`,
              type: itemType,
              role: 'attendee',
            });
          }
        }
      }
    }
  }
  return items;
};
