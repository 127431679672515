import { CONF_ID_ERROR_ID } from '../constants';

const defaultState = {
  errorId: null,
};

const reducers = {
  [CONF_ID_ERROR_ID]: (state, { payload }) => ({ errorId: payload }),
};

export default {
  defaultState,
  reducers,
};
