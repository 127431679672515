import { select, put } from 'redux-saga/effects';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* endScreenShare({ payload: partialMeetingUpdate }) {
  try {
    const room = yield select((state) => state.videoRoom.room);
    if (room) {
      room.endScreenShare(partialMeetingUpdate || {});
    }

    const mediaStatus = yield select(selectors.selectUserMediaStatus);
    mediaStatus.screenShareMuted = true;
    mediaStatus.screenShareStream = null;
    yield put(actions.updateMediaStatus(mediaStatus));
    yield put(actions.isScreenShareOverlayDismissed(false));
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: endScreenShare';
      event.addMetadata('payload', partialMeetingUpdate);
    });
  }
}
