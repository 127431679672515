export default (meetingId) => {
  if (meetingId === undefined) {
    return false;
  }

  if (meetingId === null) {
    return false;
  }

  if (typeof meetingId === 'string') {
    return meetingId.length === 9;
  }

  if (typeof meetingId === 'number') {
    return (meetingId >= 100000000 && meetingId <= 999999999);
  }

  return false;
};
