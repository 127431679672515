import React from 'react';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import { useSelector } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ComputerIcon from '@material-ui/icons/Computer';
import MicOffIcon from '@material-ui/icons/MicOff';
import PhoneIcon from '@material-ui/icons/Phone';
import PersonIcon from '@material-ui/icons/Person';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';

import meeting from '../../../../../shapes/meeting';

import AttendeeAvatar from '../../../../components/AttendeeAvatar';
import ControlsIcons from '../../controls/ControlsIcons';
import ControlsMenu from '../../controls/ControlsMenu';

import * as selectors from '../../../../../selectors';

const messages = defineMessages({
  OWNER: {
    id: 'OWNER',
    defaultMessage: 'Owner',
  },
});

const useStyles = makeStyles((theme) => ({
  badgeIcon: {
    fontSize: '.75rem',
  },
  btn: {
    margin: theme.spacing(1, 1, 0, 1),
  },
  disabledDevice: {
    color: '#B00020',
  },
  restrictedDevice: {
    background: '#eee !important',
    '& $disabledDevice': {
      opacity: 0.2,
    },
  },
  identity: {
    paddingLeft: theme.spacing(0.5),
    opacity: 0.5,
    maxWidth: '90%',
    display: 'inline-block',
  },
  clickable: {
    cursor: 'pointer',
    padding: `${theme.spacing(0.5)}px !important`,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.09)',
    },
  },
  mic: {
    color: '#1A73E8',
  },
  section: {
    margin: theme.spacing(0, 1),
  },
  sectionTitle: {
    opacity: 0.5,
  },
  text: {
    marginLeft: theme.spacing(1),
  },
  userAvatarContainer: {
    height: theme.spacing(6),
    position: 'relative',
    marginRight: theme.spacing(1.5),
    display: 'inline-block',
  },
  leftBadge: {
    position: 'absolute',
    bottom: 2,
    left: -3,
    width: 18,
    height: 18,
    backgroundColor: '#fff',
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);',
    borderRadius: 9,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightBadge: {
    position: 'absolute',
    bottom: 2,
    width: 18,
    height: 18,
    right: 0,
    backgroundColor: '#fff',
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);',
    borderRadius: 9,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'opacity 0.3s',
  },
  attendeeActions: {
    transition: 'opacity 250ms',
    whitespace: 'nowrap',
    position: 'absolute',
    color: '#ddd',
    display: 'none',
    right: 8,
    top: 6,
    '& button': {
      marginLeft: 6,
      padding: 3,
      fontSize: '1.125rem',
    },
  },
  attendeeInfo: {
    textOverflow: 'ellipsis',
  },
  showActions: {
    textOverflow: 'ellipsis',
  },
  root: {
    position: 'relative',
    padding: theme.spacing(0, 0.5, 0.5, 0.5),
    '&:hover $attendeeActions': {
      display: 'block',
    },
    '&:hover $showActions': {
      maxWidth: '55%',
      position: 'relative',
    },
  },
}));

const getIdentity = (attendee, localizationNumber) => {
  let connectionIcon = <PersonIcon fontSize="small" />;

  if (attendee.audio_participant_match) {
    if (attendee.audio_participant_match.includes('vb')) {
      connectionIcon = <ComputerIcon fontSize="small" />;
    } else {
      connectionIcon = <PhoneIcon fontSize="small" />;
    }
  }

  const id = _.get(attendee, 'uid', '').split('@');
  if (id && _.get(id, ['0']) && _.get(id, ['0']) !== 'guest') {
    return {
      icon: connectionIcon,
      text: _.get(id, ['0']),
    };
  }

  const email = _.get(attendee, 'email');
  if (email) {
    return {
      icon: connectionIcon,
      text: email,
    };
  }

  const phoneNumber = _.get(attendee, 'phonenumber');
  if (phoneNumber) {
    return {
      icon: connectionIcon,
      text: nsUtils.formatPhoneNumber(phoneNumber, localizationNumber),
    };
  }

  return null;
};

const Attendee = ({
  attendeeId,
  hideOptions,
  onAttendeeClick,
}) => {
  const classes = useStyles();
  const attendee = useSelector(((state) => selectors.selectAttendee(state, attendeeId)));
  const speaking = useSelector(((state) => selectors.selectSpeakerActivity(state, attendeeId)));
  const localizationNumber = useSelector(((state) => selectors.selectConfig(state, 'PORTAL_LOCALIZATION_NUMBER_FORMAT')));

  if (!attendee) {
    console.error('missing attendee:', attendeeId);
    return null;
  }

  let userId = attendee.id === 'guest' ? `guest_${attendee.attendee_id}` : attendee.id;
  if (!userId && attendee.attendee_id) {
    userId = attendee.attendee_id;
  }

  let attendeeMediaStatus = {};

  try {
    attendeeMediaStatus = useSelector(((state) => selectors.selectMediaStatus(state, userId)));
  } catch (e) {
    console.error(e);
  }

  const identity = getIdentity(attendee, localizationNumber);

  const hasCam = _.get(attendeeMediaStatus, 'hasCamDevice') && _.get(attendeeMediaStatus, 'hasCamPermissions');
  const hasMic = _.get(attendeeMediaStatus, 'hasMicDevice') && _.get(attendeeMediaStatus, 'hasMicPermissions');
  const audioMuted = _.get(attendeeMediaStatus, 'audioMuted');
  const videoMuted = _.get(attendeeMediaStatus, 'videoMuted');
  const isActive = _.get(speaking, 'isActive');

  const showActions = (!attendee.isSelf || meeting.type === 'presentation') && !hideOptions;

  return (
    <Grid
      className={classnames(classes.root, { [classes.clickable]: onAttendeeClick })}
      container
      alignContent="flex-end"
      onClick={() => {
        if (onAttendeeClick) {
          onAttendeeClick(attendee);
        }
      }}
    >
      <Grid item={false} className={classes.userAvatarContainer}>
        <AttendeeAvatar attendee={attendee} isActive={isActive} />

        {(!hasCam || videoMuted) && (
          <div
            className={classnames(
              classes.leftBadge,
              attendee.video_restricted && classes.restrictedDevice,
            )}
          >
            <VideocamOffIcon className={classnames(classes.badgeIcon, classes.disabledDevice)} />
          </div>
        )}

        {(!hasMic || audioMuted) && (
          <div className={classes.rightBadge}>
            <MicOffIcon className={classnames(classes.badgeIcon, classes.disabledDevice)} />
          </div>
        )}

        {!audioMuted && isActive && (
          <div className={classes.rightBadge}>
            <VolumeUpIcon
              className={classnames(classes.badgeIcon, classes.mic)}
            />
          </div>
        )}
      </Grid>
      <Grid item xs style={{ overflow: 'hidden' }}>
        <Grid container direction="column">
          <Grid item style={{ width: '100%' }}>
            <Typography
              variant="body2"
              display="inline-block"
              noWrap
              className={classnames(classes.attendeeInfo, showActions && classes.showActions)}
            >
              {attendee.name}
              {attendee.isOwner && (
                <Typography variant="caption" display="inline">
                  &nbsp;
                  <FormattedMessage {...messages.OWNER} />
                </Typography>
              )}
            </Typography>
          </Grid>

          {identity && (
            <Grid item style={{ width: '100%' }}>
              <Grid container alignItems="center">
                {identity.icon}
                <Typography
                  className={classnames(classes.identity, showActions && classes.showActions)}
                  variant="body2"
                  noWrap
                >
                  {identity.text}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      {showActions && (
        <Grid item={false} className={classes.attendeeActions}>
          <ControlsIcons attendee={attendee} hideEnlarge />
          <ControlsMenu attendee={attendee} />
        </Grid>
      )}
    </Grid>
  );
};

Attendee.defaultProps = {
  hideOptions: false,
  onAttendeeClick: null,
};

Attendee.propTypes = {
  attendeeId: PropTypes.string.isRequired,
  hideOptions: PropTypes.bool,
  onAttendeeClick: PropTypes.func,
};

export default React.memo(Attendee);
