export const JOIN_CONFERENCE = 'events/JOIN_CONFERENCE';

/**
 * @param confId
 * @return {{type: string, payload: *}}
 */
export const joinConference = (confId) => ({
  type: JOIN_CONFERENCE,
  payload: confId,
});
