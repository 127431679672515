import PropTypes from 'prop-types';

const shape = {
  isSelf: PropTypes.bool,
  isSharing: PropTypes.bool,
};

const propTypesShape = PropTypes.shape(shape);

export default {
  shape,
  propTypesShape,
};
