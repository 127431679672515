import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  makeStyles, Grid, IconButton, Typography,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { defineMessages, FormattedMessage } from 'react-intl';

import AttendeeAvatar from '../../../../components/AttendeeAvatar';

import { selectAllSpeakerActivity, selectAttendeesList, selectMyAttendee } from '../../../../../selectors';

const messages = defineMessages({
  SENT_AUDIO: {
    id: 'SENT_AUDIO',
    defaultMessage: 'sent an audio',
  },
  SENT_IMAGE: {
    id: 'SENT_IMAGE',
    defaultMessage: 'sent an image',
  },
  SENT_VIDEO: {
    id: 'SENT_VIDEO',
    defaultMessage: 'sent a image',
  },
  SENT_FILE: {
    id: 'SENT_FILE',
    defaultMessage: 'sent a file',
  },
  OWNER: {
    id: 'OWNER',
    defaultMessage: 'Owner',
  },
  YOU: {
    id: 'YOU',
    defaultMessage: 'You',
  },
});

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 600,
  },
  userAvatarContainer: {
    position: 'relative',
    height: theme.spacing(5),
    marginRight: theme.spacing(1.5),
    display: 'inline-block',
  },
  message: {
    color: 'rgba(0,0,0,.54)',
  },
  clickable: {
    cursor: 'pointer',
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.09)',
    },
  },
  sessionActions: {
    transition: 'opacity 250ms',
    whitespace: 'nowrap',
    position: 'absolute',
    color: 'rgba(0,0,0,.54)',
    opacity: 0,
    right: 0,
    top: -4,
  },
  root: {
    '&:hover $sessionActions': {
      opacity: 1,
    },
  },
}));

const PrivateSessionsList = ({
  sessions,
  onDeleteSession,
  onSessionClick,
}) => {
  const classes = useStyles();
  const attendees = useSelector(selectAttendeesList);
  const myAttendeeId = useSelector((state) => selectMyAttendee(state).attendee_id);
  const speakerActivity = useSelector(selectAllSpeakerActivity);

  return (
    <>
      {sessions.map((session) => {
        const attendee = _.find(attendees, { attendee_id: session.remoteAttendeeId });
        if (typeof attendee === 'undefined') {
          return null;
        }

        const isActive = _.get(speakerActivity, [attendee.attendee_id, 'isActive']);

        return (
          <div key={session.id}>
            <Grid
              className={classnames(classes.root, classes.clickable)}
              container
              alignContent="flex-end"
              wrap="nowrap"
              onClick={() => onSessionClick(session.id)}
            >
              <Grid item className={classes.userAvatarContainer}>
                <AttendeeAvatar
                  attendee={attendee}
                  isActive={isActive}
                />
              </Grid>
              <Grid item xs style={{ width: 120 }}>
                <Typography
                  className={classnames({ [classes.bold]: !!_.get(session, 'unreadMessageCount') })}
                  variant="body2"
                  display="inline"
                  noWrap
                >
                  {attendee.name}
                  {attendee.isOwner && (
                    <Typography variant="caption" display="inline">
                      {' '}
                      <FormattedMessage {...messages.OWNER} />
                    </Typography>
                  )}
                </Typography>
                <Typography
                  className={classnames(classes.message, { [classes.bold]: !!_.get(session, 'unreadMessageCount') })}
                  variant="body2"
                  noWrap
                >
                  {_.get(session, 'lastMessage.from_attendee_id') === myAttendeeId && (
                    <FormattedMessage {...messages.YOU} />
                  )}
                  {_.get(session, 'lastMessage.type') === 'chat' && _.get(session, 'lastMessage.text') && (
                    <>
                      {_.get(session, 'lastMessage.from_attendee_id') === myAttendeeId && ': '}
                      {_.get(session, 'lastMessage.text')}
                    </>
                  )}
                  {_.get(session, 'lastMessage.media_type', '').includes('audio') && (
                    <>
                      {_.get(session, 'lastMessage.from_attendee_id') !== myAttendeeId && attendee.name}
                      {' '}
                      <FormattedMessage {...messages.SENT_AUDIO} />
                    </>
                  )}
                  {_.get(session, 'lastMessage.media_type', '').includes('image') && (
                    <>
                      {_.get(session, 'lastMessage.from_attendee_id') !== myAttendeeId && attendee.name}
                      {' '}
                      <FormattedMessage {...messages.SENT_IMAGE} />
                    </>
                  )}
                  {_.get(session, 'lastMessage.media_type', '').includes('video') && (
                    <>
                      {_.get(session, 'lastMessage.from_attendee_id') !== myAttendeeId && attendee.name}
                      {' '}
                      <FormattedMessage {...messages.SENT_VIDEO} />
                    </>
                  )}
                  {_.get(session, 'lastMessage.type', '') === 'chat-media'
                  && !_.get(session, 'lastMessage.media_type', '').includes('audio')
                  && !_.get(session, 'lastMessage.media_type', '').includes('image')
                  && !_.get(session, 'lastMessage.media_type', '').includes('video')
                  && (
                    <>
                      {_.get(session, 'lastMessage.from_attendee_id') !== myAttendeeId && attendee.name}
                      {' '}
                      <FormattedMessage {...messages.SENT_FILE} />
                    </>
                  )}
                </Typography>
              </Grid>
              <Grid item style={{ width: 54, position: 'relative' }}>
                <IconButton
                  className={classes.sessionActions}
                  onClick={(e) => {
                    // prevent session click
                    e.preventDefault();
                    e.stopPropagation();
                    onDeleteSession(session.id);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </Grid>
            </Grid>
          </div>
        );
      })}
    </>
  );
};

PrivateSessionsList.propTypes = {
  sessions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onDeleteSession: PropTypes.func.isRequired,
  onSessionClick: PropTypes.func.isRequired,
};

export default PrivateSessionsList;
