import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';
// import chatSessionShape from '../../../../../shapes/chatSession';

import ArrowDownward from '@material-ui/icons/ArrowDownward';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    zIndex: '3',
    bottom: '67px',
    margin: '0 auto',
    height: '0px',
    textAlign: 'right',
  },
  rectangle2: {
    minHeight: '32px',
    borderRadius: '100px',
    backgroundColor: '#00A404',
    display: 'inline-flex',
    alignItems: 'center',
    textAlign: 'center',
    paddingRight: '8px',
    cursor: 'pointer',
  },
  unreadCountMsg: {
    padding: '5px 5px 5px 10px',
    maxWidth: '180px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: '#FFFFFF',
    lineHeight: '20px',
    fontFamily: 'Roboto',
    fontSize: '12px',
  },
  arrow: {
    height: '27px',
    width: '27px',
    top: '3px',
    right: '-3px',
    position: 'relative',
    opacity: '54%',
  },
  iconCircle: {
    borderRadius: '50%',
    backgroundColor: '#FAFAFA',
    height: '32px',
    width: '32px',
  },
  arrowDown: {
    color: '#000000',
  },
}));

const BubbleUnreadCount = ({
  unreadCount,
  scrollToBottom,
  isAtEnd,
  chatSessionId,
}) => {
  const classes = useStyles();

  const handleClickScrollDown = (event) => {
    event.stopPropagation();
    scrollToBottom();
  };

  let show = false;
  show = (unreadCount !== 0) && !isAtEnd;
  let sCont;
  if (chatSessionId !== '') {
    sCont = document.getElementById(`${chatSessionId}_scrollContainer`);
  }

  // check if not enough messages to scroll
  if (typeof sCont === 'undefined' || sCont === null || sCont.scrollHeight === sCont.clientHeight) {
    show = false;
  }

  return (
    <div role="button" aria-hidden="true" className={classes.container} onClick={handleClickScrollDown}>
      {show && (
        <div className={classnames(classes.rectangle2)} role="button" aria-hidden="true">
          <div className={classnames(classes.iconCircle)}>
            <div className={classnames(classes.arrow)}>
              <ArrowDownward
                className={classes.arrowDown}
              />
            </div>
          </div>
          <div className={classnames(classes.unreadCountMsg)}>
            {`${unreadCount} new messages`}
          </div>
        </div>
      )}
    </div>
  );
};

BubbleUnreadCount.defaultProps = {
  unreadCount: 0,
  chatSessionId: '',
};

BubbleUnreadCount.propTypes = {
  unreadCount: PropTypes.number,
  scrollToBottom: PropTypes.func.isRequired,
  isAtEnd: PropTypes.bool.isRequired,
  chatSessionId: PropTypes.string,
};

export default React.memo(BubbleUnreadCount);
