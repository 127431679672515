import getFaviconUrl from '../../services/getFaviconUrl';

// eslint-disable-next-line require-yield
export default function* setFavicon() {
  const link = document.querySelector('link[rel*="icon"]') || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = getFaviconUrl();

  document.getElementsByTagName('head')[0].appendChild(link);

  return Promise.resolve(true);
}
