import React, { useContext } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { store as callMeStore } from '../../../providers/CallMeProvider';

const messages = defineMessages({
  USE_MIC: {
    id: 'USE_MIC',
    defaultMessage: 'Use microphone',
  },
});

const useStyles = makeStyles((theme) => ({
  btn: {
    marginTop: theme.spacing(1),
    fontSize: 14,
  },
}));

const MicInputSetting = () => {
  const classes = useStyles();

  const { onCall, useMic } = useContext(callMeStore);

  const handleUseMic = () => {
    console.log('Use microphone');
    useMic();
  };

  return (
    <>
      {onCall && (
        <Button
          id="MicInputSettingUseMic"
          className={classes.btnSmall}
          size="small"
          color="primary"
          onClick={handleUseMic}
        >
          <FormattedMessage {...messages.USE_MIC} />
        </Button>
      )}
    </>
  );
};

export default MicInputSetting;
