import {
  put, select,
} from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import _ from 'lodash';
import bugsnagClient from '../../bugsnag';
import { selectChatSessions } from '../../selectors';
import { filterMessage } from '../../utils';
import { setChatSessions } from '../../actions';

// eslint-disable-next-line import/prefer-default-export
export function* sendChatMessage({ payload }) {
  const decodedToken = nsToken.getDecoded();

  const { sessionId } = payload;

  let { message } = payload;
  if (message) {
    message = filterMessage(message);
    try {
      console.debug(`${new Date()} - sending chat message`);
      yield nsApi.post({
        object: 'message',
        action: 'create',
        domain: decodedToken.domain,
        user: decodedToken.user,
        fromAttendeeId: payload.fromAttendeeId,
        type: payload.type,
        message,
        session_id: sessionId,
        destination: payload.destination,
        instanceId: payload.instanceId,
      }, {
        object: 'message',
        action: 'create',
        domain: decodedToken.domain,
        user: decodedToken.user,
        fromAttendeeId: payload.fromAttendeeId,
        message,
        session_id: sessionId,
        destination: payload.destination,
        data: payload.data,
        mime_type: payload.mime_type,
        size: payload.size,
        timestamp: payload.timestamp,
        instanceId: payload.instanceId,
      });
      console.debug(`${new Date()} - sending chat message complete`);
    } catch (err) {
      // remove sending for that message and put error state
      const sessions = yield select(selectChatSessions);
      const clonedSessions = _.cloneDeep(sessions);

      const failedMesg = {
        from_attendee_id: payload.fromAttendeeId,
        type: 'chat-media',
        direction: '',
        timestamp: payload.timestamp,
        text: message,
        remotepath: payload.remotepath,
        media_type: payload.mime_type,
        sending: false,
        errorSending: true,
      };

      clonedSessions[sessionId].messages.push(failedMesg);
      yield put(setChatSessions(clonedSessions));

      console.error(`${new Date()} - sending chat message error`);
      console.error(err);
      bugsnagClient.notify(err, (event) => {
        // eslint-disable-next-line no-param-reassign
        event.context = 'saga: sendChatMessage';
        event.addMetadata('payload', payload);
      });
    }
  }
}
