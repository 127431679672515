import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import useWindowSize from 'react-use/lib/useWindowSize';
import classnames from 'classnames';
import { Button, makeStyles, Typography } from '@material-ui/core';
import VolumeUp from '@material-ui/icons/VolumeUp';
import MicOffIcon from '@material-ui/icons/MicOff';
import appStyles from '../../../../styles.css';
import { gravatarSessionId } from '../../../../utils';

import BorderContainer from './BorderContainer';
import VideoItemHoverControls from './VideoItemHoverControls';
import StatsOverlay from './StatsOverlay';

import { isScreenShareOverlayDismissed } from '../../../../actions';
import * as selectors from '../../../../selectors';
import store from '../../../../store';
import participantShape from '../../../../shapes/participant';
import getInitials from '../../../../utils/getInitials';
import { LAYOUT_TYPE_SPOTLIGHT, LAYOUT_TYPE_PICTURE } from '../../../../constants';
import * as events from '../../../../events';

const messages = defineMessages({
  YOU: {
    id: 'YOU',
    defaultMessage: 'You',
  },
  YOUR_SCREENSHARE: {
    id: 'YOUR_SCREENSHARE',
    defaultMessage: 'Your shared screen',
  },
  YOU_ARE_SHARING: {
    id: 'YOU_ARE_SHARING',
    defaultMessage: 'You are sharing your screen',
  },
  YOU_ARE_SHARING_MESSAGE: {
    id: 'YOU_ARE_SHARING_MESSAGE',
    defaultMessage: 'Avoid unwanted effects like the infinity mirror by only sharing a tab or window instead of your whole screen.',
  },
  STOP_SCREEN_SHARE: {
    id: 'STOP_SCREEN_SHARE',
    defaultMessage: 'Stop sharing',
  },
  STOP: {
    id: 'STOP',
    defaultMessage: 'Stop',
  },
  DISMISS_SCREEN_SHARE_MESSAGE: {
    id: 'DISMISS_SCREEN_SHARE_MESSAGE',
    defaultMessage: 'Dismiss Message',
  },
  DISMISS: {
    id: 'DISMISS',
    defaultMessage: 'DISMISS',
  },
  PARTICIPANTS_SCREENSHARE: {
    id: 'PARTICIPANTS_SCREENSHARE',
    defaultMessage: '{participant}\'s shared screen',
  },
});

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
  },
  centerContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

  },
  screenShareOverlay: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#000',
    opacity: '78%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  screenShareOverlayMargin: {
    marginTop: 36,
  },
  gravatarImg: {
    borderRadius: '50%',
    width: '100%',
    height: '100%',
  },
  gravatarInitials: {
    display: 'table',
    position: 'absolute',
    backgroundColor: '#b9b9b9',
    borderRadius: '50%',
    zIndex: -1,
    '& span': {
      display: 'table-cell',
      verticalAlign: 'middle',
      textAlign: 'center',
    },
  },
  volumeUpContainer: {
    zIndex: 1,
    transition: 'all .3s',
    transitionTimingFunction: 'ease-in-out',
    position: 'absolute',
    bottom: 16,
    right: 16,
    height: 24,
    width: 24,
    borderRadius: '50%',
    backgroundColor: 'rgba(255,255,255,0.54)',
    '&.dense': {
      right: 8,
      bottom: 8,
    },
  },
  speaking: {
    position: 'absolute',
    top: 'calc(95% - 20px)',
    left: 'calc(95% - 20px)',
    color: '#1A73E8',
    height: 18,
    width: 18,
    transition: 'opacity .3s',
  },
  disabledDevice: {
    color: '#B00020',
  },
  video: {
    backgroundColor: '#000',
  },
  nameContainer: {
    transition: 'all .3s',
    position: 'absolute',
    left: 16,
    bottom: 16,
    fontSize: 12,
    borderRadius: 100,
    minHeight: 24,
    maxWidth: '20vw',
    lineHeight: '16px',
    backgroundColor: 'rgba(0,0,0,0.54) !important',
    display: 'inline-flex',
    height: '25px',
    alignItems: 'center',
    letterSpacing: '0.4px',
    fontFamily: 'Roboto',
    padding: '0 7px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    zIndex: '1',
    whiteSpace: 'nowrap',
    '&.dense': {
      left: 8,
      bottom: 8,
      fontSize: 10,
      minHeight: 20,
    },
  },
  mivNameContainer: {
    transition: 'all .3s',
    position: 'absolute',
    left: 16,
    bottom: 16,
    fontSize: 16,
    borderRadius: 100,
    minHeight: 24,
    maxWidth: '20vw',
    lineHeight: '20px',
    backgroundColor: 'rgba(0,0,0,0.54) !important',
    display: 'inline-flex',
    height: '30px',
    alignItems: 'center',
    letterSpacing: '0.4px',
    fontFamily: 'Roboto',
    padding: '0 7px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    zIndex: '1',
    whiteSpace: 'nowrap',
    '&.dense': {
      left: 8,
      bottom: 8,
      fontSize: 10,
      minHeight: 20,
    },
  },
  videoStat: {
    position: 'absolute',
    top: 7,
    left: 7,
    // width: 190,
    // height: 145,
    zIndex: 1,
    opacity: 1,
    display: 'inline-flex',
    overflowX: 'auto',
    overflowY: 'auto',
    textOverflow: 'ellipsis',
    borderRadius: '2.3%',
    backgroundColor: 'rgba(0,0,0,0.54) !important',
    whiteSpace: 'nowrap',
  },
  trayName: {
    bottom: '8px',
    left: '8px',
  },
  trayDisabled: {
    bottom: '8px',
    right: '8px',
  },
  player: {
    boxSizing: 'border-box',
    position: 'absolute',
    textAlign: 'center',
    top: 0,
    left: '-50%',
    right: '-50%',
    margin: 'auto',
  },
  animationDuration: {
    animationDuration: '0.3s',
  },
}));

function stopStreamedVideo(videoElem) {
  const stream = videoElem.srcObject;
  if (stream) {
    const tracks = stream.getTracks();
    tracks.forEach((track) => {
      stream.removeTrack(track);
    });
  }
  // eslint-disable-next-line no-param-reassign
  videoElem.srcObject = null;
}

const VideoItem = ({
  layout,
  participantId,
  fakeParticipant,
  speakingLocal,
  isTrayItem,
  isMobileCrop,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const ref = useRef();
  const { width } = useWindowSize();

  const element = ref?.current?.getBoundingClientRect();
  const itemWidth = element?.width || width;
  const gravatarSize = itemWidth * 0.25;

  const [attendee, setAttendee] = useState({});

  const overlayDismissed = useSelector(((state) => state.screenShare?.overlayDismissed));

  const isMobileView = useSelector((state) => selectors.selectMIV(state));

  const partId = participantId || _.get(fakeParticipant, 'id');
  const participant = useSelector((state) => {
    if (participantId) {
      return selectors.selectParticipant(state, participantId) || {};
    }
    return fakeParticipant;
  });
  const speakerActivity = useSelector((state) => selectors.selectSpeakerActivity(
    state,
    partId,
  ));

  const mediaStatus = participant.direction === 'outbound'
    ? useSelector((state) => selectors.selectUserMediaStatus(state))
    : useSelector((state) => selectors.selectMediaStatus(state, _.get(participant, 'userId')));

  const audioMuted = useMemo(() => _.get(mediaStatus, 'audioMuted', false), [mediaStatus]);
  const videoMuted = useMemo(() => _.get(mediaStatus, 'videoMuted', false), [mediaStatus]);
  const videoAspectRatio = useMemo(() => {
    if (participant.type === 'screenShare') {
      return 'auto';
    }
    return _.get(mediaStatus, 'videoAspectRatio', '16:9');
  }, [mediaStatus, participant]);

  useEffect(() => {
    let videoRef = document.getElementById(`video_${participant.id}`);
    if (!participant.videoTrackMuted
      && participant.videoTrack
      && (participant.type === 'screenShare' || !participant.videoPart)
      && (participant.type === 'screenShare' || participant.videoTrack.readyState === 'live')
    ) {
      const s = new MediaStream();
      s.addTrack(participant.videoTrack);

      videoRef = document.createElement('video');
      videoRef.setAttribute('id', `video_${participant.id}`);
      videoRef.setAttribute('class', appStyles.player);
      videoRef.setAttribute('autoplay', 'autoplay');
      videoRef.setAttribute('playsinline', 'playsinline');
      videoRef.setAttribute('muted', 'muted');
      videoRef.style.cssText = 'display:none;';

      const divRef = document.getElementById(`container_${participant.id}`);
      divRef.prepend(videoRef);

      let videoStyle = 'height: calc(100% + 16px);';
      if (videoAspectRatio === '4:3') {
        videoStyle = 'width: 100%;';
      }
      if (videoAspectRatio === 'auto') {
        videoStyle = 'width: 100%;height: calc(100% + 16px);';
      }

      let selfStyle = '';
      if (participant.isSelf && participant.type !== 'screenShare') {
        selfStyle = 'transform:scaleX(-1);';
      } else if (participant.isSelf
        && participant.type === 'screenShare'
        && layout === LAYOUT_TYPE_SPOTLIGHT
      ) {
        // VID-929 Remove infinity-mirror mitigation effect
        // videoStyle = 'width: 400px; height: calc(100% + 16px); transform:scaleX(1);';
        videoStyle += 'transform:scaleX(1);';
      } else {
        videoStyle += 'transform:scaleX(1);';
      }

      if (isMobileCrop) {
        if (participant.isSelf && participant.type !== 'screenShare') {
          videoStyle = 'width: 100%;';
          videoStyle += 'height: auto;';
          selfStyle = 'transform:scaleX(-1);';
        } else {
          // Vertical Cropping
          // videoStyle = 'width: 100%;';
          // videoStyle += 'height: auto;';
          // Horizontal Cropping
          videoStyle = 'width: auto;';
          videoStyle += 'height: 100%;';

          videoStyle += 'top: 50%;';
          videoStyle += 'left: 50%;';
          videoStyle += 'transform: translateX(-50%) translateY(-50%);';
        }
        videoStyle += 'border-radius: 16px';
      }

      videoRef.load();

      if (participant.videoTrack && participant.videoTrack.readyState
        && (participant.videoTrack.readyState === 'live' || participant.type === 'screenShare')
      //  && (!participant.videoTrack.muted || participant.isSelf
      // || participant.type === 'screenShare') //removed this part as Firefox was coming in muted.
      ) {
        videoRef.srcObject = s;

        videoRef.play()
          .then(() => {
            // Video playback started ;)
            videoRef.style.cssText = `${videoStyle}${selfStyle}`;
          })
          .catch(() => {
            // console.log(e);
            // Video playback failed ;(
            // videoRef.style.cssText = 'display:none;';
          });
      }
    }

    if (participant.videoTrackMuted && videoRef) {
      stopStreamedVideo(videoRef);
      videoRef.remove();
    }

    return () => {
      if (videoRef) {
        stopStreamedVideo(videoRef);
        videoRef.remove();
      }
    };
  }, [participant.videoTrackMuted, participant.videoTrack]);

  useEffect(() => {
    const attendees = selectors.selectAttendeesList(store.getState());
    let match = _.find(attendees, { uid: participant.uid });
    if (!match && participant.userId) {
      match = _.find(attendees, (a) => `guest_${a.attendee_id}` === participant.userId);
    }
    setAttendee(match);
  }, [participant.id]);

  const stopSharing = () => {
    dispatch(events.endScreenShare());
    dispatch(events.partialMeetingUpdate({
      presenter: '',
    }));
  };

  let name = participant.name || _.get(attendee, 'name');
  if (attendee && attendee.isSelf) {
    name = participant.type === 'screenShare'
      ? intl.formatMessage(messages.YOUR_SCREENSHARE)
      : intl.formatMessage(messages.YOU);
  } else if (name && participant.type === 'screenShare') {
    name = intl.formatMessage(messages.PARTICIPANTS_SCREENSHARE, { participant: name });
  }

  const gravatar = participant.gravatar || _.get(attendee, 'gravatar');
  const initials = participant.initials || getInitials(_.get(attendee, 'name', ''));
  const isActive = layout !== LAYOUT_TYPE_PICTURE && !audioMuted && (speakingLocal || _.get(speakerActivity, 'isActive'));
  const isAudible = layout !== LAYOUT_TYPE_PICTURE && !audioMuted && (_.get(speakerActivity, 'isAudible'));
  const isNotLiveOrMute = !participant.videoTrack
    || participant.videoTrack.readyState !== 'live'
    || participant.videoTrack.muted === true;

  return (
    <BorderContainer
      type={participant.type}
      isActive={isActive}
      isAudible={isAudible}
      videoMuted={videoMuted}
      bgColor={participant.bgColor}
      isShare={participant.type === 'screenShare' && participant.isSelf}
      isMobileCrop={isMobileCrop}
      isMiv={isMobileView === 1}
      isSelf={participant.isSelf}
      layout={layout}
    >
      {(participant.videoTrackMuted || !participant.videoTrack
        || (isNotLiveOrMute && participant.type !== 'screenShare')) && (
        <div className={classes.centerContainer}>
          <div
            style={{ width: gravatarSize, height: gravatarSize, fontSize: gravatarSize / 2 }}
            className={classes.gravatarInitials}
          >
            <span>{initials}</span>
          </div>
          <div style={{ width: gravatarSize, height: gravatarSize }}>
            {gravatar && (
              <img
                id="gravatarImg"
                src={`https://www.gravatar.com/avatar/${gravatar}?s=150&d=blank&hash=${gravatarSessionId}`}
                className={classes.gravatarImg}
                alt=""
              />
            )}
          </div>
        </div>
      )}

      <div id={`container_${participant.id}`} ref={ref} className={classes.container}>
        {!overlayDismissed
        && participant.isSelf
        && participant.type === 'screenShare'
          ? (
            <div className={classes.screenShareOverlay}>
              <div style={{ maxWidth: 480, margin: '0 auto', padding: '0 24px' }}>
                {(layout === LAYOUT_TYPE_SPOTLIGHT && width >= 600) && (
                  <>
                    <Typography variant="h6" component="div">
                      {intl.formatMessage(messages.YOU_ARE_SHARING)}
                    </Typography>
                    <Typography component="div" className={classes.screenShareOverlayMargin}>
                      {intl.formatMessage(messages.YOU_ARE_SHARING_MESSAGE)}
                    </Typography>
                    <div style={{ maxWidth: 160, margin: '0 auto' }}>
                      <Button
                        className={classes.screenShareOverlayMargin}
                        variant="contained"
                        color="primary"
                        onClick={() => stopSharing()}
                        fullWidth
                      >
                        {intl.formatMessage(messages.STOP_SCREEN_SHARE)}
                      </Button>
                      <Button
                        className={classes.screenShareOverlayMargin}
                        variant="text"
                        component="div"
                        color="primary"
                        onClick={() => dispatch(isScreenShareOverlayDismissed(true))}
                        fullWidth
                      >
                        {intl.formatMessage(messages.DISMISS_SCREEN_SHARE_MESSAGE)}
                      </Button>
                    </div>
                  </>
                )}
                {(layout !== LAYOUT_TYPE_SPOTLIGHT && !isTrayItem && width >= 600) && (
                  <>
                    <Typography variant="h6" component="div">
                      {intl.formatMessage(messages.YOU_ARE_SHARING)}
                    </Typography>
                    <div className={classes.screenShareOverlayMargin}>
                      <Button variant="contained" color="primary" onClick={() => stopSharing()}>
                        {intl.formatMessage(messages.STOP_SCREEN_SHARE)}
                      </Button>
                    </div>
                    <div className={classes.screenShareOverlayMargin}>
                      <Button
                        variant="text"
                        component="div"
                        color="primary"
                        onClick={() => dispatch(isScreenShareOverlayDismissed(true))}
                      >
                        {intl.formatMessage(messages.DISMISS_SCREEN_SHARE_MESSAGE)}
                      </Button>
                    </div>
                  </>
                )}
                {(width < 600 || isTrayItem) && (
                  <>
                    <Typography component="div" style={{ fontSize: 14 }}>
                      {intl.formatMessage(messages.YOU_ARE_SHARING)}
                    </Typography>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 18 }}>
                      <Button variant="contained" color="primary" onClick={() => stopSharing()}>
                        {intl.formatMessage(messages.STOP)}
                      </Button>
                      <Button
                        variant="text"
                        component="div"
                        color="primary"
                        onClick={() => dispatch(isScreenShareOverlayDismissed(true))}
                        style={{ marginLeft: 16 }}
                      >
                        {intl.formatMessage(messages.DISMISS)}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : (
            <>
              <div
                className={
                classnames(
                  'animate__animated',
                  isActive ? 'animate__fadeIn' : 'animate__fadeOut',
                  classes.volumeUpContainer,
                  classes.animationDuration,
                  itemWidth < 400 && 'dense',
                )
              }
              >
                <VolumeUp className={classes.speaking} size={100} />
              </div>

              {participant.videoStat && attendee && (
              <div className={classes.videoStat}>
                <StatsOverlay
                  attendeeId={attendee.attendee_id}
                  attendeeAudioId={attendee.audio_id}
                  participantId={participant.id}
                  type={participant.type}
                  isSelf={participant.isSelf}
                  videoMuted={participant.videoTrackMuted}
                  videoTrack={participant.videoTrack}
                  audioPart={participant.audioPart}
                  videoPart={participant.videoPart}
                  videoAspectRatio={videoAspectRatio}
                />
              </div>
              )}

              {name && (
              <div
                className={classnames(
                  isTrayItem && classes.trayName,
                  isMobileView !== 1 && classes.nameContainer,
                  isMobileView === 1 && classes.mivNameContainer,
                  itemWidth < 400 && 'dense',
                )}
              >
                {name}
              </div>
              )}

              {audioMuted && (
              <div
                className={classnames(
                  isTrayItem && classes.trayDisabled,
                  classes.volumeUpContainer,
                  itemWidth < 400 && 'dense',
                )}
              >
                <MicOffIcon
                  className={classnames(classes.speaking, classes.disabledDevice)}
                  size={100}
                />
              </div>
              )}

              {layout !== LAYOUT_TYPE_PICTURE && attendee && (
              <VideoItemHoverControls
                attendee={attendee}
                participant={participant}
                isMiv={isMobileView === 1}
              />
              )}
            </>
          )}
      </div>
    </BorderContainer>
  );
};

React.memo(VideoItem);

VideoItem.defaultProps = {
  layout: null,
  fakeParticipant: null,
  participantId: null,
  speakingLocal: false,
  isTrayItem: false,
  isMobileCrop: false,
};

VideoItem.propTypes = {
  layout: PropTypes.string,
  fakeParticipant: participantShape.propTypesShape,
  participantId: PropTypes.string,
  speakingLocal: PropTypes.bool,
  isTrayItem: PropTypes.bool,
  isMobileCrop: PropTypes.bool,
};

export default VideoItem;
