import _ from 'lodash';
import crypto from 'crypto-browserify';
import { put, select } from 'redux-saga/effects';

import * as selectors from '../../selectors';
import * as actions from '../../actions';
import bugsnagClient from '../../bugsnag';

const connectedAttendees = ({
  isSelf,
  status,
  status_video, // eslint-disable-line camelcase
}) => (status === 'active' || status === 'moh' || status_video === 'connected' || isSelf); // eslint-disable-line camelcase

// eslint-disable-next-line import/prefer-default-export
export function* setAttendees({ payload: attendeesList }) {
  try {
    const meeting = yield select(selectors.selectMeeting);
    const user = yield select(selectors.selectUser);

    let foundSelf = false;

    if (attendeesList) {
      for (let i = 0; i < attendeesList.length; i += 1) {
        // skip if the attendee has already been processed
        if (!_.get(attendeesList, [i, 'processed'])) {
          const clonedAttendee = { ...attendeesList[i] };

          clonedAttendee.processed = true;

          const tmpUid = _.get(clonedAttendee, 'uid', '');
          if (tmpUid) {
            ([clonedAttendee.id] = tmpUid.split('@'));
          } else {
            console.debug('unknown uid in attendee');
            console.debug(clonedAttendee);
            clonedAttendee.id = 'guest';
          }

          clonedAttendee.isOwner = clonedAttendee.uid === `${meeting.user}@${meeting.domain}`;
          if (user) {
            clonedAttendee.isSelf = clonedAttendee.uid === user.uid;
          } else {
            clonedAttendee.isSelf = false;
          }

          const attendeeId = clonedAttendee.attendee_id;
          if (user && `${user.userId}` === `guest_${attendeeId}`) {
            clonedAttendee.isSelf = true;
          }

          if (clonedAttendee.isSelf === true) {
            foundSelf = true;
          }

          // this handles the case when a user is turned into an attendee
          // the users usually have an email array
          if (clonedAttendee.email && _.isArray(clonedAttendee.email)) {
            clonedAttendee.email = clonedAttendee.email.toString();
          }

          if (typeof clonedAttendee.email !== 'string' && !(clonedAttendee.email instanceof String)) clonedAttendee.email = clonedAttendee.email.toString();

          // if the email string is a comma delimited list of emails
          // then use the first email from the array
          if (clonedAttendee.email && clonedAttendee.email.includes(';')) {
            const [attendeeEmail] = clonedAttendee.email.split(';');
            clonedAttendee.email = attendeeEmail || null;
          }

          if (clonedAttendee.email && clonedAttendee.email.includes(',')) {
            const [attendeeEmail] = clonedAttendee.email.split(',');
            clonedAttendee.email = attendeeEmail || null;
          }

          // if the attendee doesn't have a gravatar
          // set then generate the gravatar hash using the email
          if (clonedAttendee.email) {
            clonedAttendee.gravatar = crypto.createHash('md5').update(clonedAttendee.email).digest('hex');
          }

          // eslint-disable-next-line no-param-reassign
          attendeesList[i] = clonedAttendee;
        } else if (attendeesList[i].isSelf) {
          foundSelf = true;
        }
      }

      if (attendeesList.length > 0 && foundSelf !== true) {
        console.debug('Not able to find Self!');
        console.debug(attendeesList);
      } else {
        const myAttendee = _.find(attendeesList, 'isSelf');
        yield put(actions.setMyAttendee(myAttendee));
      }

      const connected = attendeesList.filter(connectedAttendees);
      yield put(actions.setConnectedAttendees(connected));
    }

    const myAttendee = _.find(attendeesList, 'isSelf');
    if (attendeesList && attendeesList.length > 0) {
      const connected = attendeesList.filter(connectedAttendees);
      yield put(actions.setAttendeesList(attendeesList));
      yield put(actions.setConnectedAttendees(connected));
    }

    if (myAttendee) yield put(actions.setMyAttendee(myAttendee));
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: setAttendees';
      event.addMetadata('payload', attendeesList);
    });
  }
}
