import { put, select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';

import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { showMeetingHubPage } from '../../events';

import store from '../../store';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* deleteMeeting({ payload }) {
  yield put(actions.showLoaderPage());

  const handleError = (err) => {
    console.error(err);

    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: deleteMeeting';
      event.addMetadata('payload', payload);
    });

    if (err && err.response && err.response.status && err.response.status === 412) {
      store.dispatch(actions.snackBarError('DELETE_FAILED_MEETING_ACTIVE'));
    } else {
      store.dispatch(actions.snackBarError('DELETE_FAILED'));
    }
    store.dispatch(showMeetingHubPage());
  };

  try {
    const { meeting, deleteType } = payload;
    const user = yield select((state) => selectors.selectUser(state));

    const deleteParams = {
      object: 'meeting',
      id: meeting.id,
      type: deleteType,
      user: user.userId,
      domain: user.domain,
    };

    if (deleteType === 'history') {
      deleteParams.action = 'deleteevents';
      deleteParams.meeting_id = meeting.id;
      deleteParams.instance_id = meeting.instance_id;
    } else {
      deleteParams.action = 'delete';

      if (deleteType === 'instance') {
        deleteParams.start_instance = meeting.start_instance;
      }
    }

    yield nsApi.post(deleteParams);
    store.dispatch(showMeetingHubPage());
  } catch (err) {
    handleError(err);
  }
}
