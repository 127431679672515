import nsToken from '@netsapiens/netsapiens-js/dist/token';
import _ from 'lodash';
import crypto from 'crypto-browserify';
import store from '../store';
import fetchMessages from './fetchMessages';
import fetchMessageSessions from './fetchMessageSessions';
import getMeetingEvents from './getMeetingEvents';
import combineEventsToMessages from './combineEventsToMessages';
import filterDeletedMessages from './filterDeletedMessages';

import * as selectors from '../selectors';
import bugsnagClient from '../bugsnag';

export default async (meeting) => {
  try {
    const myAttendee = selectors.selectMyAttendee(store.getState());
    const decodedToken = nsToken.getDecoded();

    // get meeting instance start for messages starttime
    const meetingStartEvent = await getMeetingEvents({
      domain: decodedToken.domain,
      user: decodedToken.user,
      id: meeting.id,
      instanceId: meeting.instance_id,
      groupByInstance: 'messages',
      getStart: 'true',
    });

    let meetingStartTimestamp = '';
    if (!meeting.enable_chat_history) {
      // if meeting does not allow recuring chat logs then have the...
      if (typeof meetingStartEvent[0] !== 'undefined' && meetingStartEvent[0].timestamp != null) {
        meetingStartTimestamp = meetingStartEvent[0].timestamp;
      }
    }

    const allMessages = {};

    // get general meeting chat
    allMessages[meeting.chat_sessionid] = { id: meeting.chat_sessionid, type: 'meeting', messages: null };
    allMessages[meeting.chat_sessionid].messages = await fetchMessages({
      domain: decodedToken.domain,
      user: decodedToken.user,
      sessionid: meeting.chat_sessionid,
      order: 'timestamp desc',
      startTimestamp: meetingStartTimestamp,
    });
    allMessages[meeting.chat_sessionid].messages = allMessages[meeting.chat_sessionid].messages.reverse(); // eslint-disable-line max-len

    // let timestart;
    // if (typeof allMessages[meeting.chat_sessionid].messages[0] !== 'undefined') {
    //   timestart = allMessages[meeting.chat_sessionid].messages[0].timestamp;
    // }
    const meetingEvents = await getMeetingEvents({
      domain: decodedToken.domain,
      user: decodedToken.user,
      limit: 25,
      id: meeting.id,
      instanceId: meeting.instance_id,
      groupByInstance: 'messages',
      // timeStart: timestart,
    });

    allMessages[meeting.chat_sessionid].messages = combineEventsToMessages(meetingEvents, allMessages[meeting.chat_sessionid].messages); // eslint-disable-line max-len
    allMessages[meeting.chat_sessionid].messages = filterDeletedMessages(allMessages[meeting.chat_sessionid].messages); // eslint-disable-line max-len

    allMessages[meeting.chat_sessionid].unreadMessageCount = 0;
    // get host and admin messages if the user role is appropriate
    if (myAttendee && (myAttendee.role === 'host' || myAttendee.role === 'presenter')) {
      const hostSessionId = `hosts${crypto.createHash('md5').update(meeting.chat_sessionid).digest('hex')}`;
      allMessages[hostSessionId] = { id: hostSessionId, type: 'host', messages: null };
      allMessages[hostSessionId].messages = await fetchMessages({
        domain: decodedToken.domain,
        user: decodedToken.user,
        sessionid: hostSessionId,
        order: 'timestamp desc',
        startTimestamp: meetingStartTimestamp,
      });
      allMessages[hostSessionId].messages = allMessages[hostSessionId].messages.reverse();
      allMessages[hostSessionId].messages = filterDeletedMessages(allMessages[hostSessionId].messages); // eslint-disable-line max-len
    }

    // get messages for the rest of the video sessions
    // need to get the sessions first though
    // for private messages should instead pass in the attendee id, and use that in the API
    const messageSessions = await fetchMessageSessions({
      domain: decodedToken.domain,
      user: decodedToken.user,
      // for private messagesessions need to use your own attendeeid
      attendeeId: meeting.attendee_id,
      sessionName: meeting.chat_sessionid,
    });

    // could compile everything into a nested array of messagesessions and their messages
    for (let i = 0; i < messageSessions.length; i += 1) {
      allMessages[messageSessions[i].session_id] = {
        id: messageSessions[i].session_id,
        type: 'private',
        lastStatus: messageSessions[i].last_status,
        unreadMessageCount: messageSessions[i].last_status === 'unread' ? 1 : 0,
        remoteAttendeeId: messageSessions[i].remote ? messageSessions[i].remote.substr(0, messageSessions[i].remote.indexOf('@')) : '',
        messages: null,
      };
      // eslint-disable-next-line no-await-in-loop
      allMessages[messageSessions[i].session_id].messages = await fetchMessages({
        domain: decodedToken.domain,
        user: decodedToken.user,
        attendeeId: meeting.attendee_id,
        sessionid: messageSessions[i].session_id,
        order: 'timestamp desc',
        // do not have timestart for deleting private messagesessions
        // startTimestamp: meetingStartTimestamp,
      });
      allMessages[messageSessions[i].session_id].lastMessage = _.head(allMessages[messageSessions[i].session_id].messages); // eslint-disable-line max-len
      allMessages[messageSessions[i].session_id].lastMessageTimestamp = _.get(_.head(allMessages[messageSessions[i].session_id].messages), 'timestamp'); // eslint-disable-line max-len
      allMessages[messageSessions[i].session_id].messages = allMessages[messageSessions[i].session_id].messages.reverse(); // eslint-disable-line max-len
      allMessages[messageSessions[i].session_id].messages = filterDeletedMessages(allMessages[messageSessions[i].session_id].messages); // eslint-disable-line max-len
    }

    return allMessages;
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'service: meeting';
      event.addMetadata('payload', meeting);
    });
    return undefined;
  }
};
