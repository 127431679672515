import { call, put } from 'redux-saga/effects';

import fetchUiConfigs from './services/fetchUiConfigs';
import * as actions from '../../actions';

export default function* userUiConfigs(decodedToken) {
  const uiConfigs = yield call(fetchUiConfigs, {
    domain: decodedToken ? decodedToken.domain : '*',
    user: decodedToken ? decodedToken.user : '*',
  });
  yield put(actions.setConfig(uiConfigs));
}
