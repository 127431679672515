/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { defineMessages, FormattedMessage } from 'react-intl';
import userShape from '../../shapes/user';
import UserAvatar from './UserAvatar';
import { selectUser } from '../../selectors';
import * as events from '../../events';

const messages = defineMessages({
  LOGOUT: {
    id: 'LOGOUT',
    defaultMessage: 'Log Out',
  },
});

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    float: 'right',
    cursor: 'pointer',
    zIndex: 989,
    top: 20,
    right: 20,
  },
  userInfoMenu: {
    color: '#555',
    marginTop: 48,
    marginRight: 48,
    '& svg': {
      marginRight: 12,
    },
  },
}));

const UserInfo = ({ user, logout }) => {
  const classes = useStyles();

  const [userInfoAnchorEl, setUserInfoAnchorEl] = useState(null);

  const openUserInfoMenu = (event) => {
    event.stopPropagation();
    setUserInfoAnchorEl(event.target);
  };

  const handleUserInfoClose = () => {
    setUserInfoAnchorEl(null);
  };

  const handleLogoutClick = () => {
    setUserInfoAnchorEl(null);
    logout();
  };

  return (
    <div>
      <div
        role="button"
        className={classnames('animate__animated animate__slow animate__fadeIn', classes.root)}
        onClick={openUserInfoMenu}
        tabIndex="0"
        onKeyPress={openUserInfoMenu}
      >
        <UserAvatar user={user} />

      </div>
      <Menu
        keepMounted
        anchorEl={userInfoAnchorEl}
        onClose={handleUserInfoClose}
        open={Boolean(userInfoAnchorEl)}
        className={classes.userInfoMenu}
      >
        <MenuItem onClick={handleLogoutClick}>
          <ExitToAppIcon key="9" fontSize="small" />
          {' '}
          <FormattedMessage {...messages.LOGOUT} />
        </MenuItem>
      </Menu>
    </div>

  );
};

UserInfo.propTypes = {
  user: userShape.propTypesShape.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: selectUser(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  logout: events.logoutUser,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
