export const LOGOUT_USER = 'events/LOGOUT_USER';

/**
 *
 * @param reload
 * @return {{type: string, payload: {reload: *}}}
 */
export const logoutUser = (reload) => ({
  type: LOGOUT_USER,
  payload: reload,
});
