import _ from 'lodash';
import { put, select, call } from 'redux-saga/effects';

import * as actions from '../../actions';
import * as selectors from '../../selectors';
import * as services from '../../services';
import { getAudioStream, muteStreamTracks } from '../../utils/devices';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* toggleAudioMute() {
  try {
    const userMediaStatus = yield select(selectors.selectUserMediaStatus);
    const newStatus = { ...userMediaStatus, audioMuted: !userMediaStatus.audioMuted };

    /**
     * Update the mediaStatus store
     */
    yield put(actions.updateMediaStatus(newStatus));

    /**
     * Used to reset the muted notification if the user unmutes then mutes again
     */
    if (newStatus.audioMuted) {
      // if it is muted, set to true
      yield put(actions.setMutedNotify(true));
    } else {
      // if it is unmuted, set to false
      yield put(actions.setMutedNotify(false));
    }

    /**
     * Emit to netsapiens video room
     */
    const socket = yield select(selectors.selectSocket);
    const meeting = yield select(selectors.selectMeeting);

    const metaData = { meeting_id: meeting.id, domain: meeting.domain };
    const status = _.pick(newStatus, [
      'audioMuted',
      'hasCamDevice',
      'hasCamPermissions',
      'hasMicDevice',
      'hasMicPermissions',
      'screenShareMuted',
      'userId',
      'videoAspectRatio',
      'videoMuted',
    ]);
    socket.emit(
      'video_status',
      { ...metaData, ...status },
    );

    const audioSession = yield select(selectors.selectAudioSession);
    const remoteAudio = yield select(selectors.selectRemoteAudio);

    console.log('audioSession', audioSession);
    console.log('remoteAudio', remoteAudio);
    console.log('newStatus.audioMuted', newStatus.audioMuted);

    if (remoteAudio) {
      const mute = newStatus.audioMuted ? 'mute' : 'unmute';
      const videoBridgeId = yield select((state) => state.configs.confId);
      const participantMatch = yield select(selectors.selectParticipantMatch);
      const user = yield select(selectors.selectUser);

      yield call(services.toggleNcsMute, {
        user: user.userId || user.user || null,
        domain: meeting.domain,
        videoBridgeId,
        participantMatch,
        mute,
      });
    } else if (audioSession) {
      if (newStatus.audioMuted) {
        const pc = audioSession.sessionDescriptionHandler.peerConnection;
        pc?.getLocalStreams().forEach((stream) => {
          muteStreamTracks(stream);
        });

        yield put(actions.updateMediaStatus({
          ...newStatus,
          audioStream: null,
        }));
      } else {
        const stream = yield getAudioStream(_.get(newStatus.audioInputDevice, 'deviceId'));
        yield put(actions.updateMediaStatus({
          ...newStatus,
          audioStream: stream,
        }));
        const sd = audioSession.sessionDescriptionHandler;
        sd.setLocalMediaStream(stream);
      }
    }
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: toggleAudioMute';
    });
  }
}
