import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import StopScreenShareIcon from '@material-ui/icons/StopScreenShare';

import ControlItem from './ControlItem';

import * as events from '../../../../events';
import * as selectors from '../../../../selectors';
import attendeesShape from '../../../../shapes/attendees';
import meetingShape from '../../../../shapes/meeting';

const messages = defineMessages({
  STOP_SCREENSHARE: {
    id: 'STOP_SCREENSHARE',
    defaultMessage: 'Stop screen share',
  },
});

const StopScreenShareControl = ({
  type,
  variant,
  attendee,
  myAttendee,
  meeting,
  partialMeetingUpdate,
  endScreenShareAction,
  handleClose,
}) => {
  const intl = useIntl();

  if (!attendee) return null; // I have no idea how attendee is ever null here, but it is

  if (!myAttendee) return null; // myAttendee shouldn't ever be null either, but it is

  if (!attendee.isSelf && myAttendee.role !== 'host') {
    return null;
  }

  if (meeting.presenter && meeting.presenter !== attendee.attendee_id) {
    return null;
  }

  const onToggle = () => {
    endScreenShareAction();
    partialMeetingUpdate({
      presenter: '',
    });
    handleClose();
  };

  return (
    <ControlItem
      type={type}
      variant={variant}
      onClick={onToggle}
      enabledIcon={<StopScreenShareIcon />}
      enabledTooltip={intl.formatMessage(messages.STOP_SCREENSHARE)}
    />
  );
};

StopScreenShareControl.propTypes = {
  type: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  attendee: attendeesShape.propTypesShape.isRequired,
  meeting: meetingShape.propTypesShape.isRequired,
  myAttendee: attendeesShape.propTypesShape.isRequired,
  handleClose: PropTypes.func.isRequired,
  partialMeetingUpdate: PropTypes.func.isRequired,
  endScreenShareAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  meeting: selectors.selectMeeting(state),
  myAttendee: selectors.selectMyAttendee(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  partialMeetingUpdate: events.partialMeetingUpdate,
  endScreenShareAction: events.endScreenShare,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StopScreenShareControl);
