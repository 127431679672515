import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core';

import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

import * as events from '../../../../../events';
import * as selectors from '../../../../../selectors';
import meetingShape from '../../../../../shapes/meeting';

const messages = defineMessages({
  HIDE_CHAT: {
    id: 'HIDE_CHAT',
    defaultMessage: 'Hide chat',
  },
  SHOW_CHAT: {
    id: 'SHOW_CHAT',
    defaultMessage: 'Show chat',
  },
});

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-block',
    position: 'relative',
  },
  unreadBadge: {
    backgroundColor: '#e91e63 !important',
  },
}));

const ChatToggle = ({
  // inherited
  variant,
  classes,

  // stateful
  configs,
  isUcInboxOpen,
  meeting,
  tabValue,
  ucUnReadCount,

  // dispatch
  inboxTabChangeAction,
  toggleUCInboxAction,
}) => {
  const localClasses = useStyles();

  const { videoAllowChat } = configs;

  const onOpenChat = () => {
    inboxTabChangeAction('chat');

    if (!isUcInboxOpen || (tabValue === 'chat' && isUcInboxOpen)) {
      toggleUCInboxAction();
    }
  };

  const isChatEnabled = videoAllowChat === 'yes' && meeting.enable_chat;

  return isChatEnabled ? (
    <div className={localClasses.root}>
      {(!!ucUnReadCount && !isUcInboxOpen) && (
        <div className={`animate__animated animate__bounce ${classes.badge} ${localClasses.unreadBadge}`}>
          {ucUnReadCount}
        </div>
      )}
      <Tooltip
        title={isUcInboxOpen && tabValue === 'chat'
          ? <FormattedMessage {...messages.HIDE_CHAT} />
          : <FormattedMessage {...messages.SHOW_CHAT} />}
        placement="bottom-start"
      >
        <IconButton
          id="ChatToggle"
          className={classnames(
            classes.icon,
            classes.btn,
            variant === 'small' && classes.btnSmall,
          )}
          onClick={onOpenChat}
        >
          <QuestionAnswerIcon />
        </IconButton>
      </Tooltip>
    </div>
  ) : '';
};

ChatToggle.defaultProps = {
  variant: null,
};

ChatToggle.propTypes = {
  variant: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  classes: PropTypes.shape({
    badge: PropTypes.string,
    btn: PropTypes.string,
    btnSmall: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
  configs: PropTypes.shape({
    videoAllowChat: PropTypes.string,
  }).isRequired,
  isUcInboxOpen: PropTypes.bool.isRequired,
  meeting: meetingShape.propTypesShape.isRequired,
  tabValue: PropTypes.string.isRequired,
  ucUnReadCount: PropTypes.number.isRequired,
  inboxTabChangeAction: PropTypes.func.isRequired,
  toggleUCInboxAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  configs: state.configs,
  isUcInboxOpen: selectors.selectUcIsOpen(state),
  meeting: selectors.selectMeeting(state),
  tabValue: selectors.selectUcTabValue(state),
  ucUnReadCount: selectors.selectUcUnReadCount(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  inboxTabChangeAction: events.inboxTabChange,
  toggleUCInboxAction: events.toggleUcInbox,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChatToggle);
