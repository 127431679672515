import { select } from 'redux-saga/effects';
import _ from 'lodash';

import {
  selectVideoRoom,
  selectMIV,
  selectParticipants,
  selectVideoGridIds,
  selectMediaStatus,
} from '../../selectors';
import {
  LAYOUT_TYPE_CONVERSATION,
  LAYOUT_TYPE_GRID,
  LAYOUT_TYPE_SPOTLIGHT,
} from '../../constants';
import bugsnagClient from '../../bugsnag';

function setProfile(participant, mediaStatus, videoRoom, profile) {
  if (participant.direction === 'inbound'
    && !_.get(mediaStatus, 'videoMuted')
    && (participant.type === 'video' || participant.type === 'screenShare')) {
    try {
      videoRoom.setPreferredProfile(participant.consumerId, participant.userId, participant.type, profile); // eslint-disable-line max-len
    } catch (e) {
      console.error(`Invalid consumerId:${participant.consumerId} for User:${participant.userId}`);
    }
  }
}

// eslint-disable-next-line import/prefer-default-export,no-unused-vars
export function* setProfiles({ payload }) {
  try {
    const participants = yield select(selectParticipants);
    const gridIds = yield select(selectVideoGridIds);
    const videoRoom = yield select(selectVideoRoom);
    const isMobileView = yield select(selectMIV);
    const mediaStatus = yield select(selectMediaStatus);

    if (isMobileView) {
      Object.keys(participants).forEach((id) => {
        setProfile(participants[id], mediaStatus[participants[id].userId], videoRoom, 'low');
      });
      return;
    }

    if (payload === LAYOUT_TYPE_CONVERSATION) {
      Object.keys(participants).forEach((id) => {
        if (participants[id].type === 'video'
          || participants[id].type === 'screenShare'
          || participants[id].type === 'call') {
          if (id === gridIds[0] || id === gridIds[1]) {
            setProfile(participants[id], mediaStatus[participants[id].userId], videoRoom, 'high');
            if (videoRoom) videoRoom.notifyFocus(participants[id].consumerId);
          } else {
            setProfile(participants[id], mediaStatus[participants[id].userId], videoRoom, 'small');
          }
        }
      });
    }

    if (payload === LAYOUT_TYPE_GRID) {
      Object.keys(participants).forEach((id) => {
        if (participants[id].type === 'screenShare') {
          setProfile(participants[id], mediaStatus[participants[id].userId], videoRoom, 'high');
        } else if (participants[id].type === 'video' || participants[id].type === 'call') {
          if (Object.keys(participants).length < 3) {
            setProfile(participants[id], mediaStatus[participants[id].userId], videoRoom, 'high');
          } else if (Object.keys(participants).length < 8) {
            setProfile(participants[id], mediaStatus[participants[id].userId], videoRoom, 'medium');
          } else {
            setProfile(participants[id], mediaStatus[participants[id].userId], videoRoom, 'small');
          }
        }
      });
    }

    if (payload === LAYOUT_TYPE_SPOTLIGHT) {
      Object.keys(participants).forEach((id) => {
        if (participants[id].type === 'video'
          || participants[id].type === 'screenShare'
          || participants[id].type === 'call'
        ) {
          if (id === gridIds[0]) {
            setProfile(participants[id], mediaStatus[participants[id].userId], videoRoom, 'high');
            if (videoRoom) videoRoom.notifyFocus(participants[id].consumerId);
          } else {
            setProfile(participants[id], mediaStatus[participants[id].userId], videoRoom, 'small');
          }
        }
      });
    }
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: setProfiles';
      event.addMetadata('payload', payload);
    });
  }
}
