import { select } from 'redux-saga/effects';

import * as selectors from '../../selectors';

// eslint-disable-next-line import/prefer-default-export
export function* setRoute(event) {
  // sometimes arguments come in packages in a payload, sometimes they don't
  const { title, path } = event && event.payload ? event.payload : event;

  const defaultTitle = yield select((state) => selectors.selectConfig(state, 'videoName')) || 'Video';
  const newTitle = title ? `${title} - ${defaultTitle}` : defaultTitle;
  const newPath = `/video/${path}`;

  window.history.pushState('video', newTitle, newPath);
}
