import { MEETING_CONFIG } from '../constants';

const defaultState = {
  meeting: null,
};

const reducers = {
  [MEETING_CONFIG]: (state, { payload }) => ({ meeting: payload }),
};

export default {
  defaultState,
  reducers,
};
