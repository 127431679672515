import nsToken from '@netsapiens/netsapiens-js/dist/token';
import store from '../store';

export default () => {
  const { configs } = store.getState();

  const { apiURL } = configs;
  const { hostname } = configs;

  const decoded = nsToken.getDecoded();
  const domain = (decoded && decoded.domain) || '*';
  const territory = (decoded && decoded.territory) || '*';

  if (!apiURL || !domain || !hostname) {
    return false;
  }

  let faviconURL = `${apiURL}?object=image&action=read&server=${hostname}&filename=favicon.gif`;

  if (domain) {
    faviconURL += `&domain=${domain}`;
  }

  if (territory) {
    faviconURL += `&territory=${territory}`;
  }

  return faviconURL;
};
