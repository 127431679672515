import _ from 'lodash';
import { put, select } from 'redux-saga/effects';

import * as actions from '../../actions';
import * as constants from '../../constants';
import * as events from '../../events';
import * as selectors from '../../selectors';

import store from '../../store';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* toggleVideoItemFocus({ payload }) {
  try {
    const { id, toggleSpotlight } = payload;

    if (!id) { // shrink
      yield put(events.setLayout({ layout: constants.LAYOUT_TYPE_GRID }));
    } else { // enlarge or move up
      // read in grid
      const gridIds1 = yield select(selectors.selectVideoGridIds);

      if (_.isEmpty(gridIds1)) {
        return;
      }

      const gridIds = [...gridIds1];

      // re-order grid
      let orderedGrid = [];
      for (let i = 0; i < gridIds.length; i += 1) {
        if (gridIds[i] === id) {
          const focusedItem = gridIds.splice(i, 1)[0];
          orderedGrid.push(focusedItem);
          orderedGrid = orderedGrid.concat(gridIds);
          break;
        }
      }

      // default to original order if focus item wasn't found
      if (!orderedGrid.length) {
        orderedGrid = gridIds;
      }

      // set the new order
      yield put(actions.setVideoGrid(_.uniq(orderedGrid)));
      yield put(actions.setTempVideoGrid(_.uniq(orderedGrid)));

      if (toggleSpotlight !== false) {
        yield put(events.setLayout({ layout: constants.LAYOUT_TYPE_SPOTLIGHT }));
      }

      // toggle active speaker
      const isDynamicSpeakerActive = yield select(selectors.selectDynamicSpeaker);
      if (isDynamicSpeakerActive) {
        yield put(actions.setVideoDynamicSpeaker(false));
        yield put(actions.snackBar({
          message: 'ACTIVE_SPEAKER_OFF',
          type: null,
          action: {
            label: 'TURN_ON',
            action: () => {
              store.dispatch(actions.setVideoDynamicSpeaker(true));
              setTimeout(() => {
                store.dispatch(actions.snackBarMessage('ACTIVE_SPEAKER_ON'));
              }, 500);
            },
          },
        }));
      }
    }
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: toggleVideoItemFocus';
      event.addMetadata('payload', payload);
    });
  }
}
