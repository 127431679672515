import { getMediaDevices } from '../utils/devices';

export default async () => {
  const devices = await getMediaDevices();

  const hasDevices = {
    cam: false,
    mic: false,
  };

  if (devices) {
    devices.forEach((device) => {
      if (device.kind === 'videoinput') {
        hasDevices.cam = true;
      } else if (device.kind === 'audioinput') {
        hasDevices.mic = true;
      }
    });
  }

  return hasDevices;
};
