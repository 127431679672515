import { put, select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import _ from 'lodash';
import bugsnagClient from '../../bugsnag';
import { selectChatSessions } from '../../selectors';
import { matchDeletedMessage } from '../../utils';
import { setChatSessions } from '../../actions';

// eslint-disable-next-line import/prefer-default-export
export function* deleteChatMessage({ payload }) {
  try {
    const decodedToken = nsToken.getDecoded();

    const { sessionId } = payload;

    const sessions = yield select(selectChatSessions);
    const clonedSessions = _.cloneDeep(sessions);

    const newMessagesDeleted = matchDeletedMessage(clonedSessions[payload.sessionId].messages, payload); // eslint-disable-line max-len
    if (newMessagesDeleted) {
      clonedSessions[payload.sessionId].messages = newMessagesDeleted;
      yield put(setChatSessions(clonedSessions));
    }

    yield nsApi.post({
      object: 'message',
      action: 'delete',
      domain: decodedToken.domain,
      session_id: sessionId,
      id: payload.id,
    }, {
      object: 'message',
      action: 'delete',
      domain: decodedToken.domain,
      session_id: sessionId,
      id: payload.id,
    });
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: deleteChatMessage';
      event.addMetadata('payload', payload);
    });
  }
}
