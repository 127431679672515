export const POST_VIDEO_STATUS = 'events/POST_VIDEO_STATUS';

/**
 * @param status
 * @return {{type: string, payload: *}}
 */
export const postVideoStatus = (status) => ({
  type: POST_VIDEO_STATUS,
  payload: status,
});
