import { put, select } from 'redux-saga/effects';
import _ from 'lodash';

import { selectConsumers } from '../../selectors';
import { setConsumers } from '../../actions';

// eslint-disable-next-line import/prefer-default-export
export function* updateConsumer({ payload: consumer }) {
  const consumers = yield select(selectConsumers);
  const clonedConsumers = _.cloneDeep(consumers);
  clonedConsumers[consumer.id] = consumer;

  yield put(setConsumers(clonedConsumers));
}
