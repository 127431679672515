import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import MicOn from '@material-ui/icons/Mic';
import MicOff from '@material-ui/icons/MicOff';

import * as actions from '../../../../../actions';
import * as events from '../../../../../events';
import * as selectors from '../../../../../selectors';

import attendeesShape from '../../../../../shapes/attendees';
import mediaStatusShape from '../../../../../shapes/mediaStatus';
import meetingShape from '../../../../../shapes/meeting';
import { ALLOWED_WEBINAR_ROLES } from '../../../../../constants';

import { store as callMeStore } from '../../../../providers/CallMeProvider';

const messages = defineMessages({
  TURN_ON_MICROPHONE: {
    id: 'TURN_ON_MICROPHONE',
    defaultMessage: 'Turn on microphone',
  },
  TURN_OFF_MICROPHONE: {
    id: 'TURN_OFF_MICROPHONE',
    defaultMessage: 'Turn off microphone',
  },
  MIC_DISABLED_BY_HOST: {
    id: 'MIC_DISABLED_BY_HOST',
    defaultMessage: 'Microphone disabled by host',
  },
  MIC_BLOCKED_BY_BROWSER: {
    id: 'MIC_BLOCKED_BY_BROWSER',
    defaultMessage: 'Microphone blocked by browser',
  },
  MIC_DISABLED_TOOLTIP: {
    id: 'MIC_DISABLED_TOOLTIP',
    defaultMessage: 'Your microphone is blocked by your browser. Click the blocked media icon in your browser\'s address bar to allow microphone use and then refresh',
  },
  MIC_DISABLED_ALERT_CONTENT: {
    id: 'MIC_DISABLED_ALERT_CONTENT',
    defaultMessage: "You won't be able to speak until a host enables your microphone.",
  },
  MIC_DISABLED_BY_EXTERNAL: {
    id: 'MIC_DISABLED_BY_EXTERNAL',
    defaultMessage: 'Microphone disabled by dial in audio',
  },
  MIC_DISABLED_EXTERNAL_CONTENT: {
    id: 'MIC_DISABLED_EXTERNAL_CONTENT',
    defaultMessage: 'Your computer microphone is disabled because you dialed in from a phone.',
  },
  MIC_NOT_DETECTED_TITLE: {
    id: 'MIC_NOT_DETECTED_TITLE',
    defaultMessage: 'We could not detect a microphone',
  },
  MIC_NOT_DETECTED_BODY: {
    id: 'MIC_NOT_DETECTED_BODY',
    defaultMessage: 'Make sure you have a microphone plugged into your computer and rejoin the meeting.',
  },
});

const AudioToggle = ({
  // inherited
  variant,
  classes,
  audioPopper,
  toggleAudioMute,

  // stateful
  meeting,
  mediaStatus,
  myAttendee,

  // dispatch
  openAlertDialog,
  toggleAudioMuteAction,
}) => {
  const { onCall } = React.useContext(callMeStore);

  const externalCall = onCall || mediaStatus.audioInputDevice === 'External Phone';

  const isAudioMuted = () => mediaStatus.audioMuted
    || !mediaStatus.hasMicDevice
    || !mediaStatus.hasMicPermissions
    || externalCall;

  const getAudioToolTip = (isAudioRestricted) => {
    if (!mediaStatus.hasMicDevice) {
      return <FormattedMessage {...messages.TURN_ON_MICROPHONE} />;
    }

    if (externalCall) {
      return <FormattedMessage {...messages.MIC_DISABLED_BY_EXTERNAL} />;
    }

    if (!mediaStatus.hasMicPermissions) {
      return <FormattedMessage {...messages.MIC_DISABLED_TOOLTIP} />;
    }

    if (isAudioRestricted) {
      return <FormattedMessage {...messages.MIC_DISABLED_BY_HOST} />;
    }

    return !mediaStatus.audioMuted
      ? <FormattedMessage {...messages.TURN_OFF_MICROPHONE} />
      : <FormattedMessage {...messages.TURN_ON_MICROPHONE} />;
  };

  const onToggleAudioMute = () => {
    if (!mediaStatus.hasMicDevice) {
      const title = <FormattedMessage {...messages.MIC_NOT_DETECTED_TITLE} />;
      const content = <FormattedMessage {...messages.MIC_NOT_DETECTED_BODY} />;
      openAlertDialog({ title, content });
    } else if (toggleAudioMute) {
      toggleAudioMute(!isAudioMuted());
      audioPopper();
    } else {
      toggleAudioMuteAction();
      audioPopper();
    }
  };

  const notifyRestriction = () => {
    if (externalCall) {
      const title = <FormattedMessage {...messages.MIC_DISABLED_BY_EXTERNAL} />;
      const content = <FormattedMessage {...messages.MIC_DISABLED_EXTERNAL_CONTENT} />;
      openAlertDialog({ title, content });
    } else if (!mediaStatus.hasMicPermissions) {
      const title = <FormattedMessage {...messages.MIC_BLOCKED_BY_BROWSER} />;
      const content = <FormattedMessage {...messages.MIC_DISABLED_TOOLTIP} />;
      openAlertDialog({ title, content });
    } else {
      const title = <FormattedMessage {...messages.MIC_DISABLED_BY_HOST} />;
      const content = <FormattedMessage {...messages.MIC_DISABLED_ALERT_CONTENT} />;
      openAlertDialog({ title, content });
    }
  };

  let isAudioPermitted = true;
  switch (meeting.audio_share) {
    case 'host':
      isAudioPermitted = myAttendee && myAttendee.role === 'host';
      break;
    case 'presenters':
      isAudioPermitted = myAttendee && ALLOWED_WEBINAR_ROLES.includes(myAttendee.role);
      break;
    default:
      break;
  }

  const isAudioRestricted = !myAttendee
    || !mediaStatus.hasMicDevice
    || !isAudioPermitted
    || Boolean(myAttendee.audio_restricted)
    || externalCall
    || !mediaStatus.hasMicPermissions;

  const audioMuted = isAudioMuted(mediaStatus);
  const audioMutedButtonStyle = audioMuted || isAudioRestricted ? classes.activeBtn : classes.btn;
  const audioMutedIconStyle = audioMuted || isAudioRestricted ? classes.activeIcon : classes.icon;

  const onClickAction = !isAudioRestricted ? onToggleAudioMute : notifyRestriction;

  return (
    <Tooltip title={getAudioToolTip(isAudioRestricted)}>
      <IconButton
        id="AudioToggle"
        data-restricted={isAudioRestricted}
        data-muted={audioMuted}
        onClick={onClickAction}
        className={
          classnames(
            audioMutedIconStyle,
            audioMutedButtonStyle,
            isAudioRestricted && classes.restrictedBtn,
            variant === 'small' && classes.btnSmall,
          )
        }
      >
        {audioMuted || isAudioRestricted ? <MicOff /> : <MicOn />}
      </IconButton>
    </Tooltip>
  );
};

AudioToggle.defaultProps = {
  variant: null,
  audioPopper: () => {},
  toggleAudioMute: null,
};

AudioToggle.propTypes = {
  variant: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  audioPopper: PropTypes.func,
  classes: PropTypes.shape({
    activeBtn: PropTypes.string,
    activeIcon: PropTypes.string,
    btn: PropTypes.string,
    btnSmall: PropTypes.string,
    icon: PropTypes.string,
    restrictedBtn: PropTypes.string,
  }).isRequired,
  meeting: meetingShape.propTypesShape.isRequired,
  mediaStatus: mediaStatusShape.propTypesShape.isRequired,
  myAttendee: attendeesShape.propTypesShape.isRequired,
  openAlertDialog: PropTypes.func.isRequired,
  toggleAudioMuteAction: PropTypes.func.isRequired,
  toggleAudioMute: PropTypes.func,
};

const mapStateToProps = (state) => ({
  myAttendee: selectors.selectMyAttendee(state),
  meeting: selectors.selectMeeting(state),
  mediaStatus: selectors.selectUserMediaStatus(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  openAlertDialog: actions.openAlertDialog,
  toggleAudioMuteAction: events.toggleAudioMute,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AudioToggle);
