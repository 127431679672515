import { select } from 'redux-saga/effects';

import getAllMessageSessions from '../../services/getAllMessageSessions';
import * as selectors from '../../selectors';

export default function* initMessageSessions() {
  // get messages for this video session (All)
  const meeting = yield select((state) => selectors.selectMeeting(state));
  return yield getAllMessageSessions(meeting);
}
