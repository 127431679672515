import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import {
  makeStyles, IconButton, ListItemIcon, Menu, MenuItem, Typography,
} from '@material-ui/core';

import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import MicOffIcon from '@material-ui/icons/MicOff';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import StopScreenShareIcon from '@material-ui/icons/StopScreenShare';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';

import * as selectors from '../../../../../selectors';
import meetingShape from '../../../../../shapes/meeting';

const messages = defineMessages({
  DISABLE_CAMERAS: {
    id: 'DISABLE_CAMERAS',
    defaultMessage: 'Toggle cameras',
  },
  DISABLE_MICROPHONES: {
    id: 'DISABLE_MICROPHONES',
    defaultMessage: 'Toggle microphones',
  },
  DISABLE_SCREENSHARE: {
    id: 'DISABLE_SCREENSHARE',
    defaultMessage: 'Toggle screenshare',
  },
  MESSAGE_PRESENTERS: {
    id: 'MESSAGE_PRESENTERS',
    defaultMessage: 'Message presenters',
  },
});

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
    marginLeft: 6,
    padding: 3,
    fontSize: '1.125rem',
  },
}));

const GroupsControlsMenu = ({
  variant,
  onDisableCameras,
  onDisableMicrophones,
  onDisableScreenshare,
  onMessagePresenters,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="ControlsMenuToggleMenu"
        className={classes.button}
        onClick={handleClick}
        onClose={handleClose}
      >
        {variant === 'horizontal' ? <MoreHorizIcon /> : <MoreVertIcon />}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!!onMessagePresenters && (
          <MenuItem onClick={onMessagePresenters}>
            <ListItemIcon>
              <ChatBubbleIcon />
            </ListItemIcon>
            <Typography variant="inherit">
              <FormattedMessage {...messages.MESSAGE_PRESENTERS} />
            </Typography>
          </MenuItem>
        )}

        {!!onDisableMicrophones && (
          <MenuItem onClick={() => onDisableMicrophones(handleClose)}>
            <ListItemIcon>
              <MicOffIcon />
            </ListItemIcon>
            <Typography variant="inherit">
              <FormattedMessage {...messages.DISABLE_MICROPHONES} />
            </Typography>
          </MenuItem>
        )}

        {!!onDisableCameras && (
          <MenuItem onClick={() => onDisableCameras(handleClose)}>
            <ListItemIcon>
              <VideocamOffIcon />
            </ListItemIcon>
            <Typography variant="inherit">
              <FormattedMessage {...messages.DISABLE_CAMERAS} />
            </Typography>
          </MenuItem>
        )}

        {!!onDisableScreenshare && (
          <MenuItem onClick={() => onDisableScreenshare(handleClose)}>
            <ListItemIcon>
              <StopScreenShareIcon />
            </ListItemIcon>
            <Typography variant="inherit">
              <FormattedMessage {...messages.DISABLE_SCREENSHARE} />
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

GroupsControlsMenu.defaultProps = {
  variant: 'vertical',
  onChangeRole: null,
  onClose: null,
  onDisableCameras: null,
  onDisableCamera: null,
  onDisableMicrophones: null,
  onDisableMicrophone: null,
  onDisableScreenshare: null,
  onKick: null,
  onPrivateMessage: null,
  onMessagePresenters: null,
  onTransferPresentation: null,
};

GroupsControlsMenu.propTypes = {
  variant: PropTypes.string,
  configs: PropTypes.shape({
    videoAllowChat: PropTypes.string,
  }).isRequired,
  meeting: meetingShape.propTypesShape.isRequired,
  onChangeRole: PropTypes.func,
  onClose: PropTypes.func,
  onDisableCameras: PropTypes.func,
  onDisableCamera: PropTypes.func,
  onDisableMicrophones: PropTypes.func,
  onDisableMicrophone: PropTypes.func,
  onDisableScreenshare: PropTypes.func,
  onKick: PropTypes.func,
  onPrivateMessage: PropTypes.func,
  onMessagePresenters: PropTypes.func,
  onTransferPresentation: PropTypes.func,
};

const mapStateToProps = (state) => ({
  configs: state.configs,
  meeting: selectors.selectMeeting(state),
});

export default connect(mapStateToProps)(GroupsControlsMenu);
