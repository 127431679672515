export const CHANGE_ATTENDEE_ROLE = 'attendees/CHANGE_ATTENDEE_ROLE';

/**
 * @param attendee
 * @param role
 * @returns {{payload: *, type: string}}
 */
export const changeAttendeeRole = (attendee, role) => ({
  type: CHANGE_ATTENDEE_ROLE,
  payload: {
    attendee,
    role,
  },
});
