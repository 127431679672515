import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { makeStyles } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

import * as events from '../../../../events';
import { selectMeeting } from '../../../../selectors';
import { formatMeetingId } from '../../../../utils';

import meetingShape from '../../../../shapes/meeting';

const messages = defineMessages({
  MEETING_ID: {
    id: 'MEETING_ID',
    defaultMessage: 'Meeting ID',
  },
  MEETING_INFO: {
    id: 'MEETING_INFO',
    defaultMessage: 'Meeting Info',
  },
  CONFERENCE: {
    id: 'CONFERENCE',
    defaultMessage: 'Conference',
  },
  PRESENTATION: {
    id: 'PRESENTATION',
    defaultMessage: 'Webinar',
  },
});

const useStyles = makeStyles(() => ({
  shareBtn: {
    transition: '500ms all ease-in-out',
    margin: '0 4px 0 0',
    backgroundColor: 'rgba(0, 0, 0, 0.54) !important',
    borderRadius: '40px',
    maxWidth: 250,
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,.10) !important',
    },
  },
  shareTxt: {
    position: 'relative',
    lineHeight: '18px',
    margin: '-9px 6px',
    top: 1,
    fontSize: 13,
    fontWeight: 400,
    whiteSpace: 'nowrap',
    textAlign: 'left',
    overflow: 'hidden',
    '& h6': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      marginLeft: 0,
      lineHeight: '32px',
    },
  },
}));

const MeetingInfo = ({
  // inherited
  classes,
  variant,

  // stateful
  configs,
  meeting,
  showAddUserAction,
}) => {
  const localClasses = useStyles();

  const { confId } = configs;

  const meetingName = meeting.name
    || nsUtils.formatPhoneNumber(meeting.dial_in_numbers, configs.PORTAL_LOCALIZATION_NUMBER_FORMAT) // eslint-disable-line max-len, react/prop-types
    || <FormattedMessage {...messages[meeting.type.toUpperCase()]} />;

  return (
    <Tooltip title={<FormattedMessage {...messages.MEETING_INFO} />}>
      <IconButton
        id="MeetingInfoModalOpen"
        className={
          classnames(
            classes.icon,
            localClasses.shareBtn,
            variant === 'small' && classes.btnSmall,
          )
        }
        onClick={() => showAddUserAction({ show: true, tab: 'info' })}
      >
        <InfoOutlined />
        {variant === 'default' && (
          <span className={localClasses.shareTxt}>
            <Typography className={localClasses.shareTxt} variant="subtitle2">
              {meetingName}
            </Typography>
            <Typography variant="body2">
              {formatMeetingId(confId)}
            </Typography>
          </span>
        )}
      </IconButton>
    </Tooltip>
  );
};

MeetingInfo.defaultProps = {
  variant: 'default',
};

MeetingInfo.propTypes = {
  classes: PropTypes.shape({
    btnSmall: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
  configs: PropTypes.shape({
    confId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    videoPhoneNumber: PropTypes.string,
  }).isRequired,
  showAddUserAction: PropTypes.func.isRequired,
  meeting: meetingShape.propTypesShape.isRequired,
  variant: PropTypes.string,
};

const mapStateToProps = (state) => ({
  configs: state.configs,
  meeting: selectMeeting(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showAddUserAction: events.showAddUser,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MeetingInfo);
