import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/index';
import classnames from 'classnames';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
  },
}));

const PageContainer = ({ children, style, className }) => {
  const classes = useStyles();

  return (
    <Grid
      id="PageContainer"
      className={classnames('animate__animated animate__fadeIn', className, classes.root)}
      container
      justifyContent="center"
      alignContent="center"
    >
      <Grid item style={style}>
        {children}
      </Grid>
    </Grid>
  );
};

PageContainer.defaultProps = {
  style: null,
  className: null,
};

PageContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]).isRequired,
  style: PropTypes.shape({}),
  className: PropTypes.string,
};

export default PageContainer;
