import { put, select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';

import * as actions from '../../actions';
import { userAuthenticated } from '../../events';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* mfaSubmit({ payload }) {
  const {
    username, passcode, mfaType, mfaVendor,
  } = payload;

  try {
    yield put(actions.showLoaderPage());

    const apiHash = yield select((state) => state.configs.apiHash);

    const params = {
      username,
      passcode,
      mfa_type: mfaType,
      mfa_vendor: mfaVendor,
      apiHash,
    };

    yield nsApi.mfaAuth(params);
    yield put(actions.loginFormErrorMsg(null));
    yield put(userAuthenticated());
  } catch (err) {
    yield put(actions.loginFormErrorMsg('PASSCODE_INVALID'));
    yield put(actions.showMfaFormPage({
      username,
      mfaType,
      mfaVendor,
    }));

    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.severity = 'info';
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: mfaSubmit';
      event.addMetadata('payload', payload);
    });
  }
}
