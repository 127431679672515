import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import useMediaDevices from 'react-use/lib/useMediaDevices';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import DetectRTC from '@netsapiens/netsapiens-js/dist/web-rtc';
import mediaStatusShape from '../../../../shapes/mediaStatus';

import { updateMediaStatus } from '../../../../actions';
import {
  getDefaultAudioOutput,
  getFilteredAudioOutput,
} from '../../../../utils/devices';
import {
  selectUserMediaStatus,
  selectAudioElement,
} from '../../../../selectors';

const messages = defineMessages({
  MIC_OUTPUT_LABEL: {
    id: 'MIC_OUTPUT_LABEL',
    defaultMessage: 'Audio output',
  },
  NO_MIC_OUTPUT_MESSAGE: {
    id: 'NO_MIC_OUTPUT_MESSAGE',
    defaultMessage: 'Default audio output device',
  },
  NO_DEVICES: {
    id: 'NO_DEVICES',
    defaultMessage: 'No devices detected',
  },
  BLOCKED_BY_BROWSER: {
    id: 'BLOCKED_BY_BROWSER',
    defaultMessage: 'Blocked by browser',
  },
});

const OutputDevice = ({
  mediaStatus,
  audioElement,
  updateMediaStatusAction,
}) => {
  const { devices } = useMediaDevices();

  const {
    audioOutputDevice,
    audioOutputDeviceSelect,
    hasMicPermissions,
  } = mediaStatus;

  const [devicesInit, setDevicesInit] = useState(false);

  const [defaultAudioOutput, setDefaultAudioOutput] = useState(null);
  const [audioOutputDevices, setAudioOutputDevices] = useState([]);

  const selectedAudioOutput = audioOutputDeviceSelect || audioOutputDevice;
  const setSelectedAudioOutput = (selectedDevice) => {
    updateMediaStatusAction({
      userId: mediaStatus.userId,
      audioOutputDevice: selectedDevice,
    });
  };

  // initialize devices
  useEffect(() => {
    if (devicesInit && devices) {
      const filteredDefaultAudioOutput = getDefaultAudioOutput(devices);
      const filteredAudioOutputs = getFilteredAudioOutput(devices);
      setDefaultAudioOutput(filteredDefaultAudioOutput);
      setAudioOutputDevices(filteredAudioOutputs);
      setSelectedAudioOutput(audioOutputDevice);
    } else if (devices) {
      setDevicesInit(true);
    }
  }, [devices, devicesInit]);

  // check if selected audio output device is still connected
  useEffect(() => {
    if (selectedAudioOutput && audioOutputDevices.length > 0) {
      const filter = {};
      if (selectedAudioOutput.groupId) filter.groupId = selectedAudioOutput.groupId;
      else filter.deviceId = selectedAudioOutput.deviceId;

      const stillExists = !!_.filter(audioOutputDevices, filter).length;

      if (!stillExists) {
        setSelectedAudioOutput(null);
      }
    }
  }, [audioOutputDevices, selectedAudioOutput]);

  // update audioOutput
  useEffect(() => {
    if (selectedAudioOutput) {
      localStorage.setItem('myLastAudioOutput', _.get(selectedAudioOutput, 'deviceId'));
      if (selectedAudioOutput && audioElement && DetectRTC.isSetSinkIdSupported) {
        audioElement.setSinkId(selectedAudioOutput.deviceId);
      }
    }
  }, [selectedAudioOutput]);

  const handleChange = (setter, filter, localdevices) => (e) => {
    const id = e.target.value;
    const match = _.find(filter(localdevices), { groupId: id });
    if (match) {
      setter(match);
    } else {
      setter(_.find(filter(localdevices), { deviceId: id }));
    }
  };

  const selectedOutput = _.get(selectedAudioOutput, 'groupId')
    || _.get(defaultAudioOutput, 'groupId')
    || '';

  const hasDeviceLabel = audioOutputDevices.find((device) => device.label !== '') !== undefined;

  return (
    <>
      <InputLabel id="mic_output">
        <FormattedMessage {...messages.MIC_OUTPUT_LABEL} />
      </InputLabel>
      {hasMicPermissions !== false && hasDeviceLabel && (
        <TextField
          select
          id="OutputDeviceMicOutput"
          labelid="mic_output"
          variant="filled"
          margin="dense"
          value={selectedOutput}
          onChange={handleChange(
            setSelectedAudioOutput,
            getFilteredAudioOutput,
            audioOutputDevices,
          )}
          fullWidth
        >
          {audioOutputDevices.map((device) => (
            <MenuItem key={device.groupId} value={device.groupId}>
              {device.label}
            </MenuItem>
          ))}
        </TextField>
      )}
      {(hasMicPermissions === false || !hasDeviceLabel) && (
        <TextField
          select
          labelid="mic_output"
          variant="filled"
          margin="dense"
          value={1}
          fullWidth
          disabled
        >
          <MenuItem value={1}>
            <FormattedMessage {...messages.NO_MIC_OUTPUT_MESSAGE} />
          </MenuItem>
        </TextField>
      )}
    </>
  );
};

OutputDevice.defaultProps = {
  audioElement: null,
};

OutputDevice.propTypes = {
  mediaStatus: mediaStatusShape.propTypesShape.isRequired,
  audioElement: PropTypes.shape({
    setSinkId: PropTypes.func,
  }),
  updateMediaStatusAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  mediaStatus: selectUserMediaStatus(state),
  audioElement: selectAudioElement(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateMediaStatusAction: updateMediaStatus,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OutputDevice);
