export default (participant) => {
  if (!participant.id) { throw Error('Missing Participant id - id required'); }

  return {
    // meta
    id: participant.id,
    type: participant.type || 'video',
    direction: participant.direction || '',
    isSelf: participant.isSelf || false,
    show: participant.show || true,
    status: participant.status || 'trying',

    // user info
    bgColor: participant.bgColor || '',
    contactId: participant.contactId || null,
    displayName: participant.displayName || '',
    domain: participant.domain || null,
    gravatar: participant.gravatar || null,
    email: participant.email || null,
    initials: participant.initials || '',
    uid: participant.uid || null,
    userId: participant.userId || null,

    // audio
    audioId: participant.audioId || null,
    audioPart: participant.audioPart || null,
    isOnHold: participant.isOnHold || false,

    // video
    consumerId: participant.consumerId || null,
    profile: participant.profile || 'low',
    videoPart: participant.videoPart || null,
    videoStat: participant.videoStat || false,
    videoTrack: participant.videoTrack || null,
    videoTrackMuted: participant.videoTrackMuted || !participant.videoTrack,
  };
};
