import { put } from 'redux-saga/effects';
import * as actions from '../../actions';
import { initConference } from '../../events';
import { isValidMeetingId } from '../../utils';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* joinConference({ payload }) {
  try {
    const confId = payload;

    if (!isValidMeetingId(confId)) {
      yield put(actions.confIdFormErrorId('ID_NOT_VALID'));
      return false;
    }

    yield put(actions.setConfig({ confId }));
    yield put(actions.showLoaderPage());
    yield put(initConference());
  } catch (e) {
    yield put(actions.confIdFormErrorId('CONF_NOT_FOUND'));

    console.debug(e);
    bugsnagClient.notify(e, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.severity = 'warning';
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: joinConference';
      event.addMetadata('payload', payload);
    });
  }

  return true;
}
