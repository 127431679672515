import {
  SCREENSHARE_IS_SELF,
  SCREENSHARE_IS_SHARING,
  SCREENSHARE_BLOCKED,
  SCREENSHARE_OVERLAY_DISMISSED,
} from '../constants';

const defaultState = {
  isSelf: false,
  isSharing: false,
  isBlocked: false,
  overlayDismissed: false,
};

const reducers = {
  [SCREENSHARE_IS_SELF]: (state, { payload }) => ({ isSelf: payload }),
  [SCREENSHARE_IS_SHARING]: (state, { payload }) => ({ isSharing: payload }),
  [SCREENSHARE_BLOCKED]: (state, { payload }) => ({ isBlocked: payload }),
  [SCREENSHARE_OVERLAY_DISMISSED]: (state, { payload }) => ({ overlayDismissed: payload }),
};

export default {
  defaultState,
  reducers,
};
