import * as constants from '../constants';

const defaultState = {
  logo: null,
  showBgImage: null,
  MIV: 0, // mobile integration view, 0:off 1:webview 2:mobile

  // pages
  showBrowserSupportPage: false,
  showConfIdFormPage: false,
  showConfSettingsPage: false,
  showDeviceSettingsPage: false,
  showLoaderPage: false,
  showLoginFormPage: false,
  showLandingFormPage: false,
  showRegisterFormPage: false,
  showRegisterSuccessPage: false,
  showMeetingConfig: false,
  showMeetingConfigBypass: false,
  showMeetingEndedPage: false,
  showMeetingErrorPage: false,
  showMeetingTimeErrorPage: false,
  showMeetingHubPage: false,
  showMeetingPasswordPage: false,
  showMeetingsScrollPage: false,
  showMfaFormPage: false,
  showSsoEnrollPage: false,
  showSubscriberSelectPage: false,
  showMeetingNotFoundPage: false,
  showUCLicensePage: false,
  showVideoGridPage: false,

  // modals
  showScreenshareRequestModal: false,
  showAddUser: {
    show: false,
    tab: 'info',
  },
  showPermissionsDialog: false,
  showPendingPermissionsDialog: false,
  showDeviceReconfigModal: false,
};

const reducers = {
  [constants.LOGO]: (state, { payload }) => ({ logo: payload }),
  [constants.SET_MIV]: (state, { payload }) => ({ MIV: payload }),
  [constants.SHOW_ADD_USER]: (state, { payload }) => ({ showAddUser: payload }),
  [constants.SHOW_BROWSER_SUPPORT_PAGE]: (state) => ({
    ...defaultState,
    logo: state.logo,
    MIV: state.MIV,
    showBgImage: state.showBgImage,
    showBrowserSupportPage: true,
  }),
  [constants.SHOW_BG_IMAGE]: (state, { payload }) => ({
    ...state,
    logo: state.logo,
    showBgImage: payload,
  }),
  [constants.SHOW_PENDING_PERMISSIONS_DIALOG]: (state, { payload }) => ({
    ...state,
    showPendingPermissionsDialog: payload,
  }),
  [constants.SHOW_PERMISSIONS_DIALOG]: (state, { payload }) => ({
    ...state,
    showPermissionsDialog: payload,
  }),
  [constants.SHOW_CONF_ID_FORM_PAGE]: (state) => ({
    ...defaultState,
    logo: state.logo,
    MIV: state.MIV,
    showBgImage: state.showBgImage,
    showConfIdFormPage: true,
  }),
  [constants.SHOW_CONF_SETTINGS_PAGE]: (state) => ({
    ...defaultState,
    logo: state.logo,
    MIV: state.MIV,
    showBgImage: state.showBgImage,
    showConfSettingsPage: true,
  }),
  [constants.SHOW_DEVICE_SETTINGS_PAGE]: (state) => ({
    ...defaultState,
    logo: state.logo,
    MIV: state.MIV,
    showBgImage: state.showBgImage,
    showDeviceSettingsPage: true,
  }),
  [constants.SHOW_LOADER_PAGE]: (state) => ({
    ...defaultState,
    logo: state.logo,
    MIV: state.MIV,
    showBgImage: state.showBgImage,
    showLoaderPage: true,
  }),
  [constants.SHOW_LOGIN_FORM_PAGE]: (state) => ({
    ...defaultState,
    logo: state.logo,
    MIV: state.MIV,
    showBgImage: state.showBgImage,
    showLoginFormPage: true,
  }),
  [constants.SHOW_LANDING_FORM_PAGE]: (state) => ({
    ...defaultState,
    logo: state.logo,
    showBgImage: state.showBgImage,
    showLandingFormPage: true,
  }),
  [constants.SHOW_REGISTER_FORM_PAGE]: (state) => ({
    ...defaultState,
    logo: state.logo,
    MIV: state.MIV,
    showBgImage: state.showBgImage,
    showRegisterFormPage: true,
  }),
  [constants.SHOW_REGISTER_SUCCESS_PAGE]: (state) => ({
    ...defaultState,
    logo: state.logo,
    MIV: state.MIV,
    showBgImage: state.showBgImage,
    showRegisterSuccessPage: true,
  }),
  [constants.SHOW_MFA_FORM_PAGE]: (state, { payload }) => ({
    ...defaultState,
    logo: state.logo,
    MIV: state.MIV,
    showBgImage: state.showBgImage,
    showMfaFormPage: payload,
  }),
  [constants.SHOW_SSO_ENROLL_PAGE]: (state, { payload }) => ({
    ...defaultState,
    logo: state.logo,
    MIV: state.MIV,
    showBgImage: state.showBgImage,
    showSsoEnrollPage: payload,
  }),
  [constants.SHOW_SUBSCRIBER_SELECT_PAGE]: (state, { payload }) => ({
    ...defaultState,
    logo: state.logo,
    MIV: state.MIV,
    showBgImage: state.showBgImage,
    showSubscriberSelectPage: payload,
  }),
  [constants.SHOW_MEETING_CONFIG]: (state, { payload }) => ({
    ...defaultState,
    logo: state.logo,
    MIV: state.MIV,
    showBgImage: state.showBgImage,
    showMeetingConfig: payload,
  }),
  [constants.SHOW_MEETING_CONFIG_BYPASS]: (state, { payload }) => ({
    ...defaultState,
    logo: state.logo,
    MIV: state.MIV,
    showBgImage: state.showBgImage,
    showMeetingConfigBypass: payload,
  }),
  [constants.SHOW_MEETING_ENDED]: (state) => ({
    ...defaultState,
    logo: state.logo,
    MIV: state.MIV,
    showMeetingEndedPage: true,
  }),
  [constants.SHOW_MEETING_PASSWORD_PAGE]: (state) => ({
    ...defaultState,
    logo: state.logo,
    MIV: state.MIV,
    showBgImage: state.showBgImage,
    showMeetingPasswordPage: true,
  }),
  [constants.SHOW_MEETINGS_SCROLL_PAGE]: (state, { payload }) => ({
    ...defaultState,
    logo: state.logo,
    MIV: state.MIV,
    showBgImage: state.showBgImage,
    showMeetingsScrollPage: payload,
  }),
  [constants.SHOW_MEETING_ERROR_PAGE]: (state, { payload }) => ({
    ...defaultState,
    logo: state.logo,
    MIV: state.MIV,
    showBgImage: state.showBgImage,
    showMeetingErrorPage: payload,
  }),
  [constants.SHOW_MEETING_TIME_ERROR_PAGE]: (state, { payload }) => ({
    ...defaultState,
    logo: state.logo,
    MIV: state.MIV,
    showBgImage: state.showBgImage,
    showMeetingTimeErrorPage: payload,
  }),
  [constants.SHOW_MEETING_HUB_PAGE]: (state, { payload }) => ({
    ...defaultState,
    logo: state.logo,
    MIV: state.MIV,
    showBgImage: state.showBgImage,
    showMeetingHubPage: payload,
  }),
  [constants.SHOW_MEETING_NOT_FOUND_PAGE]: (state) => ({
    ...defaultState,
    logo: state.logo,
    MIV: state.MIV,
    showBgImage: state.showBgImage,
    showMeetingNotFoundPage: true,
  }),
  [constants.SHOW_SCREENSHARE_REQUEST_MODAL]: (state, { payload }) => ({
    showScreenshareRequestModal: payload,
  }),
  [constants.SHOW_UC_LICENSE_PAGE]: (state, { payload }) => ({
    ...defaultState,
    logo: state.logo,
    MIV: state.MIV,
    showBgImage: state.showBgImage,
    showUCLicensePage: payload,
  }),
  [constants.SHOW_DEVICE_RECONFIG_MODAL]: (state, { payload }) => ({
    showDeviceReconfigModal: payload,
  }),
  [constants.SHOW_VIDEO_GRID_PAGE]: (state, { payload }) => ({
    ...defaultState,
    logo: state.logo,
    MIV: state.MIV,
    showBgImage: state.showBgImage,
    showVideoGridPage: payload,
  }),
};

export default {
  defaultState,
  reducers,
};
