import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import MenuItem from '@material-ui/core/MenuItem';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import * as selectors from '../../../../../selectors';
import * as actions from '../../../../../actions';

/* eslint react/prop-types: 0 */
const messages = defineMessages({
  HIDE_PEOPLE_WITH_NO_VIDEO: {
    id: 'HIDE_PEOPLE_WITH_NO_VIDEO',
    defaultMessage: 'Hide people with no video',
  },
  SHOW_PEOPLE_WITH_NO_VIDEO: {
    id: 'SHOW_PEOPLE_WITH_NO_VIDEO',
    defaultMessage: 'Show people with no video',
  },
});

const HideMutedMenuItem = ({
  menuItemKey,
  clickCallback,
  setVideoMuteHideAction,
  videoMuteHide,
}) => {
  const toggleMuteHide = () => {
    clickCallback();
    setVideoMuteHideAction(!videoMuteHide);
  };

  return videoMuteHide ? (
    <MenuItem key={menuItemKey} onClick={toggleMuteHide}>
      <Visibility />
      <FormattedMessage {...messages.SHOW_PEOPLE_WITH_NO_VIDEO} />
    </MenuItem>
  ) : (
    <MenuItem key={menuItemKey} onClick={toggleMuteHide}>
      <VisibilityOff />
      <FormattedMessage {...messages.HIDE_PEOPLE_WITH_NO_VIDEO} />
    </MenuItem>
  );
};

HideMutedMenuItem.propTypes = {
  menuItemKey: PropTypes.string.isRequired,
  clickCallback: PropTypes.func.isRequired,
  setVideoMuteHideAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  videoMuteHide: selectors.selectVideoMuteHide(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setVideoMuteHideAction: actions.setVideoMuteHide,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HideMutedMenuItem);
