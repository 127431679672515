import {
  call, put, select,
} from 'redux-saga/effects';
import {
  Registerer, UserAgent, SIPExtension, RegistererState,
} from 'sip.js';
import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';

import store from '../../store';
import * as actions from '../../actions';
import fetchAudioDevice from './services/fetchAudioDevice';
import getSipLoggingConfigs from './services/getSipLoggingConfigs';
import bugsnagClient from '../../bugsnag';
import packageJson from '../../../package.json';

export const AUDIO_REGISTRATION_FAILED = 'AUDIO_REGISTRATION_FAILED';

let registerer = null;

// Number of seconds to wait between reconnection attempts
const reconnectionDelay = 2;
// Used to guard against overlapping reconnection attempts
let attemptingReconnection = false;
let connectionAttempt = 0;

let attemptReconnection = null;
let registrationTimeoutTimer = null;
export const addRegistrar = (ua) => {
  const registererOptions = {
    expires: 180,
    refreshFrequency: 75,
  };
  if (!registerer || registerer.state === 'Terminated') registerer = new Registerer(ua, registererOptions);
  else return registerer;

  registerer.stateChange.addListener((newState) => {
    switch (newState) {
      case RegistererState.Initial:
        console.debug('Initial');
        // dispatch(setDeviceRegistered(false));
        break;
      case RegistererState.Registered:
        if (registrationTimeoutTimer) {
          clearTimeout(registrationTimeoutTimer);
          registrationTimeoutTimer = null;
        }
        // dispatch(setDeviceRegistered(true));
        break;
      case RegistererState.Terminated:
        console.debug('Registration Terminated');
        // dispatch(setDeviceRegistered(false));
        break;
      case RegistererState.Unregistered:
        if (!registrationTimeoutTimer) {
          registrationTimeoutTimer = setTimeout(() => {
            ua.stop().then(() => {
              // dispatch(attemptReconnection(ua));
              console.debug('attemptReconnection');
              attemptReconnection(ua);
            });
            registrationTimeoutTimer = null;
          }, 8000);
        }

        break;

      default:
    }
  });
  registerer.register()
    .then(() => {
      console.debug('Registraion in progress');
    })
    .catch((error) => {
      console.error('Failed to Register', error);
      attemptReconnection(ua);
    });

  return true;
};

// Function which recursively attempts reconnection
attemptReconnection = (ua) => {
  // Reconnection attempt already in progress
  if (attemptingReconnection) {
    return;
  }

  const { configs } = store.getState();
  const { uaSocketURLs } = configs;

  // We're attempting a reconnection
  attemptingReconnection = true;

  setTimeout(() => {
    connectionAttempt += 1;
    console.debug(`connectionAttempt= ${connectionAttempt}`);
    const serverListSize = uaSocketURLs.length || uaSocketURLs.length;
    const newIndex = connectionAttempt % serverListSize;

    // eslint-disable-next-line no-param-reassign
    ua.transport.configuration.server = uaSocketURLs[newIndex].ws_uri || uaSocketURLs[newIndex];
    put(actions.setAudioUA(ua));
    console.debug(`new server = ${ua.transport.configuration.server}`);
    // Attempt reconnect

    if (ua.state === 'Stopped') {
      console.debug('UA stopped is starting...');
      ua.start()
        .then(() => {
          addRegistrar(ua);

          attemptingReconnection = false;
        })
        .catch((error) => {
          attemptingReconnection = false;
          console.error('Failed to start', error);
          // dispatch({
          //     type: UA,
          //     payload: false
          // });
          // dispatch(setDeviceRegistered(false));
          // dispatch();
          attemptReconnection(ua);
        });
    } else {
      ua.reconnect()
        .then(() => {
          console.debug('reconnect success!');

          if (!registerer) {
            // dispatch(addRegistrar(ua));
            addRegistrar(ua);
          }

          // Reconnect attempt succeeded
          attemptingReconnection = false;
        })
        .catch((error) => {
          // Reconnect attempt failed
          console.debug('reconnect failed!');
          if (error) console.error(error);
          attemptingReconnection = false;
          attemptReconnection(ua);
        });
    }
  }, reconnectionDelay * 1000);
};

export default function* registerAudioUa(decodedToken) {
  const configs = yield select((state) => state.configs);
  // fetch the user audio device, if it doesn't exist it will get created
  const audioDevice = yield call(fetchAudioDevice, {
    combinedId: configs.combinedId,
    devicePostFix: configs.devicePostFix,
    domain: decodedToken.domain,
    userId: configs.userId,
  });
  const isMiv = yield select((state) => state.layout.MIV);

  if (!audioDevice || isMiv === 1) {
    yield put(actions.setAudioDevice(false));
    return Promise.resolve(false);
  }

  // set the audio device, this will be used later and for dev tools
  yield put(actions.setAudioDevice(audioDevice));

  // get sip logging configuration
  const sipLoggingConfigs = yield getSipLoggingConfigs(configs.appName);

  const displayName = audioDevice.subscriber_name !== 'guest'
    ? audioDevice.sub_fullname : decodedToken.displayName;

  const { iceCheckingTimeout } = configs;
  const { uaSocketURLs } = configs;
  const { version } = packageJson;
  const { stunServers } = configs;

  // https://github.com/onsip/SIP.js/blob/master/docs/api/sip.js.useragentoptions.md
  const UserAgentOptions = {
    uri: UserAgent.makeURI(audioDevice.aor),
    transportOptions: { server: uaSocketURLs[0].ws_uri || uaSocketURLs[0] },
    codecsVideo: false,
    displayName,
    iceGatheringTimeout: iceCheckingTimeout,
    noAnswerTimeout: 180,
    authorizationUsername: audioDevice.aor,
    authorizationPassword: audioDevice.authentication_key,
    register: true,
    sipExtension100rel: SIPExtension.Supported,
    sipExtensionReplaces: SIPExtension.Supported,
    logLevel: sipLoggingConfigs.sipLog,
    traceSip: sipLoggingConfigs.sipTrace,
    userAgentString: `${configs.appName} ${version} (${nsBrowser.name} ${nsBrowser.version})`,
  };

  yield put(actions.setAudioConfiguration(UserAgentOptions));

  if (stunServers) {
    UserAgentOptions.stunServers = stunServers.split(/[, ]+/);
  } else {
    UserAgentOptions.stunServers = ['stun.l.google.com:19302'];
  }

  try {
    const ua = new UserAgent(UserAgentOptions);
    ua.delegate = {
      onInvite(invitation) {
        console.debug('Got invitaion?');
        console.debug(invitation);
        // not expecting invites
      },
      onConnect() {
        if (registerer && registerer.state !== 'Terminated') {
          registerer.register()
            .catch((e) => {
              if (e) {
                console.debug(e);
                // dispatch(attemptReconnection(ua));
              }
              // Register failed
            });
        } else {
          // dispatch(addRegistrar(ua));
          addRegistrar(ua);
        }
      },
      onDisconnect(error) {
        // On disconnect, cleanup invalid registrations
        if (registerer) {
          registerer.unregister()
            .catch((e) => {
              console.debug(e);
            // Unregister failed
            });
        }
        // Only attempt to reconnect if network/server dropped the connection (if there is an error)
        if (error) {
          // dispatch();
          attemptReconnection(ua);
        }
      },

    };

    /*
     * Start the user agent
     */
    ua.start().then(() => {
      // dispatch(addRegistrar(ua));
      addRegistrar(ua);
    })
      .catch((error) => {
        console.error('Failed to Connect', error);
        attemptReconnection(ua);
      });

    yield put(actions.setAudioUA(ua));
    return Promise.resolve(true);
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: registerAudioUa';
    });
    yield put(actions.setAudioUA(false));
    return Promise.resolve(false);
  }
}
