import {
  AUDIO_DEVICE, AUDIO_SESSION, CONFIGURATION, UA, REMOTE_AUDIO, PARTICIPANT_MATCH,
} from '../constants';

const defaultState = {
  audioElement: document.createElement('audio'),
  configuration: null,
  device: null,
  session: null,
  ua: null,
  remoteAudio: false,
  participantMatch: null,
};

const reducers = {
  [AUDIO_DEVICE]: (state, { payload }) => ({ device: payload }),
  [AUDIO_SESSION]: (state, { payload }) => ({ session: payload }),
  [CONFIGURATION]: (state, { payload }) => ({ configuration: payload }),
  [UA]: (state, { payload }) => ({ ua: payload }),
  [REMOTE_AUDIO]: (state, { payload }) => ({ remoteAudio: payload }),
  [PARTICIPANT_MATCH]: (state, { payload }) => ({ participantMatch: payload }),
};

export default {
  defaultState,
  reducers,
};
