import bowser from 'bowser';
import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';

// TODO: For testing.
window.BOWSER = bowser;

export default () => {
  const ua = navigator.userAgent;
  let flag = 'unknown';
  let miv = false;

  const parser = bowser.getParser(ua);
  const browser = parser.getResult();
  console.log('browser', browser);

  const manualParse = () => {
    if (/WebKit/.test(parser.getEngineName(false))) {
      flag = 'safari';
    }
    return flag;
  };

  if (parser.satisfies({ chrome: '>=0', chromium: '>=0' })) flag = 'chrome';
  else if (parser.satisfies({ firefox: '>=0' })) flag = 'firefox';
  else if (parser.satisfies({ safari: '>=0' })) flag = 'safari';
  else if (parser.satisfies({ opera: '>=0' })) flag = 'opera';
  else if (parser.satisfies({ 'microsoft edge': '>=0' })) flag = 'edge';
  else flag = manualParse();

  const nsM = nsBrowser.getQuery('m');
  // 1 = ALL_ON  2 = MIC_ONLY 3 = CAM_ONLY 6 = ALL_OFF
  if (nsM === '1' || nsM === '2' || nsM === '3' || nsM === '6') {
    miv = true;
  }

  console.log(`Platform: ${navigator.platform}`);
  return {
    flag,
    name: parser.getBrowserName(),
    version: parser.getBrowserVersion() || parser.getOSVersion() || '',
    isMobile: /iPad|iPhone|iPod|Mobile/.test(navigator.platform),
    isMiv: miv,
    defaultCam: nsM % '2',
    defaultMic: nsM % '3',
    isTouchScreen: navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1,
  };
};
