export const EMIT_VIDEO_INVITE = 'events/EMIT_VIDEO_INVITE';

/**
 * @param invitee
 * @return {{type: string, payload: *}}
 */
export const emitVideoInvite = (invitee) => ({
  type: EMIT_VIDEO_INVITE,
  payload: invitee,
});
