import * as constants from '../constants';

const defaultState = {
  snackBar: null,
  alertDialog: null,
  rejectDialog: null,
};

const reducers = {
  [constants.SNACK_BAR]: (state, { payload }) => ({
    ...state,
    snackBar: payload,
  }),
  [constants.SNACK_BAR_MESSAGE]: (state, { payload }) => ({
    ...state,
    snackBar: { message: payload },
  }),
  [constants.SNACK_BAR_ERROR]: (state, { payload }) => ({
    ...state,
    snackBar: {
      message: payload,
      type: 'error',
    },
  }),
  [constants.SNACK_BAR_SUCCESS]: (state, { payload }) => ({
    ...state,
    snackBar: {
      message: payload,
      type: 'success',
    },
  }),
  [constants.OPEN_ALERT_DIALOG]: (state, { payload }) => {
    const modifiedPayload = payload;
    modifiedPayload.dialogOpen = true;

    return {
      ...state,
      alertDialog: modifiedPayload,
    };
  },
  [constants.CLOSE_ALERT_DIALOG]: (state) => ({
    ...state,
    alertDialog: null,
  }),
  [constants.OPEN_REJECT_DIALOG]: (state, { payload }) => {
    const modifiedPayload = payload;
    modifiedPayload.dialogOpen = true;

    return {
      ...state,
      rejectDialog: modifiedPayload,
    };
  },
  [constants.CLOSE_REJECT_DIALOG]: (state) => ({
    ...state,
    rejectDialog: null,
  }),
};

export default {
  defaultState,
  reducers,
};
