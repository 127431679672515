import { put, select } from 'redux-saga/effects';
import _ from 'lodash';

import {
  setVideoGrid,
  setVideoLayout,
  setVideoDynamicSpeaker,
} from '../../actions';
import {
  setProfiles,
  partialMeetingUpdate,
} from '../../events';
import {
  selectMeeting,
  selectMyAttendee,
  selectVideoGridIds,
  selectVideoLayout,
  selectTempVideoGrid,
  selectParticipants,
  selectMIV,
} from '../../selectors';
import {
  ALLOWED_WEBINAR_ROLES,
  LAYOUT_TYPE_GRID,
  LAYOUT_TYPE_PICTURE,
} from '../../constants';

// eslint-disable-next-line import/prefer-default-export
export function* setLayout({ payload }) {
  const { layout } = payload;
  let { updateMeeting } = payload;

  // default to true if undefined
  if (updateMeeting === undefined) {
    updateMeeting = true;
  }

  const participants = yield select(selectParticipants);
  let videoGridIds = yield select(selectVideoGridIds);
  // if changing from grid view, select the temporary video grid as the participants instead
  const currentLayout = yield select(selectVideoLayout);
  const tempVideoGridIds = yield select(selectTempVideoGrid);
  const isMobileView = yield select(selectMIV);

  if (currentLayout === LAYOUT_TYPE_GRID
      && layout !== LAYOUT_TYPE_GRID
      && tempVideoGridIds.length === videoGridIds.length) {
    videoGridIds = tempVideoGridIds;
  }

  if (layout === LAYOUT_TYPE_PICTURE) {
    yield put(setVideoDynamicSpeaker(true));
  } else if (currentLayout === LAYOUT_TYPE_PICTURE) {
    yield put(setVideoDynamicSpeaker(false));
  }

  // not sure why this is here
  let gridIds = [];
  videoGridIds.forEach((id) => {
    if (participants[id] && (participants[id].type === 'video'
      || participants[id].type === 'screenShare'
      || participants[id].type === 'call')
    ) {
      gridIds.push(id);
    }
  });

  if (isMobileView === 1) {
    gridIds = _.filter(gridIds, (partId) => !participants[partId].isSelf);
  }
  yield put(setVideoLayout(layout));
  yield put(setVideoGrid(_.uniq(gridIds)));
  yield put(setProfiles(layout));

  const meeting = yield select(selectMeeting);
  const myAttendee = yield select(selectMyAttendee);
  if (updateMeeting
    && meeting.type === 'presentation'
    && ALLOWED_WEBINAR_ROLES.includes(myAttendee.role)
  ) {
    yield put(partialMeetingUpdate({ layout }));
  }
}
