import PropTypes from 'prop-types';

import participant from './participant';

const shape = {
  fullScreenParticipant: participant.propTypesShape,
  layout: PropTypes.string,
  trayOpen: PropTypes.bool,
};

const propTypesShape = PropTypes.shape(shape);

export default {
  shape,
  propTypesShape,
};
