/* eslint-disable max-len */
import _ from 'lodash';
import * as mediasoupClient from 'mediasoup-client';
import protooClient from 'protoo-client';

import nsToken from '@netsapiens/netsapiens-js/dist/token';
import { put } from 'redux-saga/effects';
// import * as cookiesManager from '../utils/cookiesManager';
// import getDeviceInfo from '../utils/getDeviceInfo';

import * as actions from '../actions';
import * as events from '../events';
import * as selectors from '../selectors';
import store from '../store';
import * as constants from '../constants';

import { getSfu } from '../services';
import MediaSoupHelpers from './mediasoup/MediaSoupHelpers';

import bugsnagClient from '../bugsnag';

const MODULE_NAME = 'ClientRoom';
const loglevel = require('loglevel');

const logger = loglevel.getLogger(MODULE_NAME);

/**
 * https://mediasoup.org/documentation/mediasoup-client/api/#Room
 * @type {{requestTimeout: number, transportOptions: {tcp: boolean}}}
 */
// const ROOM_OPTIONS = {
//   requestTimeout: 10000,
//   transportOptions: { tcp: false },
// };

const VIDEO_SIMULCAST_ENCODINGS = [
  { maxBitrate: 175000, scaleResolutionDownBy: 2.0 },
  { maxBitrate: 300000, scaleResolutionDownBy: 1.0 },
  { maxBitrate: 1800000, scaleResolutionDownBy: 1.0 },
];

// Used for VP9 webcam video.
const VIDEO_KSVC_ENCODINGS = [
  { active: true, scalabilityMode: 'S3T3_KEY' },
];

// Used for VP9 desktop sharing.
const VIDEO_SVC_ENCODINGS = [
  { active: true, scalabilityMode: 'L3T3', dtx: true },
];

const createMutedParticipant = async (newParticipant) => {
  const newParticipantID = newParticipant.type === 'screenShare'
    ? `screenShare_${newParticipant.userId}` : `video_${newParticipant.userId}`;

  const selectedParticipant = await selectors.selectParticipant(store.getState(), newParticipantID); // eslint-disable-line max-len

  if (selectedParticipant) {
    // There is already a participant match, likely more valid so not updating.
  } else {
    const globalStats = selectors.selectVideoAllStats(store.getState());

    store.dispatch(events.addParticipant({
      direction: 'inbound',
      displayName: newParticipant.displayName,
      domain: newParticipant.domain,
      email: newParticipant.email,
      gravatar: newParticipant.gravatar,
      id: newParticipantID,
      initials: newParticipant.initials,
      isMuted: true,
      videoTrackMuted: true,
      isSelf: false,
      videoStat: globalStats,
      show: true,
      status: 'accepted',
      type: newParticipant.type || 'video',
      uid: newParticipant.uid,
      userId: newParticipant.userId,
    }));
  }
};

// const SCREEN_CONSTRAINTS = {
//   audio: false,
//   video: {
//     mandatory: {
//       chromeMediaSource: 'desktop',
//       maxWidth: window.screen.width > 1920 ? window.screen.width : 1920,
//       maxHeight: window.screen.height > 1080 ? window.screen.height : 1080,
//       maxFrameRate: 10,
//       // minAspectRatio: 1.77
//     },
//   },
// };

/* eslint-disable no-unused-vars */
export default class ClientRoom extends MediaSoupHelpers {
  /**
   * @param accessToken
   * @param appName
   * @param displayName
   * @param device
   * @param domain
   * @param peerId
   * @param port
   * @param produce
   * @param roomId
   * @param instanceId
   * @param screenShareTrack
   * @param userId
   * @param consume
   * @param handler
   * @param useSimulcast
   * @param useSharingSimulcast
   * @param forceTcp
   * @param svc
   * @param externalVideo
   * @param datachannel
   * @param statsRate
   * @param pendingStart
   * @param partTimers
   * @param partTimersShort
   * @param reconnectFailures
   */
  constructor({
    accessToken,
    appName,
    displayName,
    device,
    domain,
    peerId,
    port,
    produce = false,
    roomId,
    instanceId,
    screenShareTrack = null,
    userId,
    consume = true,
    handler,
    useSimulcast = true,
    useSharingSimulcast = false,
    forceTcp = false,
    svc = false,
    externalVideo = false,
    datachannel = false,
    statsRate = 300000,
    pendingStart = false,
    partTimers,
    partTimersShort,
    reconnectFailures,
  }) {
    super();
    this.accessToken = accessToken;
    this.appName = appName;
    this.snaphdHostName = domain;
    this.peerId = peerId;
    this.port = port;
    this.roomId = roomId;
    this.instanceId = instanceId;
    this.userId = userId;
    this.screenShareTrack = screenShareTrack;

    // Closed flag.
    // @type {Boolean}
    this.closed = false;

    // Display name.
    // @type {String}
    this.displayName = displayName;

    // Device info.
    // @type {Object}
    this.device = device;

    // Whether we want to force RTC over TCP.
    // @type {Boolean}
    this.forceTcp = forceTcp;

    // Whether we want to produce audio/video.
    // @type {Boolean}
    this.produce = produce;

    // Whether we should consume.
    // @type {Boolean}
    this.consume = consume;

    // Whether we want DataChannels.
    // @type {Boolean}
    this.useDataChannel = datachannel;

    // External video.
    // @type {HTMLVideoElement}
    this.externalVideo = null;

    // MediaStream of the external video.
    // @type {MediaStream}
    this.externalVideoStream = null;

    // Next expected dataChannel test number.
    // @type {Number}
    this.nextDataChannelTestNumber = 0;

    if (externalVideo) {
      this.externalVideo = document.createElement('video');

      this.externalVideo.controls = true;
      this.externalVideo.controlsList = 'nodownload';
      this.externalVideo.muted = true;
      this.externalVideo.loop = true;
      this.externalVideo.setAttribute('playsinline', '');
      this.externalVideo.src = null;

      this.externalVideo.play().catch((error) => { logger.warn('externalVideo.play() failed:%o', error); });
    }

    // Custom mediasoup-client handler (to override default browser detection if
    // desired).
    // @type {String}
    this.handler = handler;

    // Whether simulcast should be used.
    // @type {Boolean}
    this.useSimulcast = useSimulcast;

    // Whether simulcast should be used in desktop sharing.
    // @type {Boolean}
    this.useSharingSimulcast = useSharingSimulcast;

    // protoo-client Peer instance.
    // @type {protooClient.Peer}
    this.protoo = null;

    // mediasoup-client Device instance.
    // @type {mediasoupClient.Device}
    this.mediasoupDevice = null;

    // mediasoup Transport for sending.
    // @type {mediasoupClient.Transport}
    this.sendTransport = null;

    // mediasoup Transport for receiving.
    // @type {mediasoupClient.Transport}
    this.recvTransport = null;

    // Local webcam mediasoup Producer.
    // @type {mediasoupClient.Producer}
    this.webcamProducer = null;

    // Local share mediasoup Producer.
    // @type {mediasoupClient.Producer}
    this.shareProducer = null;

    // mediasoup Consumers.
    // @type {Map<String, mediasoupClient.Consumer>}
    this.consumers = new Map();

    // Map of webcam MediaDeviceInfos indexed by deviceId.
    // @type {Map<String, MediaDeviceInfos>}
    this.webcams = new Map();

    this.downscaleSet = false;

    this.statsRate = statsRate;
    this.statsTimer = null;

    const { configs } = store.getState();
    this.downscaleBitrate = configs.PORTAL_VIDEO_DOWNSCALE_LIMITER || 200000;
    this.downscaleCount = configs.PORTAL_VIDEO_DOWNSCALE_COUNT || 30;

    const h264Profiles = ['42e01f', '640c1f', '64001f'];
    this.forceH264 = configs.PORTAL_VIDEO_FORCE_H264 === 'yes' || h264Profiles.includes(configs.PORTAL_VIDEO_FORCE_H264);
    this.codecProfile = h264Profiles.includes(configs.PORTAL_VIDEO_FORCE_H264) ? configs.PORTAL_VIDEO_FORCE_H264 : null;
    this.forceVP9 = configs.PORTAL_VIDEO_FORCE_VP9 === 'yes';

    this.configEncodings = VIDEO_SIMULCAST_ENCODINGS;
    if (configs.PORTAL_VIDEO_SIMULCAST_LOW) {
      this.configEncodings[0].maxBitrate = Number(configs.PORTAL_VIDEO_SIMULCAST_LOW);
    }
    if (configs.PORTAL_VIDEO_SIMULCAST_MID) {
      this.configEncodings[1].maxBitrate = Number(configs.PORTAL_VIDEO_SIMULCAST_MID);
    }
    if (configs.PORTAL_VIDEO_SIMULCAST_HIGH) {
      this.configEncodings[2].maxBitrate = Number(configs.PORTAL_VIDEO_SIMULCAST_HIGH);
    }

    if (configs.PORTAL_SCREENSHARE_CODEC_H264 === 'yes') {
      this.screenShareCodecH264 = true;
    }

    // Set custom SVC scalability mode.
    if (svc) {
      VIDEO_SVC_ENCODINGS[0].scalabilityMode = svc;
      VIDEO_KSVC_ENCODINGS[0].scalabilityMode = `${svc}_KEY`;
    }

    // Start the room
    if (this.consume || this.produce) {
      this.connectSNAPhd();
    } else {
      /* eslint-disable no-unused-vars */
      this.pendingStart = true;
    }

    this.partTimers = new Map();
    this.partTimersShort = new Map();
    this.reconnectFailures = 0;
  }

  connectSNAPhd() {
    // protoo-client Peer instance.
    const transportUrl = `wss://${this.snaphdHostName}:${this.port}/?peerName=${this.peerId}&userId=${this.userId}&roomId=${this.roomId}&instanceId=${this.instanceId}&access_token=${this.accessToken}`;

    if (this.protoo) {
      logger.error('Overwriting existing sfu websocket');
      this.protoo.close();
    }

    logger.debug(`${MODULE_NAME} transportUrl`, transportUrl);
    const retry = {
      retries: 16,
      factor: 2,
      minTimeout: 5 * 100,
      maxTimeout: 3 * 1000,
    };
    const protooTransport = new protooClient.WebSocketTransport(transportUrl, { retry });
    this.protoo = new protooClient.Peer(protooTransport);

    this.protooClientPeerHandlers();
  }

  closeRoom() {
    if (this.closed) {
      return;
    }

    this.closed = true;

    logger.debug('close()');

    // Close protoo Peer
    this.protoo.close();

    clearInterval(this.notifyInterval);
    clearInterval(this.pingInterval);

    // Close mediasoup Transports.
    if (this.sendTransport) {
      this.sendTransport.close();
      this.sendTransport = null;
    }

    if (this.recvTransport) {
      this.recvTransport.close();
      this.recvTransport = null;
    }
  }

  recheckSFU() {
    return new Promise((resolve, reject) => {
      const meeting = selectors.selectMeeting(store.getState());
      const attendeeId = selectors.selectConfig(store.getState(), 'attendeeId');

      const confInstance = meeting.instance_id;
      const meetingType = meeting.type;
      const confId = meeting.id;
      const decodedToken = nsToken.getDecoded();
      let { uid } = decodedToken;
      const { user, domain } = decodedToken;

      if (!uid) {
        uid = `${user}@${domain}`;
      }
      const sfuOld = selectors.selectConfig(store.getState(), 'sfu');

      getSfu({
        confId,
        confInstance,
        uid,
        attendeeId,
        action: 'create',
      }).then((sfuRes) => {
        if (sfuRes.hostname && sfuOld.hostname === sfuRes.hostname) {
          reject();
          return;
        }
        this.reconnectFailures = 0;
        this.snaphdHostName = sfuRes.hostname;
        if (sfuRes.port) this.port = sfuRes.port;
        if (sfuRes.access_token) this.accessToken = sfuRes.access_token;
        if (sfuRes.peerName) this.peerId = sfuRes.peerName;

        put(actions.setConfig({ sfu: sfuRes }));
        resolve(sfuRes);
      }).catch(() => {
        reject();
      });
    });
  }

  protooClientPeerHandlers() {
    logger.debug(`${MODULE_NAME} protooClientPeerHandlers`);
    this.protoo.on('open', () => {
      this.reconnectFailures = 0;
      this.joinRoom();
    });

    this.protoo.on('failed', async () => {
      this.reconnectFailures += 1;
      logger.error('protooClient failed');
      if (this.reconnectFailures > 3) {
        console.error(`${this.reconnectFailures} Failed Attempts at current SFU, attempting change`);
        this.recheckSFU().then(() => { this.connectSNAPhd(); }).catch();
      }

      // might want to wait a couple reconnects to tear down users video.
      const mediaStatus = selectors.selectUserMediaStatus(store.getState());
      mediaStatus.videoStream = null;
      store.dispatch(actions.updateMediaStatus(mediaStatus));
    });

    this.protoo.on('disconnected', () => {
      // Close mediasoup Transports.
      if (this.sendTransport) {
        this.sendTransport.close();
        this.sendTransport = null;
      }

      if (this.recvTransport) {
        this.recvTransport.close();
        this.recvTransport = null;
      }
    });

    this.protoo.on('close', () => {
      if (this.closed) {
        return;
      }

      this.closeRoom();
    });

    this.protoo.on('request', async (request, accept, reject) => {
      logger.debug('proto "request" event [method:%s, data:%o]', request.method, request.data);
      switch (request.method) {
        case 'newConsumer': {
          if (!this.consume) {
            reject(403, 'I do not want to consume');
            return;
          }
          const {
            peerId,
            producerId,
            id,
            user,
            kind,
            rtpParameters,
            appData,
            type,
            // producerPaused,
          } = request.data;
          let codecOptions;

          if (kind === 'audio') {
            codecOptions = {
              opusStereo: 1,
            };
          }

          if (appData.share === true) {
            user.type = 'screenShare';
          } else {
            user.type = 'video';
          }

          try {
            const consumer = await this.recvTransport.consume({
              id,
              producerId,
              kind,
              rtpParameters,
              codecOptions,
              appData: {
                ...appData, peerId, user, rtpType: type,
              },
            });

            accept();

            // Store in the map.
            this.consumers.set(consumer.id, consumer);

            const consumers = selectors.selectConsumers(store.getState());
            const clonedConsumers = _.cloneDeep(consumers);
            clonedConsumers[consumer.id] = consumer;
            store.dispatch(actions.setConsumers(clonedConsumers));

            consumer.on('transportclose', () => {
              console.error('[transportClosed] delete consumer:', consumer.id);
              clearInterval(consumer.appData.keyframe);
              this.consumers.delete(consumer.id);

              const closingConsumers = selectors.selectConsumers(store.getState());
              const updatedConsumers = _.omit(closingConsumers, [consumer.id]);
              store.dispatch(actions.setConsumers(updatedConsumers));
            });

            this.createRecvParticipant(consumer);
          } catch (error) {
            logger.error('"newConsumer" request failed:%o', error);
            bugsnagClient.notify(error, (event) => {
              // eslint-disable-next-line no-param-reassign
              event.context = 'ClientRoom: protooClientPeerHandlers';
            });
          }

          break;
        }

        default:
          logger.debug(`request fell through ${request.method}`);
      }
    });

    /* eslint-disable no-unused-vars */
    this.protoo.on('notification', (notification) => {
      switch (notification.method) {
        case 'producerScore': {
          const { producerId, score } = notification.data;
          logger.debug('producerScore Notification [producer:%s, score:%o]',
            producerId, notification.data.score);
          break;
        }

        case 'newPeer': {
          const peer = notification.data;
          const meeting = selectors.selectMeeting(store.getState());
          setTimeout(() => { // delay to allow real consumers to load first.
            const newParticipant = peer.user;
            const newParticipantAttendee = selectors.selectAttendee(
              store.getState(),
              newParticipant.attendeeId,
            );

            if (newParticipant
              && newParticipantAttendee
              && ((meeting && (meeting.type === 'meeting' || meeting.type === 'conference'))
                || constants.ALLOWED_WEBINAR_ROLES.includes(newParticipantAttendee.role))
            ) {
              createMutedParticipant(newParticipant);
            }
          }, 1000);

          break;
        }

        case 'createConsumerFailed': {
          const { producerId, producerUser } = notification.data;
          logger.error('createConsumerFailed [producerId:%s, user:%o]', producerId, producerUser);
          break;
        }

        case 'gainFocus': {
          if (this.webcamProducer) {
            if (!this.forceVP9 || !this.forceH264) {
              this.webcamProducer.setMaxSpatialLayer(3).catch((error) => {
                logger.debug(`setMaxSpatialLayer failed ${error}`);
                bugsnagClient.notify(error, (event) => {
                  // eslint-disable-next-line no-param-reassign
                  event.context = 'ClientRoom: setMaxSpatialLayer';
                  event.addMetadata('layer', { layer: 3 });
                });
              });
            }
          }
          break;
        }

        case 'lostFocus': {
          if (this.webcamProducer) {
            if (!this.forceVP9 || !this.forceH264) {
              this.webcamProducer.setMaxSpatialLayer(0).catch((error) => {
                logger.debug(`lostFocus failed ${error}`);
                bugsnagClient.notify(error, (event) => {
                  // eslint-disable-next-line no-param-reassign
                  event.context = 'ClientRoom: setMaxSpatialLayer';
                  event.addMetadata('layer', { layer: 0 });
                });
              });
            }
          }
          break;
        }

        case 'peerClosed': {
          const { user } = notification.data;

          let participantId;
          if (user.type) {
            participantId = `${user.type}_${user.userId}`;
          } else {
            participantId = `video_${user.userId}`;
          }

          let participant = selectors.selectParticipant(store.getState(), participantId);

          if (!participant) {
            participantId = `video_guest_${user.userId}`;
            participant = selectors.selectParticipant(store.getState(), participantId);
          }

          if (participant) store.dispatch(events.removeParticipant(participant));
          break;
        }

        case 'peerDisplayNameChanged': {
          const { peerId, displayName, oldDisplayName } = notification.data;
          break;
        }

        case 'consumerClosed': {
          const { consumerId } = notification.data;
          const consumer = this.consumers.get(consumerId);
          if (!consumer) {
            break;
          }

          logger.debug(`[notification] onsumerClosed:${consumerId} userId:${consumer.appData.user.userId}`);

          if (consumer.appData.user.type
            && (consumer.appData.user.type === 'screenshare' || consumer.appData.user.type === 'screenShare')) {
            const participantId = `${consumer.appData.user.type}_${consumer.appData.user.userId}`;
            const participant = selectors.selectParticipant(store.getState(), participantId);
            if (participant) store.dispatch(events.removeParticipant(participant));
            const layout = selectors.selectVideoLayout(store.getState());
            const meeting = selectors.selectMeeting(store.getState());
            if (meeting.type && meeting.type === 'presentation' && layout) {
              store.dispatch(events.setProfiles(layout));
            }
          }

          consumer.close();
          clearInterval(consumer.appData.keyframe);
          this.consumers.delete(consumerId);
          const closingConsumers = selectors.selectConsumers(store.getState());
          const updatedConsumers = _.omit(closingConsumers, [consumerId]);
          store.dispatch(actions.setConsumers(updatedConsumers));

          const mediaStatus = selectors.selectUserMediaStatus(store.getState());
          console.log('before request downscale', _.get(mediaStatus, 'videoMuted'), ((_.size(updatedConsumers) + 1) > this.downscaleCount));
          if (!_.get(mediaStatus, 'videoMuted') && ((this.consumers.size + 1) <= this.downscaleCount)) {
            this.removeDownscale();
          }

          break;
        }

        case 'consumerPaused': {
          const { consumerId } = notification.data;
          const consumer = this.consumers.get(consumerId);
          break;
        }

        case 'consumerResumed': {
          const { consumerId } = notification.data;
          const consumer = this.consumers.get(consumerId);
          break;
        }

        case 'consumerLayersChanged': {
          const { consumerId, spatialLayer, temporalLayer } = notification.data;
          const consumer = this.consumers.get(consumerId);
          // const consumer = selectors.selectConsumer(store.getState(), consumerId);
          if (!consumer) { break; }
          consumer.appData.ActualLayers = { spatialLayer, temporalLayer };
          store.dispatch(events.updateConsumer(consumer));
          break;
        }

        case 'consumerScore': {
          const { consumerId, score } = notification.data;
          break;
        }

        case 'activeSpeaker': {
          const { peerId } = notification.data;
          break;
        }

        default: {
          logger.error('unknown protoo notification.method "%s"', notification.method);
        }
      }
    });
    /* eslint-enable no-unused-vars */
  }

  async joinRoom() {
    logger.debug('joinRoom()');

    try {
      this.mediasoupDevice = new mediasoupClient.Device({ handlerName: this.handler });
    } catch (err) {
      logger.error(err);
      bugsnagClient.notify(err, (event) => {
        // eslint-disable-next-line no-param-reassign
        event.context = 'service: protooClientPeerHandlers';
      });
    }

    try {
      const routerRtpCapabilities = await this.protoo.request('getRouterRtpCapabilities');
      if (!routerRtpCapabilities) {
        logger.error('Invalid sfu configuration');
        return;
      }

      // const deviceInfo = getDeviceInfo();
      // if (deviceInfo.flag === 'safari') {
      //   routerRtpCapabilities.headerExtensions = routerRtpCapabilities.headerExtensions.filter(
      //     (ext) => ext.uri !== 'urn:3gpp:video-orientation',
      //   );
      // }

      if (this.codecProfile) {
        routerRtpCapabilities.codecs = routerRtpCapabilities.codecs.filter((codec) => !codec.mimeType.includes('H264') || codec.parameters['profile-level-id'] === this.codecProfile);
      }

      await this.mediasoupDevice.load({ routerRtpCapabilities });

      // Create mediasoup Transport for sending (unless we don't want to produce).
      if (this.produce) {
        await this.createWebRtcSendTransport();
      }

      // Create mediasoup Transport for sending (unless we don't want to consume).
      if (this.consume) {
        await this.createWebRtcRecvTransport();
      }

      // Join now into the room.
      // NOTE: Don't send our RTP capabilities if we don't want to consume.
      const user = selectors.selectUser(store.getState());
      const attendee = selectors.selectMyAttendee(store.getState());
      user.attendeeId = attendee.attendee_id;

      const joinResponse = await this.protoo.request('join', {
        displayName: this.displayName,
        device: this.device || { name: 'unknown', version: 'unknown' },
        user,
        rtpCapabilities: this.consume ? this.mediasoupDevice.rtpCapabilities : undefined,
        sctpCapabilities: this.useDataChannel
          && this.consume ? this.mediasoupDevice.sctpCapabilities : undefined,
      });

      const meeting = selectors.selectMeeting(store.getState());

      if (joinResponse && joinResponse.peers) {
        setTimeout(() => { // delay to allow real consumers to load first.
          for (let i = 0; i < joinResponse.peers.length; i += 1) {
            const newParticipant = joinResponse.peers[i].user;
            const newParticipantAttendee = selectors.selectAttendee(
              store.getState(),
              newParticipant.attendeeId,
            );

            if (newParticipant
              && newParticipantAttendee
              && ((meeting && (meeting.type === 'meeting' || meeting.type === 'conference'))
                || constants.ALLOWED_WEBINAR_ROLES.includes(newParticipantAttendee.role))
            ) {
              createMutedParticipant(newParticipant);
            }
          }
        }, 2000);
      }

      // Enable mic/webcam.
      if (this.produce) {
        // const devicesCookie = cookiesManager.getDevices({
        //   appName: this.appName,
        //   userId: this.userId,
        // });

        const mediaStatus = selectors.selectUserMediaStatus(store.getState());

        // if (!devicesCookie || devicesCookie.webcamEnabled) {
        if (!mediaStatus.videoMuted) {
          console.log('enableWebcam', mediaStatus);
          this.enableWebcam();
        }

        if (this.screenShareTrack) {
          this.shareScreen(this.screenShareTrack);
        }
      }

      this.notifyInterval = setInterval(() => {
        this.periodicNotify();
      }, 10000);

      this.pingInterval = setInterval(() => {
        this.snaphdPing();
      }, 3000);

      // setInterval(() => {
      //   this.participantHealthCheck();
      // }, 10000);
    } catch (error) {
      try {
        const errorObj = JSON.parse(error.toString().replace('Error: ', ''));
        this.protoo.close();
        if (errorObj.err_code === 409 && errorObj.activeRoom) {
          this.snaphdHostName = errorObj.activeRoom;
          this.connectSNAPhd();
        } else if (errorObj.err_code === 401) {
          logger.info(`joinRoom rejected due to ${errorObj.message}`);
          store.dispatch(actions.openRejectDialog({
            title: 'SFU_MAX_PARTICIPANTS_ERROR_TITLE',
            content: 'SFU_MAX_PARTICIPANTS_ERROR_BODY',
            limit: errorObj.limit,
          }));
        } else if (errorObj.err_code === 555) {
          logger.info(`joinRoom rejected due to ${errorObj.message}`);
          store.dispatch(actions.openRejectDialog({
            title: 'SFU_MAX_ROOMS_ERROR_TITLE',
            content: 'SFU_MAX_ROOMS_ERROR_BODY',
            limit: errorObj.limit,
          }));
        } else {
          logger.error('joinRoom() failed:', error);
          bugsnagClient.notify(error, (event) => {
            // eslint-disable-next-line no-param-reassign
            event.context = 'ClientRoom: subscriberSelect';
          });
        }
      } catch (e) {
        logger.error(e);
        bugsnagClient.notify(e, (event) => {
          // eslint-disable-next-line no-param-reassign
          event.context = 'ClientRoom: subscriberSelect';
        });
        if (error) console.log(error);
      }
    }
  }

  async createRecvParticipant(consumer) {
    const newParticipant = consumer.appData.user;

    const mediaStatus = await selectors.selectMediaStatus(store.getState(), newParticipant.userId);
    //
    // if (!mediaStatus) {
    //   mediaStatus = { ...consumer.appData.mediaStatus };
    //   store.dispatch(actions.updateMediaStatus(mediaStatus));
    // }

    const participantId = newParticipant.type === 'screenShare' ? `screenShare_${newParticipant.userId}` : `video_${newParticipant.userId}`;

    const globalStats = await selectors.selectVideoAllStats(store.getState());

    let participant = await selectors.selectParticipant(store.getState(), participantId);

    if (participant) {
      participant.consumerId = consumer.id;
      participant.videoTrack = consumer.track;
      participant.videoTrackMuted = !consumer.track;
      store.dispatch(events.updateParticipant(participant));
    } else {
      participant = {
        direction: 'inbound',
        displayName: newParticipant.displayName,
        domain: newParticipant.domain,
        email: newParticipant.email,
        gravatar: newParticipant.gravatar,
        id: participantId,
        initials: newParticipant.initials,
        isMuted: mediaStatus ? mediaStatus.videoMuted : true,
        isSelf: false,
        videoStat: globalStats,
        recvTransport: this.recvTransport,
        consumerId: consumer.id,
        show: true,
        status: 'accepted',
        type: newParticipant.type || 'video',
        uid: newParticipant.uid,
        userId: newParticipant.userId,
        videoTrack: consumer.track,
        videoTrackMuted: !consumer.track,
        audioId: consumer.appData.audio_id,
      };
      store.dispatch(events.addParticipant(participant));
    }

    if (!this.partTimers.get(participant.id)) {
      this.partTimers.set(participant.id,
        setTimeout((myThis) => { // delay for health check [VID-816]
          myThis.verifyDecodedHealth(participant.id);
          myThis.partTimers.delete(participant.id);
          const videoRef = document.getElementById(`video_${participant.id}`);
          if (!videoRef && !(participant).videoTrackMuted) {
            bugsnagClient.notify(Error(`Missing video Element type:${participant.type}`));
            const foundConsumer = myThis.consumers.get(participant.consumerId);
            if (foundConsumer && !foundConsumer.appData.newConsumerRequested) {
              logger.info('found out of sync consumer: %o', foundConsumer);
              myThis.requestNewConsumer(participant.userId, participant.type);
              foundConsumer.appData.newConsumerRequested = true;
              myThis.consumers.delete(participant.consumerId);
            }
          }
        }, 2500, this));
    }

    // // limit this request to once per 2 seconds
    // if (!this.partTimersShort.get(participant.id)) {
    //   if (participant.videoTrack && !participant.videoTrack.muted) {
    //     this.requestNewConsumer(participant.userId, participant.type);
    //     this.partTimersShort.set(participant.id, true);
    //     setTimeout((myThis) => {
    //       myThis.partTimersShort.set(participant.id, false);
    //     }, 2000, this);
    //   }
    // }

    const layout = selectors.selectVideoLayout(store.getState());
    setTimeout(() => { // delay for health check [VID-816]
      store.dispatch(events.setProfiles(layout));
    }, 300);

    if (participant.type === 'screenShare') {
      store.dispatch(actions.isScreenShareSelf(false));
      store.dispatch(actions.isScreenShareSharing(true));
      store.dispatch(actions.setLayoutPreScreenshare(layout));
      const miv = selectors.selectMIV(store.getState());

      if (miv !== 1 && layout !== constants.LAYOUT_TYPE_SPOTLIGHT) {
        store.dispatch(events.setLayout({
          layout: constants.LAYOUT_TYPE_SPOTLIGHT,
          updateMeeting: false,
        }));
      }
    } else {
      const { isSelf } = store.getState().screenShare;
      if (!isSelf) {
        const userMediaStatus = selectors.selectUserMediaStatus(store.getState());
        console.log('before request downscale', _.get(userMediaStatus, 'videoMuted'), ((this.consumers.size + 1) > this.downscaleCount));
        if (!_.get(userMediaStatus, 'videoMuted') && ((this.consumers.size + 1) > this.downscaleCount)) {
          this.requestDownscale();
        }
      }
    }
  }

  forceMeetingClose() {
    try {
      logger.info('Host Request Meeting Close');
      this.protoo.request('hostRequestMeetingClose', {});
    } catch (error) {
      logger.error(`_forceMeetingClose | failed:${error}`);
      bugsnagClient.notify(error, (event) => {
        // eslint-disable-next-line no-param-reassign
        event.context = 'ClientRoom: forceMeetingClose';
      });
    }
  }

  forcePeerClose(restrictedUserId) {
    try {
      logger.info(`Host Request Kick Peer ${restrictedUserId}`);
      this.protoo.request('closePeer', { restrictedUserId });
    } catch (error) {
      logger.error(`_forcePeerClose | failed:${error}`);
      bugsnagClient.notify(error, (event) => {
        // eslint-disable-next-line no-param-reassign
        event.context = 'ClientRoom: forcePeerClose';
        event.addMetadata('restrictedUserId', restrictedUserId);
      });
    }
  }

  async pauseConsumer(consumerId) {
    try {
      await this.protoo.request('pauseConsumer', { consumerId });
    } catch (error) {
      logger.info('_pauseConsumer | failed: %o', error);
      bugsnagClient.notify(error, (event) => {
        // eslint-disable-next-line no-param-reassign
        event.context = 'ClientRoom: pauseConsumer';
        event.addMetadata('consumerId', consumerId);
      });
    }
  }

  async pauseAllConsumers() {
    const allowPause = store.getState().configs.PORTAL_VIDEO_PAUSE_CONSUMER
      ? (store.getState().configs.PORTAL_VIDEO_PAUSE_CONSUMER.toLowerCase() !== 'no') : true;
    const miv = selectors.selectMIV(store.getState());
    if (allowPause || miv === 1) {
      try {
        /* eslint-disable no-restricted-syntax */
        Promise.all([...this.consumers.keys()].map(async (key) => {
          await this.pauseConsumer(key);
        }));
      } catch (error) {
        logger.info('_pauseAllConsumers | failed: %o', error);
        bugsnagClient.notify(error, (event) => {
          // eslint-disable-next-line no-param-reassign
          event.context = 'ClientRoom: pauseAllConsumers';
        });
      }
    }
  }

  async resumeConsumer(consumerId) {
    try {
      await this.protoo.request('resumeConsumer', { consumerId });
    } catch (error) {
      logger.info('_resumeConsumer | failed: %o', error);
      bugsnagClient.notify(error, (event) => {
        // eslint-disable-next-line no-param-reassign
        event.context = 'ClientRoom: resumeConsumer';
        event.addMetadata('consumerId', consumerId);
      });
    }
  }

  async resumeAllConsumers() {
    const allowPause = store.getState().configs.PORTAL_VIDEO_PAUSE_CONSUMER
      ? (store.getState().configs.PORTAL_VIDEO_PAUSE_CONSUMER.toLowerCase() !== 'no') : true;
    const miv = selectors.selectMIV(store.getState());
    if (allowPause || miv === 1) {
      try {
        /* eslint-disable no-restricted-syntax */
        Promise.all([...this.consumers.keys()].map(async (key) => {
          await this.resumeConsumer(key);
        }));
      } catch (error) {
        logger.info('_resumeAllConsumers | failed: %o', error);
        bugsnagClient.notify(error, (event) => {
          // eslint-disable-next-line no-param-reassign
          event.context = 'ClientRoom: resumeAllConsumers';
        });
      }
    }
  }

  async createWebProducer(track) {
    if (this.webcamProducer) {
      await this.webcamProducer.replaceTrack({ track });
      return;
    }

    if (!this.sendTransport) {
      await this.createWebRtcSendTransport();
    }

    try {
      let encodings = this.configEncodings;
      let videoCodec = this.mediasoupDevice
        .rtpCapabilities
        .codecs
        .find((c) => c.kind === 'video');
      if (this.useSimulcast) {
        if (this.forceVP9) {
          encodings = VIDEO_KSVC_ENCODINGS;
          videoCodec = this.mediasoupDevice
            .rtpCapabilities
            .codecs
            .find((c) => c.mimeType.toLowerCase() === 'video/vp9');
        } else if (this.forceH264) {
          videoCodec = this.mediasoupDevice
            .rtpCapabilities
            .codecs
            .find((c) => (c.mimeType.toLowerCase() === 'video/h264') && (!this.codecProfile || c.parameters['profile-level-id'] === this.codecProfile));
        }
      } else {
        encodings = [{ maxBitrate: this.configEncodings[1].maxBitrate }];
      }

      const user = selectors.selectUser(store.getState());
      const myAttendee = selectors.selectMyAttendee(store.getState());
      let mediaStatus = selectors.selectMediaStatus(store.getState(), user.userId);
      mediaStatus = _.pick(mediaStatus, [
        'audioMuted',
        'hasCamDevice',
        'hasCamPermissions',
        'hasMicDevice',
        'hasMicPermissions',
        'screenShareMuted',
        'userId',
        'videoAspectRatio',
        'videoMuted',
      ]);

      if (!this.sendTransport || this.sendTransport == null) {
        await this.createWebRtcSendTransport();
      }
      this.webcamProducer = await this.sendTransport.produce(
        {
          track,
          encodings,
          codec: videoCodec,
          appData: {
            share: false,
            mediaStatus,
            audio_id: myAttendee.audio_id,
          },
        },
      );

      const participant = await selectors.selectUserParticipant(store.getState());
      const globalStats = await selectors.selectVideoAllStats(store.getState());
      if (participant) {
        participant.videoTrack = track;
        participant.videoTrackMuted = false;
        store.dispatch(events.updateParticipant(participant));
      } else {
        store.dispatch(events.addParticipant({
          direction: 'outbound',
          displayName: user.displayName,
          domain: user.domain,
          email: user.email,
          gravatar: user.gravatar,
          id: `video_${user.userId}`,
          initials: user.initials,
          isSelf: true,
          videoStat: globalStats,
          consumerId: null,
          show: true,
          status: 'accepted',
          type: 'video',
          uid: user.uid,
          userId: user.userId,
          videoTrack: track,
          videoTrackMuted: !track,
        }));
      }

      this.webcamProducer.on('transportclose', () => {
        this.webcamProducer = null;
      });

      this.webcamProducer.on('trackended', () => {
        this.disableWebcam()
          .catch(() => {});
      });
    } catch (error) {
      logger.error('enableWebcam() | failed:%o', error);
      bugsnagClient.notify(error, (event) => {
        // eslint-disable-next-line no-param-reassign
        event.context = 'ClientRoom: createWebProducer';
      });

      if (track) {
        track.stop();
      }
    }
  }

  async destroyWebProducer() {
    if (!this.webcamProducer) {
      return;
    }

    const { participants } = store.getState().video;
    const ids = Object.keys(participants);

    for (let i = 0; i < ids.length; i += 1) {
      if (participants[ids[i]].userId === this.userId
        && participants[ids[i]].type === 'video'
      ) {
        if (participants[ids[i]] && participants[ids[i]].videoTrack) participants[ids[i]].videoTrack.stop();
        participants[ids[i]].videoTrack = null;
        participants[ids[i]].videoTrackMuted = true;
        store.dispatch(events.updateParticipant(participants[ids[i]]));
        break;
      }
    }

    this.webcamProducer.close();

    try {
      await this.protoo.request('closeProducer', { producerId: this.webcamProducer.id });
    } catch (error) {
      logger.error('destroyWebProducer() | failed:%o', error);
      bugsnagClient.notify(error, (event) => {
        // eslint-disable-next-line no-param-reassign
        event.context = 'ClientRoom: destroyWebProducer';
      });
    }

    this.webcamProducer = null;
  }

  async shareScreen(shareScreenTrack, onSuccess) { // eslint-disable-line consistent-return
    logger.info(`${MODULE_NAME} shareScreen called`);

    if (store.getState().screenShare.isSharing || this.shareProducer) {
      return;
    }

    if (!this.mediasoupDevice.canProduce('video')) {
      logger.error('enableShare() | cannot produce video');
      return;
    }

    let track;

    try {
      if (!shareScreenTrack) {
        const stream = await navigator.mediaDevices.getDisplayMedia({
          audio: false,
          video: {
            displaySurface: 'monitor',
            logicalSurface: true,
            cursor: true,
            width: { max: 1920 },
            height: { max: 1080 },
            frame: { max: 5 },
          },
        });

        // May mean cancelled (in some implementations).
        if (!stream) {
          logger.error('enableShare() | Stream is null');
          return;
        }

        const mediaStatus = selectors.selectUserMediaStatus(store.getState());
        mediaStatus.screenShareStream = stream;
        store.dispatch(actions.updateMediaStatus(mediaStatus));

        track = stream.getVideoTracks()[0]; // eslint-disable-line prefer-destructuring
      } else {
        track = shareScreenTrack;
      }

      if (!this.sendTransport) {
        await this.createWebRtcSendTransport();
      }

      let encodings = null;

      if (this.useSharingSimulcast) {
      // If VP9 is the only available video codec then use SVC.
        const firstVideoCodec = this.mediasoupDevice
          .rtpCapabilities
          .codecs
          .find((c) => c.kind === 'video');

        if (firstVideoCodec.mimeType.toLowerCase() === 'video/vp9') {
          encodings = VIDEO_SVC_ENCODINGS;
        } else {
          encodings = VIDEO_SIMULCAST_ENCODINGS
            .map((encoding) => ({ ...encoding, dtx: true }));
        }
      } else {
        encodings = [{ maxBitrate: this.configEncodings[2].maxBitrate }];
      }
      let codec = this.mediasoupDevice
        .rtpCapabilities
        .codecs
        .find((c) => c.mimeType.toLowerCase() === 'video/vp8');

      if (this.screenShareCodecH264) {
        codec = this.mediasoupDevice
          .rtpCapabilities
          .codecs
          .find((c) => c.mimeType.toLowerCase() === 'video/h264');
      }

      const user = selectors.selectUser(store.getState());
      const globalStats = selectors.selectVideoAllStats(store.getState());

      this.shareProducer = await this.sendTransport.produce({
        track,
        encodings,
        codec,
        appData: {
          share: true,
          user: {
            userId: user.userId,
            type: 'screenShare',
          },
        },
      });

      this.shareProducer.on('transportclose', () => {
        this.shareProducer = null;
      });

      this.shareProducer.on('trackended', () => {
        this.endScreenShare()
          .catch((error) => {
            logger.error('end screen share', error);
            bugsnagClient.notify(error, (event) => {
              // eslint-disable-next-line no-param-reassign
              event.context = 'ClientRoom: shareScreen';
            });
          });
      });

      // settings is screen share self to true changes the header icon
      store.dispatch(actions.isScreenShareSelf(true));
      store.dispatch(actions.isScreenShareSharing(true));
      store.dispatch(events.addParticipant({
        direction: 'outbound',
        displayName: user.displayName,
        domain: user.domain,
        email: user.email,
        gravatar: user.gravatar,
        bgcolor: '#232323',
        id: `screenShare_${user.userId}`,
        isMuted: false,
        isSelf: true,
        videoStat: globalStats,
        initials: user.initials,
        recvTransport: this.recvTransport,
        consumerId: null,
        show: true,
        status: 'accepted',
        type: 'screenShare',
        uid: user.uid,
        userId: user.userId,
        videoTrack: track,
        videoTrackMuted: false,
      }));

      // update the media status
      const mediaStatus = selectors.selectUserMediaStatus(store.getState());
      mediaStatus.screenShareMuted = false;
      store.dispatch(actions.updateMediaStatus(mediaStatus));

      // toggle spotlight layout
      const layout = selectors.selectVideoLayout(store.getState());
      const miv = selectors.selectMIV(store.getState());
      store.dispatch(actions.setLayoutPreScreenshare(layout));
      if (miv !== 1 && layout !== constants.LAYOUT_TYPE_SPOTLIGHT) {
        store.dispatch(events.setLayout({
          layout: constants.LAYOUT_TYPE_SPOTLIGHT,
          updateMeeting: false,
        }));
      }

      // update the meeting
      const attendee = selectors.selectMyAttendee(store.getState());
      const meeting = await selectors.selectMeeting(store.getState());

      if (meeting.type
        && (meeting.type === 'presentation' || meeting.type === 'webinar')
      ) {
        store.dispatch(events.partialMeetingUpdate({ presenter: attendee.attendee_id, layout: 'spotlight' }));
      } else {
        store.dispatch(events.partialMeetingUpdate({ presenter: attendee.attendee_id }));
      }

      if (_.isFunction(onSuccess)) {
        onSuccess();
      }
    } catch (error) {
      if (error.name === 'NotAllowedError' && error.message !== 'Permission denied') {
        logger.error(`enableShare() | failed: ${error}`);
        bugsnagClient.notify(error, (event) => {
          // eslint-disable-next-line no-param-reassign
          event.context = 'ClientRoom: shareScreen';
        });
        store.dispatch(actions.snackBarError('SCREEN_SHARE_PERMISSION_FAILED'));
        store.dispatch(actions.isScreenShareBlocked(true));
      }
      if (track) {
        track.stop();
      }
    }
  }

  async endScreenShare(partialMeetingUpdate) {
    if (!this.shareProducer) {
      return;
    }

    this.shareProducer.close();

    // update local state
    const user = selectors.selectUser(store.getState());
    store.dispatch(actions.isScreenShareSelf(false));
    store.dispatch(actions.isScreenShareSharing(false));

    // remove the participant
    const participant = selectors.selectParticipant(store.getState(), `screenShare_${user.userId}`);
    if (participant) store.dispatch(events.removeParticipant(participant));

    // update the media status
    const mediaStatus = selectors.selectUserMediaStatus(store.getState());
    mediaStatus.screenShareMuted = true;

    if (mediaStatus.screenShareStream !== null) {
      mediaStatus.screenShareStream.getTracks().forEach((track) => { track.stop(); });
      mediaStatus.screenShareStream = null;
    }

    store.dispatch(actions.updateMediaStatus(mediaStatus));

    // update the meeting
    const meeting = await selectors.selectMeeting(store.getState());
    if (meeting.type
      && (meeting.type === 'presentation' || meeting.type === 'webinar')
    ) {
      store.dispatch(events.partialMeetingUpdate({
        presenter: '',
        layout: 'grid',
        ...(partialMeetingUpdate || {}),
      }));
    } else {
      store.dispatch(events.partialMeetingUpdate({
        presenter: '',
        ...(partialMeetingUpdate || {}),
      }));
    }

    try {
      await this.protoo.request('closeProducer', { producerId: this.shareProducer.id });
    } catch (error) {
      logger.info('endScreenShare() | failed:%o', error);
      bugsnagClient.notify(error, (event) => {
        // eslint-disable-next-line no-param-reassign
        event.context = 'ClientRoom: endScreenShare';
      });
    }

    this.shareProducer = null;

    if (meeting.type && (meeting.type === 'presentation' || meeting.type === 'webinar')) {
      const layout = await selectors.selectVideoLayout(store.getState());
      if (layout === constants.LAYOUT_TYPE_SPOTLIGHT) {
        store.dispatch(events.setLayout({
          layout: constants.LAYOUT_TYPE_GRID,
          updateMeeting: false,
        }));
      }
    }
  }

  async setPreferredProfile(consumerId, userId, type, profile) {
    if (!consumerId) { return; }
    const consumer = this.consumers.get(consumerId);
    if (consumer) {
      if (consumer.appData?.rtpType !== 'svc' && consumer.appData?.rtpType !== 'simulcast') {
        console.log(`${consumer.appData.rtpType} != simulcast | svc : dont set layers`);
        return;
      }
      let { spatialLayers, temporalLayers } = mediasoupClient // eslint-disable-line prefer-const
        .parseScalabilityMode(consumer.rtpParameters.encodings[0].scalabilityMode);

      if (profile === 'small') {
        spatialLayers = 0;
        temporalLayers = 3;
      }
      if (profile === 'medium') {
        spatialLayers = 1;
        temporalLayers = 3;
      }

      if (!consumer.appData.PreferedLayers || consumer.appData.PreferedLayers.spatialLayers !== spatialLayers) { // eslint-disable-line max-len
        this.setPreferedLayers(consumerId, spatialLayers, temporalLayers);
        consumer.appData.PreferedLayers = { spatialLayers, temporalLayers };
        store.dispatch(events.updateConsumer(consumer));
      }
    } else {
      const foundConsumer = Array.from(this.consumers.values())
        .find((cons) => cons.appData.user.userId === userId && cons.appData.user.type === type);
      if (foundConsumer && !foundConsumer.appData.newConsumerRequested) {
        logger.info('found out of sync consumer: %o', foundConsumer);
        this.requestNewConsumer(userId, type);
        foundConsumer.appData.newConsumerRequested = true;
      }
    }
  }

  async processActiveSpeaker(newActiveSpeaker) {
    // logger.info('ActiveSpeaker', newActiveSpeaker);
    const meeting = await selectors.selectMeeting(store.getState());

    if (meeting.type && meeting.type === 'presentation' && this.userId.includes('guest_')) {
      return;
    }
    if (this.presistActiveSpeaker == null || this.presistActiveSpeaker !== newActiveSpeaker) {
      this.presistActiveSpeaker = newActiveSpeaker;
      try {
        await this.protoo.request('activeSpeaker', { newActiveSpeaker });
      } catch (error) {
        logger.info('_processActiveSpeaker | failed: %o', error);
        bugsnagClient.notify(error, (event) => {
          // eslint-disable-next-line no-param-reassign
          event.context = 'ClientRoom: processActiveSpeaker';
          event.addMetadata('newActiveSpeaker', newActiveSpeaker);
        });
      }
    }
  }

  async requestNewConsumer(userId, kind) {
    try {
      logger.info(`Clientside Request for new Consumer userId:${userId} kind:${kind}`);
      await this.protoo.request('newClientConsumer', { userId, kind });
    } catch (error) {
      logger.info('_requestNewConsumer | failed: %o', error);
      bugsnagClient.notify(error, (event) => {
        // eslint-disable-next-line no-param-reassign
        event.context = 'ClientRoom: requestNewConsumer';
        event.addMetadata('params', { userId, kind });
      });
    }
  }

  async requestDownscale() {
    console.debug('requestDownscale');
    try {
      const maxBitrate = this.downscaleBitrate;
      if (this.sendTransport && !this.downscaleSet) {
        this.downscaleSet = true;
        await this.protoo.request('transportDownscale', { transportId: this.sendTransport.id, maxBitrate });
        const shrunkTrack = await this.getTrackFromState(2);
        this.updateWebcamTrack(shrunkTrack);
      }
    } catch (error) {
      logger.info('_transportDownscale | failed: %o', error);
      bugsnagClient.notify(error, (event) => {
        // eslint-disable-next-line no-param-reassign
        event.context = 'ClientRoom: requestDownscale';
      });
      this.downscaleSet = false;
    }
  }

  async removeDownscale() {
    console.debug('removeDownscale');
    try {
      if (this.sendTransport && this.downscaleSet) {
        await this.protoo.request('removeDownscale', { transportId: this.sendTransport.id });
        const expandTrack = await this.getTrackFromState();
        this.updateWebcamTrack(expandTrack);
        this.downscaleSet = false;
      }
    } catch (error) {
      logger.info('_transportDownscale | failed: %o', error);
      bugsnagClient.notify(error, (event) => {
        // eslint-disable-next-line no-param-reassign
        event.context = 'ClientRoom: removeDownscale';
      });
    }
  }

  async requestKeyFrame(consumerId) {
    if (!consumerId) { return; }
    try {
      await this.protoo.request('requestConsumerKeyFrame', { consumerId });
    } catch (error) {
      logger.info('_requestKeyFrame() | failed:%o', error);
      bugsnagClient.notify(error, (event) => {
        // eslint-disable-next-line no-param-reassign
        event.context = 'ClientRoom: requestKeyFrame';
        event.addMetadata('consumerId', consumerId);
      });
    }
  }

  async notifyFocus(consumerId) {
    try {
      if (!consumerId) { return; }
      const consumer = this.consumers.get(consumerId);
      if (!consumer || !consumer.appData) { return; }
      const timeNow = new Date();
      if (!consumer.appData.lastFocus || timeNow - consumer.appData.lastFocus > 5000) {
        consumer.appData.lastFocus = timeNow;
        this.protoo.notify('focus', { ...consumer.appData, consumerId });
        store.dispatch(events.updateConsumer(consumer));
      }
    } catch (error) {
      bugsnagClient.notify(error, (event) => {
        // eslint-disable-next-line no-param-reassign
        event.context = 'ClientRoom: notifyFocus';
        event.addMetadata('consumerId', consumerId);
      });
    }
  }

  async notifyActiveSpeaker(newActiveSpeaker) {
    if (!newActiveSpeaker) { return; }
    this.protoo.notify('activeSpeaker', { newActiveSpeaker });
  }

  async periodicNotify() {
    const layout = await selectors.selectVideoLayout(store.getState());
    const gridIds = await selectors.selectVideoGridIds(store.getState());
    const participants = await selectors.selectParticipants(store.getState());
    if (_.isEmpty(gridIds)) { return; }

    if (layout === constants.LAYOUT_TYPE_SPOTLIGHT) {
      this.notifyFocus(participants[gridIds[0]].consumerId);
    } else if (layout === constants.LAYOUT_TYPE_CONVERSATION) {
      this.notifyFocus(participants[gridIds[0]].consumerId);
      if (gridIds[1] && participants[gridIds[1]]) {
        this.notifyFocus(participants[gridIds[1]].consumerId);
      }
    } else if (layout === constants.LAYOUT_TYPE_GRID) {
      if (gridIds.length < 3) {
        if (gridIds[0]) { this.notifyFocus(participants[gridIds[0]].consumerId); }
        if (gridIds[1]) { this.notifyFocus(participants[gridIds[1]].consumerId); }
      }
    }
  }

  async snaphdPing() {
    try {
      const timeNow = new Date();
      const lastPong = await selectors.selectSnapHDVolley(store.getState());
      if (lastPong && timeNow - lastPong > 10 * 1000) {
        console.error(`${timeNow - lastPong} Missing Pong, reset wss w/snaphd`);
        store.dispatch(actions.setSnapHDVolley(null));
        clearInterval(this.pingInterval);
        this.recheckSFU().then(() => { this.connectSNAPhd(); }).catch();
        return;
      }
      await this.protoo.request('ping');
      const timeAfter = new Date();
      store.dispatch(actions.setSnapHDVolley(timeAfter));
    } catch (error) {
      console.error('Missing Pong response');
    }
  }

  async verifyDecodedHealth(participantId) {
    const participant = await selectors.selectParticipant(store.getState(), participantId);

    if (!participant || !participant.consumerId) { return; }

    this.getConsumerLocalStats(participant.consumerId).then((cls) => {
      if (!cls) { return; }
      const statline = Array.from(cls.values()).find((stat) => stat.type === 'inbound-rtp' || stat.type === 'track');
      if (!statline) {
        return;
      }
      if (statline.framesDecoded === 0) {
        console.log(`Participant ${participant.id} consumerStats framesDecoded: ${statline.framesDecoded}`);
        this.requestNewConsumer(participant.userId, participant.type);
      }
    });
  }

  // async participantHealthCheck() {
  //   const participants = await selectors.selectParticipants(store.getState());
  //   const ids = Object.keys(participants);
  //   for (let i = 0; i < ids.length; i += 1) {
  //     if (participants[ids[i]].isSelf || participants[ids[i]].id.includes('call')) { continue; } // eslint-disable-line no-continue
  //     if (!participants[ids[i]].videoTrackMuted
  //       && (participants[ids[i]].videoTrack === null || participants[ids[i]].consumerId === null)) {
  //       if (!this.consumers.has(participants[ids[i]].consumerId)) {
  //         console.error(`Participant ${participants[ids[i]].id} failed health check`);
  //         this.requestNewConsumer(participants[ids[i]].userId, participants[ids[i]].type);
  //       }
  //     }
  //   }
  // }
}
