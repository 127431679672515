import nsApi from '@netsapiens/netsapiens-js/dist/api';
import { generatePeerName } from '../utils';

export default ({
  confId,
  confInstance,
  uid,
  attendeeId,
  action,
}) => nsApi.get({
  object: 'sfu',
  action,
  uid,
  room_id: confId,
  instance_id: confInstance,
}).then((res) => ({ ...res, peerName: attendeeId || generatePeerName(), port: '3443' }));
