export const REMOVE_PARTICIPANT = 'events/REMOVE_PARTICIPANT';

/**
 *
 * @param participant
 * @return {{type: string, payload: *}}
 */
export const removeParticipant = (participant) => ({
  type: REMOVE_PARTICIPANT,
  payload: participant,
});
