export const CHAT_SETUP = 'events/CHAT_SETUP';

/**
 * @param invitee
 * @return {{type: string, payload: *}}
 */
export const chatSetup = (invitee) => ({
  type: CHAT_SETUP,
  payload: invitee,
});
