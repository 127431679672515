import _ from 'lodash';

export default (messages, newMesg) => {
  const messagesClone = _.cloneDeep(messages);

  for (let i = 0; i < messagesClone.length; i += 1) {
    if (messagesClone[i].id === newMesg.id) {
      messagesClone[i].deleted = true;
      return messagesClone;
    }
  }

  if (!messages || !newMesg) {
    return false;
  }

  return false;
};
