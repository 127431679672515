import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';

import * as actions from '../../../../actions';
import * as events from '../../../../events';
import * as selectors from '../../../../selectors';
import chatMessage from '../../../../shapes/chatMessage';

import SpeakerBubbles from './SpeakerBubbles';
import gravatarSessionId from '../../../../utils/gravatarSessionId';

const useStyles = makeStyles(() => ({
  messageGroup: {
    height: '32px',
    padding: '5px',
    width: '100%',
  },
  messageGroupHide: {
    height: '32px',
    width: '100%',
  },
  messageContainer: {
    position: 'absolute',
    right: '10px',
    minHeight: '32px',
    borderRadius: '100px',
    display: 'inline-flex',
    alignItems: 'center',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: '0',
    transition: 'all 1s linear',
  },
  textBox: {
    padding: '5px 10px 5px 5px',
    backgroundColor: '#FFFFFF',
    color: 'black',
    borderRadius: '4px',
    maxWidth: '250px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  triangleTopLeft: {
    position: 'relative',
    top: '-6px',
    right: '3px',
    height: '0px',
    width: '0px',
    borderTop: '16px solid white',
    borderRight: '16px solid transparent',
    margin: '-5px',
  },
  chatGravatar: {
    borderRadius: '50%',
    backgroundColor: '#FAFAFA',
    position: 'relative',
  },
  listGravatar: {
    height: '32px',
    width: '32px',
    position: 'relative',
  },
  fadeOut: {
    display: 'none',
    opacity: '0',
    width: '0',
    height: '0',
    transition: 'width 0.1s 0.1s, height 0.1s 0.1s, opacity 0.1s',
  },
  name: {
    padding: '5px 5px 5px 10px',
    maxWidth: '180px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  container: {
    top: '60px',
    right: '1%',
    position: 'absolute',
    zIndex: 2,
    maxWidth: '250px',
  },
  outerContainer: {
    position: 'relative',
    width: '100%',
    textAlign: 'right',
  },
}));

const ChatBubbles = ({
  recentMessages,
  tabValue,
  isUcInboxOpen,
  setRecentMessagesAction,
  setChatInFocusAction,
  inboxTabChangeAction,
  toggleUCInboxAction,
}) => {
  const classes = useStyles();
  const [messages, setMessages] = useState(recentMessages);

  // have a second counter to update the state of react, to clear out the messages after 4 seconds
  useEffect(() => {
    const interval = setInterval(() => { // eslint-disable-line no-shadow
      const updateRecentMessages = [];
      recentMessages.forEach((m) => {
        const messageDate = new Date(`${m.timestamp} GMT`);
        const now = new Date();
        const dif = now.getTime() - messageDate.getTime();
        const secondsBtwT1T2 = dif / 1000;
        const secondsBetweenDates = Math.abs(secondsBtwT1T2);
        if (secondsBetweenDates < 4
          && ((!isUcInboxOpen) || (isUcInboxOpen && tabValue !== 'chat'))) {
          updateRecentMessages.push(m);
        }
      });
      if (recentMessages.length !== 0 && updateRecentMessages !== 0) {
        setRecentMessagesAction(updateRecentMessages);
      }
    }, 1000);

    return () => clearInterval(interval);
  });

  useEffect(() => {
    if (!_.isEqual(recentMessages, messages)) {
      setMessages(recentMessages);
    }
  }, [recentMessages]);

  // if chat is open, only show the speaker bubbles part
  if (isUcInboxOpen && tabValue === 'chat') {
    return (
      <>
        <div className={classnames(classes.outerContainer)}>
          <div className={classnames(classes.container)}>
            <SpeakerBubbles />
          </div>
        </div>
      </>
    );
  }

  const goToConversation = (message) => {
    setChatInFocusAction(message.session_id);
    inboxTabChangeAction('chat');
    toggleUCInboxAction();
  };

  return (
    <>
      <div className={classnames(classes.outerContainer)}>
        <div className={classnames(classes.container)}>
          {recentMessages
            .map((message) => (
              <div
                key={message.id}
                className={classnames('animate__animated',
                  'animate__fadeIn',
                  classes.messageGroup)}
              >
                <div
                  tabIndex={0}
                  role="button"
                  className={classnames(classes.messageContainer)}
                  onClick={() => goToConversation(message)}
                  onKeyUp={() => {}}
                >
                  <div className={classnames(classes.textBox)}>
                    {message?.text}
                  </div>
                  <div className={classnames(classes.triangleTopLeft)} />
                  <div className={classnames(classes.chatGravatar)}>
                    <Avatar
                      className={classes.listGravatar}
                      src={`https://www.gravatar.com/avatar/${message.attendee.gravatar}?s=42&d=blank&hash=${gravatarSessionId}`}
                    />
                  </div>
                </div>
              </div>
            ))}
          <SpeakerBubbles />
        </div>
      </div>
    </>
  );
};

React.memo(ChatBubbles);

ChatBubbles.defaultProps = {
  recentMessages: [],
};

ChatBubbles.propTypes = {
  setRecentMessagesAction: PropTypes.func.isRequired,
  setChatInFocusAction: PropTypes.func.isRequired,
  inboxTabChangeAction: PropTypes.func.isRequired,
  toggleUCInboxAction: PropTypes.func.isRequired,
  recentMessages: chatMessage.arrayOfShapes,
  tabValue: PropTypes.string.isRequired,
  isUcInboxOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  recentMessages: selectors.selectRecentMessages(state),
  tabValue: selectors.selectUcTabValue(state),
  isUcInboxOpen: selectors.selectUcIsOpen(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setRecentMessagesAction: actions.setRecentMessages,
  setChatInFocusAction: actions.setChatInFocus,
  inboxTabChangeAction: events.inboxTabChange,
  toggleUCInboxAction: events.toggleUcInbox,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChatBubbles);
