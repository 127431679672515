import { put, select } from 'redux-saga/effects';

import { selectParticipants } from '../../selectors';
import { setVideoParticipants } from '../../actions';

// eslint-disable-next-line import/prefer-default-export
export function* updateParticipant({ payload: participant }) {
  const participants = yield select(selectParticipants);

  const clonedParticipants = {};
  Object.values(participants).forEach((p) => {
    if (p.id === participant.id) {
      clonedParticipants[p.id] = { ...participant };
    } else {
      clonedParticipants[p.id] = { ...p };
    }
  });

  yield put(setVideoParticipants(clonedParticipants));
}
