import nsApi from '@netsapiens/netsapiens-js/dist/api';
import moment from 'moment-timezone';

export default ({
  domain,
  videoBridgeId,
}) => new Promise((resolve) => {
  nsApi.get({
    object: 'conference',
    action: 'read',
    aor: videoBridgeId,
    domain,
  }).then((res) => {
    if (res && res[0].aor === videoBridgeId) {
      if (res[0].time_end !== '0000-00-00 00:00:00' && moment(res[0].time_end).isBefore(moment.utc().format('YYYY-MM-DD HH:mm:ss'))) {
        nsApi.post({
          object: 'conference',
          action: 'update',
          aor: videoBridgeId,
          domain,
          time_end: moment().add(1, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        }).then(() => {
          console.log('Updated time_end');
        }).catch((err) => {
          console.log(err);
        });
      }
      resolve(videoBridgeId);
    } else {
      resolve(false);
    }
  }).catch(() => {
    resolve(false);
  });
});
