import PropTypes from 'prop-types';

const shape = {
  dialPlan: PropTypes.string,
  displayName: PropTypes.string,
  domain: PropTypes.string,
  email: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  firstName: PropTypes.string,
  initials: PropTypes.string,
  isSelf: PropTypes.bool,
  language: PropTypes.string,
  lastName: PropTypes.string,
  scope: PropTypes.string,
  subscriberLogin: PropTypes.string,
  uid: PropTypes.string,
  user: PropTypes.string,
  userId: PropTypes.string,
  timeZone: PropTypes.string,
};

const propTypesShape = PropTypes.shape(shape);

export default {
  shape,
  propTypesShape,
};
