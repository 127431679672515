/* eslint-disable */
import React, { Fragment, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import _ from 'lodash';

import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PersonIcon from '@material-ui/icons/Person';

import { makeStyles } from '@material-ui/core';

import attendeesShape from '../../../../shapes/attendees';

import * as events from '../../../../events';
import * as selectors from '../../../../selectors';

const useStyles = makeStyles(() => ({
  changeRoleList: {
    cursor: 'pointer',
  },
}));

const messages = defineMessages({
  CHANGE_ROLE: {
    id: 'CHANGE_ROLE',
    defaultMessage: 'Change role',
  },
  HOST: {
    id: 'HOST',
    defaultMessage: 'Host',
  },
  PRESENTER: {
    id: 'PRESENTER',
    defaultMessage: 'Presenter',
  },
  ATTENDEE: {
    id: 'ATTENDEE',
    defaultMessage: 'Attendee',
  },
});

const ChangeRoleControl = ({
  attendee,
  changeAttendeeRole,
  handleClose,
  attendees,
}) => {
  if (!attendee || _.isEmpty(attendee)) return null; // I have no idea how attendee is ever null here, but it is

  const stateAttendee = attendees.find(sAttendee => sAttendee.attendee_id === attendee.attendee_id);
  if (!stateAttendee || _.isEmpty(stateAttendee)) return null;
  const classes = useStyles();

  const [roleChangeOpen, setRoleChangeOpen] = useState(false);

  const handleAttendeeRoleChange = (role) => {
    setRoleChangeOpen(false);
    changeAttendeeRole(stateAttendee, role);
    handleClose();
  };

  const roleOptions = ['host', 'presenter', 'attendee'].map(role => (
    <MenuItem
      key={`${role}_change`}
      className={classes.right}
      onClick={() => handleAttendeeRoleChange(role)}
      disabled={stateAttendee.role === role}
    >
      <FormattedMessage {...messages[role.toUpperCase()]} />
    </MenuItem>
  ));

  return (
    <Fragment>
      <MenuItem
        onClick={() => setRoleChangeOpen(true)}
        onMouseLeave={() => setRoleChangeOpen(false)}
      >
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>

        <Grid container>
          <Grid item xs>
            <Typography variant="inherit">
              <FormattedMessage {...messages.CHANGE_ROLE} />
            </Typography>
          </Grid>
          <ExpandMoreIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
        </Grid>
      </MenuItem>

      <div
        style={{ display: 'block' }}
        onMouseEnter={() => setRoleChangeOpen(true)}
        onMouseLeave={() => setRoleChangeOpen(false)}
      >
        <Collapse className={classes.changeRoleList} in={roleChangeOpen}>
          {roleOptions}
        </Collapse>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  attendees: selectors.selectConnectedAttendees(state),
});


const mapDispatchToProps = dispatch => bindActionCreators({
  changeAttendeeRole: events.changeAttendeeRole,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChangeRoleControl);
