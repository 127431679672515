import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import CamOn from '@material-ui/icons/Videocam';
import CamOff from '@material-ui/icons/VideocamOff';

import * as actions from '../../../../../actions';
import * as events from '../../../../../events';
import * as selectors from '../../../../../selectors';

import mediaStatusShape from '../../../../../shapes/mediaStatus';
import attendeesShape from '../../../../../shapes/attendees';
import meetingShape from '../../../../../shapes/meeting';

const messages = defineMessages({
  TURN_OFF_CAMERA: {
    id: 'TURN_OFF_CAMERA',
    defaultMessage: 'Turn off camera',
  },
  TURN_ON_CAMERA: {
    id: 'TURN_ON_CAMERA',
    defaultMessage: 'Turn on camera',
  },
  CAM_DISABLED_BY_HOST: {
    id: 'CAM_DISABLED_BY_HOST',
    defaultMessage: 'Camera disabled by host',
  },
  CAM_DISABLED_ALERT_CONTENT: {
    id: 'CAM_DISABLED_ALERT_CONTENT',
    defaultMessage: "You won't be able to share your video until a host enables your camera.",
  },
  CAM_BLOCKED_BY_BROWSER: {
    id: 'CAM_BLOCKED_BY_BROWSER',
    defaultMessage: 'Camera blocked by browser',
  },
  CAMERA_DISABLED_TOOLTIP: {
    id: 'CAMERA_DISABLED_TOOLTIP',
    defaultMessage: 'Your camera is blocked by your browser. Click the blocked media icon in your browser\'s address bar to allow camera use and then refresh',
  },
  CAM_NOT_DETECTED_TITLE: {
    id: 'CAM_NOT_DETECTED_TITLE',
    defaultMessage: 'We could not detect a camera',
  },
  CAM_NOT_DETECTED_BODY: {
    id: 'CAM_NOT_DETECTED_BODY',
    defaultMessage: 'Make sure you have a camera plugged into your computer and rejoin the meeting.',
  },
});

const VideoToggle = ({
  // inherited
  variant,
  classes,
  toggleVideoMute,

  // stateful
  mediaStatus,
  meeting,
  myAttendee,

  // dispatch
  openAlertDialog,
  toggleVideoMuteAction,
}) => {
  const isVideoMuted = () => mediaStatus.videoMuted
      || !mediaStatus.hasCamDevice
      || !mediaStatus.hasCamPermissions;

  const getVideoToolTip = (isVideoRestricted) => {
    if (!mediaStatus.hasCamDevice) {
      return <FormattedMessage {...messages.TURN_ON_CAMERA} />;
    }

    if (!mediaStatus.hasCamPermissions) {
      return <FormattedMessage {...messages.CAMERA_DISABLED_TOOLTIP} />;
    }

    if (isVideoRestricted) {
      return <FormattedMessage {...messages.CAM_DISABLED_BY_HOST} />;
    }

    return !mediaStatus.videoMuted
      ? <FormattedMessage {...messages.TURN_OFF_CAMERA} />
      : <FormattedMessage {...messages.TURN_ON_CAMERA} />;
  };

  const onToggleVideoMute = () => {
    if (!mediaStatus.hasCamDevice) {
      const title = <FormattedMessage {...messages.CAM_NOT_DETECTED_TITLE} />;
      const content = <FormattedMessage {...messages.CAM_NOT_DETECTED_BODY} />;
      openAlertDialog({ title, content });
    } else if (toggleVideoMute) {
      toggleVideoMute(!isVideoMuted());
    } else {
      toggleVideoMuteAction();
    }
  };

  const notifyRestriction = () => {
    if (!mediaStatus.hasCamPermissions) {
      const title = <FormattedMessage {...messages.CAM_BLOCKED_BY_BROWSER} />;
      const content = <FormattedMessage {...messages.CAMERA_DISABLED_TOOLTIP} />;
      openAlertDialog({ title, content });
    } else {
      const title = <FormattedMessage {...messages.CAM_DISABLED_BY_HOST} />;
      const content = <FormattedMessage {...messages.CAM_DISABLED_ALERT_CONTENT} />;
      openAlertDialog({ title, content });
    }
  };

  let isVideoPermitted = true;
  switch (meeting.video_share) {
    case 'host':
      isVideoPermitted = myAttendee && myAttendee.role === 'host';
      break;
    case 'presenters':
      isVideoPermitted = myAttendee && ['host', 'presenter'].includes(myAttendee.role);
      break;
    default:
      break;
  }

  const isVideoRestricted = !myAttendee
    || !isVideoPermitted
    || Boolean(myAttendee.video_restricted)
    || !mediaStatus.hasCamPermissions;

  const videoMuted = isVideoMuted();
  const videoButtonStyle = videoMuted || isVideoRestricted ? classes.activeBtn : classes.btn;
  const getVideoIconStyle = videoMuted || isVideoRestricted ? classes.activeIcon : classes.icon;

  const onClickAction = !isVideoRestricted ? onToggleVideoMute : notifyRestriction;

  return (
    <Tooltip title={getVideoToolTip(isVideoRestricted)}>
      <IconButton
        id="VideoToggle"
        data-restricted={isVideoRestricted}
        data-muted={videoMuted}
        onClick={onClickAction}
        className={
          classnames(
            getVideoIconStyle,
            videoButtonStyle,
            isVideoRestricted && classes.restrictedBtn,
            variant === 'small' && classes.btnSmall,
          )
        }
      >
        {isVideoMuted(mediaStatus) || isVideoRestricted ? <CamOff /> : <CamOn />}
      </IconButton>
    </Tooltip>
  );
};

VideoToggle.defaultProps = {
  variant: null,
  toggleVideoMute: null,
};

VideoToggle.propTypes = {
  variant: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  classes: PropTypes.shape({
    activeBtn: PropTypes.string,
    btn: PropTypes.string,
    btnSmall: PropTypes.string,
    activeIcon: PropTypes.string,
    icon: PropTypes.string,
    restrictedBtn: PropTypes.string,
  }).isRequired,
  mediaStatus: mediaStatusShape.propTypesShape.isRequired,
  meeting: meetingShape.propTypesShape.isRequired,
  myAttendee: attendeesShape.propTypesShape.isRequired,
  openAlertDialog: PropTypes.func.isRequired,
  toggleVideoMuteAction: PropTypes.func.isRequired,
  toggleVideoMute: PropTypes.func,
};

const mapStateToProps = (state) => ({
  meeting: selectors.selectMeeting(state),
  mediaStatus: selectors.selectUserMediaStatus(state),
  myAttendee: selectors.selectMyAttendee(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  openAlertDialog: actions.openAlertDialog,
  toggleVideoMuteAction: events.toggleVideoMute,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VideoToggle);
