import { put, select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';

import { openAlertDialog, setMeeting } from '../../actions';
import * as selectors from '../../selectors';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* partialMeetingUpdate({ payload: meetingUpdate }) {
  try {
    // check if the socket is connected
    const isConnected = yield select(selectors.selectSocketConnected);
    if (!isConnected) {
      yield put(openAlertDialog({
        title: 'SOCKET_CONNECTION_ERROR_TITLE',
        content: 'SOCKET_CONNECTION_ERROR_BODY',
        caption: 'SOCKET_CONNECTION_ERROR_CAPTION',
      }));
      return;
    }

    const meeting = yield select(selectors.selectMeeting);
    yield put(setMeeting({ ...meeting, ...meetingUpdate }));

    const user = yield select(selectors.selectUser);

    yield nsApi.post({
      object: 'meeting',
      action: 'update',
      id: meeting.id,
      user: user.userId,
      domain: user.domain,
      ...meetingUpdate,
    });
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: partialMeetingUpdate';
      event.addMetadata('payload', meetingUpdate);
    });
  }
}
