export default (name) => {
  if (name) {
    const nameString = String(name);
    const split = nameString.split(' ');
    let initials = '';
    for (let i = 0; i < split.length; i += 1) {
      initials += split[i].substring(0, 1);
    }
    return initials.toUpperCase();
  }
  return '';
};
