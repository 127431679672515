import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';

import config from '../../config.json';
import { CONFIG } from '../constants';

const ns = config.nsSocketURLs;
const ua = config.uaSocketURLs;
const nsSocketURLs = [];
const uaSocketURLs = [];

if (ns.length) {
  for (let i = 0; i < ns.length; i += 1) {
    nsSocketURLs.push(`wss://${ns[i].replace('*', window.location.hostname)}`);
  }
}

if (ua.length) {
  for (let i = 0; i < ua.length; i += 1) {
    uaSocketURLs.push(`wss://${ua[i].replace('*', window.location.hostname)}`);
  }
}

let tmpId = nsBrowser.getQuery('id');
if (tmpId && tmpId.length > 9 && !Number.isNaN(Number(tmpId.substring(0, 9)))) {
  tmpId = tmpId.substring(0, 9);
  nsBrowser.removeQuery('id');
  nsBrowser.setQuery({ name: 'id', value: tmpId });
}

const defaultState = {
  appName: 'netsapiens-video',
  apiURL: `https://${config.apiURL.replace('*', window.location.hostname)}`,
  devicePostFix: config.devicePostFix,
  hostname: window.location.hostname,
  nsSocketURLs,
  pathname: window.location.pathname,
  portalURL: `https://${config.portalURL.replace('*', window.location.hostname)}`,
  protocol: window.location.protocol,
  supportedBrowsers: config.supportedBrowsers,
  themeDefaults: config.ui.themeDefaults,
  uaSocketURLs,
  userId: nsBrowser.getQuery('user'),
  confId: tmpId,
  version: process.env.version,
  registerId: nsBrowser.getQuery('register') || false,
  attendeeId: nsBrowser.getQuery('a') || false,
  suppliedPwd: nsBrowser.getQuery('p') || '',
};

const reducers = {
  [CONFIG]: (state, { payload }) => ({ ...payload }),
};

export default {
  defaultState,
  reducers,
};
