import {
  CHAT_SESSION_IN_FOCUS, CHAT_SESSIONS, RECENT_MESSAGES, CHAT_ATTACHMENTS,
} from '../constants';

const defaultState = {
  inFocus: null,
  sessions: {},
  attachments: {},
};

const reducers = {
  [CHAT_SESSION_IN_FOCUS]: (state, { payload }) => ({ inFocus: payload }),
  [CHAT_SESSIONS]: (state, { payload }) => ({ sessions: payload }),
  [RECENT_MESSAGES]: (state, { payload }) => ({ recentMessages: payload }),
  [CHAT_ATTACHMENTS]: (state, { payload }) => ({ attachments: payload }),
};

export default {
  defaultState,
  reducers,
};
