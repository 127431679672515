import React from 'react';
import { makeStyles } from '@material-ui/core';

import SideTrayHeader from './SideTrayHeader';
import SideTrayTabs from './SideTrayTabs';

const useStyle = makeStyles(() => ({
  root: { overflow: 'hidden' },
}));

const SideTray = () => {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <SideTrayHeader />
      <SideTrayTabs />
    </div>
  );
};

export default React.memo(SideTray);
