import { combineReducers } from 'redux';

import attendees from './attendees';
import speakerActivity from './speakerActivity';
import audio from './audio';
import chat from './chat';
import confIdForm from './confIdForm';
import configs from './configs';
import contacts from './contacts';
import layout from './layout';
import loginForm from './loginForm';
import mediaStatus from './mediaStatus';
import meetings from './meetings';
import meetingConfig from './meetingConfig';
import notices from './notices';
import screenShare from './screenShare';
import socket from './socket';
import ucInbox from './ucInbox';
import user from './user';
import video from './video';
import videoRoom from './videoRoom';

// creates a reducer to use objects instead of switch statements
// see https://toddmotto.com/deprecating-the-switch-statement-for-object-literals/
const createReducer = (initialState, actions) => (state = initialState, action) => {
  const reduce = actions[action.type];

  if (!reduce) {
    return state;
  }

  return {
    ...state,
    ...reduce(state, action),
  };
};

export default () => combineReducers({
  attendees: createReducer(attendees.defaultState, { ...attendees.reducers }),
  speakerActivity: createReducer(speakerActivity.defaultState, { ...speakerActivity.reducers }),
  audio: createReducer(audio.defaultState, { ...audio.reducers }),
  chat: createReducer(chat.defaultState, { ...chat.reducers }),
  confIdForm: createReducer(confIdForm.defaultState, { ...confIdForm.reducers }),
  configs: createReducer(configs.defaultState, { ...configs.reducers }),
  contacts: createReducer(contacts.defaultState, { ...contacts.reducers }),
  layout: createReducer(layout.defaultState, { ...layout.reducers }),
  loginForm: createReducer(loginForm.defaultState, { ...loginForm.reducers }),
  mediaStatus: createReducer(mediaStatus.defaultState, { ...mediaStatus.reducers }),
  meetings: createReducer(meetings.defaultState, { ...meetings.reducers }),
  meetingConfig: createReducer(meetingConfig.defaultState, { ...meetingConfig.reducers }),
  notices: createReducer(notices.defaultState, { ...notices.reducers }),
  screenShare: createReducer(screenShare.defaultState, { ...screenShare.reducers }),
  socket: createReducer(socket.defaultState, { ...socket.reducers }),
  ucInbox: createReducer(ucInbox.defaultState, { ...ucInbox.reducers }),
  user: createReducer(user.defaultState, { ...user.reducers }),
  video: createReducer(video.defaultState, { ...video.reducers }),
  videoRoom: createReducer(videoRoom.defaultState, { ...videoRoom.reducers }),
});
