export const CREATE_UC_ITEM = 'events/CREATE_UC_ITEM';

/**
 *
 * @param text
 * @return {{type: string, payload: *}}
 */
export const createUcItem = (text) => ({
  type: CREATE_UC_ITEM,
  payload: text,
});
