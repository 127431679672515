import checkMediaPermission from './checkMediaPermission';

// return map of user and environment deviceIds
export default async (devices) => {
  const user = [];
  const environment = [];
  for (let i = 0; i < devices.length; i += 1) {
    try {
      // eslint-disable-next-line
      const stream = await checkMediaPermission({
        audio: false,
        video: {
          deviceId: { exact: devices[i].deviceId },
          facingMode: { exact: 'user' },
        },
      });

      if (stream) { user.push(devices[i].deviceId); }
    } catch (e) {
      console.debug(`user media device id ${devices[i].deviceId} !user`);
      console.debug(e);
    }

    try {
      // eslint-disable-next-line
      const stream = await checkMediaPermission({
        audio: false,
        video: {
          deviceId: devices[i].deviceId ? { exact: devices[i].deviceId } : undefined,
          facingMode: { exact: 'environment' },
        },
      });

      if (stream) { environment.push(devices[i].deviceId); }
    } catch (e) {
      console.debug(`user media device id ${devices[i].deviceId} !environment`);
      console.debug(e);
    }
  }

  return { user, environment };
};
