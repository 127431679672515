import nsApi from '@netsapiens/netsapiens-js/dist/api';

export default ({
  registrationId,
}) => nsApi.get({
  registration_id: registrationId,
  object: 'meeting',
  action: 'read',
  format: 'json',
}).then((res) => {
  const meeting = res[0];

  if (!meeting) {
    // eslint-disable-next-line no-throw-literal
    throw 'NO_MEETING';
  }

  meeting.user = String(meeting.user); // API sends this as number but we want it to be string

  return meeting;
});
