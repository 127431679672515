export const READ_MORE_MEETINGS = 'events/READ_MORE_MEETINGS';

/**
 * @param tense
 * @param offset
 * @return {{type: string}}
 */
export const readMoreMeetings = (tense, offset) => ({
  type: READ_MORE_MEETINGS,
  payload: { tense, offset },
});
