export const AUTHENTICATE_GUEST = 'events/AUTHENTICATE_GUEST';

/**
 * @param confId
 * @param guest
 * @param guestEmail
 * @return {{type: string, payload: {confId: *, guest: *}}}
 */
export const authenticateGuest = (confId, guest, guestEmail, attId) => ({
  type: AUTHENTICATE_GUEST,
  payload: {
    confId, guest, guestEmail, attId,
  },
});
