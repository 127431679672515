import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useWindowSize from 'react-use/lib/useWindowSize';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';

import MeetingInfo from './MeetingInfo';

import AudioToggle from './buttons/AudioToggle';
import ChatToggle from './buttons/ChatToggle';
import RecordingIndicator from './buttons/RecordingIndicator';
import EndSession from './buttons/EndSession';
import InvitePerson from './buttons/InvitePerson';
import MeetingSettings from './buttons/MeetingSettings';
import PeopleToggle from './buttons/PeopleToggle';
import ScreenShareToggle from './buttons/ScreenShareToggle';
import VideoToggle from './buttons/VideoToggle';

import LayoutSelect from './layout-select/LayoutSelect';

import { ALLOWED_WEBINAR_ROLES } from '../../../../constants';
import * as selectors from '../../../../selectors';

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'absolute',
    boxSizing: 'border-box',
    width: '100%',
    padding: theme.spacing(1, 1, 0, 1),
    zIndex: 4,
  },
  icon: {
    color: '#ffffff',
  },
  activeIcon: {
    color: '#000000',
  },
  badge: {
    marginBottom: '0px !important',
    backgroundColor: '#00bcd4',
    color: '#fff',
    right: 0,
    zIndex: 9,
    height: 18,
    fontSize: 12,
    lineHeight: 1,
    borderRadius: 9,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 5px',
    position: 'absolute',
  },
  btn: {
    transition: '500ms all ease-in-out',
    margin: '-1px 3px',
    border: '1px solid transparent !important',
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 0, 0, 0.54) !important',
    '&:hover': {
      backgroundColor: 'rgba(140, 140, 140, 1) !important',
    },
  },
  activeBtn: {
    transition: '100ms all ease-in-out',
    margin: '-1px 3px',
    borderRadius: '50%',
    backgroundColor: 'rgba(255, 255, 255, 0.87) !important',
    color: '#F44336',
    border: '1px solid #F44336',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,.74) !important',
    },
  },
  restrictedBtn: {
    opacity: 0.5,
  },
  btnSmall: {
    border: '1px solid transparent !important',
    padding: '8px !important',
    margin: '0 4px 4px !important',
    '& svg': {
      fontSize: '1.15rem',
    },
  },
  leftNoWrap: {
    textAlign: 'left',
    whiteSpace: 'nowrap',
  },
  centerNoWrap: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  rightNoWrap: {
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
}));

const Header = ({
  meetingType,
  meetingPresenter,
  myAttendeeId,
  myAttendeeRole,
  isUcInboxOpen,
  isMobileView,
}) => {
  const classes = useStyles();
  const { width } = useWindowSize();

  let STACK_BREAKPOINT_HORZ = 780;
  let INFO_BREAKPOINT_HORZ = 910;
  if (isUcInboxOpen) {
    STACK_BREAKPOINT_HORZ += 480;
    INFO_BREAKPOINT_HORZ += 480;
  }

  const useSmallStyle = width < STACK_BREAKPOINT_HORZ;
  const attendeeRole = myAttendeeRole || 'attendee';

  const [allowedButtons, setAllowedButtons] = useState({
    invite: ALLOWED_WEBINAR_ROLES.includes(attendeeRole),
    audio: meetingType === 'conference' || ALLOWED_WEBINAR_ROLES.includes(attendeeRole),
    video: meetingType === 'conference' || ALLOWED_WEBINAR_ROLES.includes(attendeeRole),
    endSession: true,
    screenShare: meetingType === 'conference' || ALLOWED_WEBINAR_ROLES.includes(attendeeRole),
    layout: meetingType === 'conference' || attendeeRole === 'host' || meetingPresenter === myAttendeeId,
  });

  useEffect(() => {
    setAllowedButtons({
      invite: ALLOWED_WEBINAR_ROLES.includes(attendeeRole),
      audio: meetingType === 'conference' || ALLOWED_WEBINAR_ROLES.includes(attendeeRole),
      video: meetingType === 'conference' || ALLOWED_WEBINAR_ROLES.includes(attendeeRole),
      endSession: true,
      screenShare: meetingType === 'conference' || ALLOWED_WEBINAR_ROLES.includes(attendeeRole),
      layout: meetingType === 'conference' || attendeeRole === 'host' || meetingPresenter === myAttendeeId,
    });
  }, [meetingPresenter, meetingType, attendeeRole]);

  let meetingInfoStyle = 'default';
  if (useSmallStyle) {
    meetingInfoStyle = 'small';
  } else if (width < INFO_BREAKPOINT_HORZ && 'dense') {
    meetingInfoStyle = 'dense';
  }

  if (isMobileView === 1) {
    return null;
  }

  return (
    <Grid
      container
      wrap="nowrap"
      justifyContent="center"
      alignItems="center"
      className="animate__animated animate__fadeIn"
    >
      <Grid item xs={2} sm={3} className={classes.leftNoWrap}>
        <MeetingInfo
          classes={classes}
          variant={meetingInfoStyle}
        />
        {allowedButtons.invite && (
          <InvitePerson variant={useSmallStyle && 'small'} classes={classes} />
        )}
      </Grid>

      <Grid item xs={8} sm={6} className={classes.centerNoWrap}>
        {allowedButtons.audio && <AudioToggle variant={useSmallStyle && 'small'} classes={classes} />}
        {allowedButtons.video && <VideoToggle variant={useSmallStyle && 'small'} classes={classes} />}
        {allowedButtons.screenShare && <ScreenShareToggle variant={useSmallStyle && 'small'} classes={classes} />}
        {allowedButtons.layout && <LayoutSelect variant={useSmallStyle && 'small'} classes={classes} />}
        {allowedButtons.endSession && <EndSession classes={classes} />}
      </Grid>

      <Grid item xs={2} sm={3} className={classes.rightNoWrap}>
        <RecordingIndicator variant={useSmallStyle && 'small'} classes={classes} />
        <MeetingSettings variant={useSmallStyle && 'small'} classes={classes} />
        <ChatToggle variant={useSmallStyle && 'small'} classes={classes} />
        <PeopleToggle variant={useSmallStyle && 'small'} classes={classes} />
      </Grid>
    </Grid>
  );
};

React.memo(Header);

Header.defaultProps = {
  meetingPresenter: null,
};

Header.propTypes = {
  meetingType: PropTypes.string.isRequired,
  meetingPresenter: PropTypes.string,
  myAttendeeId: PropTypes.string.isRequired,
  myAttendeeRole: PropTypes.string.isRequired,
  isUcInboxOpen: PropTypes.bool.isRequired,
  isMobileView: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  meetingType: selectors.selectMeeting(state).type,
  meetingPresenter: selectors.selectMeeting(state).presenter,
  myAttendeeId: selectors.selectMyAttendee(state).attendee_id,
  myAttendeeRole: selectors.selectMyAttendee(state).role,
  isUcInboxOpen: selectors.selectUcIsOpen(state),
  isMobileView: selectors.selectMIV(state),
});

export default connect(mapStateToProps)(Header);
