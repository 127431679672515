import {
  all, call, put, select,
} from 'redux-saga/effects';
import moment from 'moment';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import { selectChatAttachments } from '../../selectors';
import { sendChatMessage } from './sendChatMessage';
import { setChatAttachments } from '../../actions';

// eslint-disable-next-line import/prefer-default-export
export function* sendChatAttachments({ payload }) {
  const decodedToken = nsToken.getDecoded();

  const chatAttachments = yield select(selectChatAttachments);
  yield put(setChatAttachments({}));
  try {
    yield all(Object.keys(chatAttachments).map((key) => {
      const attachment = chatAttachments[key];
      const timestamp = moment(Date.now()).utc().format('YYYY-MM-DD HH:mm:ss');

      return call(sendChatMessage, {
        payload: {
          type: 'chat-media',
          user: decodedToken.user,
          domain: decodedToken.domain,
          timestamp,
          sessionId: payload.sessionId,
          fromAttendeeId: attachment.fromAttendeeId,
          message: attachment.file.name,
          data: attachment.base64ToSend,
          mime_type: attachment.file.type,
          size: attachment.file.size,
          destination: attachment.destination,
          instanceId: attachment.instanceId,
          remotepath: key,
        },
      });
    }));
  } catch (err) {
    console.error(err);
  }
}
