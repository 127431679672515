export const TOGGLE_VIDEO_STAT = 'events/TOGGLE_VIDEO_STAT';

/**
 *
 * @param payload
 * @return {{type: string, payload: *}}
 */
export const toggleVideoStat = (payload) => ({
  type: TOGGLE_VIDEO_STAT,
  payload,
});
