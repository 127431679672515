import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';
import moment from 'moment-timezone';
import { LAYOUT_TYPE_GRID } from '../constants';

const newMeetingWithDefaults = (id, user, phonenumbers, registration,
  maxMeeting, maxPresentation, configType, defaultPassword, contacts, requested) => {
  // add requesting user as attendee
  const attendees = [];
  attendees.push({
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    role: 'host',
    uid: user.uid,
  });
  if (requested && contacts) {
    const requestedArray = requested.split(',');
    for (let i = 0; i < requestedArray.length; i += 1) {
      const requestUid = `${requestedArray[i]}@${user.domain}`;
      const contactMatch = contacts.contacts.find((contact) => requestUid === contact.uid);
      if (contactMatch && contactMatch.uid !== user.uid) {
        attendees.push({
          name: `${contactMatch.first_name} ${contactMatch.last_name}`,
          email: contactMatch.email,
          role: 'attendee',
          uid: contactMatch.uid,
        });
      }
    }
  }

  nsBrowser.removeQuery('users');

  return {
    id,
    user: user.userId,
    domain: user.domain,
    hostname: window.location.hostname,

    // configs
    name: '',
    description: '',
    type: 'conference',
    dial_in_numbers: phonenumbers,
    registration_id: registration,
    audio_share: 'everyone',
    video_share: 'everyone',

    // timing
    rrule: '',
    start: configType === 'schedule' ? Math.ceil(Date.now() / 1000 / 3600) * 3600 : (Date.now() / 1000),
    start_local: moment().add(configType === 'schedule' ? 1 : 0, 'hours').tz(user.timeZone)
      .format(configType === 'schedule' ? 'YYYY-MM-DD HH:00:00' : 'YYYY-MM-DD HH:mm:00'),
    timeZone: user.timeZone,
    duration: 60,

    // attendees
    attendees,
    capacity: maxMeeting,
    maxMeeting,
    maxPresentation,

    // option flags
    allow_public_with_id: 1,
    attendee_count: 0,
    enable_chat: 1,
    enable_chat_history: 1,
    enable_qa: 0,
    hide_viewers_list: 0,
    hosts_can_edit: 1,
    password_required: defaultPassword ? 1 : 0,
    password: defaultPassword ? String(Math.floor(1000 + Math.random() * 9000)) : '',
    record: 0,
    wait_for_host: 0,
    iCanEdit: true,
    layout: LAYOUT_TYPE_GRID,
  };
};

export default ({
  user,
  maxMeeting,
  maxPresentation,
  configType,
  defaultPassword,
  contacts,
  requested,
}) => nsApi.get({
  user: user.userId,
  domain: user.domain,
  object: 'meeting',
  action: 'create',
  getId: 'yes',
  format: 'json',
}).then((res) => newMeetingWithDefaults(res.id, user, res.phonenumbers, res.registration_id || '', maxMeeting,
  maxPresentation, configType, defaultPassword, contacts || [], requested || ''));
