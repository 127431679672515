import {
  UPDATE_MEDIA_STATUS,
  REMOVE_MEDIA_STATUS,
} from '../constants';

const defaultState = {};

const reducers = {
  [UPDATE_MEDIA_STATUS]: (state, { payload }) => {
    const status = { ...state[payload.userId], ...payload };
    return { [payload.userId]: status };
  },
  [REMOVE_MEDIA_STATUS]: (state, { payload }) => {
    const newState = { ...state };
    delete newState[payload.userId];
    return { ...newState };
  },
};

export default {
  defaultState,
  reducers,
};
