export default (messages) => {
  const filtered = [];
  // filter out where message status is deleted
  Object.keys(messages).forEach((key) => {
    if (typeof messages[key].status !== 'undefined' && messages[key].status === 'deleted') {
      return;
    }
    filtered.push(messages[key]);
  });

  return filtered;
};
