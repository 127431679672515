import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Message from '@material-ui/icons/Message';

import ControlItem from './ControlItem';

import attendeesShape from '../../../../shapes/attendees';
import meetingShape from '../../../../shapes/meeting';
import * as selectors from '../../../../selectors';
import * as events from '../../../../events';

const messages = defineMessages({
  PRIVATE_MESSAGE: {
    id: 'PRIVATE_MESSAGE',
    defaultMessage: 'Private Message',
  },
});

const MessageControl = ({
  type,
  variant,
  attendee,
  meeting,
  newPrivateChatAction,
  handleClose,
}) => {
  if (!attendee) return null; // I have no idea how attendee is ever null here, but it is

  if (attendee.isSelf) return null;

  if (meeting.enable_chat !== 1) return null;

  const intl = useIntl();

  const isToggled = false;

  const onToggle = () => {
    newPrivateChatAction(attendee);
    handleClose();
  };

  return (
    <ControlItem
      type={type}
      variant={variant}
      onClick={onToggle}
      isToggled={isToggled}
      enabledIcon={<Message />}
      enabledTooltip={intl.formatMessage(messages.PRIVATE_MESSAGE)}
    />
  );
};

MessageControl.propTypes = {
  type: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  attendee: attendeesShape.propTypesShape.isRequired,
  meeting: meetingShape.propTypesShape.isRequired,
  newPrivateChatAction: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  meeting: selectors.selectMeeting(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  newPrivateChatAction: events.newPrivateChat,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MessageControl);
