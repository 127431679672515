import nsDevice from '@netsapiens/netsapiens-js/dist/device';

export default ({
  combinedId,
  devicePostFix,
  domain,
  userId,
}) => nsDevice.get({
  createDevice: true,
  domain,
  userId,
  device: `sip:${combinedId}${devicePostFix}@${domain}`,
  callProcess: 'PresenceIgnore',
}).then((res) => res).catch(() => false);
