import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import _ from 'lodash';

import { createTheme, makeStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';

import * as actions from '../../actions';
import * as selectors from '../../selectors';

const messages = defineMessages({
  OK: {
    id: 'OK',
    defaultMessage: 'Ok',
  },
  MEETING_CANCEL: {
    id: 'MEETING_CANCEL',
    defaultMessage: 'Cancel',
  },
  MEETING_LEAVE: {
    id: 'MEETING_LEAVE',
    defaultMessage: 'Leave',
  },
  MEETING_LEAVE_DIALOG: {
    id: 'MEETING_LEAVE_DIALOG',
    defaultMessage: 'Leave meeting?',
  },
  PERFORMANCE_LIMITER_NOTICE_TITLE: {
    id: 'PERFORMANCE_LIMITER_NOTICE_TITLE',
    defaultMessage: 'Video Performance Limitated',
  },
  PERFORMANCE_LIMITER_NOTICE_BODY: {
    id: 'PERFORMANCE_LIMITER_NOTICE_BODY',
    defaultMessage: 'The limtied network or reduced device performance has caused the videos to be paused.',
  },
  PERFORMANCE_LIMITER_NOTICE_CAPTION: {
    id: 'PERFORMANCE_LIMITER_NOTICE_CAPTION',
    defaultMessage: 'Limited Perfomance',
  },
  SOCKET_CONNECTION_ERROR_TITLE: {
    id: 'SOCKET_CONNECTION_ERROR_TITLE',
    defaultMessage: 'Unable to join meeting',
  },
  SOCKET_CONNECTION_ERROR_BODY: {
    id: 'SOCKET_CONNECTION_ERROR_BODY',
    defaultMessage: 'There is a problem with your socket connection which prevents you from joining the meeting.',
  },
  SOCKET_CONNECTION_ERROR_CAPTION: {
    id: 'SOCKET_CONNECTION_ERROR_CAPTION',
    defaultMessage: 'No connection',
  },
  SFU_MAX_ROOMS_ERROR_TITLE: {
    id: 'SFU_MAX_ROOMS_ERROR_TITLE',
    defaultMessage: 'Concurrent meeting limit reached',
  },
  SFU_MAX_ROOMS_ERROR_BODY: {
    id: 'SFU_MAX_ROOMS_ERROR_BODY',
    defaultMessage: 'You cannot start another meeting until an active meeting ends.',
  },
  SFU_MAX_PARTICIPANTS_ERROR_TITLE: {
    id: 'SFU_MAX_PARTICIPANTS_ERROR_TITLE',
    defaultMessage: 'Meeting is full',
  },
  SFU_MAX_PARTICIPANTS_ERROR_BODY: {
    id: 'SFU_MAX_PARTICIPANTS_ERROR_BODY',
    defaultMessage: 'You cannot join this meeting until someone else in the meeting leaves.',
  },
  ATTENDEE_ALERT_ELEVATED_HOST_ROLE: {
    id: 'ATTENDEE_ALERT_ELEVATED_HOST_ROLE',
    defaultMessage: 'A host has elevated you to host. You may now share your camera and microphone.',
  },
  ATTENDEE_ALERT_ELEVATED_PRESENTER_ROLE: {
    id: 'ATTENDEE_ALERT_ELEVATED_PRESENTER_ROLE',
    defaultMessage: 'A host has elevated you to presenter. You may now share your camera and microphone.',
  },
  ATTENDEE_ALERT_HEARD_BY_OTHERS: {
    id: 'ATTENDEE_ALERT_HEARD_BY_OTHERS',
    defaultMessage: 'A host has enabled your microphone. You may now be heard by others.',
  },
  ATTENDEE_ALERT_SEEN_BY_OTHERS: {
    id: 'ATTENDEE_ALERT_SEEN_BY_OTHERS',
    defaultMessage: 'A host has enabled your camera. You may now be seen by others',
  },
  SCREEN_SHARING_BLOCKED: {
    id: 'SCREEN_SHARING_BLOCKED',
    defaultMessage: 'Screenshare is blocked',
  },
  SCREEN_SHARING_BLOCKED_CONTENT: {
    id: 'SCREEN_SHARING_BLOCKED_CONTENT',
    defaultMessage: 'Screenshare is blocked by your browser. Click the blocked media icon in your browser\'s address bar to allow screenshare use and then refresh',
  },
  DEVICE_SETUP: {
    id: 'DEVICE_SETUP',
    defaultMessage: 'setup',
  },
});

const useStyles = makeStyles(() => ({
  dialogContent: {
    width: 344,
  },
}));

const backdropOverride = createTheme({
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'transparent',
      },
    },
  },
});

const AlertDialog = ({
  alertDialog,
  closeAlertDialog,
}) => {
  const classes = useStyles();
  const [animState, setAnimState] = useState();

  useEffect(() => {
    // clear anim state after render
    if (alertDialog && alertDialog.dialogOpen) {
      setAnimState('opening');
    }
  }, [alertDialog]);

  if (!alertDialog) return null;

  const {
    title,
    content,
    showOk,
    actions: dialogActions,
    caption,
    dialogOpen,
  } = alertDialog;

  const displayOkButton = showOk !== false;

  const dismissAlertDialog = () => {
    setTimeout(() => { // wait for ripple before animating
      setAnimState('closing');

      setTimeout(() => { // wait for animations before navigating
        closeAlertDialog();
        setAnimState(null);
      }, 525);
    }, 75);
  };

  return (
    <ThemeProvider theme={backdropOverride}>
      <Dialog
        open={dialogOpen || false}
        onClose={dismissAlertDialog}
        className={
          classnames(
            'animate__animated animate__faster',
            animState === 'closing' ? 'animate__fadeOut' : 'animate__fadeIn',
          )
        }
      >
        {title && (
          <DialogTitle>
            {_.isString(title) ? <FormattedMessage {...messages[title]} /> : title}
          </DialogTitle>
        )}

        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            {_.isString(content) ? <FormattedMessage {...messages[content]} /> : content}
          </DialogContentText>
          {caption && (
            <Typography variant="caption" gutterBottom>
              {_.isString(caption) ? <FormattedMessage {...messages[caption]} /> : caption}
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          {dialogActions && dialogActions.map((action) => (
            <Button
              id={action.id}
              key={action.id}
              onClick={action.onClick}
              color="primary"
              disabled={action.disabled}
              autoFocus
            >
              {_.isString(action.label)
                ? <FormattedMessage {...messages[action.label]} />
                : action.label}
            </Button>
          ))}

          {displayOkButton && (
            <Button id="AlertDialogOk" onClick={dismissAlertDialog} color="primary" autoFocus>
              <FormattedMessage {...messages.OK} />
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

AlertDialog.defaultProps = {
  alertDialog: null,
};

AlertDialog.propTypes = {
  alertDialog: PropTypes.shape({
    title: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.string,
    ]),
    content: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.string,
    ]).isRequired,
    caption: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.string,
    ]),
    actions: PropTypes.arrayOf(PropTypes.shape({})),
    showOk: PropTypes.bool,
    dialogOpen: PropTypes.bool.isRequired,
  }),
  closeAlertDialog: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  alertDialog: selectors.selectAlertDialog(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  closeAlertDialog: actions.closeAlertDialog,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AlertDialog);
