export const REMOVE_CHAT_ATTACHMENT = 'events/REMOVE_CHAT_ATTACHMENT';

/**
 * @param attachmentKey
 * @return {{type: string, payload: *}}
 */
export const removeChatAttachment = (attachmentKey) => ({
  type: REMOVE_CHAT_ATTACHMENT,
  payload: attachmentKey,
});
