import bugsnagClient from '../bugsnag';
import { stopStream } from '../utils/devices';
import store from '../store';
import * as selectors from '../selectors';

export default async (constraints) => {
  try {
    const isMiv = selectors.selectMIV(store.getState());
    if (isMiv === 1) {
      constraints.audio = false; // eslint-disable-line no-param-reassign
      if (!constraints.audio && !constraints.video) {
        console.debug('checkMediaPermissions', false);
        return false;
      }
    }
    if (window.location && !navigator.mediaDevices && window.location.protocol !== 'https:') {
      window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
    }
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    console.debug('checkMediaPermissions', true);
    stopStream(stream);
    return true;
  } catch (e) {
    console.debug('checkMediaPermissions', false);
    bugsnagClient.notify(e, { severity: 'info' });

    if (e.name === 'NotAllowedError') {
      return false;
    }

    return null;
  }
};
