/**
 * @param appName
 * @return {Promise<{sipLog: string|string, sipTrace: string}>}
 */

const isNumber = (value) => !Number.isNaN(Number(value));

export default (appName) => {
  let sipTrace = localStorage.getItem(`${appName}_sip-trace`);
  let sipLog = localStorage.getItem(`${appName}_sip-log`);

  if (sipLog === 0 || sipLog === 'error') {
    sipLog = 'error';
    localStorage.setItem(`${appName}_sip-log`, sipLog);
  }

  if (sipTrace === null) {
    sipTrace = false;
    localStorage.setItem(`${appName}_sip-trace`, 'false');
  } else if (sipTrace === 'true') {
    sipTrace = true;
  } else if (sipTrace === 'false') {
    sipTrace = false;
  }
  if (sipLog === null) {
    sipLog = 'error';
    localStorage.setItem(`${appName}_sip-log`, sipLog);
  } else if (!isNumber(sipLog)) localStorage.setItem(`${appName}_sip-log`, sipLog);
  else {
    localStorage.setItem(`${appName}_sip-log`, 'error');
  }

  return Promise.resolve({
    sipLog,
    sipTrace,
  });
};
