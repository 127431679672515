import React from 'react';
import { makeStyles } from '@material-ui/core';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

import { ALLOWED_WEBINAR_ROLES } from '../../../../../constants';
import attendeesShape from '../../../../../shapes/attendees';
import { showAddUser } from '../../../../../events';
import { selectMyAttendee } from '../../../../../selectors';

import PeopleList from '../components/PeopleList';

const messages = defineMessages({
  INVITE_PEOPLE: {
    id: 'INVITE_PEOPLE',
    defaultMessage: 'Invite People',
  },
});

const useStyles = makeStyles((theme) => ({
  container: {
    overflowX: 'hidden',
  },
  btn: {
    margin: theme.spacing(1, 1, 0, 1),
  },
  text: {
    marginLeft: theme.spacing(1),
  },
  peopleListContainer: {
    height: 'calc(100vh - 99px)',
    overflowY: 'auto',
    padding: theme.spacing(0, 1, 1, 1),
  },
  shortPeopleListContainer: {
    height: 'calc(100vh - 154px)',
    overflowY: 'auto',
    padding: theme.spacing(0, 1, 1, 1),
  },
}));

const PeopleTab = ({
  attendees,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const addUserEnabled = useSelector((state) => ALLOWED_WEBINAR_ROLES.includes(
    selectMyAttendee(state).role,
  ));

  const handleShowAddUser = () => {
    dispatch(showAddUser({ show: true, tab: 'invite' }));
  };

  return (
    <div className={classes.container}>
      {addUserEnabled && (
        <Button
          id="PeopleTabInvite"
          className={classes.btn}
          color="primary"
          onClick={handleShowAddUser}
        >
          <GroupAddIcon />
          <span className={classes.text}>
            <FormattedMessage {...messages.INVITE_PEOPLE} />
          </span>
        </Button>
      )}
      <div className={addUserEnabled
        ? classes.shortPeopleListContainer
        : classes.peopleListContainer}
      >
        <PeopleList attendees={attendees} />
      </div>
    </div>
  );
};

PeopleTab.propTypes = {
  attendees: attendeesShape.arrayOfShapes.isRequired,
};

export default React.memo(PeopleTab);
