import { put, select } from 'redux-saga/effects';
import getBestMediaResolution from '../../services/getBestMediaResolution';
import {
  getAudioStream,
  getDefaultAudioInput,
  getDefaultAudioOutput,
  getAudioDeviceById,
  getDefaultVideo,
  getMIVVideo,
  getFilteredAudioInput,
  getFilteredVideo,
  getVideoDeviceById,
  getMediaDevices,
  stopStream,
} from '../../utils/devices';
import * as actions from '../../actions';
import getAspectRatio from '../../utils/getAspectRatio';
import getDeviceInfo from '../../utils/getDeviceInfo';
import * as selectors from '../../selectors';
import checkMediaPermission from '../../services/checkMediaPermission';
import * as services from '../../services';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* initMediaStreams({ audio, video, muted = true }) {
  try {
    let hasMicPermissions = audio;
    let hasCamPermissions = video;

    const hasDevices = yield services.hasDevices();
    const hasCamDevice = hasDevices.cam;
    const hasMicDevice = hasDevices.mic;

    let devices = yield getMediaDevices();
    const filteredAudioDevices = getFilteredAudioInput(devices);
    const filteredVideoDevices = getFilteredVideo(devices);

    const isMobileView = yield select((state) => selectors.selectMIV(state));

    let audioInputDevice = getDefaultAudioInput(devices);
    let audioInputDeviceId = audioInputDevice ? audioInputDevice.deviceId : null;
    let videoDevice = getDefaultVideo(devices);
    if (isMobileView === 1 && localStorage.getItem('facingMode')) {
      const deviceIdMap = yield select((state) => selectors.selectCameraMap(state));
      const map = localStorage.getItem('facingMode') === 'user' ? deviceIdMap.user : deviceIdMap.environment;
      videoDevice = getMIVVideo(devices, map);
    }

    if (hasMicPermissions === null && hasMicDevice) {
      if (filteredAudioDevices.length > 1) {
        for (let i = 0; i < filteredAudioDevices.length; i += 1) {
          const permission = yield checkMediaPermission({
            audio: { deviceId: { exact: filteredAudioDevices[i].deviceId } },
            video: false,
          });

          if (permission) {
            audioInputDeviceId = filteredAudioDevices[i].deviceId;
            hasMicPermissions = true;
            break;
          }
        }
      }
    }

    const mediaStatus = yield select((state) => selectors.selectUserMediaStatus(state));

    if (mediaStatus.videoDevice) {
      ({ videoDevice } = mediaStatus);
    }
    let videoDeviceId = videoDevice ? videoDevice.deviceId : null;

    if (hasCamPermissions === null && hasCamDevice) {
      if (videoDevice) {
        const permission = yield checkMediaPermission({
          audio: false,
          video: { deviceId: { exact: videoDevice.deviceId } },
        });
        if (permission) {
          hasCamPermissions = true;
        }
      }
      if (hasCamPermissions === null && filteredVideoDevices.length > 1) {
        for (let i = 0; i < filteredVideoDevices.length; i += 1) {
          const permission = yield checkMediaPermission({
            audio: false,
            video: { deviceId: { exact: filteredVideoDevices[i].deviceId } },
          });

          if (permission) {
            videoDeviceId = filteredVideoDevices[i].deviceId;
            hasCamPermissions = true;
            break;
          }
        }
      }
    }

    let audioStream = null;
    if (!mediaStatus.audioStream) {
      if (audio) {
        if (audioInputDeviceId) {
          audioStream = yield getAudioStream(audioInputDeviceId);
        } else {
          audioStream = yield getAudioStream();
        }
      }
    } else {
      ({ audioStream } = mediaStatus);
    }

    let videoStream = null;
    if (!mediaStatus.videoStream) {
      if (hasCamPermissions && hasCamDevice) {
        if (videoDeviceId) {
          videoStream = yield getBestMediaResolution(videoDeviceId);
        } else {
          videoStream = yield getBestMediaResolution();
        }
      }
    } else {
      ({ videoStream } = mediaStatus);
    }

    const combinedId = yield select((state) => selectors.selectConfig(state, 'combinedId'));

    if (muted) {
      if (audioStream) {
        stopStream(audioStream);
        audioStream = null;
      }
      if (videoStream) {
        stopStream(videoStream);
        videoStream = null;
      }
    }

    devices = yield getMediaDevices();
    audioInputDevice = getAudioDeviceById(devices, audioInputDeviceId);
    videoDevice = getVideoDeviceById(devices, videoDeviceId);

    const newMedia = {
      userId: combinedId,
      audioMuted: !audioStream ? true : muted,
      audioStream,
      audioInputDevice,
      audioOutputDevice: getDefaultAudioOutput(devices),
      hasCamDevice,
      hasCamPermissions,
      hasMicDevice,
      hasMicPermissions,
      videoAspectRatio: getAspectRatio(videoStream),
      videoMuted: !videoStream ? true : muted,
      videoDevice,
      videoStream,
    };

    if (isMobileView === 1) {
      if (audioStream) {
        stopStream(audioStream);
        audioStream = null;
      }
      newMedia.audioMuted = !getDeviceInfo().defaultMic;
      if (videoStream && !getDeviceInfo().defaultCam) {
        stopStream(videoStream);
        videoStream = null;
        newMedia.videoMuted = !getDeviceInfo().defaultCam;
      }
    }

    yield put(actions.updateMediaStatus(newMedia));
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: initMediaStreams';
      event.addMetadata('payload', { audio, video, muted });
    });
  }
}
