import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import Typography from '@material-ui/core/Typography';

import QueryBuilder from '@material-ui/icons/QueryBuilder';

import meetingShape from '../../../../shapes/meeting';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  currentDatetimeBig: {
    fontSize: 40,
    margin: '10px 8px',
    '& svg': {
      position: 'relative',
      width: 40,
      height: 40,
      top: 6,
    },
  },
  currentDatetimeSmall: {
    float: 'left',
    marginTop: 14,
    fontSize: 16,
    margin: '10px 8px',
    '& svg': {
      position: 'relative',
      width: 16,
      height: 16,
      top: 2,
    },
  },
}));

const messages = defineMessages({
  UNTIL_SCHEDULED_START_TIME: {
    id: 'UNTIL_SCHEDULED_START_TIME',
    defaultMessage: 'until scheduled start time',
  },
  CONFERENCE: {
    id: 'CONFERENCE',
    defaultMessage: 'Conference',
  },
  WEBINAR: {
    id: 'WEBINAR',
    defaultMessage: 'Webinar',
  },
  PRESENTATION: { // neds to stay here.
    id: 'PRESENTATION',
    defaultMessage: 'Webinar',
  },
  START_TIME_PASSED: {
    id: 'START_TIME_PASSED',
    defaultMessage: 'The meeting is scheduled to begin now.',
  },
});

let clockInterval;
let notifTimer;

const CountdownTimer = ({
  meeting,
  startTime,
  variant,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const meetingName = meeting.name
    ? meeting.name
    : intl.formatMessage(messages[meeting.type.toUpperCase()]);

  const getTimeUntilStart = () => {
    const nowInSeconds = Date.now() / 1000;
    return startTime - nowInSeconds;
  };

  const [remainingTime, setRemainingTime] = useState(getTimeUntilStart());

  useEffect(() => {
    clockInterval = setInterval(() => { // set clock update
      setRemainingTime(getTimeUntilStart());
    }, 1000);

    return () => {
      clearInterval(clockInterval);
    };
  }, []);

  useEffect(() => {
    if (remainingTime > 0) {
      notifTimer = setTimeout(() => { // set clock update
        // eslint-disable-next-line no-new
        new Notification(meetingName, {
          body: intl.formatMessage(messages.START_TIME_PASSED),
          // icon: BroNoIco,
        });
      }, remainingTime * 1000);
    }

    return () => {
      clearTimeout(notifTimer);
    };
  }, []);

  let countdownStr = moment(remainingTime * 1000).format('mm:ss');

  if (remainingTime > 3600) { // more than an hour left, include hours
    const hoursRemaining = Math.floor(remainingTime / 60 / 60);
    countdownStr = `${hoursRemaining}:${countdownStr}`;
  }

  if (remainingTime > 86400) { // more than a day left, just show date
    countdownStr = moment(startTime * 1000).format('MMM Do YYYY, h:mm a');
  }

  return remainingTime > 0 ? (
    <div>
      <div className={variant === 'big' ? classes.currentDatetimeBig : classes.currentDatetimeSmall}>
        <QueryBuilder />
        &nbsp;
        {countdownStr}
      </div>

      {remainingTime < 86400 && variant === 'big' && (
        <Typography variant="body2">
          <FormattedMessage {...messages.UNTIL_SCHEDULED_START_TIME} />
        </Typography>
      )}
    </div>
  ) : '';
};

CountdownTimer.propTypes = {
  meeting: meetingShape.propTypesShape.isRequired,
  startTime: PropTypes.number.isRequired,
  variant: PropTypes.string.isRequired,
};

export default CountdownTimer;
