import React, { useMemo } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import LayoutContainer from './components/LayoutContainer';
import ParticipantsGrid from './components/ParticipantsGrid';
import {
  selectParticipants, selectVideoGridIds, selectVideoMuteHide, selectVideoHideSelf,
} from '../../../../selectors';
import { LAYOUT_TYPE_GRID } from '../../../../constants';

const GridLayout = () => {
  const videoGridIds = useSelector(selectVideoGridIds);
  const participants = useSelector(selectParticipants);
  const muteHide = useSelector(selectVideoMuteHide);
  const hideSelf = useSelector(selectVideoHideSelf);

  const filteredIds = useMemo(() => {
    let filtered = videoGridIds;

    if (muteHide) {
      filtered = filtered.filter(
        (id) => _.get(participants, [id, 'videoTrackMuted']) !== true,
      );
    }

    if (hideSelf) {
      filtered = filtered.filter(
        (id) => _.get(participants, [id, 'isSelf']) !== true,
      );
    }
    return _.take(filtered, 25);
  }, [videoGridIds, muteHide, hideSelf]);

  return (
    <LayoutContainer>
      <ParticipantsGrid participantIds={filteredIds} layout={LAYOUT_TYPE_GRID} />
    </LayoutContainer>
  );
};

export default GridLayout;
