import {
  ROOM, LAYOUT_TYPE_SPOTLIGHT, LAYOUT_PRE_SCREENSHARE, SNAPHD_VOLLEY, CONSUMERS, CAMERA_MAP,
} from '../constants';

const defaultState = {
  room: null,
  prelayout: LAYOUT_TYPE_SPOTLIGHT,
  snaphdvolley: null,
  consumers: {},
  cameraMap: { user: [], environment: [] },
};

const reducers = {
  [ROOM]: (state, { payload }) => ({ room: payload }),
  [LAYOUT_PRE_SCREENSHARE]: (state, { payload }) => ({ prelayout: payload }),
  [SNAPHD_VOLLEY]: (state, { payload }) => ({ snaphdvolley: payload }),
  [CONSUMERS]: (state, { payload }) => ({ consumers: payload }),
  [CAMERA_MAP]: (state, { payload }) => ({ cameraMap: payload }),
};

export default {
  defaultState,
  reducers,
};
