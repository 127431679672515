import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core';

import PeopleIcon from '@material-ui/icons/People';

import { ALLOWED_WEBINAR_ROLES } from '../../../../../constants';
import * as events from '../../../../../events';
import * as selectors from '../../../../../selectors';
import attendeesShape from '../../../../../shapes/attendees';
import meetingShape from '../../../../../shapes/meeting';

const messages = defineMessages({
  HIDE_PARTICIPANTS: {
    id: 'HIDE_PARTICIPANTS',
    defaultMessage: 'Hide participants',
  },
  SHOW_PARTICIPANTS: {
    id: 'SHOW_PARTICIPANTS',
    defaultMessage: 'Show participants',
  },
});

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-block',
    position: 'relative',
  },
  small: {
    top: -3,
    left: 26,
    width: 14,
    height: 14,
    fontSize: 11,
  },
}));

const PeopleToggle = ({
  // inherited
  variant,
  classes,

  // stateful
  attendees,
  isUcInboxOpen,
  meeting,
  myAttendee,
  tabValue,

  // dispatch
  inboxTabChangeAction,
  toggleUCInboxAction,
}) => {
  const localClasses = useStyles();

  const onOpenParticipants = () => {
    inboxTabChangeAction('participants');

    if (!isUcInboxOpen || (tabValue === 'participants' && isUcInboxOpen)) {
      toggleUCInboxAction();
    }
  };

  return (
    <div className={localClasses.root}>
      {(meeting.hide_viewers_list === 0
        || ALLOWED_WEBINAR_ROLES.includes(myAttendee.role)
      ) && (
        <div className={classes.badge}>
          {attendees.length}
        </div>
      )}
      <Tooltip
        title={isUcInboxOpen && tabValue === 'participants'
          ? <FormattedMessage {...messages.HIDE_PARTICIPANTS} />
          : <FormattedMessage {...messages.SHOW_PARTICIPANTS} />}
        placement="bottom-start"
      >
        <IconButton
          id="PeopleToggle"
          className={classnames(
            classes.icon,
            classes.btn,
            variant === 'small' && classes.btnSmall,
          )}
          onClick={onOpenParticipants}
        >
          <PeopleIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

PeopleToggle.defaultProps = {
  variant: null,
};

PeopleToggle.propTypes = {
  variant: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  classes: PropTypes.shape({
    btn: PropTypes.string,
    btnSmall: PropTypes.string,
    badge: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
  attendees: attendeesShape.arrayOfShapes.isRequired,
  isUcInboxOpen: PropTypes.bool.isRequired,
  meeting: meetingShape.propTypesShape.isRequired,
  myAttendee: attendeesShape.propTypesShape.isRequired,
  tabValue: PropTypes.string.isRequired,
  inboxTabChangeAction: PropTypes.func.isRequired,
  toggleUCInboxAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  attendees: selectors.selectConnectedAttendees(state),
  isUcInboxOpen: selectors.selectUcIsOpen(state),
  meeting: selectors.selectMeeting(state),
  myAttendee: selectors.selectMyAttendee(state),
  tabValue: selectors.selectUcTabValue(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  inboxTabChangeAction: events.inboxTabChange,
  toggleUCInboxAction: events.toggleUcInbox,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PeopleToggle);
