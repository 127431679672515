import _ from 'lodash';
import { eventChannel } from 'redux-saga';
import { put, select, take } from 'redux-saga/effects';
import { UserAgent, Inviter } from 'sip.js';
import DetectRTC from '@netsapiens/netsapiens-js/dist/web-rtc';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import * as events from '../../events';
import { stopStream } from '../../utils/devices';
import bugsnagClient from '../../bugsnag';

const md5 = require('md5');

export const AUDIO_ACCEPTED = 'AUDIO_ACCEPTED';
export const AUDIO_TERMINATED = 'AUDIO_TERMINATED';

export default function* joinAudioBridge() {
  try {
    const videoBridgeId = yield select((state) => selectors.selectConfig(state, 'videoBridgeId'));
    const audioUa = yield select(selectors.selectAudioUA);
    const myAttendee = yield select(selectors.selectMyAttendee);
    const meeting = yield select(selectors.selectMeeting);
    let mediaStatus = yield select(selectors.selectUserMediaStatus);
    const audioElement = yield select(selectors.selectAudioElement);
    const configs = yield select((state) => state.configs);
    const isMiv = yield select(selectors.selectMIV);

    console.debug(`isMiv:${isMiv}`);
    if (isMiv === 1) {
      console.log('Mobile APP in use, dont sent invite.');
      localStorage.setItem('nsVideoCallLive', false);
      return Promise.resolve(AUDIO_TERMINATED);
    }
    console.debug('joinAudioBridge', mediaStatus);

    if (mediaStatus.audioOutputDevice === null && mediaStatus.audioInputDevice === null) {
      console.log('No Audio Devices, dont join audio bridge');
      return Promise.resolve(AUDIO_TERMINATED);
    }

    let options = {};
    if (!mediaStatus.hasMicDevice || !(mediaStatus.hasMicPermissions && _.get(mediaStatus.audioInputDevice, 'deviceId'))) {
      options = {
        sessionDescriptionHandlerOptions: {
          iceGatheringTimeout: configs.iceGatheringTimeout || 500,
          constraints: {
            audio: false,
            video: false,
          },
          offerOptions: {
            offerToReceiveAudio: true,
          },
        },
      };
    } else {
      options = {
        sessionDescriptionHandlerOptions: {
          iceGatheringTimeout: configs.iceGatheringTimeout || 500,
          constraints: {
            audio: { deviceId: { exact: _.get(mediaStatus.audioInputDevice, 'deviceId') } },
            video: false,
          },
        },
      };
    }

    options.sessionDescriptionHandlerOptionsReInvite = options.sessionDescriptionHandlerOptions;

    options.extraHeaders = [];
    if (meeting.password) {
      const timestamp = Math.floor(Date.now() / 1000);
      const toHash = `${timestamp}${meeting.password}`;
      const tsHeader = `P-Bridge-Authorization: ${md5(toHash)};ts=${timestamp}`;
      options.extraHeaders.push(tsHeader);
    }

    if (meeting && meeting.my_audio_id) {
      const audioIdHeader = `P-Served-Parameters: audioId=${meeting.my_audio_id}`;
      options.extraHeaders.push(audioIdHeader);
    } else if (myAttendee && myAttendee.audio_id) {
      const audioIdHeader = `P-Served-Parameters: audioId=${myAttendee.audio_id}`;
      options.extraHeaders.push(audioIdHeader);
    } else if (meeting) {
      console.error('Missing audio ID');
      console.debug(meeting);
    } else {
      console.error('Missing audio ID and meeting is NULL');
    }

    options.iceGatheringTimeout = configs.iceGatheringTimeout || 500;

    let myEmitter;
    // create channel for callbacks
    const channel = eventChannel((emitter) => {
      myEmitter = emitter;

      return () => {};
    });

    let outgoingSession = null;
    options.requestDelegate = {
      onAccept: (response) => {
        console.log('Positive response = ', response);
        myEmitter(AUDIO_ACCEPTED);
      },

      onReject: (response) => {
        if (response.message.statusCode === 407) {
          return; // this is ok, not an error.
        }
        localStorage.setItem('nsVideoCallLive', false);
        console.error('Negative response (onReject) = ', response);
        bugsnagClient.notify(Error('Negative response (onReject)'), (event) => {
          // eslint-disable-next-line no-param-reassign
          event.context = 'saga: joinAudioBridge';
          event.addMetadata('response', response);
        });
        myEmitter(AUDIO_TERMINATED);
      },
      onTerminated: (response) => {
        localStorage.setItem('nsVideoCallLive', false);
        console.error('Negative response (onTerminated) = ', response);
        bugsnagClient.notify(Error('Negative response (onTerminated)'), (event) => {
          // eslint-disable-next-line no-param-reassign
          event.context = 'saga: joinAudioBridge';
          event.addMetadata('response', response);
        });
        myEmitter(AUDIO_TERMINATED);
      },
    };

    if (!audioUa) {
      return Promise.reject(new Error('Failed to select audioUa'));
    }

    const target = UserAgent.makeURI(videoBridgeId);
    if (!target) {
      return Promise.reject(new Error('Failed to build target'));
    }

    if (mediaStatus.audioInputDevice === 'External Phone') {
      console.log('External Phone in use, dont sent invite.');
      localStorage.setItem('nsVideoCallLive', false);
      return Promise.resolve(AUDIO_TERMINATED);
    }

    outgoingSession = new Inviter(audioUa, target, options);
    if (!outgoingSession) {
      return Promise.reject(new Error('Failed to build outgoingSession'));
    }
    outgoingSession.invite(options).then(() => {}).catch(console.error);

    yield put(actions.setAudioSession(outgoingSession));
    const res = yield take(channel);

    // handle accepted
    if (res === AUDIO_ACCEPTED) {
      localStorage.setItem('nsVideoCallLive', true);

      const pc = outgoingSession.sessionDescriptionHandler.peerConnection;

      // set up receiver
      const remoteStream = new MediaStream();
      pc.getReceivers().forEach((receiver) => {
        const { track } = receiver;
        if (track) {
          remoteStream.addTrack(track);
        }
      });
      audioElement.srcObject = remoteStream;
      if (mediaStatus.audioOutputDevice && audioElement && DetectRTC.isSetSinkIdSupported) {
        audioElement.setSinkId(mediaStatus.audioOutputDevice.deviceId);
      }
      audioElement.play().catch(() => {});

      // set sender parameters
      const senders = yield pc.getSenders();
      // eslint-disable-next-line no-restricted-syntax
      for (const sender of senders) {
        if (sender.track) {
          const parameters = sender.getParameters();
          if (parameters && parameters.encodings && parameters.encodings.length > 0) {
            parameters.encodings[0].networkPriority = 'high';
            parameters.encodings[0].priority = 'high';

            try {
              yield sender.setParameters(parameters);
              console.info('set audio priority to high ');
            } catch (err) {
              console.error(err);
              bugsnagClient.notify(err, (event) => {
                // eslint-disable-next-line no-param-reassign
                event.context = 'saga: joinAudioBridge';
              });
            }
          }
        }
      }

      // check if the user has audio permitted
      let isAudioPermitted = true;
      switch (meeting.audio_share) {
        case 'host':
          isAudioPermitted = myAttendee && myAttendee.role === 'host';
          break;
        case 'presenters':
          isAudioPermitted = myAttendee && ['host', 'presenter'].includes(myAttendee.role);
          break;
        default:
          break;
      }

      // handle mute of stream or add to redux
      if (mediaStatus.audioMuted) {
        const [stream] = pc.getLocalStreams();
        stopStream(stream);
      } else {
        const [stream] = pc.getLocalStreams();
        mediaStatus = yield select(selectors.selectUserMediaStatus);
        yield put(actions.updateMediaStatus({
          ...mediaStatus,
          audioStream: stream,
        }));
      }

      // check if the user should have an audio stream
      if (!myAttendee
        || !isAudioPermitted
        || myAttendee.audio_restricted
      ) {
        const [stream] = pc.getLocalStreams();
        stopStream(stream);
        mediaStatus = yield select(selectors.selectUserMediaStatus);
        yield put(actions.updateMediaStatus({
          ...mediaStatus,
          audioMuted: true,
          audioStream: null,
        }));
      }
    }

    if (meeting && meeting.type === 'presentation' && meeting.status === 'started') {
      if (myAttendee && (myAttendee.role === 'host' || myAttendee.role === 'presenter')) {
        // this will make sure they are unmuted on the NCS as a leader
        yield put(events.updateNcsParticipant('leader', () => {}, myAttendee.audio_id));
      }
    }

    if (meeting && meeting.type === 'conference' && meeting.status === 'started' && meeting.wait_for_host) {
      if (myAttendee && (myAttendee.role === 'host' || myAttendee.role === 'presenter')) {
        // this will make sure they are unmuted on the NCS as a leader
        yield put(events.updateNcsParticipant('leader', () => {}, myAttendee.audio_id));
      }
    }

    return Promise.resolve(res);
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: joinAudioBridge';
    });
    return Promise.reject(err);
  }
}
