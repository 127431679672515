import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';

import useMediaDevices from 'react-use/lib/useMediaDevices';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { store as callMeStore } from '../../../providers/CallMeProvider';

import mediaStatusShape from '../../../../shapes/mediaStatus';
import attendeesShape from '../../../../shapes/attendees';

import { updateMediaStatus } from '../../../../actions';
import {
  getAudioStream,
  getDefaultAudioInput,
  getFilteredAudioInput,
  stopStream,
} from '../../../../utils/devices';
import {
  selectUserMediaStatus,
  selectMyAttendee,
} from '../../../../selectors';

const messages = defineMessages({
  DEVICE_SETTINGS_TITLE: {
    id: 'DEVICE_SETTINGS_TITLE',
    defaultMessage: 'Device settings',
  },
  DONE: {
    id: 'DONE',
    defaultMessage: 'done',
  },
  MIC_INPUT_LABEL: {
    id: 'MIC_INPUT_LABEL',
    defaultMessage: 'Microphone input',
  },
  NO_DEVICES: {
    id: 'NO_DEVICES',
    defaultMessage: 'No devices detected',
  },
  NO_LABEL_MESSAGE: {
    id: 'NO_LABEL_MESSAGE',
    defaultMessage: 'Default mic device',
  },
  BLOCKED_BY_BROWSER: {
    id: 'BLOCKED_BY_BROWSER',
    defaultMessage: 'Blocked by browser',
  },
  MIC_DISABLED_BY_HOST: {
    id: 'MIC_DISABLED_BY_HOST',
    defaultMessage: 'Microphone disabled by host',
  },
  MIC_DISABLED_BY_EXTERNAL: {
    id: 'MIC_DISABLED_BY_EXTERNAL',
    defaultMessage: 'Disabled by dial-in audio',
  },
  MIC_DISABLED_TOOLTIP: {
    id: 'MIC_DISABLED_TOOLTIP',
    defaultMessage: 'Your microphone is blocked by your browser. Click the blocked media icon in your browser\'s address bar to allow microphone use and then refresh',
  },
});

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth: 240,
  },
}));

const MicDevice = ({
  mediaStatus,
  updateMediaStatusAction,
  myAttendee,
}) => {
  const { devices } = useMediaDevices();
  const classes = useStyles();

  const {
    audioInputDevice,
    audioMuted,
    audioStream,
    hasMicDevice,
    hasMicPermissions,
  } = mediaStatus;

  const [devicesInit, setDevicesInit] = useState(false);

  const [defaultAudioInput, setDefaultAudioInput] = useState(null);
  const [audioInputDevices, setAudioInputDevices] = useState([]);

  const setSelectedAudioInput = (selectedDevice) => {
    updateMediaStatusAction({
      userId: mediaStatus.userId,
      audioInputDevice: selectedDevice,
    });
  };

  // initialize devices
  useEffect(() => {
    if (devicesInit && devices) {
      const filteredDefaultAudioInput = getDefaultAudioInput(devices);
      const filteredAudioInputs = getFilteredAudioInput(devices);
      setDefaultAudioInput(filteredDefaultAudioInput);
      setAudioInputDevices(filteredAudioInputs);
      setSelectedAudioInput(filteredDefaultAudioInput);
    } else if (devices) {
      setDevicesInit(true);
    }
  }, [devices, devicesInit]);

  // check if selected audio input device is still connected
  useEffect(() => {
    if (audioInputDevice) {
      const filter = {};
      if (audioInputDevice.groupId) filter.groupId = audioInputDevice.groupId;
      else filter.deviceId = audioInputDevice.deviceId;
      const stillExists = !!_.filter(audioInputDevices, filter).length;

      if (!stillExists) {
        setSelectedAudioInput(null);
      }
    }
  }, [audioInputDevices, audioInputDevice]);

  // update audioInput
  useEffect(() => {
    const myLastAudioInput = localStorage.getItem('myLastAudioInput');
    if (audioInputDevice && myLastAudioInput !== _.get(audioInputDevice, 'deviceId')) {
      localStorage.setItem('myLastAudioInput', _.get(audioInputDevice, 'deviceId'));

      if (audioStream) {
        stopStream(audioStream);
      }

      if (!audioMuted) {
        getAudioStream(_.get(audioInputDevice, 'deviceId')).then((stream) => {
          updateMediaStatusAction({
            userId: mediaStatus.userId,
            audioStream: stream,
          });
        }).catch(console.error);
      }
    }
  }, [audioInputDevice]);

  const handleDeviceChange = (e) => {
    const id = e.target.value;
    const match = _.find(getFilteredAudioInput(audioInputDevices), { groupId: id });
    if (match) {
      setSelectedAudioInput(match);
    } else {
      setSelectedAudioInput(_.find(getFilteredAudioInput(audioInputDevices), { deviceId: id }));
    }
  };

  const selectedInput = _.get(audioInputDevice, 'groupId')
    || _.get(audioInputDevice, 'deviceId')
    || _.get(defaultAudioInput, 'groupId')
    || _.get(defaultAudioInput, 'deviceId')
    || '';
  const [openTT, setOpen] = useState(false);
  const handleClick = () => { setOpen((prev) => !prev); };
  const handleClickAway = () => { setOpen(false); };

  const { onCall } = useContext(callMeStore);

  const getAudioToolTip = (isAudioRestricted) => {
    if (onCall) {
      return <FormattedMessage {...messages.MIC_DISABLED_BY_EXTERNAL} />;
    }

    if (isAudioRestricted) {
      return <FormattedMessage {...messages.MIC_DISABLED_BY_HOST} />;
    }

    return <FormattedMessage {...messages.MIC_DISABLED_TOOLTIP} />;
  };

  const isAudioRestricted = !myAttendee
    || Boolean(myAttendee.audio_restricted);

  const hasDeviceLabel = audioInputDevices.find((device) => device.label !== '') !== undefined;

  return (
    <>
      <InputLabel id="mic_input">
        <FormattedMessage {...messages.MIC_INPUT_LABEL} />
      </InputLabel>
      {hasMicDevice && hasMicPermissions !== false && !onCall && !isAudioRestricted && hasDeviceLabel && ( // eslint-disable-line max-len
        <TextField
          select
          id="MicDeviceMicInput"
          labelid="mic_input"
          variant="filled"
          margin="dense"
          value={selectedInput}
          onChange={handleDeviceChange}
          fullWidth
        >
          {audioInputDevices.map((device) => (
            <MenuItem
              key={device.groupId || device.deviceId}
              value={device.groupId || device.deviceId}
            >
              {device.label}
            </MenuItem>
          ))}
        </TextField>
      )}
      {(!hasMicDevice || hasMicPermissions === false || onCall || isAudioRestricted || !hasDeviceLabel) && ( // eslint-disable-line max-len
        <ClickAwayListener onClickAway={handleClickAway}>
          <Tooltip
            title={getAudioToolTip(isAudioRestricted)}
            classes={{ tooltip: classes.customWidth }}
            open={openTT}
          >
            <TextField
              select
              onClick={handleClick}
              labelid="mic_input"
              variant="filled"
              margin="dense"
              value={1}
              fullWidth
              disabled
            >
              {!hasMicDevice && (
              <MenuItem value={1}>
                <FormattedMessage {...messages.NO_DEVICES} />
              </MenuItem>
              )}
              {!hasMicPermissions && (
              <MenuItem value={1}>
                <FormattedMessage {...messages.BLOCKED_BY_BROWSER} />
              </MenuItem>
              )}
              {isAudioRestricted && (
              <MenuItem value={1}>
                <FormattedMessage {...messages.MIC_DISABLED_BY_HOST} />
              </MenuItem>
              )}
              {onCall && (
              <MenuItem value={1}>
                <FormattedMessage {...messages.MIC_DISABLED_BY_EXTERNAL} />
              </MenuItem>
              )}
              {!hasDeviceLabel && (
              <MenuItem value={1}>
                <FormattedMessage {...messages.NO_LABEL_MESSAGE} />
              </MenuItem>
              )}
            </TextField>
          </Tooltip>
        </ClickAwayListener>
      )}
    </>
  );
};

MicDevice.propTypes = {
  mediaStatus: mediaStatusShape.propTypesShape.isRequired,
  updateMediaStatusAction: PropTypes.func.isRequired,
  myAttendee: attendeesShape.propTypesShape.isRequired,

};

const mapStateToProps = (state) => ({
  mediaStatus: selectUserMediaStatus(state),
  myAttendee: selectMyAttendee(state),

});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateMediaStatusAction: updateMediaStatus,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MicDevice);
