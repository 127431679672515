import {
  ATTENDEES_LIST,
  CONNECTED_ATTENDEES,
  MY_ATTENDEE,
} from '../constants';

const defaultState = {
  attendeesList: [],
  connectedAttendees: [],
  myAttendee: null,
};

const reducers = {
  [ATTENDEES_LIST]: (state, { payload }) => ({ attendeesList: payload }),
  [CONNECTED_ATTENDEES]: (state, { payload }) => ({ connectedAttendees: payload }),
  [MY_ATTENDEE]: (state, { payload }) => ({ myAttendee: payload }),
};

export default {
  defaultState,
  reducers,
};
