import PropTypes from 'prop-types';

const shape = {
  id: PropTypes.number,
  user: PropTypes.string,
  domain: PropTypes.string,
  hostname: PropTypes.string, // default?
  chat_sessionid: PropTypes.string, // default?
  ics_uuid: PropTypes.string, // default?
  instance_id: PropTypes.string,

  // configs
  name: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  dial_in_numbers: PropTypes.string, // default?
  audio_share: PropTypes.string,
  video_share: PropTypes.string,

  // timing
  rrule: PropTypes.string,
  start: PropTypes.number, // start time in GMT unixtimestamp format.
  start_local: PropTypes.string, // start time in local mysql format YYYY-MM-DD hh:mm:ss
  start_instance: PropTypes.number, // the start time in uniztimetamp of the given instance
  timeZone: PropTypes.string,
  timezoneOffset: PropTypes.number,
  duration: PropTypes.number,

  // attendees
  attendee_count: PropTypes.number,
  active_attendee_count: PropTypes.number,
  capacity: PropTypes.number,

  // option flags
  allow_public_with_id: PropTypes.number,
  enable_chat: PropTypes.number,
  enable_chat_history: PropTypes.number,
  enable_qa: PropTypes.number,
  hide_viewers_list: PropTypes.number,
  hosts_can_edit: PropTypes.number,
  password_required: PropTypes.number,
  record: PropTypes.number,
  wait_for_host: PropTypes.number,
  is_room: PropTypes.number,
};

const propTypesShape = PropTypes.shape(shape);

export default {
  shape,
  propTypesShape,
};
