import { put, select } from 'redux-saga/effects';

import _ from 'lodash';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import getAspectRatio from '../../utils/getAspectRatio';
import getBestMediaResolution from '../../services/getBestMediaResolution';
import { ALLOWED_WEBINAR_ROLES } from '../../constants';
import { stopStream } from '../../utils/devices';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* toggleVideoMute({ payload: checkMax = true }) {
  try {
    const meeting = yield select(selectors.selectMeeting);
    const userMediaStatus = yield select(selectors.selectUserMediaStatus);

    if (!userMediaStatus.hasCamPermissions) {
      return;
    }

    // prevent toggle if max video streams are met
    if (checkMax && meeting.type === 'presentation' && userMediaStatus.videoMuted) {
      const attendees = yield select(selectors.selectAttendeesList);
      const count = _.filter(attendees, (attendee) => attendee.status_video === 'connected'
        && ALLOWED_WEBINAR_ROLES.includes(attendee.role)).length;

      if (count >= 12) { // TODO add a ui config
        yield put(actions.snackBarMessage('WEBINAR_MAX_VIDEO_MESSAGE', 'info'));
        return;
      }
    }

    const newStatus = { ...userMediaStatus, videoMuted: !userMediaStatus.videoMuted };

    /**
     * Emit to netsapiens video room
     * */
    const socket = yield select((state) => selectors.selectSocket(state));
    const metaData = { meeting_id: meeting.id, domain: meeting.domain };
    const status = _.pick(newStatus, [
      'audioMuted',
      'hasCamDevice',
      'hasCamPermissions',
      'hasMicDevice',
      'hasMicPermissions',
      'screenShareMuted',
      'userId',
      'videoAspectRatio',
      'videoMuted',
    ]);
    socket.emit(
      'video_status',
      { ...metaData, ...status },
    );

    /**
     * Update Mediasoup-ClientRoom
     */
    const room = yield select((state) => state.videoRoom.room);
    if (newStatus.videoMuted) {
      if (newStatus.videoStream) {
        stopStream(newStatus.videoStream);
        yield put(actions.updateMediaStatus({
          ...newStatus,
          videoStream: null,
        }));
      }
      if (room) {
        yield room.disableWebcam();
      }
    } else {
      if (!newStatus.videoStream) {
        const newStream = yield getBestMediaResolution(newStatus.videoDevice.deviceId);

        yield put(actions.updateMediaStatus({
          ...newStatus,
          videoAspectRatio: getAspectRatio(newStream),
          videoMuted: false,
          videoStream: newStream,
        }));
      }

      if (room) {
        yield room.enableWebcam();
      }
    }
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: toggleVideoMute';
      event.addMetadata('payload', checkMax);
    });
  }
}
