export const POST_MEETING_STATUS = 'events/POST_MEETING_STATUS';

/**
 * @param status
 * @return {{type: string, payload: *}}
 */
export const postMeetingStatus = (status) => ({
  type: POST_MEETING_STATUS,
  payload: status,
});
