import nsApi from '@netsapiens/netsapiens-js/dist/api';
import bugsnagClient from '../bugsnag';

export default ({
  domain,
  user,
  sessionid,
  order,
  startTimestamp,
  attendeeId,
  limit,
}) => new Promise((resolve) => {
  nsApi.get({
    object: 'message',
    action: 'read',
    domain,
    user,
    session_id: sessionid,
    order,
    startTimestamp,
    attendee_id: attendeeId,
    limit: limit || 50,
  }).then((messages) => {
    resolve(messages);
  }).catch((err) => {
    console.error(err);
    bugsnagClient.notify(err);
    resolve([]);
  });
});
