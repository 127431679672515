import { put, select } from 'redux-saga/effects';
import { selectParticipants } from '../../selectors';
import { updateParticipant } from '../../events';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* toggleVideoStat({ payload }) {
  try {
    const participants = yield select(selectParticipants);

    const ids = Object.keys(participants);

    for (let i = 0; i < ids.length; i += 1) {
      if (ids[i] === payload.partID) {
        participants[ids[i]].videoStat = !participants[ids[i]].videoStat;
        yield put(updateParticipant(participants[ids[i]]));
      }
    }
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: toggleVideoStat';
      event.addMetadata('payload', payload);
    });
  }
}
