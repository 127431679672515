export default (stream) => {
  if (!stream) {
    return null;
  }

  const tracks = stream.getTracks();

  if (!tracks) {
    return null;
  }

  const track = tracks[0];
  const settings = track.getSettings();

  let aspectRatio;
  if (settings.aspectRatio) {
    ({ aspectRatio } = settings);
  } else if (settings.width && settings.height) {
    aspectRatio = settings.width / settings.height;
  }

  if (aspectRatio < 1) {
    aspectRatio = settings.height / settings.width;
  }

  if (aspectRatio && aspectRatio < 1.34) {
    return '4:3';
  }

  return '16:9';
};
