import { MEETINGS, MEETINGS_LOADING, ROOMS } from '../constants';

const defaultState = {
  meetings: [],
  rooms: [],
  isLoading: false,
};

const reducers = {
  [MEETINGS]: (state, { payload }) => ({ meetings: payload }),
  [MEETINGS_LOADING]: (state, { payload }) => ({ isLoading: payload }),
  [ROOMS]: (state, { payload }) => ({ rooms: payload }),
};

export default {
  defaultState,
  reducers,
};
