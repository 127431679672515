import nsApi from '@netsapiens/netsapiens-js/dist/api';

/**
 * test for a dial rule for the user, if the user doesn't have one post it
 * this fixes a bug for missing dial rules
 * @param dialPlan
 * @param domain
 * @return {Promise}
 */
export default ({
  dialPlan,
  domain,
}) => new Promise((resolve) => {
  const matchRule = 'sip:*vb@*';

  nsApi.get({
    object: 'dialrule',
    action: 'read',
    dialplan: dialPlan,
    domain,
    matchrule: matchRule,
    format: 'json',
  }).then((res) => {
    let found = false;
    for (let i = 0; i < res.length; i += 1) {
      if (res[i].matchrule === matchRule) {
        found = true;
        resolve();
        break;
      }
    }
    if (!found) {
      // this is actually posting, thanks api for making it confusing
      nsApi.get({
        object: 'dialrule',
        action: 'create',
        domain,
        dialplan: dialPlan,
        matchrule: matchRule,
        to_user: '[*]',
        to_host: '<OwnDomain>',
        responder: 'sip:start@to-device',
        plan_description: 'Video Bridge: device to device',
        format: 'json',
      }).then(() => {
        resolve();
      });
    }
  }).catch(() => {
    resolve();
  });
});
