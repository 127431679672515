import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import PageContainer from '../../containers/PageContainer';

const Loader = () => (
  <PageContainer>
    <CircularProgress size={50} />
  </PageContainer>
);

export default Loader;
