import React, { Fragment } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

import Attendee from './Attendee';
import GroupsControlsMenu from '../people-tab/GroupsControlsMenu';

import * as events from '../../../../../events';
import { selectMyAttendee } from '../../../../../selectors';
import attendeesShape from '../../../../../shapes/attendees';

const messages = defineMessages({
  PEOPLE_ATTENDEES: {
    id: 'PEOPLE_ATTENDEES',
    defaultMessage: 'Attendees',
  },
  PEOPLE_HOSTS: {
    id: 'PEOPLE_HOSTS',
    defaultMessage: 'Hosts',
  },
  PEOPLE_PRESENTERS: {
    id: 'PEOPLE_PRESENTERS',
    defaultMessage: 'Presenters',
  },
});

const useStyles = makeStyles((theme) => ({
  btn: {
    margin: theme.spacing(1, 1, 0, 1),
  },
  titleContainer: {
    minHeight: 30,
    position: 'relative',
  },
  title: {
    opacity: 0.5,
  },
  titleMargin: {
    paddingLeft: theme.spacing(1),
  },
  text: {
    marginLeft: theme.spacing(1),
  },
  userAvatarContainer: {
    marginRight: theme.spacing(1.5),
    display: 'inline-block',
    position: 'relative',
  },
}));

const PeopleList = ({
  attendees,
  hideOptions,
  onAttendeeClick,
  titleMargin,
  restrictAttendeeMulti,
}) => {
  const classes = useStyles();
  const myAttendeeRole = useSelector((state) => selectMyAttendee(state).role);

  const hostsArr = _.filter(attendees, { role: 'host', access_restricted: 0 }).sort((a, b) => ((a.name > b.name) ? 1 : -1));
  const presentersArr = _.filter(attendees, { role: 'presenter', access_restricted: 0 }).sort((a, b) => ((a.name > b.name) ? 1 : -1));
  const attendeesArr = _.filter(attendees, { role: 'attendee', access_restricted: 0 }).sort((a, b) => ((a.name > b.name) ? 1 : -1));

  const getAttendeeGroupControls = () => {
    switch (myAttendeeRole) {
      case 'host':
        return (
          <GroupsControlsMenu
            onDisableMicrophones={(callback) => restrictAttendeeMulti(attendeesArr, 'audio', callback)}
            onDisableCameras={(callback) => restrictAttendeeMulti(attendeesArr, 'video', callback)}
            onDisableScreenshare={(callback) => restrictAttendeeMulti(attendeesArr, 'screenshare', callback)}
          />
        );
      default:
        return null;
    }
  };

  const getPresenterGroupControls = () => {
    switch (myAttendeeRole) {
      case 'host':
        return (
          <GroupsControlsMenu
            onMessagePresenters={() => {}}
            onDisableMicrophones={(callback) => restrictAttendeeMulti(presentersArr, 'audio', callback)}
            onDisableCameras={(callback) => restrictAttendeeMulti(presentersArr, 'video', callback)}
            onDisableScreenshare={(callback) => restrictAttendeeMulti(presentersArr, 'screenshare', callback)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {!!_.size(hostsArr) && (
        <div>
          <Grid container alignItems="center" className={classes.titleContainer}>
            <Grid item xs>
              <Typography
                variant="body2"
                className={classnames(classes.title, { [classes.titleMargin]: titleMargin })}
              >
                <FormattedMessage {...messages.PEOPLE_HOSTS} />
              </Typography>
            </Grid>

            {myAttendeeRole === 'host' && !hideOptions && (
              <GroupsControlsMenu
                onMessagePresenters={() => {}}
              />
            )}
          </Grid>

          {hostsArr.map((attendee) => (
            <Attendee
              key={attendee.attendee_id}
              attendeeId={attendee.attendee_id}
              hideOptions={hideOptions}
              onAttendeeClick={onAttendeeClick}
            />
          ))}
        </div>
      )}

      {!!_.size(presentersArr) && (
        <div>
          <Grid container alignItems="center" className={classes.titleContainer}>
            <Grid item xs>
              <Typography
                variant="body2"
                className={classnames(classes.title, { [classes.titleMargin]: titleMargin })}
              >
                <FormattedMessage {...messages.PEOPLE_PRESENTERS} />
              </Typography>
            </Grid>

            {myAttendeeRole === 'host' && !hideOptions && getPresenterGroupControls()}
          </Grid>

          {presentersArr.map((attendee) => (
            <Attendee
              key={attendee.attendee_id}
              attendeeId={attendee.attendee_id}
              hideOptions={hideOptions}
              onAttendeeClick={onAttendeeClick}
            />
          ))}
        </div>
      )}

      {!!_.size(attendeesArr) && (
        <div>
          <Grid container alignItems="center" className={classes.titleContainer}>
            <Grid item xs>
              <Typography
                variant="body2"
                className={classnames(classes.title, { [classes.titleMargin]: titleMargin })}
              >
                <FormattedMessage {...messages.PEOPLE_ATTENDEES} />
              </Typography>
            </Grid>

            {myAttendeeRole === 'host' && !hideOptions && getAttendeeGroupControls()}
          </Grid>

          {attendeesArr.map((attendee) => (
            <Attendee
              key={attendee.attendee_id}
              attendeeId={attendee.attendee_id}
              hideOptions={hideOptions}
              onAttendeeClick={onAttendeeClick}
            />
          ))}
        </div>
      )}
    </>
  );
};

PeopleList.defaultProps = {
  hideOptions: false,
  onAttendeeClick: null,
  titleMargin: false,
};

PeopleList.propTypes = {
  attendees: attendeesShape.arrayOfShapes.isRequired,
  hideOptions: PropTypes.bool,
  onAttendeeClick: PropTypes.func,
  titleMargin: PropTypes.bool,
  restrictAttendeeMulti: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  restrictAttendeeMulti: events.restrictAttendeeMulti,
}, dispatch);

export default connect(null, mapDispatchToProps)(PeopleList);
