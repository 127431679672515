// import * as cookiesManager from '../../utils/cookiesManager';
import store from '../../store';
import * as events from '../../events';
import * as selectors from '../../selectors';
import getBestMediaResolution from '../../services/getBestMediaResolution';
import bugsnagClient from '../../bugsnag';

const MODULE_NAME = 'MediaSoupHelpers';
const loglevel = require('loglevel');

const logger = loglevel.getLogger(MODULE_NAME);

export default class MediaSoupHelpers {
  /** ******* Media Soup ********** */

  async createWebRtcSendTransport() {
    const myAttendee = selectors.selectMyAttendee(store.getState());
    const mediaStatus = selectors.selectUserMediaStatus(store.getState());

    this.produce = (!mediaStatus.videoMuted || mediaStatus.screenShareStream)
      && !myAttendee.video_restricted;

    if (!this.produce) {
      logger.info('Not allowed to Produce media');
      return;
    }

    try {
      const {
        id,
        iceParameters,
        iceCandidates,
        dtlsParameters,
        sctpParameters,
      } = await this.protoo.request('createWebRtcTransport', {
        forceTcp: this.forceTcp,
        producing: true,
        consuming: false,
        sctpCapabilities: this.useDataChannel ? this.mediasoupDevice.sctpCapabilities : undefined,
      });

      this.sendTransport = this.mediasoupDevice.createSendTransport({
        id,
        iceParameters,
        iceCandidates,
        dtlsParameters,
        sctpParameters,
      });
    } catch (error) {
      logger.error(`createSendWebRtcTransport failed:${error}`);
      bugsnagClient.notify(error, (event) => {
        // eslint-disable-next-line no-param-reassign
        event.context = 'MediaSoupHelpers: createWebRtcSendTransport';
      });
      return;
    }

    // eslint-disable-next-line no-shadow
    this.sendTransport.on('connect', ({ dtlsParameters }, callback, errback) => {
      this.protoo.request('connectWebRtcTransport', {
        transportId: this.sendTransport.id,
        dtlsParameters,
      }).then(callback).catch((error) => {
        errback(error);
        logger.error(`sendTransport connect failed:${error}`);
        bugsnagClient.notify(error, (event) => {
          // eslint-disable-next-line no-param-reassign
          event.context = 'MediaSoupHelpers: sendTransport connect';
        });
      });
    });

    this.sendTransport.on('produce', async ({ kind, rtpParameters, appData }, callback, errback) => {
      try {
        const rtpParams = rtpParameters;
        if (rtpParams.encodings[2]) {
          rtpParams.encodings[0].maxBitrate = this.configEncodings[0].maxBitrate;
          rtpParams.encodings[1].maxBitrate = this.configEncodings[1].maxBitrate;
          rtpParams.encodings[2].maxBitrate = this.configEncodings[2].maxBitrate;
        }

        // eslint-disable-next-line no-shadow
        const { id } = await this.protoo.request('produce', {
          transportId: this.sendTransport.id,
          kind,
          rtpParameters: rtpParams,
          appData,
        });

        callback({ id });
      } catch (error) {
        logger.error(`sendTransport produce failed:${error}`);
        bugsnagClient.notify(error, (event) => {
          // eslint-disable-next-line no-param-reassign
          event.context = 'MediaSoupHelpers: sendTransport produce';
        });
        errback(error);
      }
    });

    // eslint-disable-next-line object-curly-newline
    this.sendTransport.on('producedata', async ({ sctpStreamParameters, label, protocol, appData }, callback, errback) => {
      logger.debug('"producedata" event: [sctpStreamParameters:%o, appData:%o]', sctpStreamParameters, appData);
      try {
        // eslint-disable-next-line no-shadow
        const { id } = await this.protoo.request('produceData', {
          transportId: this.sendTransport.id,
          sctpStreamParameters,
          label,
          protocol,
          appData,
        });

        callback({ id });
      } catch (error) {
        logger.error(`sendTransport producedata failed:${error}`);
        bugsnagClient.notify(error, (event) => {
          // eslint-disable-next-line no-param-reassign
          event.context = 'MediaSoupHelpers: sendTransport producedata';
        });
        errback(error);
      }
    });

    this.sendTransport.on('connectionstatechange', (connectionState) => {
      this.sendStatus = connectionState;
      this.postConnectionUpdate();
    });
  }

  async createWebRtcRecvTransport() {
    try {
      const {
        id,
        iceParameters,
        iceCandidates,
        dtlsParameters,
        sctpParameters,
      } = await this.protoo.request('createWebRtcTransport', {
        forceTcp: this.forceTcp,
        producing: false,
        consuming: true,
        sctpCapabilities: this.useDataChannel ? this.mediasoupDevice.sctpCapabilities : undefined,
      });

      this.recvTransport = this.mediasoupDevice.createRecvTransport({
        id,
        iceParameters,
        iceCandidates,
        dtlsParameters,
        sctpParameters,
      });
    } catch (error) {
      logger.error(`createRecvWebRtcTransport failed:${error}`);
      bugsnagClient.notify(error, (event) => {
        // eslint-disable-next-line no-param-reassign
        event.context = 'MediaSoupHelpers: createRecvWebRtcTransport';
      });
      return;
    }

    // eslint-disable-next-line no-shadow
    this.recvTransport.on('connect', ({ dtlsParameters }, callback, errback) => {
      this.protoo.request('connectWebRtcTransport', {
        transportId: this.recvTransport.id,
        dtlsParameters,
      }).then(callback).catch((error) => {
        logger.error(`recvTransport connect failed:${error}`);
        bugsnagClient.notify(error, (event) => {
          // eslint-disable-next-line no-param-reassign
          event.context = 'MediaSoupHelpers: recvTransport connect';
        });
        errback(error);
      });
    });

    this.recvTransport.on('connectionstatechange', (connectionState) => {
      this.recvStatus = connectionState;
      this.postConnectionUpdate();
    });
  }

  async setPreferedLayers(consumerId, spatialLayer, temporalLayer) {
    try {
      await this.protoo.request('setConsumerPreferedLayers',
        { consumerId, spatialLayer, temporalLayer });
    } catch (error) {
      // TODO: Consumer Failed recreate
      logger.error('_setPreferedLayers() | failed:%o', error);
      bugsnagClient.notify(error, (event) => {
        // eslint-disable-next-line no-param-reassign
        event.context = 'MediaSoupHelpers: setPreferedLayers';
      });
    }
  }

  setStatusInterval(status) {
    if (this.videoStatusInterval) {
      clearInterval(this.videoStatusInterval);
      this.videoStatusInterval = null;
    }

    store.dispatch(events.postVideoStatus(status));

    const intervalPeriod = (5 * 60 * 1000) + Math.floor(Math.random() * 120000);
    // 5-7 min interval for some randomization.
    this.videoStatusInterval = setInterval(() => {
      store.dispatch(events.postVideoStatus(status));
    }, intervalPeriod, status);
  }

  postConnectionUpdate() {
    // connected | video | screenShare
    function getStatusValue(connectionState) {
      let status = 0;
      switch (connectionState) {
        case 'closed':
        case 'disconnected':
          status = 1;
          break;
        case 'failed':
          status = 2;
          break;
        case 'new':
        case 'connecting':
          status = 3;
          break;
        case 'connected':
          status = 4;
          break;
        default:
          status = 0;
      }
      return status;
    }

    function getStatusString(connectionState) {
      let string = 0;
      switch (connectionState) {
        case 'closed':
        case 'disconnected':
          string = 'disconnected';
          break;
        case 'failed':
          string = 'failed';
          break;
        case 'new':
          string = 'connecting';
          break;
        case 'connecting':
        case 'connected':
          string = 'connected';
          break;
        default:
          console.warn('Unexpected RTCPeerConnectionState', connectionState);
          string = 'unknown';
      }
      return string;
    }

    if (!this.recvStatus && !this.sendStatus) {
      return;
    }

    const connectionStatus = (getStatusValue(this.recvStatus) > getStatusValue(this.sendStatus))
      ? getStatusString(this.recvStatus) : getStatusString(this.sendStatus);

    this.setStatusInterval(connectionStatus);
  }

  /** ******** Media Device Setup ********* */

  async getTrackFromState(xBest) {
    let stream;
    let track;

    if (!this.externalVideo) {
      const mediaStatus = selectors.selectUserMediaStatus(store.getState());
      if (!mediaStatus.videoStream || !mediaStatus.videoStream.active || xBest) {
        stream = await getBestMediaResolution(mediaStatus.videoDevice.deviceId, xBest);
      } else {
        stream = mediaStatus.videoStream;
      }

      if (!stream) {
        bugsnagClient.notify(Error('getTrackFromState failed to get stream'));
      }

      ([track] = stream.getVideoTracks());
    } else {
      stream = await this.getExternalVideoStream();

      if (stream) {
        track = stream.getVideoTracks()[0].clone();
      }
    }

    logger.debug('VideoTrack:', stream);
    return track;
  }

  /**
  *
  * @return {Promise}
  */
  async enableWebcam() {
    logger.debug(`${MODULE_NAME} enableWebcam`);

    // Store in cookie
    // cookiesManager.setDevices({ webcamEnabled: true });

    if (!this.mediasoupDevice.canProduce('video')) {
      logger.error('enableWebcam() | cannot produce video');
      return;
    }

    const track = await this.getTrackFromState();

    if (track) {
      this.createWebProducer(track);
    }
  }

  async updateWebcamTrack(track) {
    this.createWebProducer(track);
    const participant = await selectors.selectUserParticipant(store.getState());
    if (participant) {
      participant.videoTrack = track;
      participant.videoTrackMuted = false;
      store.dispatch(events.updateParticipant(participant));
    }
  }

  /**
     *
     * @return {Promise}
     */
  async disableWebcam() {
    logger.debug(`${MODULE_NAME} disableWebcam`);

    // cookiesManager.setDevices({ webcamEnabled: false });

    this.destroyWebProducer();
  }

  /**
     *
     * @return {Promise}
     */
  async getExternalVideoStream() {
    if (this.externalVideoStream) return this.externalVideoStream;

    if (this.externalVideo.readyState < 3) {
      await new Promise((resolve) => (
        this.externalVideo.addEventListener('canplay', resolve)
      ));
    }

    if (this.externalVideo.captureStream) {
      this.externalVideoStream = this.externalVideo.captureStream();
    } else if (this.externalVideo.mozCaptureStream) {
      this.externalVideoStream = this.externalVideo.mozCaptureStream();
    } else {
      throw new Error('video.captureStream() not supported');
    }

    return this.externalVideoStream;
  }

  /** ******* WebRTC Stats ******** */
  /**
     *
     * @param label
     * @param stats
     * @private
     */
  static printStatsArray(label, stats) {
    if (!stats) return;

    if (stats.length > 1) {
      Object.keys(stats).forEach((key) => {
        logger.info(label, stats[key].ssrc, stats[key]);
      });
    } else {
      logger.info(label, stats[0]);
    }
  }

  /**
     *
     * @return {Promise}
     */
  async getSendTransportRemoteStats() {
    logger.debug('getSendTransportRemoteStats()');

    if (!this.sendTransport) return null;

    return this.protoo.request(
      'getTransportStats', { transportId: this.sendTransport.id },
    );
  }

  /**
     *
     * @return {Promise}
     */
  async getRecvTransportRemoteStats() {
  // logger.debug('getRecvTransportRemoteStats()');

    if (!this.recvTransport) return null;

    return this.protoo.request(
      'getTransportStats', { transportId: this.recvTransport.id },
    );
  }

  /**
     *
     * @return {Promise}
     */
  async getProducerRemoteStats() {
  // logger.debug('getProducerRemoteStats()');

    const producer = this.webcamProducer;

    if (!producer) return null;

    return this.protoo.request(
      'getProducerStats', { producerId: producer.id },
    );
  }

  /**
     *
     * @return {Promise}
     */
  async getShareScreenRemoteStats() {
  // logger.debug('getShareScreenRemoteStats()');

    const producer = this.shareProducer;

    if (!producer) return null;

    return this.protoo.request(
      'getProducerStats', { producerId: producer.id },
    );
  }

  /**
     *
     * @param consumerId
     * @return {Promise}
     */
  async getConsumerRemoteStats(consumerId) {
  // logger.debug('getConsumerRemoteStats()');
    const consumer = this.consumers.get(consumerId);

    if (!consumer) return null;

    return this.protoo.request('getConsumerStats', { consumerId });
  }

  /**
     *
     * @return {Promise}
     */
  async getChatDataProducerRemoteStats() {
    logger.debug('getChatDataProducerRemoteStats()');

    const dataProducer = this.chatDataProducer;

    if (!dataProducer) return null;

    return this.protoo.request(
      'getDataProducerStats', { dataProducerId: dataProducer.id },
    );
  }

  /**
     *
     * @return {Promise}
     */
  async getSendTransportLocalStats() {
    logger.debug('getSendTransportLocalStats()');

    if (!this.sendTransport) return null;

    return this.sendTransport.getStats();
  }

  /**
     *
     * @return {Promise}
     */
  async getRecvTransportLocalStats() {
    logger.debug('getRecvTransportLocalStats()');

    if (!this.recvTransport) return null;

    return this.recvTransport.getStats();
  }

  /**
     *
     * @return {Promise}
     */
  async getProducerLocalStats() {
    const producer = this.webcamProducer;

    if (!producer) return null;

    return producer.getStats();
  }

  /**
     *
     * @return {Promise}
     */
  async getShareScreenLocalStats() {
    const producer = this.shareProducer;

    if (!producer) return null;

    return producer.getStats();
  }

  /**
     *
     * @param consumerId
     * @return {Promise}
     */
  async getConsumerLocalStats(consumerId) {
    const consumer = this.consumers.get(consumerId);

    if (!consumer) return null;

    return consumer.getStats();
  }
}
