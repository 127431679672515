import { put } from 'redux-saga/effects';

import { restrictAttendee } from '../../events';

// eslint-disable-next-line import/prefer-default-export
export function* restrictAttendeeMulti({ payload }) {
  const { attendeesList, restriction, successCallback } = payload;

  if (attendeesList) {
    for (let i = 0; i < attendeesList.length; i += 1) {
      const attendee = attendeesList[i];
      yield put(restrictAttendee(attendee, restriction, successCallback));
    }
  }
}
