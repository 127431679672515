import { call, put } from 'redux-saga/effects';
import * as actions from '../../actions';
import { setAttendees } from './setAttendees';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* setMeeting({ payload: meeting }) {
  try {
    yield put(actions.setMeeting(meeting ? { ...meeting } : null));

    if (meeting && (typeof meeting.attendees === 'string' || meeting.attendees instanceof String)) {
      // eslint-disable-next-line no-param-reassign
      meeting.attendees = JSON.parse(meeting.attendees);
    }
    const attendees = meeting ? meeting.attendees : [];
    yield call(setAttendees, { payload: attendees });
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: setMeeting';
      event.addMetadata('payload', meeting);
    });
  }
}
