import { put, select } from 'redux-saga/effects';
import _ from 'lodash';

import {
  selectParticipant,
  selectParticipants,
  selectMediaStatus,
  selectAttendeesList,
  selectAttendeeAudioId,
  selectMeeting,
  selectSpeakerActivity,
  selectAllSpeakerActivity,
  selectVideoRoom,
} from '../../selectors';
import { ALLOWED_WEBINAR_ROLES } from '../../constants';
import { setSpeakerActivity } from '../../actions';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* updateActiveStatus({ payload }) {
  try {
    const energyRecord = payload?.data;
    if (!energyRecord || !energyRecord.user) return;

    const user = energyRecord.user.replace(/\D/g, '');
    let userId = energyRecord.user;
    if (userId.endsWith('vb')) {
      userId = userId.substring(0, userId.length - 2);
    }

    let participant = yield select((state) => selectParticipant(state, `video_${userId}`));
    const { audioId } = energyRecord;

    if (participant) {
      // we found a match...
    } else {
      // we need to loop (ouch?) and match by audioID?
      console.log(`Looping (NOT IDEAL) for audio = ${audioId}, user = ${user}, userId = ${userId}, participantId = video_${userId}`);
      const participants = yield select(selectParticipants);

      const ids = Object.keys(participants);
      for (let i = 0; i < ids.length; i += 1) {
        if (String(participants[ids[i]].audioId) === String(audioId)) {
          participant = participants[ids[i]];
        }
      }
    }

    // if media status of this user is shown as muted, then need to not show them as active ever
    const mediaStatusPart = yield select(selectMediaStatus);

    let isMuted = false;
    if (mediaStatusPart[participant?.userId]?.audioMuted || mediaStatusPart[user]?.audioMuted) {
      isMuted = true;
    }

    let needsUpdate = true;

    if (participant) {
      const partId = participant.id;
      let updatedActivity = yield select((state) => selectSpeakerActivity(state, partId));
      const speakerActivityInit = { ...updatedActivity };

      if (!updatedActivity) {
        console.debug(`Deep search on selectAllSpeakerActivity (ok if not repeated often) partId= ${partId}`);
        const speakerActivity = yield select(selectAllSpeakerActivity);
        // minimum threshold, participant is isAudible but not active
        updatedActivity = {
          ..._.get(speakerActivity, [partId], { isActive: false, isAudible: false }),
        };
      }

      updatedActivity.isAudible = energyRecord.fsum > 2000;

      if (energyRecord.isActive === '1' && !isMuted && updatedActivity.isActive !== true) {
        updatedActivity.isActive = true;
      }

      if (energyRecord.isActive === '0' && updatedActivity.isActive !== false) {
        updatedActivity.isActive = false;
      }

      // if is active is 1 but fsum is 0, set isActive to false.
      // This is fix for host muting participant while they're talking.
      if (energyRecord.isActive === '1' && energyRecord.fsum === '0' && updatedActivity.isActive !== false) {
        updatedActivity.isActive = false;
      }

      if (!_.isEqual(speakerActivityInit, updatedActivity)) {
        yield put(setSpeakerActivity({ id: partId, ...updatedActivity }));
      } else {
        needsUpdate = false; // because no change
      }

      if (updatedActivity.isActive || updatedActivity.isAudible) {
        const videoRoom = yield select(selectVideoRoom);
        if (videoRoom) videoRoom.notifyFocus(participant.consumerId);
      }
    }

    if (!needsUpdate) return;

    const attendeeMatch = yield select(selectAttendeeAudioId, audioId);
    if (attendeeMatch) {
      if (energyRecord.isActive === '1' && !isMuted && attendeeMatch.isActive !== true) {
        yield put(setSpeakerActivity({
          id: attendeeMatch.attendee_id,
          isActive: true,
        }));
      } else if (energyRecord.isActive === '0' && attendeeMatch.isActive !== false) {
        yield put(setSpeakerActivity({
          id: attendeeMatch.attendee_id,
          isActive: false,
        }));
      }
    } else {
      let attendees = yield select(selectAttendeesList);

      // if it is in presentation mode, should only have hosts and presenters
      const meeting = yield select(selectMeeting);
      if (meeting.type === 'presentation') {
        attendees = _.filter(attendees, (a) => ALLOWED_WEBINAR_ROLES.includes(a.role));
      }

      // set attendee active status for side bar
      for (let i = 0; i < attendees.length; i += 1) {
        let phonenumber;
        if (typeof attendees[i].phonenumber !== 'undefined' && attendees[i].phonenumber !== null) {
          phonenumber = attendees[i].phonenumber.toString().replace(/\D/g, '');
        }

        if (attendees[i].audio_id === audioId
          || (attendees[i].audio_id !== null && attendees[i].audio_id.toString() === audioId)
          || (typeof attendees[i].phonenumber !== 'undefined' // also for phonenumber only
            && phonenumber === audioId)
        ) {
          console.debug('attendee found');
          console.debug(attendees[i]);
          const speakerActivity = yield select(selectAllSpeakerActivity);
          // only way to be active is NCS threshold and mediastatus is not muted
          if (energyRecord.isActive === '1' && !isMuted && _.get(speakerActivity[attendees[i].attendee_id], 'isActive') !== true) {
            yield put(setSpeakerActivity({
              id: attendees[i].attendee_id,
              isActive: true,
            }));
          } else if (energyRecord.isActive === '0' && _.get(speakerActivity[attendees[i].attendee_id], 'isActive') !== false) {
            yield put(setSpeakerActivity({
              id: attendees[i].attendee_id,
              isActive: false,
            }));
          }
        }
      }
    }
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: updateActiveStatus';
      event.addMetadata('payload', payload);
    });
  }
}
