import nsApi from '@netsapiens/netsapiens-js/dist/api';

export default ({
  domain,
  videoBridgeId,
  uid,
  user,
  meetingType,
}) => nsApi.post({
  object: 'conference',
  action: 'create',
  announce_depart: 'null.wav',
  announce_join: 'null.wav',
  annc_part: 'no',
  aor: videoBridgeId,
  call_processing_rule: 'PresenceIgnore',
  domain,
  extension: user,
  id: videoBridgeId,
  name: 'Video Bridge',
  save_participants: 'no',
  type: 'video.bridge',
  termination_match: videoBridgeId,
  owner: user,
  owner_uid: uid,
  RegRequired: 'no',
  promptAudioId: 'yes',
  mute: meetingType === 'presentation' ? 'yes' : 'no',
  confOptions: meetingType === 'presentation' ? 'NoKnobReset' : '',
});
