import { put, select } from 'redux-saga/effects';
import _ from 'lodash';

import { selectChatAttachments } from '../../selectors';
import { setChatAttachments } from '../../actions';

// eslint-disable-next-line import/prefer-default-export
export function* removeChatAttachment({ payload }) {
  const attachments = yield select(selectChatAttachments);
  const clonedAttachments = _.cloneDeep(attachments);
  if (clonedAttachments[payload]) {
    delete clonedAttachments[payload];
  }
  yield put(setChatAttachments(clonedAttachments));
}
