export const NOTIFY_SFU_SPEAKER = 'events/NOTIFY_SFU_SPEAKER';

/**
 *
 * @param res
 * @return {{type: string, payload: *}}
 */
export const notifySfuSpeaker = (res) => ({
  type: NOTIFY_SFU_SPEAKER,
  payload: res,
});
