import React, { createContext, useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import socketIO from 'socket.io-client/dist/socket.io.slim';

import reduxStore from '../../store';
import {
  selectConfig, selectMeeting, selectUser,
} from '../../selectors';
import bugsnagClient from '../../bugsnag';

const initialState = {
  callId: null,
};
const store = createContext(initialState);
const { Provider } = store;

let onCall;
let myAttendee;

const StateProvider = ({ children }) => {
  const meeting = selectMeeting(reduxStore.getState());
  const myAttendeeId = meeting.attendee_id;
  const decodedToken = nsToken.getDecoded();

  const [callErrorState, setCallError] = useState(false);
  const [onCallState, setOnCall] = useState(false);
  const [meetingState, setMeeting] = useState(meeting);

  // get initial meeting and attendee data
  useEffect(() => {
    const getMeeting = async () => {
      try {
        const [updatedMeeting] = await nsApi.get({
          id: meeting.id,
          object: 'meeting',
          action: 'read',
          user: decodedToken.user,
          domain: decodedToken.domain,
          include_attendees: 'yes',
          attendee_id: myAttendeeId,
        });

        setMeeting(updatedMeeting);

        if (_.get(updatedMeeting, 'attendees') && _.isString(meeting.attendees)) {
          try {
            const attendees = JSON.parse(meeting.attendees);

            if (!_.isArray(meeting.attendees)) {
              console.error(`Meeting attendees should be an array. Got ${typeof attendees}`);
              bugsnagClient.notify(`Meeting attendees should be an array. Got ${typeof attendees}`);
              meeting.attendees = [];
            }

            const attendee = _.find(attendees, { attendee_id: myAttendeeId });

            if (!onCall && attendee.status === 'active') {
              console.debug('attendee status - on call');
              onCall = true;
              setOnCall(true);
              setCallError(false);
            } else if (onCall && attendee.status !== 'active') {
              console.debug('attendee status - off call');
              onCall = false;
              setOnCall(false);
            } else {
              console.debug('no attendee changes');
            }
          } catch (e) {
            console.error(e);
            bugsnagClient.notify(e);
            meeting.attendees = [];
          }
        }
      } catch (e) {
        console.error(e);
        bugsnagClient.notify(e);
      }
    };

    getMeeting();
  }, []);

  // connect to the socket
  useEffect(() => {
    const user = selectUser(reduxStore.getState());
    const { confId } = reduxStore.getState().configs;
    const token = nsToken.get();
    const nsSocketURLs = selectConfig(reduxStore.getState(), 'nsSocketURLs');

    let socket;

    try {
      socket = socketIO.connect(
        nsSocketURLs[0], { secure: true, transports: ['websocket'] },
      );

      socket.on('connect', () => {
        socket.emit('subscribe', {
          type: 'meeting',
          domain: user.domain,
          filter: confId,
          bearer: token,
        });

        socket.emit('subscribe', {
          type: 'video',
          domain: user.domain,
          filter: confId,
          bearer: token,
        });

        // listen for meeting updates
        socket.on('meeting', (data) => {
          console.debug('meeting update - on call');
          setMeeting(data);
        });

        // listen for attendee updates
        socket.on('attendee', (attendee) => {
          if (attendee.attendee_id === myAttendeeId) {
            myAttendee = attendee;

            if (!onCall && attendee.status === 'active') {
              console.debug('attendee status - on call');
              onCall = true;
              setOnCall(true);
              setCallError(false);
            } else if (onCall && attendee.status !== 'active') {
              console.debug('attendee status - off call');
              onCall = false;
              setOnCall(false);
            } else {
              console.debug('no attendee changes');
            }
          }
        });
      });
    } catch (e) {
      console.error(e);
      bugsnagClient.notify(e);
    }

    return () => {
      if (socket) {
        socket.disconnect(true);
      }
    };
  }, []);

  const callMe = async (number) => {
    const videoBridgeId = selectConfig(reduxStore.getState(), 'videoBridgeId');
    const randomNumber = Math.floor(100000000 + Math.random() * 900000000);

    const callId = `VideoInvite_${decodedToken.sub}_${randomNumber}`;
    setOnCall(true);
    setCallError(false);

    try {
      // hang up existing call if there is one
      if (_.get(myAttendee, 'audio_participant_match')) {
        await nsApi.post({
          object: 'participant',
          action: 'delete_participant', // delete instead of update no audio id
        }, {
          user: decodedToken.user,
          domain: decodedToken.domain,
          participant: myAttendee.audio_participant_match,
          conference_match: `sip:${meeting.id}.video.bridge@conference-bridge`,
          audioId: meeting.my_audio_id,
        });
      }

      await nsApi.post({
        action: 'call',
        object: 'call',
        uid: decodedToken.sub,
        callid: callId,
        destHdr: `P-Served-Parameters: audioId=${meeting.my_audio_id}`,
        destination: videoBridgeId,
        origination: `sip:${number}@${decodedToken.domain}`,
      });
    } catch (e) {
      console.error(e);
      bugsnagClient.notify(e);
      onCall = false;
      setOnCall(false);
      setCallError(true);
    }
  };

  const useMic = async () => {
    try {
      if (_.get(myAttendee, 'audio_participant_match')) {
        await nsApi.post({
          object: 'participant',
          action: 'delete_participant', // delete instead of update no audio id
        }, {
          user: decodedToken.user,
          domain: decodedToken.domain,
          participant: myAttendee.audio_participant_match,
          conference_match: `sip:${meeting.id}.video.bridge@conference-bridge`,
          audioId: meeting.my_audio_id,
        });
      }

      onCall = false;
      setOnCall(false);
      setCallError(false);
    } catch (e) {
      console.error(e);
      bugsnagClient.notify(e);
    }
  };

  return (
    <Provider value={{
      callError: callErrorState,
      onCall: onCallState,
      meeting: meetingState,
      callMe,
      useMic,
    }}
    >
      {children}
    </Provider>
  );
};

StateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { store, StateProvider };
