export const SAVE_SELECTED_DEVICES = 'events/SAVE_SELECTED_DEVICES';

/**
 *
 * @param mediaStatus
 * @return {{type: string, payload: *}}
 */
export const saveSelectedDevices = (mediaStatus) => ({
  type: SAVE_SELECTED_DEVICES,
  payload: mediaStatus,
});
