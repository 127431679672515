import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import hark from 'hark';
import { connect } from 'react-redux';
import DetectRTC from '@netsapiens/netsapiens-js/dist/web-rtc';
import { defineMessages, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useAudio from 'react-use/lib/useAudio';
import VolumeUp from '@material-ui/icons/VolumeUp';

import { selectUserMediaStatus } from '../../../../selectors';

import testAudio from '../../../../assets/audio/test.wav';
import SettingsContainer from '../../../containers/SettingsContainer';

const messages = defineMessages({
  AUDIO_TEST: {
    id: 'AUDIO_TEST',
    defaultMessage: 'test output',
  },
  TEST_AUDIO: {
    id: 'TEST_AUDIO',
    defaultMessage: 'Test Audio',
  },
  SPEAKERS: {
    id: 'SPEAKERS',
    defaultMessage: 'speakers',
  },
});

const useStyles = makeStyles((theme) => ({
  settingContainer: {
    width: 128,
    borderColor: '#fff',
  },
  icon: {
    width: 72,
    height: 72,
  },
  text: {
    marginTop: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.54)',
  },
  btn: {
    marginTop: theme.spacing(1),
    fontSize: 14,
  },
}));

const MicOutputSetting = ({
  audioOutputDevice,
  showText,
  variant,
}) => {
  const classes = useStyles();
  const [speaking, setSpeaking] = useState(null);
  const [audio, , controls, ref] = useAudio({
    src: testAudio,
    autoPlay: false,
  });

  useEffect(() => {
    if (audioOutputDevice && ref && ref.current) {
      if (DetectRTC.isSetSinkIdSupported) {
        ref.current.setSinkId(audioOutputDevice.deviceId);
      }
    }

    return () => {
      if (controls && controls.stop) {
        controls.stop();
      }
    };
  }, [ref.current, audioOutputDevice]);

  const handlePlay = () => {
    controls.play();
    if (ref.current) return;

    const stream = ref.current.captureStream();

    const speechEvents = hark(stream);

    speechEvents.on('speaking', () => {
      setSpeaking(true);
    });

    speechEvents.on('stopped_speaking', () => {
      setSpeaking(false);
    });

    const track = stream.getTracks()[0];

    track.onended = () => {
      setSpeaking(false);
      track.stop();
      speechEvents.stop();
    };
  };

  return (
    <>
      {variant === 'full' ? (
        <SettingsContainer variant="premeet">
          <Grid
            className={classes.settingContainer}
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Grid item>
              <VolumeUp
                className={classes.icon}
                style={{ opacity: (!speaking) ? 0.54 : 1 }}
                color={speaking ? 'primary' : 'inherit'}
              />
            </Grid>
            {showText && (
              <Grid item className={classes.text}>
                <Typography variant="subtitle2" align="center">
                  <FormattedMessage {...messages.SPEAKERS} />
                </Typography>
              </Grid>
            )}
            <Grid item>
              <Button
                id="MicOutputSettingAudioTest"
                className={classes.btn}
                size="small"
                color="primary"
                onClick={handlePlay}
              >
                <FormattedMessage {...messages.AUDIO_TEST} />
              </Button>
            </Grid>
          </Grid>
        </SettingsContainer>
      ) : (
        <Button
          id="MicOutputSettingAudioTest"
          size="small"
          color="primary"
          onClick={handlePlay}
        >
          <FormattedMessage {...messages.TEST_AUDIO} />
        </Button>
      )}
      {audio}
    </>
  );
};

MicOutputSetting.defaultProps = {
  audioOutputDevice: null,
  showText: false,
  variant: 'full',
};

MicOutputSetting.propTypes = {
  audioOutputDevice: PropTypes.shape({
    deviceId: PropTypes.string,
  }),
  showText: PropTypes.bool,
  variant: PropTypes.string,
};

const mapStateToProps = (state) => ({
  audioOutputDevice: selectUserMediaStatus(state).audioOutputDeviceSelect
    || selectUserMediaStatus(state).audioOutputDevice,
});

export default connect(mapStateToProps)(MicOutputSetting);
