import {
  all, call, delay, put, race, select, take,
} from 'redux-saga/effects';

import { selectUserMediaStatus } from '../../selectors';
import {
  OPEN_DEVICE_RECONFIG_MODAL,
  TOGGLE_AUDIO_MUTE,
  TOGGLE_VIDEO_MUTE,
} from '../../events';
import checkMediaPermission from '../../services/checkMediaPermission';
import { initMediaStreams } from './initMediaStreams';
import * as actions from '../../actions';
import { SHOW_PENDING_PERMISSIONS_DIALOG } from '../../constants';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* recheckMediaPermissions(saga, { type, payload }) {
  try {
    let userMedia = yield select(selectUserMediaStatus);

    if (userMedia.hasCamPermissions === null || userMedia.hasMicPermissions === null) {
      let camPermission;
      if (userMedia.videoDevice) {
        camPermission = checkMediaPermission({ audio: false, video: true });
      }

      let micPermission;
      if (userMedia.audioInputDevice) {
        micPermission = checkMediaPermission({ audio: true, video: false });
      }

      let { permissions } = yield race({
        permissions: all([
          micPermission,
          camPermission,
        ]),
        timeout: delay(3000),
      });

      if (permissions) {
        yield call(initMediaStreams, {
          audio: permissions[0],
          video: permissions[1],
          muted: true,
        });

        userMedia = yield select(selectUserMediaStatus);

        switch (type) {
          case TOGGLE_AUDIO_MUTE:
            if (userMedia.hasMicPermissions) {
              yield call(saga, { payload });
              return;
            }
            break;
          case TOGGLE_VIDEO_MUTE:
            if (userMedia.hasCamPermissions) {
              yield call(saga, { payload });
              return;
            }
            break;
          case OPEN_DEVICE_RECONFIG_MODAL:
            yield call(saga, { payload });
            return;
          default:
        }
        return;
      }

      yield put(actions.showPendingPermissionsDialog({ dismiss: true }));

      ({ permissions } = yield race({
        permissions: all([
          micPermission,
          camPermission,
        ]),
        dismissed: take(SHOW_PENDING_PERMISSIONS_DIALOG),
      }));

      if (permissions) {
        yield call(initMediaStreams, {
          audio: permissions[0],
          video: permissions[1],
          muted: true,
        });

        yield put(actions.showPendingPermissionsDialog(false));
        userMedia = yield select(selectUserMediaStatus);

        switch (type) {
          case TOGGLE_AUDIO_MUTE:
            if (userMedia.hasMicPermissions) {
              yield call(saga, { payload });
              return;
            }
            break;
          case TOGGLE_VIDEO_MUTE:
            if (userMedia.hasCamPermissions) {
              yield call(saga, { payload });
              return;
            }
            break;
          case OPEN_DEVICE_RECONFIG_MODAL:
            yield call(saga, { payload });
            return;
          default:
        }
      }

      return;
    }

    userMedia = yield select(selectUserMediaStatus);

    switch (type) {
      case TOGGLE_AUDIO_MUTE:
        if (userMedia.hasMicPermissions) {
          yield call(saga, { payload });
          return;
        }
        break;
      case TOGGLE_VIDEO_MUTE:
        if (userMedia.hasCamPermissions) {
          yield call(saga, { payload });
          return;
        }
        break;
      case OPEN_DEVICE_RECONFIG_MODAL:
        yield call(saga, { payload });
        return;
      default:
    }

    yield put(actions.showPermissionsDialog(type));
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: recheckMediaPermissions';
      event.addMetadata('payload', { type, payload });
    });
  }
}
