/* eslint-disable func-names */
import getBGPastelColor from './getBGPastelColor';
import gravatarSessionId from '../../utils/gravatarSessionId';

export default ({
  gravatar,
}) => new Promise((resolve) => {
  // find average color of gravatar image
  // const imgEl = document.getElementById('gravatarImg');
  if (!gravatar) {
    resolve(getBGPastelColor());
    return;
  }
  const imgEl = new Image();
  imgEl.onload = function () {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext && canvas.getContext('2d');
    let imgdata;
    const rgb = { r: 0, g: 0, b: 0 };
    let count = 0;

    if (!context || !imgEl) {
      resolve(getBGPastelColor());
      return;
    }

    const height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
    const width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;
    canvas.height = height;
    canvas.width = width;

    context.drawImage(imgEl, 0, 0);

    try {
      imgdata = context.getImageData(0, 0, width, height);
    } catch (e) {
      /* security error, img on diff domain */
      console.error(e);
      resolve(getBGPastelColor());
      return;
    }

    const { length } = imgdata.data;

    for (let i = 0; i < length; i += 4) {
      rgb.r += imgdata.data[i];
      rgb.g += imgdata.data[i + 1];
      rgb.b += imgdata.data[i + 2];
      count += 1;
    }

    // ~~ used to floor values
    const R = Math.floor(rgb.r / count);
    const G = Math.floor(rgb.g / count);
    const B = Math.floor(rgb.b / count);
    const rgbbit = (R << 16) + (G << 8) + B; // eslint-disable-line no-bitwise

    resolve(`#${rgbbit.toString(16)}`);
  };
  imgEl.onerror = function (e) {
    console.error(e);
    resolve(getBGPastelColor());
  };
  imgEl.crossOrigin = '';
  imgEl.src = `https://www.gravatar.com/avatar/${gravatar}?s=42&d=identicon&hash=${gravatarSessionId}`;
});
