import {
  LOGIN_ERROR_MSG,
  LOGIN_IMAGE,
} from '../constants';

const defaultState = {
  errorMsg: null,
  loginImage: null,
};

const reducers = {
  [LOGIN_ERROR_MSG]: (state, { payload }) => ({ errorMsg: payload }),
  [LOGIN_IMAGE]: (state, { payload }) => ({ loginImage: payload }),
};

export default {
  defaultState,
  reducers,
};
