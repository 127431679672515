export const UPDATE_AUDIO_STATUS = 'events/UPDATE_AUDIO_STATUS';

/**
 *
 * @param audioMuted
 * @return {{type: string, payload: *}}
 */
export const updateAudioStatus = (audioMuted) => ({
  type: UPDATE_AUDIO_STATUS,
  payload: audioMuted,
});
