import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
  selectAttendee,
  selectMeeting,
  selectShowScreenshareRequestModal,
  selectVideoRoom,
} from '../../selectors';
import * as actions from '../../actions';
import * as events from '../../events';

const messages = defineMessages({
  SCREENSHARE_TRANSFER_MODAL_TITLE: {
    id: 'SCREENSHARE_TRANSFER_MODAL_TITLE',
    defaultMessage: 'transferred the presentation to you',
  },
  SCREENSHARE_TRANSFER_MODAL_TEXT: {
    id: 'SCREENSHARE_TRANSFER_MODAL_TEXT',
    defaultMessage: 'You may share your screen and continue the presentation',
  },
  DISMISS: {
    id: 'DISMISS',
    defaultMessage: 'Dismiss',
  },
  SHARE_SCREEN: {
    id: 'SHARE_SCREEN',
    defaultMessage: 'Share Screen',
  },
});

const ScreenshareRequestDialog = ({
  byAttendeeName,
  isOpen,
  partialMeetingUpdateAction,
  room,
  showScreenshareRequestModalAction,
}) => {
  const handleDismiss = () => {
    partialMeetingUpdateAction({ presenter: '' });
    showScreenshareRequestModalAction(false);
  };

  const handleShare = () => {
    function onSuccess() {
      showScreenshareRequestModalAction(false);
    }

    room.shareScreen(null, onSuccess);
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
    >
      <DialogTitle>
        {byAttendeeName}
        {' '}
        <FormattedMessage {...messages.SCREENSHARE_TRANSFER_MODAL_TITLE} />
      </DialogTitle>
      <DialogContent style={{ width: 344 }}>
        <DialogContentText>
          <FormattedMessage {...messages.SCREENSHARE_TRANSFER_MODAL_TEXT} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleDismiss}>
          <FormattedMessage {...messages.DISMISS} />
        </Button>
        <Button color="primary" variant="contained" onClick={handleShare}>
          <FormattedMessage {...messages.SHARE_SCREEN} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ScreenshareRequestDialog.defaultProps = {
  byAttendeeName: '',
  room: null,
};

ScreenshareRequestDialog.propTypes = {
  byAttendeeName: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  partialMeetingUpdateAction: PropTypes.func.isRequired,
  room: PropTypes.shape({ shareScreen: PropTypes.func }),
  showScreenshareRequestModalAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const meeting = selectMeeting(state);
  return {
    byAttendeeName: _.get(selectAttendee(state, _.get(meeting, 'presentation_transferred_by')), 'name', ''),
    isOpen: selectShowScreenshareRequestModal(state),
    room: selectVideoRoom(state),
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  partialMeetingUpdateAction: events.partialMeetingUpdate,
  showScreenshareRequestModalAction: actions.showScreenshareRequestModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ScreenshareRequestDialog);
