export const SAVE_MEETING = 'events/SAVE_MEETING';

/**
 * @param saveMode
 * @param successCallback
 * @param suppressLoader
 * @return {{type: string, payload: *}}
 */
export const saveMeeting = (saveMode, successCallback, suppressLoader) => ({
  type: SAVE_MEETING,
  payload: {
    saveMode,
    successCallback,
    suppressLoader,
  },
});
