import React, { useMemo } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { defineMessages, FormattedMessage } from 'react-intl';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { ALLOWED_WEBINAR_ROLES } from '../../../../constants';
import {
  selectConfig,
  selectMeeting,
  selectMyAttendee,
  selectUcUnReadCount,
  selectUcTabValue,
  selectConnectedAttendees,
} from '../../../../selectors';
import { inboxTabChange } from '../../../../events';
import isAttendeeActive from '../../../../utils/isAttendeeActive';

import ChatTab from './chat-tab/ChatTab';
import PeopleTab from './people-tab/PeopleTab';

const messages = defineMessages({
  CLOSE: {
    id: 'CLOSE',
    defaultMessage: 'Close',
  },
  NEW_PRIVATE_MESSAGE: {
    id: 'NEW_PRIVATE_MESSAGE',
    defaultMessage: 'New Private Message',
  },
  SEARCH: {
    id: 'SEARCH',
    defaultMessage: 'Search',
  },
  TAB_CHAT_LABEL: {
    id: 'TAB_CHAT_LABEL',
    defaultMessage: 'chat',
  },
  TAB_PEOPLE_LABEL: {
    id: 'TAB_PEOPLE_LABEL',
    defaultMessage: 'people',
  },
});

const useStyle = makeStyles((theme) => ({
  badge: {
    marginBottom: '0px !important',
    lineHeight: 1,
    height: 18,
    backgroundColor: '#00bcd4',
    color: '#fff',
    fontSize: 12,
    borderRadius: 9,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 5px',
  },
  unreadCount: {
    backgroundColor: '#E91E63',
  },
  labelIcon: {
    minHeight: 0,
    padding: 4,
    paddingTop: 8,
  },
  noVis: {
    visibility: 'hidden',
  },
  tabs: {
    boxShadow: theme.shadows[3],
  },
}));

const SideTrayTabs = () => {
  const classes = useStyle();
  const dispatch = useDispatch();

  const attendees = useSelector(selectConnectedAttendees);
  const chatEnabled = useSelector((state) => selectConfig(state, 'videoAllowChat') === 'yes' && selectMeeting(state).enable_chat);
  const tabValue = useSelector(selectUcTabValue);
  const ucUnReadCount = useSelector(selectUcUnReadCount);
  const showViewersList = useSelector((state) => selectMeeting(state).hide_viewers_list === 0
    || ALLOWED_WEBINAR_ROLES.includes(selectMyAttendee(state).role));

  const filteredAttendees = useMemo(() => {
    let filtered;
    if (showViewersList) {
      filtered = attendees;
    } else {
      filtered = _.filter(
        attendees,
        (attendee) => ALLOWED_WEBINAR_ROLES.includes(attendee.role) && isAttendeeActive(attendee),
      );
    }
    return filtered;
  }, [attendees, showViewersList]);

  const handleTabChange = (event, newValue) => {
    dispatch(inboxTabChange(newValue));
  };

  return (
    <>
      <Tabs
        className={classes.tabs}
        variant="fullWidth"
        value={chatEnabled ? tabValue : 'participants'}
        onChange={handleTabChange}
      >
        {chatEnabled && (
          <Tab
            id="SideTrayTabChat"
            classes={{ labelIcon: classes.labelIcon }}
            label={<FormattedMessage {...messages.TAB_CHAT_LABEL} />}
            icon={(
              <div
                className={
                  classnames(
                    classes.badge,
                    classes.unreadCount,
                    !ucUnReadCount && classes.noVis,
                  )
                }
              >
                {ucUnReadCount}
              </div>
            )}
            value="chat"
          />
        )}
        <Tab
          id="SideTrayTabPeople"
          classes={{ labelIcon: classes.labelIcon }}
          label={<FormattedMessage {...messages.TAB_PEOPLE_LABEL} />}
          value="participants"
          icon={(
            <div className={classnames(classes.badge, !showViewersList && classes.noVis)}>
              {filteredAttendees.length}
            </div>
          )}
        />
      </Tabs>
      {(tabValue === 'chat') && (
        <ChatTab attendees={filteredAttendees} />
      )}
      {tabValue === 'participants' && (
        <PeopleTab attendees={filteredAttendees} />
      )}
    </>
  );
};

export default SideTrayTabs;
