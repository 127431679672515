import _ from 'lodash';
import { select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';

import * as selectors from '../../selectors';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* updateNcsParticipant({ payload }) {
  try {
    const { successCallback, leader, audioId } = payload;

    const myAttendee = yield select(selectors.selectMyAttendee);
    const meeting = yield select(selectors.selectMeeting);
    const user = yield select(selectors.selectUser);

    const conferenceMatch = `sip:${meeting.id}.video.bridge@conference-bridge`;
    let participant = myAttendee.audio_participant_match;

    if (!participant || audioId) {
      const aId = audioId || myAttendee.audio_id;

      try {
        const participantObj = yield nsApi.get({
          object: 'participant',
          action: 'read',
          conference_match: conferenceMatch,
          user: user.userId,
          domain: user.domain,
          audioId: aId,
        });

        if (participantObj && participantObj[0] && participantObj[0].participant_match) {
          participant = participantObj[0].participant_match;
        }
      } catch (e) {
        console.warn(e);
      }
    }

    if (!participant) return;
    const toUpdate = {
      participant,
      conference_match: conferenceMatch,
      uid: user.uid,
    };
    if (leader) {
      toUpdate.leader = leader;
      toUpdate.keep = 'yes';
      if (myAttendee.attendee_id) toUpdate.attendee_id = myAttendee.attendee_id;
    }

    yield nsApi.post({
      object: 'participant',
      action: 'update',
    }, toUpdate);

    if (_.isFunction(successCallback)) {
      successCallback();
    }
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: updateNcsParticipant';
      event.addMetadata('payload', payload);
    });
  }
}
