export const ITEM_GROUP_TYPE_EMAIL = 'ITEM_GROUP_TYPE_EMAIL';
export const ITEM_TYPE_EMAIL_GUEST = 'ITEM_TYPE_EMAIL_GUEST';
export const ITEM_TYPE_EMAIL_OFF_NET = 'ITEM_TYPE_EMAIL_OFF_NET';
export const ITEM_TYPE_EMAIL_ON_NET = 'ITEM_TYPE_EMAIL_ON_NET';

export const ITEM_TYPE_INVALID = 'ITEM_TYPE_INVALID';

export const ITEM_GROUP_TYPE_NET = 'ITEM_GROUP_TYPE_NET';
export const ITEM_TYPE_ON_NET = 'ITEM_TYPE_ON_NET';

export const ITEM_GROUP_TYPE_PHONE = 'ITEM_GROUP_TYPE_PHONE';
export const ITEM_TYPE_PHONE_GUEST = 'ITEM_TYPE_PHONE_GUEST';
export const ITEM_TYPE_PHONE_CELL = 'ITEM_TYPE_PHONE_CELL';
export const ITEM_TYPE_PHONE_HOME = 'ITEM_TYPE_PHONE_HOME';
export const ITEM_TYPE_PHONE_WORK = 'ITEM_TYPE_PHONE_WORK';
