import React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { makeStyles, Button } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import * as selectors from '../../selectors';
import { showPendingPermissionsDialog } from '../../actions';

const messages = defineMessages({
  ALLOW_CAMERA_AND_MICROPHONE: {
    id: 'ALLOW_CAMERA_AND_MICROPHONE',
    defaultMessage: 'Allow us to use your camera and microphone',
  },
  ALLOW_CAMERA_AND_MICROPHONE_DETAILS: {
    id: 'ALLOW_CAMERA_AND_MICROPHONE_DETAILS',
    defaultMessage: 'Your camera and microphone are needed so others in the meeting can see and hear you. Please grant access by clicking allow in the browser prompt.',
  },
  DISMISS: {
    id: 'DISMISS',
    defaultMessage: 'DISMISS',
  },
});

const useStyles = makeStyles((theme) => ({
  content: {
    width: 500,
    marginBottom: theme.spacing(2),
  },
}));

const PendingPermissionsDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => selectors.selectLayout(state).showPendingPermissionsDialog);

  const handleDismiss = () => {
    dispatch(showPendingPermissionsDialog(false));
  };

  return (
    <Dialog id="PendingPermissionsDialog" open={!!isOpen}>
      <DialogTitle>
        <FormattedMessage {...messages.ALLOW_CAMERA_AND_MICROPHONE} />
      </DialogTitle>

      <DialogContent>
        <DialogContentText className={classes.content}>
          <FormattedMessage {...messages.ALLOW_CAMERA_AND_MICROPHONE_DETAILS} />
        </DialogContentText>
      </DialogContent>

      {_.get(isOpen, 'dismiss') && (
        <DialogActions className={classes.dialogActions}>
          <Button
            id="PendingPermissionsDismiss"
            color="primary"
            variant="contained"
            onClick={handleDismiss}
          >
            <FormattedMessage {...messages.DISMISS} />
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default PendingPermissionsDialog;
