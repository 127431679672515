import _ from 'lodash';
import { put, select } from 'redux-saga/effects';
import {
  setVideoGrid,
  setVideoDynamicSpeakerCooldown,
  setVideoTempDynamicSpeaker,
  setTempVideoGrid,
} from '../../actions';

import { getGridStatusId } from '../../utils';

import * as events from '../../events';

import {
  selectDynamicSpeaker,
  selectTempDynamicSpeaker,
  selectDynamicSpeakerCooldown,
  selectParticipants,
  selectVideoGridIds,
  selectVideoLayout,
  selectAllSpeakerActivity,
  selectTempVideoGrid,
} from '../../selectors';

import {
  LAYOUT_TYPE_CONVERSATION,
  LAYOUT_TYPE_SPOTLIGHT,
  LAYOUT_TYPE_GRID,
} from '../../constants';
import bugsnagClient from '../../bugsnag';

let lastActiveParticipantsIds = [];

// eslint-disable-next-line import/prefer-default-export
export function* updateSpeakerOrder({ payload }) {
  try {
    const selectDynamicSpeakerToggle = yield select(selectDynamicSpeaker);
    if (!selectDynamicSpeakerToggle) {
      return;
    }

    // new energy came, update the various(check for map and update the grid)
    const gridIds1 = yield select(selectVideoGridIds);
    if (_.isEmpty(gridIds1)) {
      return;
    }
    let gridIds = [...gridIds1];

    const participants = yield select(selectParticipants);

    const layout = yield select(selectVideoLayout);

    // get the screensharing participant (ssPartId)
    let ssPartId;
    Object.values(participants).forEach((participant) => {
      if (participant.type === 'screenShare') {
        ssPartId = participant.id;
      }
    });

    if (layout === 'grid') {
      // use tempgrid for grid layout, but default to normal grid when not set
      const tempGrid1 = yield select(selectTempVideoGrid);
      gridIds = [...tempGrid1];

      // if temp hasnt been made yet, or it is of a different size (participants need to be added)
      if (_.isEmpty(gridIds) || gridIds1.length !== gridIds.length) {
        gridIds = [...gridIds1];
      }

      // if grid, save the first and second speaker order
      // then save a temporary grid to switch to
      if (!payload.data.map_audioIds) {
        return;
      }

      if (!payload?.data?.map_users) {
        return;
      }

      const energyMapAudioIds = payload.data.map_audioIds.split(','); // '2500vb,1123vb'
      if (!energyMapAudioIds) {
        return;
      }

      const beforeId = getGridStatusId(gridIds, 1);
      const newOrderedGridIds = [];
      const speakerActivity = yield select(selectAllSpeakerActivity);

      let someoneChanged = false;
      // move top two speakers up to front of array (so start at 1 and go down to including 0)
      for (let e = 1; e >= 0; e--) { // eslint-disable-line no-plusplus
        if (energyMapAudioIds[e] === undefined) {
          continue; // eslint-disable-line no-continue
        }

        energyMapAudioIds[e] = energyMapAudioIds[e].replace('vb', '');
        energyMapAudioIds[e] = energyMapAudioIds[e].replace(/"/g, '');
        energyMapAudioIds[e] = energyMapAudioIds[e].replace(/,/g, '');

        for (let i = 0; i < gridIds.length; i += 1) { // eslint-disable-line no-plusplus
          // put screenshare always on the front of the grid
          if (_.get(participants, [gridIds[i], 'audioId'], null) !== null
            && _.get(participants, [gridIds[i], 'audioId'], '').toString() === energyMapAudioIds[e]
          ) {
            // if matches, split this participant out of original grid, and add to the new grid
            const partToAddId = gridIds[i];
            // only change the grid if the user is actually speaking
            // in order to preserve the previous order
            // also should NOT shift if the found user is already in one of the top two spots
            if (_.get(speakerActivity, [partToAddId, 'isActive'])) {
              newOrderedGridIds.unshift(partToAddId);
              gridIds.splice(i, 1);
              someoneChanged = true;
            }
          }
        }
      }

      // add back the rest of the grid (not the top two)
      for (let i = 0; i < gridIds.length; i += 1) {
        newOrderedGridIds.push(gridIds[i]);
      }

      const tempActiveSpeaker = yield select(selectTempDynamicSpeaker);

      // setVideoTempDynamicSpeaker
      if (tempActiveSpeaker === '' || tempActiveSpeaker !== getGridStatusId(newOrderedGridIds, 1)) {
        yield put(setVideoTempDynamicSpeaker(getGridStatusId(newOrderedGridIds, 1)));
        // set the cool down time (which is when this person became the top spot)
        const nowStartCountdown = new Date();
        yield put(setVideoDynamicSpeakerCooldown(nowStartCountdown));
      }

      if ((beforeId !== getGridStatusId(newOrderedGridIds, 1) && someoneChanged)) {
        // should always set a new update time
        yield put(setTempVideoGrid(_.uniq(newOrderedGridIds)));
        yield put(events.setProfiles(LAYOUT_TYPE_GRID));
      }
    } else if (!selectDynamicSpeakerToggle && ssPartId) {
      // there is a screenshare happening, place that user in front of grid
      // if screenshare is already in the front, do nothing
      if (_.get(gridIds, '0') && _.get(participants, [gridIds[0], 'type']) !== 'screenShare') {
        // create new grid, with screensharing boosted to front
        const newOrderedGridIds = [];
        const beforeId = getGridStatusId(gridIds, 1);

        for (let i = 0; i < gridIds.length; i += 1) {
          if (gridIds[i] === ssPartId) {
            // if matches, split this participant out of original grid, and add to the new grid
            const partToAddId = gridIds[i];
            newOrderedGridIds.unshift(partToAddId);
            gridIds.splice(i, 1);
          }
        }

        // add back the rest of the grid (not the top two)
        for (let i = 0; i < gridIds.length; i += 1) {
          newOrderedGridIds.push(gridIds[i]);
        }

        if (beforeId !== getGridStatusId(newOrderedGridIds, 1)) {
          yield put(setVideoGrid(_.uniq(newOrderedGridIds)));
        }
      }
    } else if (['conversation', 'spotlight'].includes(layout)
      && ssPartId
      && selectDynamicSpeakerToggle
    ) {
      // if there is a screenshare, move the top person to the second slot only.
      // create new grid, with highestAudioId boosted to front
      const newOrderedGridIds = [];
      if (!payload?.data?.map_audioIds) {
        return;
      }

      const energyMapAudioIds = payload.data.map_audioIds.split(','); // '2500vb,1123vb'
      if (!_.get(energyMapAudioIds, '0')) {
        return;
      }

      let highestAudioId = energyMapAudioIds[0].replace('vb', '');
      highestAudioId = highestAudioId.replace(/"/g, '');
      highestAudioId = highestAudioId.replace('+', '');
      const beforeId = getGridStatusId(gridIds, 1);
      const speakerActivity = yield select(selectAllSpeakerActivity);

      for (let i = 0; i < gridIds.length; i += 1) { // eslint-disable-line no-plusplus
        if (_.get(participants, [gridIds[i], 'audioId'], null) !== null
          && _.get(participants, [gridIds[i], 'audioId'], '').toString() === highestAudioId
          && _.get(participants, [gridIds[i], 'type']) !== 'screenShare'
        ) {
          // if matches, split this participant out of original grid, and add to the new grid
          // only change the grid if the user is actually speaking
          // in order to preserve the previous order
          const partToAddId = gridIds[i];
          if (_.get(speakerActivity, [partToAddId, 'isActive'])) {
            newOrderedGridIds.unshift(partToAddId);
            gridIds.splice(i, 1);
          }
        }
      }

      // add the ssPartId to the front now
      for (let i = 0; i < gridIds.length; i += 1) { // eslint-disable-line no-plusplus
        if (gridIds[i] === ssPartId) {
          // if matches, split this participant out of original grid, and add to the new grid
          // only change the grid if the user is actually speaking
          // in order to preserve the previous order
          const partToAddId = gridIds[i];
          newOrderedGridIds.unshift(partToAddId);
          gridIds.splice(i, 1);
        }
      }

      // add back the rest of the grid (not the top two)
      for (let i = 0; i < gridIds.length; i += 1) { // eslint-disable-line no-plusplus
        newOrderedGridIds.push(gridIds[i]);
      }

      const tempActiveSpeaker = yield select(selectTempDynamicSpeaker);

      // setVideoTempDynamicSpeaker
      if (tempActiveSpeaker === '' || tempActiveSpeaker !== getGridStatusId(newOrderedGridIds, 1)) {
        yield put(setVideoTempDynamicSpeaker(getGridStatusId(newOrderedGridIds, 1)));
        // set the cool down time (which is when this person became the top spot)
        const nowStartCountdown = new Date();
        yield put(setVideoDynamicSpeakerCooldown(nowStartCountdown));
      }

      // check time of the last stable tempSpeaker switch
      const lastUpdate = yield select(selectDynamicSpeakerCooldown);
      const now = new Date();
      const luPlusCooldown = new Date(lastUpdate);
      const cd = yield select((state) => state.configs.videoSpeechDetectInterval) || 2;
      luPlusCooldown.setSeconds(parseFloat(luPlusCooldown.getSeconds()) + parseFloat(cd));

      if (beforeId !== getGridStatusId(newOrderedGridIds, 1)
        && ((luPlusCooldown < now) || typeof luPlusCooldown === 'undefined' || lastUpdate === '')) {
        yield put(setVideoDynamicSpeakerCooldown(now));
        yield put(setVideoGrid(_.uniq(newOrderedGridIds)));
        yield put(events.setProfiles(LAYOUT_TYPE_CONVERSATION));
      }
    } else if (layout === 'conversation' && !ssPartId && selectDynamicSpeakerToggle) {
      // normal conversation layout
      if (!payload?.data?.map_audioIds) {
        return;
      }

      // if lastActiveParticipantsIds is empty, populate with first two grid items
      if (lastActiveParticipantsIds.length < 2) {
        lastActiveParticipantsIds = [];

        if (_.get(gridIds, '0')) {
          lastActiveParticipantsIds.push(gridIds[0]);
        }

        if (_.get(gridIds, '1')) {
          lastActiveParticipantsIds.push(gridIds[1]);
        }
      }

      const energyMapAudioIds = payload.data.map_audioIds.split(','); // '2500vb,1123vb'
      if (!_.get(energyMapAudioIds, '0')) {
        return;
      }

      const beforeId = getGridStatusId(gridIds, 1);
      const newOrderedGridIds = [];
      const speakerActivity = yield select(selectAllSpeakerActivity);

      // move top two speakers up to front of array (so start at 1 and go down to including 0)
      for (let e = 1; e >= 0; e--) { // eslint-disable-line no-plusplus
        if (!energyMapAudioIds[e]) {
          continue; // eslint-disable-line no-continue
        }

        energyMapAudioIds[e] = energyMapAudioIds[e].replace('vb', '');
        energyMapAudioIds[e] = energyMapAudioIds[e].replace(/"/g, '');
        energyMapAudioIds[e] = energyMapAudioIds[e].replace(/,/g, '');

        for (let i = 0; i < gridIds.length; i += 1) { // eslint-disable-line no-plusplus
          // put screenshare always on the front of the grid
          if (_.get(participants, [gridIds[i], 'audioId'], null) !== null
            && (_.get(participants, [gridIds[i], 'audioId'], '')).toString() === energyMapAudioIds[e]
          ) {
            // if matches, split this participant out of original grid, and add to the new grid
            const partToAddId = gridIds[i];
            // only change the grid if the user is actually speaking
            // in order to preserve the previous order
            if (_.get(speakerActivity, [partToAddId, 'isActive'])) {
              // update lastActiveParticipants to know which two spoke last
              if (lastActiveParticipantsIds[0] !== partToAddId) {
                lastActiveParticipantsIds = [
                  partToAddId,
                  lastActiveParticipantsIds[0],
                ];
              }

              // should NOT shift if the found user is already in one of the top two spots
              if (i !== 0 && i !== 1) {
                // if grid0 is more stale than grid1, flip the order so grid1 stays in convo
                if (lastActiveParticipantsIds.includes(gridIds[1])) {
                  const gridItemId1 = gridIds.splice(1, 1)[0];
                  gridIds.unshift(gridItemId1);

                  newOrderedGridIds.unshift(partToAddId);
                  gridIds.splice(i, 1);
                } else {
                  newOrderedGridIds.unshift(partToAddId);
                  gridIds.splice(i, 1);

                  newOrderedGridIds.unshift(gridIds[0]);
                  gridIds.splice(0, 1);
                }
              }
            }
          }
        }
      }

      // add back the rest of the grid (not the top two)
      for (let i = 0; i < gridIds.length; i += 1) {
        newOrderedGridIds.push(gridIds[i]);
      }

      const tempActiveSpeaker = yield select(selectTempDynamicSpeaker);

      // setVideoTempDynamicSpeaker
      if (tempActiveSpeaker === '' || tempActiveSpeaker !== getGridStatusId(newOrderedGridIds, 1)) {
        yield put(setVideoTempDynamicSpeaker(getGridStatusId(newOrderedGridIds, 1)));
        // set the cool down time (which is when this person became the top spot)
        const nowStartCountdown = new Date();
        yield put(setVideoDynamicSpeakerCooldown(nowStartCountdown));
      }

      // check time of the last stable tempSpeaker switch
      const lastUpdate = yield select(selectDynamicSpeakerCooldown);
      const now = new Date();
      const luPlusCooldown = new Date(lastUpdate);
      const cd = yield select((state) => state.configs.videoSpeechDetectInterval) || 2;
      luPlusCooldown.setSeconds(parseFloat(luPlusCooldown.getSeconds()) + parseFloat(cd));

      if (beforeId !== getGridStatusId(newOrderedGridIds, 1)
        && ((luPlusCooldown < now) || typeof luPlusCooldown === 'undefined' || lastUpdate === '')) {
        // should always set a new update time
        yield put(setVideoDynamicSpeakerCooldown(now));
        yield put(setVideoGrid(_.uniq(newOrderedGridIds)));
        yield put(events.setProfiles(LAYOUT_TYPE_CONVERSATION));
      }
    } else if (selectDynamicSpeakerToggle) {
      // create new grid, with highestUser boosted to front
      const newOrderedGridIds = [];

      if (!payload?.data?.map_audioIds) {
        return;
      }

      const energyMapAudioIds = payload.data.map_audioIds.split(','); // '2500vb,1123vb'
      if (!_.get(energyMapAudioIds, '0')) {
        return;
      }

      let highestAudioId = energyMapAudioIds[0];
      highestAudioId = highestAudioId.replace(/"/g, '');
      const beforeId = getGridStatusId(gridIds, 0);
      const speakerActivity = yield select(selectAllSpeakerActivity);

      for (let i = 0; i < gridIds.length; i += 1) { // eslint-disable-line no-plusplus
        if ((_.get(participants, [gridIds[i], 'audioId']) === highestAudioId
            || (_.get(participants, [gridIds[i], 'audioId'], null) !== null && _.get(participants, [gridIds[i], 'audioId'], '').toString() === highestAudioId))
          && _.get(participants, [gridIds[i], 'videoPart'], null) === null
        ) {
          // if matches, split this participant out of original grid, and add to the new grid
          // only change the grid if the user is actually speaking
          // in order to preserve the previous order
          const partToAddId = gridIds[i];
          if (_.get(speakerActivity, [partToAddId, 'isActive'])) {
            newOrderedGridIds.unshift(partToAddId);
            gridIds.splice(i, 1);
          }
        }
      }

      // add back the rest of the grid (not the top two)
      for (let i = 0; i < gridIds.length; i += 1) {
        newOrderedGridIds.push(gridIds[i]);
      }

      const tempActiveSpeaker = yield select(selectTempDynamicSpeaker);

      // setVideoTempDynamicSpeaker
      if (tempActiveSpeaker === '' || tempActiveSpeaker !== getGridStatusId(newOrderedGridIds, 0)) {
        yield put(setVideoTempDynamicSpeaker(getGridStatusId(newOrderedGridIds, 0)));
        // set the cool down time (which is when this person became the top spot)
        const nowStartCountdown = new Date();
        yield put(setVideoDynamicSpeakerCooldown(nowStartCountdown));
      }

      // check time of the last stable tempSpeaker switch
      const lastUpdate = yield select(selectDynamicSpeakerCooldown);
      const now = new Date();
      const luPlusCooldown = new Date(lastUpdate);
      const cd = yield select((state) => state.configs.videoSpeechDetectInterval) || 2;
      luPlusCooldown.setSeconds(parseFloat(luPlusCooldown.getSeconds()) + parseFloat(cd));

      if (beforeId !== getGridStatusId(newOrderedGridIds, 0)
        && ((luPlusCooldown < now) || typeof luPlusCooldown === 'undefined' || lastUpdate === '')) {
        yield put(setVideoDynamicSpeakerCooldown(now));
        yield put(setVideoGrid(_.uniq(newOrderedGridIds)));
        yield put(events.setProfiles(LAYOUT_TYPE_SPOTLIGHT));
      }
    }
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: updateSpeakerOrder';
      event.addMetadata('payload', payload);
    });
  }
}
