import { put, select } from 'redux-saga/effects';
import _ from 'lodash';

import { selectAttendeesList, selectConnectedAttendees } from '../../selectors';
import { setConnectedAttendees, setMyAttendee } from '../../actions';
import isAttendeeActive from '../../utils/isAttendeeActive';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* updateAttendee({ payload: attendee }) {
  try {
    const attendeesList = yield select(selectAttendeesList);

    const index = _.findIndex(attendeesList, { attendee_id: attendee.attendee_id });

    // intentionally mutate list
    attendeesList.splice(index, 1, attendee);

    if (attendee.isSelf) {
      yield put(setMyAttendee(attendee));
    }

    // update connected
    const isConnected = isAttendeeActive(attendee);
    const connectedAttendees = yield select(selectConnectedAttendees);
    const connectedAttendee = _.find(connectedAttendees, { id: attendee.id });
    if (isConnected && !connectedAttendee) {
      yield put(setConnectedAttendees([connectedAttendees, attendee]));
    } else if (!isConnected && connectedAttendee) {
      yield put(setConnectedAttendees(_.reject(connectedAttendees, { id: attendee.id })));
    } else {
      yield put(setConnectedAttendees(
        connectedAttendees.map((a) => (a.id === attendee.id ? attendee : a)),
      ));
    }
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: updateAttendee';
      event.addMetadata('payload', attendee);
    });
  }
}
