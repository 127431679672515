import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import MenuItem from '@material-ui/core/MenuItem';
import Person from '@material-ui/icons/Person';
import PersonOff from '@material-ui/icons/PersonAddDisabled';
import * as selectors from '../../../../../selectors';
import * as actions from '../../../../../actions';

/* eslint react/prop-types: 0 */
const messages = defineMessages({
  HIDE_SELF: {
    id: 'HIDE_PEOPLE_WITH_NO_VIDEO',
    defaultMessage: 'Hide myself from my view',
  },
  SHOW_SLEF: {
    id: 'SHOW_SLEF',
    defaultMessage: 'Show myself',
  },
});

const HideSelf = ({
  menuItemKey,
  clickCallback,
  setVideoHideSelfAction,
  toastSelfHidenAction,
  videoHideSelf,
}) => {
  const toggleHideSelf = () => {
    clickCallback();
    setVideoHideSelfAction(!videoHideSelf);
    if (!videoHideSelf) {
      toastSelfHidenAction('TOAST_SELF_HIDDEN');
    }
  };

  return videoHideSelf ? (
    <MenuItem key={menuItemKey} onClick={toggleHideSelf}>
      <Person />
      <FormattedMessage {...messages.SHOW_SLEF} />
    </MenuItem>
  ) : (
    <MenuItem key={menuItemKey} onClick={toggleHideSelf}>
      <PersonOff />
      <FormattedMessage {...messages.HIDE_SELF} />
    </MenuItem>
  );
};

HideSelf.propTypes = {
  menuItemKey: PropTypes.string.isRequired,
  clickCallback: PropTypes.func.isRequired,
  setVideoHideSelfAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  videoHideSelf: selectors.selectVideoHideSelf(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setVideoHideSelfAction: actions.setVideoHideSelf,
  toastSelfHidenAction: actions.snackBarMessage,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HideSelf);
