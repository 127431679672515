export const SET_ROUTE = 'events/SET_ROUTE';

/**
 * @param title
 * @param path
 * @returns {{payload: *, type: string}}
 */
export const setRoute = (title, path) => ({
  type: SET_ROUTE,
  payload: {
    title,
    path,
  },
});
