import PropTypes from 'prop-types';

const shape = {
  meeting_id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  domain: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  role: PropTypes.string,
  uid: PropTypes.string,
  phonenumber: PropTypes.string,
  audio_id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  contact_id: PropTypes.string,
  status: PropTypes.string,
  status_video: PropTypes.string,
  attendee_id: PropTypes.string,
  update_ts: PropTypes.string,
  gravatar: PropTypes.string,
};

const propTypesShape = PropTypes.shape(shape);

export default {
  shape,
  propTypesShape,
  arrayOfShapes: PropTypes.arrayOf(propTypesShape),
};
