import _ from 'lodash';
import { select } from 'redux-saga/effects';
import {
  setVideoDynamicSpeakerCooldownSFU,
} from '../../actions';

import store from '../../store';

import {
  selectDynamicSpeakerCooldownSFU,
  selectAllSpeakerActivity,
  selectMeeting,
  selectMediaStatus,
  selectVideoRoom,
  selectVideoGridIds,
  selectParticipants,
} from '../../selectors';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* notifySfuSpeaker({ payload: res }) {
  try {
    // process for recording
    // always screenshare if it is on. or just whover is spotlight
    const room = yield select(selectVideoRoom);
    const meeting = yield select(selectMeeting);
    if (room && meeting && meeting.record_enabled === 1) {
      const lastUpdateSFU = yield select(selectDynamicSpeakerCooldownSFU);
      const now = new Date();
      const luPlusCooldown = new Date(lastUpdateSFU);
      const cd = yield select((state) => state.configs.videoSpeechDetectInterval) || 2;
      luPlusCooldown.setSeconds(parseFloat(luPlusCooldown.getSeconds()) + parseFloat(cd));

      if ((luPlusCooldown < now) || typeof luPlusCooldown === 'undefined' || lastUpdateSFU === '') {
        // set new time for lastupdateSFU
        store.dispatch(setVideoDynamicSpeakerCooldownSFU(now));
        if (typeof res.data.map_audioIds === 'undefined') {
          return;
        }

        const energyMapAudioIds = res.data.map_audioIds.split(','); // '2500vb,1123vb'
        if (energyMapAudioIds[0] === undefined) {
          return;
        }

        let highestAudioId = energyMapAudioIds[0].replace('vb', '');
        highestAudioId = highestAudioId.replace(/"/g, '');
        highestAudioId = highestAudioId.replace('+', '');

        const mediaStatusPart = yield select(selectMediaStatus);

        const gridIds = yield select(selectVideoGridIds);
        const speakerActivity = yield select(selectAllSpeakerActivity);

        const participants = yield select(selectParticipants);
        const ids = Object.keys(participants);
        for (let i = 0; i < ids.length; i += 1) {
          if (participants[ids[i]].type === 'screenShare') {
            room.notifyActiveSpeaker(participants[ids[i]].id);
            return;
          }
        }

        for (let i = 0; i < gridIds.length; i += 1) { // eslint-disable-line no-plusplus
          if (participants[gridIds[i]].audioId !== null
            && participants[gridIds[i]].audioId.toString() === highestAudioId
          ) {
            const partToAddId = gridIds[i];
            const user = partToAddId.replace(/\D/g, '');

            let isMuted = false;
            if (typeof mediaStatusPart[user] !== 'undefined' && mediaStatusPart[user].audioMuted) {
              isMuted = true;
            }
            if (_.get(speakerActivity, [partToAddId, 'isActive']) && !isMuted) {
              room.notifyActiveSpeaker(partToAddId);
            }
          }
        }
      }
    }
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: notifySfuSpeaker';
      event.addMetadata('payload', res);
    });
  }
}
