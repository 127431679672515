import nsApi from '@netsapiens/netsapiens-js/dist/api';

export default (decodedToken) => new Promise((resolve) => {
  nsApi.getLogo({
    object: 'image',
    action: 'read',
    server: window.location.hostname,
    filename: 'video_login_full_bg.png',
    territory: decodedToken?.territory || '*',
    domain: decodedToken?.domain || '*',
    user: decodedToken?.user || '*',
  }).then((res) => {
    if (res !== 'data:') {
      resolve(res);
    } else {
      nsApi.getLogo({
        object: 'image',
        action: 'read',
        server: window.location.hostname,
        filename: 'video_login_default.png',
        territory: decodedToken?.territory || '*',
        domain: decodedToken?.domain || '*',
        user: decodedToken?.user || '*',
      }).then((logoRes) => {
        resolve(logoRes);
      }).catch(() => {
        resolve(false);
      });
    }
  }).catch(() => {
    nsApi.getLogo({
      object: 'image',
      action: 'read',
      server: window.location.hostname,
      filename: 'video_login_default.png',
      territory: decodedToken?.territory || '*',
      domain: decodedToken?.domain || '*',
      user: decodedToken?.user || '*',
    }).then((logoRes) => {
      resolve(logoRes);
    }).catch(() => {
      resolve(false);
    });
  });
});
