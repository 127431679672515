import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import { selectLoginImage } from '../../selectors';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    zIndex: -1,
    width: '100vw',
    height: '100vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
}));

const LoginImage = ({ loginImage }) => {
  const classes = useStyles();

  if (!loginImage) {
    return null;
  }

  return (
    <div
      className={classes.root}
      style={{ backgroundImage: `url(${loginImage})` }}
    />
  );
};

LoginImage.defaultProps = {
  loginImage: null,
};

LoginImage.propTypes = {
  loginImage: PropTypes.string,
};

const mapStateToProps = (state) => ({
  loginImage: selectLoginImage(state),
});

export default connect(mapStateToProps)(LoginImage);
