import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import _ from 'lodash';
import Moment from 'react-moment';
import classnames from 'classnames';
import { defineMessages, FormattedMessage } from 'react-intl';

const MODULE_NAME = 'MeetingStatusChange';
const loglevel = require('loglevel');

const logger = loglevel.getLogger(MODULE_NAME);

const useStyles = makeStyles(() => ({
  eventText: {
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: 'normal',
    textAlign: 'center',
    fontFamily: 'Roboto',
    color: 'rgba(0,0,0,0.54)',
  },
  specialEvent: {
    lineHeight: '50px',
    backgroundColor: '#ffffff',
  },
  bold: {
    fontWeight: 'bold',
  },
}));

const messages = defineMessages({
  MEETING_STARTED: {
    id: 'MEETING_STARTED',
    defaultMessage: 'Meeting started - ',
  },
  MEETING_ENDED: {
    id: 'MEETING_ENDED',
    defaultMessage: 'Meeting ended - ',
  },
  MEETING_LOCKED: {
    id: 'MEETING_LOCKED',
    defaultMessage: 'Meeting locked.',
  },
  MEETING_UNLOCKED: {
    id: 'MEETING_UNLOCKED',
    defaultMessage: 'Meeting unlocked.',
  },
  RECORDING_START: {
    id: 'RECORDING_START',
    defaultMessage: 'Meeting recording has started.',
  },
  RECORDING_STOP: {
    id: 'RECORDING_STOP',
    defaultMessage: 'Meeting recording has stopped.',
  },
  VIDEO_RESTRICTED: {
    id: 'VIDEO_RESTRICTED',
    defaultMessage: 'Video has been restricted.',
  },
  SCREENSHARE_RESTRICTED: {
    id: 'SCREENSHARE_RESTRICTED',
    defaultMessage: 'Screenshare has been restricted.',
  },
  ACCESS_RESTRICTED: {
    id: 'ACCESS_RESTRICTED',
    defaultMessage: 'Access has been restricted.',
  },
  UNABLE_TO_PARSE: {
    id: 'UNABLE_TO_PARSE',
    defaultMessage: 'Unable to parse meeting event.',
  },
  LEFT_MEETING: {
    id: 'LEFT_MEETING',
    defaultMessage: 'left the meeting. -',
  },
  JOINED_MEETING: {
    id: 'JOINED_MEETING',
    defaultMessage: 'joined the meeting. -',
  },
  PART_WAIT_YOU: {
    id: 'PART_WAIT_YOU',
    defaultMessage: 'are waiting to join the meeting. -',
  },
  PART_WAIT: {
    id: 'PART_WAIT',
    defaultMessage: 'is waiting to join the meeting. -',
  },
  MISSING_EVENT_TYPE: {
    id: 'MISSING_EVENT_TYPE',
    defaultMessage: 'Missing meeting event type -',
  },
  YOU: {
    id: 'YOU',
    defaultMessage: 'You',
  },
});

const MeetingStatusChange = ({
  event,
  details,
  timestamp,
  myAttendeeId,
  scrollToBottom,
}) => {
  const classes = useStyles();

  useEffect(() => scrollToBottom(), []);

  // keep timestamp in the past my minus 10 seconds
  const dateMinus10 = new Date(timestamp);
  dateMinus10.setSeconds(dateMinus10.getSeconds() - 10);
  const newDate = new Date(`${dateMinus10} GMT`);

  if (event === 'meeting_start') {
    return (
      <div className={classnames(classes.eventText, classes.specialEvent)}>
        <span className={classes.bold}>
          <FormattedMessage {...messages.MEETING_STARTED} />
        </span>
        <span>
          &nbsp;
          <Moment fromNow>{newDate}</Moment>
        </span>
      </div>
    );
  }

  if (event === 'meeting_completed' || event === 'meeting_end') {
    return (
      <div className={classnames(classes.eventText, classes.specialEvent)}>
        <span className={classes.bold}>
          <FormattedMessage {...messages.MEETING_ENDED} />
        </span>
        <span>
          &nbsp;
          <Moment fromNow>{newDate}</Moment>
        </span>
      </div>
    );
  }

  if (event === 'meeting_locked') {
    return (
      <div className={classes.eventText}>
        <span className={classes.bold}>
          <FormattedMessage {...messages.MEETING_LOCKED} />
        </span>
      </div>
    );
  }

  if (event === 'meeting_unlocked') {
    return (
      <div className={classes.eventText}>
        <span className={classes.bold}>
          <FormattedMessage {...messages.MEETING_UNLOCKED} />
        </span>
      </div>
    );
  }

  if (event === 'recording_start') {
    return (
      <div className={classes.eventText}>
        <span className={classes.bold}>
          <FormattedMessage {...messages.RECORDING_START} />
        </span>
      </div>
    );
  }

  if (event === 'recording_stop') {
    return (
      <div className={classes.eventText}>
        <span className={classes.bold}>
          <FormattedMessage {...messages.RECORDING_STOP} />
        </span>
      </div>
    );
  }

  if (event === 'video_restricted') {
    return (
      <div className={classes.eventText}>
        <span className={classes.bold}>
          <FormattedMessage {...messages.VIDEO_RESTRICTED} />
        </span>
      </div>
    );
  }

  if (event === 'screenshare_restrict') {
    return (
      <div className={classes.eventText}>
        <span className={classes.bold}>
          <FormattedMessage {...messages.SCREENSHARE_RESTRICTED} />
        </span>
      </div>
    );
  }

  if (event === 'access_restricted') {
    return (
      <div className={classes.eventText}>
        <span className={classes.bold}>
          <FormattedMessage {...messages.ACCESS_RESTRICTED} />
        </span>
      </div>
    );
  }

  let detailsClone = _.cloneDeep(details);

  // promotion stubbed out, there is notice modal already
  if (typeof detailsClone === 'string'
    && (detailsClone === 'host' || detailsClone === 'attendee' || detailsClone === 'presenter')) {
    return (
      <>
      </>
    );
  }

  if (typeof detailsClone === 'string' && detailsClone !== '') {
    try {
      // JSON.stringify(detailsClone);
      detailsClone = JSON.parse(detailsClone);
    } catch (e) {
      logger.error('failed parse meeting event: %o', e);
      logger.error('failed parse meeting event: %o', detailsClone);
      return (
        <>
        </>
      );
    }
  }

  if (event === 'part_depart') {
    return (
      <div className={classes.eventText} title={newDate}>
        <span className={classes.bold}>
          {typeof detailsClone.attendee_id !== 'undefined' && detailsClone.attendee_id === myAttendeeId
            ? <FormattedMessage {...messages.YOU} />
            : detailsClone.name}
        </span>
        <span>
          &nbsp;
          <FormattedMessage {...messages.LEFT_MEETING} />
          &nbsp;
          <Moment fromNow>{newDate}</Moment>
        </span>
      </div>
    );
  }

  if (event === 'part_join') {
    return (
      <div className={classes.eventText} title={newDate}>
        <span className={classes.bold}>
          {typeof detailsClone.attendee_id !== 'undefined' && detailsClone.attendee_id === myAttendeeId
            ? <FormattedMessage {...messages.YOU} />
            : detailsClone.name}
        </span>
        <span>
          &nbsp;
          <FormattedMessage {...messages.JOINED_MEETING} />
          &nbsp;
          <Moment fromNow>{newDate}</Moment>
        </span>
      </div>
    );
  }

  if (event === 'part_wait') {
    return (
      <div className={classes.eventText} title={newDate}>
        <span className={classes.bold}>
          {typeof detailsClone.attendee_id !== 'undefined' && detailsClone.attendee_id === myAttendeeId
            ? <FormattedMessage {...messages.YOU} />
            : detailsClone.name}
        </span>
        <span>
          &nbsp;
          {typeof detailsClone.attendee_id !== 'undefined' && detailsClone.attendee_id === myAttendeeId
            ? <FormattedMessage {...messages.PART_WAIT_YOU} />
            : <FormattedMessage {...messages.PART_WAIT} />}
          &nbsp;
          <Moment fromNow>{newDate}</Moment>
        </span>
      </div>
    );
  }

  // default response
  return (
    <>
    </>
  );
};

MeetingStatusChange.defaultProps = {
  details: '',
};

MeetingStatusChange.propTypes = {
  event: PropTypes.string.isRequired,
  details: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  timestamp: PropTypes.string.isRequired,
  myAttendeeId: PropTypes.string.isRequired,
  scrollToBottom: PropTypes.func.isRequired,
};

export default React.memo(MeetingStatusChange);
