/* eslint-disable camelcase */
import crypto from 'crypto-browserify';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import UserModel from '@netsapiens/netsapiens-js/dist/models/user/User';
import _ from 'lodash';

import bugsnagClient from '../../../bugsnag';
import getInitials from '../../../utils/getInitials';

export default ({
  displayName,
  domain,
  sub,
  user,
  user_email = '',
  user_scope,
  userId,
}) => new Promise((resolve) => {
  bugsnagClient.user = {
    domain,
    id: sub,
    user,
    userId,
    scope: user_scope,
  };
  if (user_scope !== 'guest' && user_scope !== 'Meeting Guest') {
    const userFields = [
      'dial_plan',
      'domain',
      'first_name',
      'email',
      'last_name',
      'language',
      'scope',
      'subscriber_login',
      'user',
      'initials',
      'time_zone',
    ];

    nsApi.get({
      user,
      domain,
      object: 'subscriber',
      action: 'read',
      fields: userFields.join(','),
      format: 'json',
      includeDomain: 'yes',
    }).then((res) => {
      const resUser = res[0];

      if (!resUser || _.isEmpty(resUser)) {
        resolve(false);
      }

      if (resUser.email !== '') {
        resUser.email = (resUser.email || []).trim().toLowerCase().split(';');
      }

      const User = new UserModel({
        dialPlan: resUser.dial_plan,
        displayName,
        domain,
        email: resUser.email,
        firstName: resUser.first_name,
        initials: getInitials(displayName),
        isSelf: true,
        language: resUser.language,
        lastName: resUser.last_name,
        scope: user_scope,
        subscriberLogin: resUser.subscriber_login,
        uid: sub,
        user: resUser,
        userId,
        timeZone: resUser.time_zone,
      });

      if (User.email) {
        User.gravatar = crypto.createHash('md5').update(User.email[0]).digest('hex');
        resolve(User);
      } else {
        resolve(User);
      }
    });
  } else {
    const User = new UserModel({
      displayName,
      domain,
      initials: getInitials(displayName),
      isSelf: true,
      scope: user_scope,
      uid: `${userId}@${domain}`,
      userId,
    });

    if (user_email) {
      User.email = [user_email];
    }

    if (User.email) {
      if (!_.isArray(User.email)) {
        User.email = [User.email];
      }

      User.gravatar = crypto.createHash('md5').update(User.email[0]).digest('hex');
    }

    resolve(User);
  }
});
