import { all, takeEvery } from 'redux-saga/effects';

import * as events from '../events';
import * as sagas from './event-sagas';
import initRoom from './init-sagas/initRoom';
import bugsnagClient from '../bugsnag';

export default function* rootSaga() {
  try {
    yield all([
      // init
      yield takeEvery(events.START_APP, sagas.preLogin),
      yield takeEvery(events.CONF_SETTINGS_SUBMITTED, sagas.joinConf),
      yield takeEvery(events.INIT_CONFERENCE, sagas.initConference),
      yield takeEvery(events.INIT_ROOM, initRoom),
      yield takeEvery(events.USER_AUTHENTICATED, sagas.postLogin),
      yield takeEvery(events.PROCESS_ROUTE, sagas.processRoute),
      yield takeEvery(events.SET_ROUTE, sagas.setRoute),

      // authentication
      yield takeEvery(events.AUTHENTICATE_GUEST, sagas.authenticateGuest),
      yield takeEvery(events.AUTHENTICATE_LANDING, sagas.authenticateLanding),
      yield takeEvery(events.AUTHENTICATE_USER, sagas.authenticateUser),
      yield takeEvery(events.LOGOUT_USER, sagas.logoutUser),
      yield takeEvery(events.MFA_SUBMIT, sagas.mfaSubmit),
      yield takeEvery(events.SHOW_LOGIN_FORM_PAGE, sagas.showLoginFormPage),
      yield takeEvery(events.SHOW_LANDING_FORM_PAGE, sagas.showLandingFormPage),
      yield takeEvery(events.SHOW_REGISTER_FORM_PAGE, sagas.showRegisterFormPage),
      yield takeEvery(events.SHOW_REGISTER_SUCCESS_PAGE, sagas.showRegisterSuccessPage),
      yield takeEvery(events.SUBMIT_REGISTRATION, sagas.submitRegistration),
      yield takeEvery(events.SHOW_MFA_FORM_PAGE, sagas.showMfaFormPage),
      yield takeEvery(events.SHOW_SSO_ENROLL_PAGE, sagas.showSsoEnrollPage),
      yield takeEvery(events.SSO_ENROLL_SUBMIT, sagas.ssoEnrollSubmit),
      yield takeEvery(events.SSO_LOGIN_USER, sagas.ssoLoginUser),
      yield takeEvery(events.SUBSCRIBER_SELECT, sagas.subscriberSelect),

      // conf id form
      yield takeEvery(events.JOIN_CONFERENCE, sagas.joinConference),
      yield takeEvery(events.NEW_CONFERENCE, sagas.newConference),

      // configuration & settings
      yield takeEvery(events.INIT_MEDIA_STREAMS, sagas.initMediaStreams),
      yield takeEvery(events.SET_MEETING, sagas.setMeeting),
      yield takeEvery(events.PARTIAL_MEETING_UPDATE, sagas.partialMeetingUpdate),
      yield takeEvery(events.SHOW_MEETING_CONFIG, sagas.showMeetingConfig),
      yield takeEvery(events.SHOW_MEETING_CONFIG_BYPASS, sagas.showMeetingConfigBypass),
      yield takeEvery(events.SHOW_MEETING_HUB_PAGE, sagas.showMeetingHubPage),
      yield takeEvery(events.SHOW_MEETINGS_SCROLL_PAGE, sagas.showMeetingsScrollPage),
      yield takeEvery(events.READ_MORE_MEETINGS, sagas.readMoreMeetings),
      yield takeEvery(events.SAVE_MEETING, sagas.saveMeeting),
      yield takeEvery(events.DELETE_MEETING, sagas.deleteMeeting),
      yield takeEvery(
        events.OPEN_DEVICE_RECONFIG_MODAL,
        sagas.recheckMediaPermissions,
        sagas.openDeviceReconfigModal,
      ),
      yield takeEvery(events.SAVE_SELECTED_DEVICES, sagas.saveSelectedDevices),

      // header
      yield takeEvery(events.END_CALL, sagas.endCall),
      yield takeEvery(events.END_MEETING, sagas.endMeeting),
      yield takeEvery(events.SHOW_ADD_USER, sagas.showAddUser),
      // eslint-disable-next-line max-len
      yield takeEvery(events.TOGGLE_AUDIO_MUTE, sagas.recheckMediaPermissions, sagas.toggleAudioMute),
      yield takeEvery(events.UPDATE_AUDIO_STATUS, sagas.updateAudioStatus),
      yield takeEvery(events.TOGGLE_VIDEO_ITEM_FOCUS, sagas.toggleVideoItemFocus),
      // eslint-disable-next-line max-len
      yield takeEvery(events.TOGGLE_VIDEO_MUTE, sagas.recheckMediaPermissions, sagas.toggleVideoMute),
      yield takeEvery(events.CYCLE_VIDEO_DEVICE, sagas.cycleVideoDevices),
      yield takeEvery(events.SET_LAYOUT, sagas.setLayout),
      yield takeEvery(events.SET_PROFILES, sagas.setProfiles),
      yield takeEvery(events.START_SCREENSHARE, sagas.startScreenShare),
      yield takeEvery(events.END_SCREENSHARE, sagas.endScreenShare),
      yield takeEvery(events.END_AUDIO_BRIDGE, sagas.endAudioBridge),

      // video controls
      yield takeEvery(events.POST_MEETING_STATUS, sagas.postMeetingStatus),
      yield takeEvery(events.POST_VIDEO_STATUS, sagas.postVideoStatus),
      yield takeEvery(events.TOGGLE_VIDEO_STAT, sagas.toggleVideoStat),
      yield takeEvery(events.POST_WEBKIT_ACTION, sagas.postWebkitAction),

      // chat/people tabs
      yield takeEvery(events.INBOX_TAB_CHANGE, sagas.inboxTabValue),
      yield takeEvery(events.RESTRICT_ATTENDEE, sagas.restrictAttendee),
      yield takeEvery(events.RESTRICT_ATTENDEE_MULTI, sagas.restrictAttendeeMulti),
      yield takeEvery(events.SEND_CHAT_MESSAGE, sagas.sendChatMessage),
      yield takeEvery(events.DELETE_CHAT_MESSAGE, sagas.deleteChatMessage),
      yield takeEvery(events.UPDATE_SESSION_TO_READ, sagas.updateSessionToRead),
      yield takeEvery(events.DELETE_CHAT_SESSION, sagas.deleteChatSession),
      yield takeEvery(events.SET_ATTENDEES, sagas.setAttendees),
      yield takeEvery(events.CREATE_UC_ITEM, sagas.createUcItem),
      yield takeEvery(events.TOGGLE_UC_INBOX, sagas.toggleUcInbox),
      yield takeEvery(events.NEW_PRIVATE_CHAT, sagas.newPrivateChat),

      // chat attachments
      yield takeEvery(events.ADD_CHAT_ATTACHMENT, sagas.addChatAttachment),
      yield takeEvery(events.REMOVE_CHAT_ATTACHMENT, sagas.removeChatAttachment),
      yield takeEvery(events.SEND_CHAT_ATTACHMENTS, sagas.sendChatAttachments),

      // invite user
      yield takeEvery(events.CHAT_SETUP, sagas.chatSetup),
      yield takeEvery(events.EMIT_VIDEO_INVITE, sagas.emitVideoInvite),

      // attendees/participants
      yield takeEvery(events.ADD_PARTICIPANT, sagas.addParticipant),
      yield takeEvery(events.CHANGE_ATTENDEE_ROLE, sagas.changeAttendeeRole),
      yield takeEvery(events.UPDATE_ACTIVE_STATUS, sagas.updateActiveStatus),
      yield takeEvery(events.UPDATE_ATTENDEE, sagas.updateAttendee),
      yield takeEvery(events.UPDATE_PARTICIPANT, sagas.updateParticipant),
      yield takeEvery(events.UPDATE_SPEAKER_ORDER, sagas.updateSpeakerOrder),
      yield takeEvery(events.NOTIFY_SFU_SPEAKER, sagas.notifySfuSpeaker),
      yield takeEvery(events.REMOVE_PARTICIPANT, sagas.removeParticipant),

      yield takeEvery(events.UPDATE_NCS_PARTICIPANT, sagas.updateNcsParticipant),

      yield takeEvery(events.UPDATE_CONSUMER, sagas.updateConsumer),
    ]);
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: rootSaga';
    });
  }
}
