import React, { useMemo } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';

import LayoutContainer from './components/LayoutContainer';
import ParticipantsGrid from './components/ParticipantsGrid';

import { LAYOUT_TYPE_CONVERSATION } from '../../../../constants';
import {
  selectParticipants, selectVideoGridIds, selectVideoMuteHide, selectVideoHideSelf,
} from '../../../../selectors';

const useStyles = makeStyles(() => ({
  fullHeight: {
    height: '100%',
  },
  halfHeight: {
    height: '50%',
  },
}));

const ConversationLayout = () => {
  const classes = useStyles();
  const videoGridIds = useSelector(selectVideoGridIds);
  const participants = useSelector(selectParticipants);
  const muteHide = useSelector(selectVideoMuteHide);
  const hideSelf = useSelector(selectVideoHideSelf);

  const filteredIdsArr = useMemo(() => {
    let filtered = videoGridIds;

    if (muteHide) {
      filtered = filtered.filter(
        (id) => _.get(participants, [id, 'videoTrackMuted']) !== true,
      );
    }

    if (hideSelf) {
      filtered = filtered.filter(
        (id) => _.get(participants, [id, 'isSelf']) !== true,
      );
    }

    if (filtered.length > 2) {
      return _.take(
        [filtered.slice(0, 2), filtered.slice(2)],
        23,
      );
    }

    return [filtered];
  }, [videoGridIds, muteHide, hideSelf]);

  return (
    <LayoutContainer>
      {filteredIdsArr.length === 2 ? (
        <Grid container justifyContent="center" alignContent="center" className={classes.fullHeight}>
          <Grid item xs={12} className={classes.halfHeight}>
            <ParticipantsGrid
              participantIds={filteredIdsArr[0]}
              alignContent="flex-end"
              layout={LAYOUT_TYPE_CONVERSATION}
            />
          </Grid>
          <Grid item xs={12} className={classes.halfHeight}>
            <ParticipantsGrid
              participantIds={filteredIdsArr[1]}
              minCols={3}
              alignContent="flex-start"
              layout={LAYOUT_TYPE_CONVERSATION}
            />
          </Grid>
        </Grid>
      ) : (
        // force basic grid view if less than 3 participants
        <ParticipantsGrid participantIds={filteredIdsArr[0]} />
      )}
    </LayoutContainer>
  );
};

export default ConversationLayout;
