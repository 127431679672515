import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import Fullscreen from '@material-ui/icons/Fullscreen';
import FullscreenExit from '@material-ui/icons/FullscreenExit';

import ControlItem from './ControlItem';

import { LAYOUT_TYPE_CONVERSATION, LAYOUT_TYPE_SPOTLIGHT } from '../../../../constants';

import { toggleVideoItemFocus } from '../../../../events';
import * as selectors from '../../../../selectors';

import participantShape from '../../../../shapes/participant';

const messages = defineMessages({
  ENLARGE: {
    id: 'ENLARGE',
    defaultMessage: 'Enlarge',
  },
  SHRINK: {
    id: 'SHRINK',
    defaultMessage: 'Shrink',
  },
});

const EnlargeControl = ({
  type,
  variant,
  participant,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const myAttendeeRole = useSelector((state) => _.get(selectors.selectMyAttendee(state), 'role'));
  const meetingType = useSelector((state) => _.get(selectors.selectMeeting(state), 'type'));
  const participants = useSelector(selectors.selectParticipants);
  const layout = useSelector(selectors.selectVideoLayout);
  const room = useSelector(selectors.selectVideoRoom);

  const firstGridItemId = useSelector((state) => _.get(selectors.selectVideoGridIds(state), '0'));
  const isPartFirst = participant.id === firstGridItemId;

  const secondGridItemId = useSelector((state) => _.get(selectors.selectVideoGridIds(state), '1'));
  const isPartSecond = participant.id === secondGridItemId;

  // hide control for attendees in presentations
  if (meetingType === 'presentation' && myAttendeeRole === 'attendee') {
    return '';
  }

  // hide control for conversation orbiters, substituted with 'move up' control
  if (layout === LAYOUT_TYPE_CONVERSATION && !isPartFirst && !isPartSecond) {
    return '';
  }

  const isToggled = layout === LAYOUT_TYPE_SPOTLIGHT
    && isPartFirst && participant.type === _.get(participants, [firstGridItemId, 'type']);

  const findConsumerId = () => {
    const filterConsumerId = [...room.consumers.keys()].filter((consumerId) => {
      const consumer = room.consumers.get(consumerId);
      if (!consumer) { return null; }
      if (consumer.appData.share) {
        return (participant.id === `screenShare_${consumer.appData.user.userId}`);
      }
      return (participant.id === `video_${consumer.appData.user.userId}`);
    });

    return filterConsumerId[0];
  };

  const onToggle = () => {
    if (room) {
      const consumerId = findConsumerId();
      room.requestKeyFrame(consumerId);
      room.notifyFocus(consumerId);
    }

    dispatch(toggleVideoItemFocus(!isToggled ? participant.id : null));
  };

  return (
    <ControlItem
      type={type}
      variant={variant}
      onClick={onToggle}
      isToggled={isToggled}
      enabledIcon={<Fullscreen />}
      disabledIcon={<FullscreenExit />}
      enabledTooltip={intl.formatMessage(messages.ENLARGE)}
      disabledTooltip={intl.formatMessage(messages.SHRINK)}
    />
  );
};

EnlargeControl.propTypes = {
  type: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  participant: participantShape.propTypesShape.isRequired,
};

export default EnlargeControl;
