import _ from 'lodash';

export const getMediaDevices = () => navigator.mediaDevices.enumerateDevices();

export const getMedia = (constraints) => navigator.mediaDevices.getUserMedia(constraints);

export const getAudioStream = (deviceId) => getMedia({
  audio: { deviceId: deviceId ? { ideal: deviceId } : undefined },
  video: false,
});

export const stopStream = (stream) => {
  if (stream && stream.getTracks()) {
    stream.getTracks().forEach((track) => {
      track.stop();
    });
  }
};

export const muteStreamTracks = (stream) => {
  if (stream && stream.getTracks()) {
    stream.getTracks().forEach((track) => {
      track.enabled = false; // eslint-disable-line no-param-reassign
    });
  }
};

export const getFilteredAudioInput = (devices) => _.filter(devices, (device) => device.deviceId !== 'default' && device.kind === 'audioinput');
export const getAudioDeviceById = (devices, deviceId) => _.get(_.filter(devices, { deviceId, kind: 'audioinput' }), '0');
export const getDefaultAudioInput = (devices) => {
  const previouslySelected = _.get(_.filter(devices, { deviceId: localStorage.getItem('myLastAudioInput'), kind: 'audioinput' }), '0');
  const defaultDevice = _.get(_.filter(devices, { deviceId: 'default', kind: 'audioinput' }), '0');
  return previouslySelected || defaultDevice || _.get(getFilteredAudioInput(devices), '0') || null;
};

export const getFilteredAudioOutput = (devices) => _.filter(devices, (device) => device.deviceId !== 'default' && device.kind === 'audiooutput');
export const getDefaultAudioOutput = (devices) => {
  const previouslySelected = _.get(_.filter(devices, { deviceId: localStorage.getItem('myLastAudioOutput'), kind: 'audiooutput' }), '0');
  const defaultDevice = _.get(_.filter(devices, { deviceId: 'default', kind: 'audiooutput' }), '0');
  return previouslySelected || defaultDevice || _.get(getFilteredAudioOutput(devices), '0') || null;
};

export const getFilteredVideo = (devices) => _.filter(devices, (device) => device.deviceId !== 'default' && device.kind === 'videoinput');
export const getVideoDeviceById = (devices, deviceId) => _.get(_.filter(devices, { deviceId, kind: 'videoinput' }), '0');
export const getDefaultVideo = (devices) => {
  const previouslySelected = _.get(_.filter(devices, { deviceId: localStorage.getItem('myLastVideoInput'), kind: 'videoinput' }), '0');
  const defaultDevice = _.get(_.filter(devices, { deviceId: 'default', kind: 'videoinput' }), '0');
  const filtered = _.get(getFilteredVideo(devices), '0');
  return previouslySelected || defaultDevice || filtered || null;
};

export const getMIVVideo = (devices, map) => {
  const videoDevices = getFilteredVideo(devices);
  const availablVideos = videoDevices.filter((deviceId) => map.includes(deviceId));
  const defaultDevice = _.get(_.filter(availablVideos, { deviceId: 'default', kind: 'videoinput' }), '0');
  const firstVideoDevice = _.get(availablVideos, '0');
  return firstVideoDevice || defaultDevice || null;
};
