import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import useWindowSize from 'react-use/lib/useWindowSize';

import ControlsIcons from '../controls/ControlsIcons';
import ControlsMenu from '../controls/ControlsMenu';

import attendeesShape from '../../../../shapes/attendees';
import participantShape from '../../../../shapes/participant';

const useStyles = makeStyles(() => ({
  container: {
    whiteSpace: 'nowrap',
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    color: '#000',
    textAlign: 'center',
    zIndex: 2,
    '& #controls': {
      height: '100%',
      opacity: 0,
      transition: '100ms all',
    },
    '&:hover': {
      '& #controls': {
        transition: '100ms all',
        background: 'rgba(0,0,0,0.2)',
        color: '#fff',
        opacity: 1,
      },
    },
  },
  compressBtns: {
    '& button': {
      padding: 10,
      '& svg': {
        fontSize: '0.8em',
      },
    },
  },
}));

const VideoItemHoverControls = ({
  attendee,
  participant,
  isMiv,
}) => {
  const classes = useStyles();

  const { width } = useWindowSize();
  const [itemWidth, setItemWidth] = useState(width);

  const refCallback = (element) => {
    if (element) {
      const elementRect = element.getBoundingClientRect();
      setItemWidth(elementRect.width);
    }
  };

  return (
    <div
      className={
        classnames(
          classes.container,
          itemWidth < 400 && classes.compressBtns,
        )
      }
      ref={refCallback}
    >
      <div id="controls">
        <ControlsIcons
          centered
          attendee={attendee}
          participant={participant}
          hideEnlarge={isMiv && !!participant.isSelf}
          variant="contained"
        />
        <ControlsMenu
          centered
          attendee={attendee}
          participant={participant}
          variant="horizontal"
        />
      </div>
    </div>
  );
};

VideoItemHoverControls.defaultProps = {
  isMiv: false,
};
VideoItemHoverControls.propTypes = {
  attendee: attendeesShape.propTypesShape.isRequired,
  participant: participantShape.propTypesShape.isRequired,
  isMiv: PropTypes.bool,
};

export default React.memo(VideoItemHoverControls);
