export const SHOW_MFA_FORM_PAGE = 'events/SHOW_MFA_FORM_PAGE';

/**
 *
 * @param username
 * @param mfaType
 * @param mfaVendor
 * @return {{type}}
 */
export const showMfaFormPage = (username, mfaType, mfaVendor) => ({
  type: SHOW_MFA_FORM_PAGE,
  payload: {
    username,
    mfaType,
    mfaVendor,
  },
});
