export const ADD_CHAT_ATTACHMENT = 'events/ADD_CHAT_ATTACHMENT';

/**
 * @param fileObject
 * @return {{type: string, payload: *}}
 */
export const addChatAttachment = (fileObject) => ({
  type: ADD_CHAT_ATTACHMENT,
  payload: fileObject,
});
