import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import _ from 'lodash';

import { createTheme, makeStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';

import * as actions from '../../actions';
import * as selectors from '../../selectors';

const messages = defineMessages({
  OK: {
    id: 'OK',
    defaultMessage: 'Ok',
  },
  SFU_MAX_PARTICIPANTS_ERROR_TITLE: {
    id: 'SFU_MAX_PARTICIPANTS_ERROR_TITLE',
    defaultMessage: 'Meeting is full',
  },
  SFU_MAX_PARTICIPANTS_ERROR_BODY: {
    id: 'SFU_MAX_PARTICIPANTS_ERROR_BODY',
    defaultMessage: 'You cannot join this meeting until someone else in the meeting leaves. The maximum number of attendees for meetings is {limit}.',
  },
  SFU_MAX_ROOMS_ERROR_TITLE: {
    id: 'SFU_MAX_ROOMS_ERROR_TITLE',
    defaultMessage: 'Concurrent meeting limit reached',
  },
  SFU_MAX_ROOMS_ERROR_BODY: {
    id: 'SFU_MAX_ROOMS_ERROR_BODY',
    defaultMessage: 'You cannot start another meeting until an active meeting ends. The maximum concurrent meetings is {limit}.',
  },
});

const useStyles = makeStyles(() => ({
  dialogContent: {
    width: 344,
  },
}));

const backdropOverride = createTheme({
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'transparent',
      },
    },
  },
});

const RejectDialog = ({
  rejectDialog,
  closeRejectDialog,
}) => {
  const classes = useStyles();
  const [animState, setAnimState] = useState();

  if (!rejectDialog) return null;

  const {
    title,
    content,
    limit,
    actions: dialogActions,
    caption,
    dialogOpen,
  } = rejectDialog;

  const dismissRejectDialog = () => {
    setTimeout(() => { // wait for ripple before animating
      setAnimState('closing');

      setTimeout(() => { // wait for animations before navigating
        closeRejectDialog();
      }, 525);
    }, 75);
  };

  return (
    <ThemeProvider theme={backdropOverride}>
      <Dialog
        open={dialogOpen || false}
        onClose={dismissRejectDialog}
        className={
          classnames(
            'animate__animated animate__faster',
            animState === 'closing' ? 'animate__fadeOut' : 'animate__fadeIn',
          )
        }
      >
        {title && (
          <DialogTitle>
            {_.isString(title) ? <FormattedMessage {...messages[title]} /> : title}
          </DialogTitle>
        )}

        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            {_.isString(content)
              ? <FormattedMessage {...messages[content]} values={{ limit }} /> : content}
          </DialogContentText>
          {caption && (
            <Typography variant="caption" gutterBottom>
              {_.isString(caption) ? <FormattedMessage {...messages[caption]} /> : caption}
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          {dialogActions && dialogActions.map((action) => (
            <Button
              id={action.id}
              key={action.id}
              onClick={action.onClick}
              color="primary"
              disabled={action.disabled}
              autoFocus
            >
              {_.isString(action.label)
                ? <FormattedMessage {...messages[action.label]} />
                : action.label}
            </Button>
          ))}

          <Button id="RejectDialogOk" onClick={dismissRejectDialog} color="primary" autoFocus>
            <FormattedMessage {...messages.OK} />
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

RejectDialog.defaultProps = {
  rejectDialog: null,
};

RejectDialog.propTypes = {
  rejectDialog: PropTypes.shape({
    title: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.string,
    ]),
    content: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.string,
    ]).isRequired,
    caption: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.string,
    ]).isRequired,
    limit: PropTypes.string,
    actions: PropTypes.arrayOf(PropTypes.shape({})),
    dialogOpen: PropTypes.bool.isRequired,
  }),
  closeRejectDialog: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  rejectDialog: selectors.selectRejectDialog(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  closeRejectDialog: actions.closeRejectDialog,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RejectDialog);
