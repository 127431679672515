import React, { useMemo } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

import LayoutContainer from '../components/LayoutContainer';
import VideoItem from '../../video-item/VideoItem';

import {
  selectVideoGridIds, selectParticipants,
} from '../../../../../selectors';

const useStyles = makeStyles(() => ({
  pip: {
    height: '100%',
    width: '100%',
  },
}));

const MobileSoloLayout = ({ layout }) => {
  const classes = useStyles();
  const videoGridIds = useSelector(selectVideoGridIds);
  const participants = useSelector(selectParticipants);

  const participantId = useMemo(() => {
    const filtered = videoGridIds.filter((id) => _.get(participants, [id, 'videoTrackMuted']) !== true);
    if (filtered.length) {
      return [filtered[0]];
    }
    return [];
  }, [videoGridIds]);

  return (
    <LayoutContainer>
      <div
        className={classes.pip}
      >
        <VideoItem
          participantId={participantId[0]}
          layout={layout}
        />
      </div>
    </LayoutContainer>
  );
};

MobileSoloLayout.propTypes = {
  layout: PropTypes.string.isRequired,
};

export default MobileSoloLayout;
