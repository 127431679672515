import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import Assessment from '@material-ui/icons/Assessment';

import ControlItem from './ControlItem';

import { toggleVideoStat } from '../../../../events';
import * as selectors from '../../../../selectors';

import participantShape from '../../../../shapes/participant';
import attendeesShape from '../../../../shapes/attendees';
import meetingShape from '../../../../shapes/meeting';

const messages = defineMessages({
  TOGGLE_STATS_ON: {
    id: 'TOGGLE_STATS_ON',
    defaultMessage: 'Show Stats',
  },
  TOGGLE_STATS_OFF: {
    id: 'TOGGLE_STATS_OFF',
    defaultMessage: 'Hide Stats',
  },
});

const StatsControl = ({
  type,
  variant,
  participant,
  toggleVideoStatAction,
  handleClose,
  meeting,
  myAttendee,
}) => {
  const intl = useIntl();

  // hide control for attendees in presentations
  if (meeting.type === 'presentation' && myAttendee.role === 'attendee') {
    return '';
  }

  const onToggleVideoStat = () => {
    const partID = participant.id;
    toggleVideoStatAction({ partID });
    handleClose();
  };

  const notifyRestriction = () => {};

  const isStatRestricted = false;
  const onClickAction = !isStatRestricted ? onToggleVideoStat : notifyRestriction;

  return (
    <ControlItem
      type={type}
      variant={variant}
      onClick={onClickAction}
      isToggled={participant.videoStat}
      enabledIcon={<Assessment />}
      disabledIcon={<Assessment />}
      enabledTooltip={intl.formatMessage(messages.TOGGLE_STATS_ON)}
      disabledTooltip={intl.formatMessage(messages.TOGGLE_STATS_OFF)}
    />
  );
};

StatsControl.propTypes = {
  type: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  participant: participantShape.propTypesShape.isRequired,
  toggleVideoStatAction: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  meeting: meetingShape.propTypesShape.isRequired,
  myAttendee: attendeesShape.propTypesShape.isRequired,
};

const mapStateToProps = (state) => ({
  meeting: selectors.selectMeeting(state),
  myAttendee: selectors.selectMyAttendee(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  toggleVideoStatAction: toggleVideoStat,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StatsControl);
