import React, { useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import MenuItem from '@material-ui/core/MenuItem';

import Fullscreen from '@material-ui/icons/Fullscreen';
import FullscreenExit from '@material-ui/icons/FullscreenExit';

const messages = defineMessages({
  FULL_SCREEN: {
    id: 'FULL_SCREEN',
    defaultMessage: 'Enter full screen',
  },
  EXIT_FULL_SCREEN: {
    id: 'EXIT_FULL_SCREEN',
    defaultMessage: 'Exit full screen',
  },
});

const FullScreenMenuItem = ({
  menuItemKey,
  clickCallback,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [fullscreenRequested, setFullscreenRequested] = useState(false);

  const isFullscreen = () => (
    document.fullscreenElement
    || document.mozFullScreenElement
    || document.webkitFullscreenElement
    || document.msFullscreenElement
  );

  /* thewebflash.com/toggling-fullscreen-mode-using-the-html5-fullscreen-api */
  const toggleFullScreen = () => {
    const elem = document.documentElement;

    if (!isFullscreen()) {
      setFullscreenRequested(true);
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else if (document.exitFullscreen) {
      setFullscreenRequested(false);
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      setFullscreenRequested(false);
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      setFullscreenRequested(false);
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      setFullscreenRequested(false);
      document.webkitExitFullscreen();
    }

    clickCallback();
  };

  return isFullscreen() ? (
    <MenuItem key={menuItemKey} onClick={toggleFullScreen}>
      <FullscreenExit />
      <FormattedMessage {...messages.EXIT_FULL_SCREEN} />
    </MenuItem>
  ) : (
    <MenuItem key={menuItemKey} onClick={toggleFullScreen}>
      <Fullscreen />
      <FormattedMessage {...messages.FULL_SCREEN} />
    </MenuItem>
  );
};

FullScreenMenuItem.propTypes = {
  menuItemKey: PropTypes.string.isRequired,
  clickCallback: PropTypes.func.isRequired,
};

export default FullScreenMenuItem;
