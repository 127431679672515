import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';

import MenuItem from '@material-ui/core/MenuItem';
import Assessment from '@material-ui/icons/Assessment';

import { toggleVideoStat } from '../../../../../events';
import * as actions from '../../../../../actions';
import * as selectors from '../../../../../selectors';

import bugsnagClient from '../../../../../bugsnag';

/* eslint react/prop-types: 0 */
const messages = defineMessages({
  GLOBAL_STATS_ON: {
    id: 'GLOBAL_STATS_ON',
    defaultMessage: 'Show All Video Stats',
  },
  GLOBAL_STATS_OFF: {
    id: 'GLOBAL_STATS_OFF',
    defaultMessage: 'Hide All Video Stats',
  },
});

const GlobalStatMenuItem = ({
  menuItemKey,
  clickCallback,
  participants,
  toggleVideoStatAction,
  setVideoAllStatsAction,
  videoAllStats,
}) => {
  const toggleAllVideoStats = async (toggle) => {
    try {
      const ids = Object.keys(participants);
      for (let i = 0; i < ids.length; i += 1) {
        if (participants[ids[i]].videoStat !== toggle) {
          toggleVideoStatAction({ partID: ids[i] });
        }
      }
    } catch (err) {
      console.error(err);
      bugsnagClient.notify(err);
    }
  };

  const toggleGlobalStats = () => {
    clickCallback();
    toggleAllVideoStats(!videoAllStats);
    setVideoAllStatsAction(!videoAllStats);
  };

  return videoAllStats ? (
    <MenuItem key={menuItemKey} onClick={toggleGlobalStats}>
      <Assessment />
      <FormattedMessage {...messages.GLOBAL_STATS_OFF} />
    </MenuItem>
  ) : (
    <MenuItem key={menuItemKey} onClick={toggleGlobalStats}>
      <Assessment />
      <FormattedMessage {...messages.GLOBAL_STATS_ON} />
    </MenuItem>
  );
};

GlobalStatMenuItem.propTypes = {
  menuItemKey: PropTypes.string.isRequired,
  clickCallback: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  participants: selectors.selectParticipants(state),
  toggleVideoStatAction: PropTypes.func.isRequired,
  videoAllStats: selectors.selectVideoAllStats(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  toggleVideoStatAction: toggleVideoStat,
  setVideoAllStatsAction: actions.setVideoAllStats,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GlobalStatMenuItem);
