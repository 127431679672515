import React, { useState } from 'react';
import PropTypes from 'prop-types';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import { useDispatch } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import * as events from '../../../../../events';

const messages = defineMessages({
  DELETE_MESSAGE_QUESTION: {
    id: 'DELETE_MESSAGE_QUESTION',
    defaultMessage: 'Delete this message?',
  },
  CANCEL: {
    id: 'CANCEL',
    defaultMessage: 'Cancel',
  },
  DELETE: {
    id: 'DELETE',
    defaultMessage: 'Delete',
  },
  DELETE_INFO_MESSAGE: {
    id: 'DELETE_INFO_MESSAGE',
    defaultMessage: 'This will delete the message for everyone in the room.',
  },
});

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'relative',
    height: 'calc(100vh - 91px)',
  },
  dialogContent: {
    width: 344,
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    color: 'rgba(0,0,0,0.6)',
  },
  btn: {
    marginTop: theme.spacing(1),
    fontWeight: 900,
  },
  deleteButton: {
    color: 'rgba(0,0,0,0.54)',
    padding: '9px',
    fontSize: '1rem',
  },
}));

const DeleteMessageModal = ({
  text,
  id,
  sessionId,
  fromName,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // show modal or not
  const [show, setShow] = useState(false);

  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      setShow(false);
    }
  };
  const handleShow = () => setShow(true);

  // on key up of the input field
  const handleDelete = () => {
    const decodedToken = nsToken.getDecoded();

    dispatch(events.deleteChatMessage({
      domain: decodedToken.domain,
      id,
      sessionId,
    }));

    // also hide the modal
    setShow(false);
  };

  return (
    <>
      <Tooltip title={<FormattedMessage {...messages.DELETE} />}>
        <IconButton variant="primary" className={classes.deleteButton} onClick={handleShow} aria-label="delete">
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <Dialog
        open={show}
        onClose={handleClose}
        maxWidth="sm"
      >
        <DialogTitle>
          <FormattedMessage {...messages.DELETE_MESSAGE_QUESTION} />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          &quot;
          {text}
          &quot;
          &nbsp;-&nbsp;
          {fromName}
        </DialogContent>
        <DialogContent className={classes.dialogContent}>
          <FormattedMessage {...messages.DELETE_INFO_MESSAGE} />
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.btn}
            onClick={handleClose}
            size="small"
            color="primary"
          >
            <FormattedMessage {...messages.CANCEL} />
          </Button>
          <Button
            className={classes.btn}
            onClick={handleDelete}
            size="small"
            color="primary"
          >
            <FormattedMessage {...messages.DELETE} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

DeleteMessageModal.defaultProps = {
};

DeleteMessageModal.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  sessionId: PropTypes.string.isRequired,
  fromName: PropTypes.string.isRequired,
};

export default React.memo(DeleteMessageModal);
