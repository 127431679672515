import { select } from 'redux-saga/effects';
import moment from 'moment';
import nsModels from '@netsapiens/netsapiens-js/dist/models';

import * as selectors from '../../selectors';
import bugsnagClient from '../../bugsnag';

// todo deprecate
// eslint-disable-next-line import/prefer-default-export
export function* createUcItem({ payload }) {
  try {
    const user = yield select((state) => selectors.selectUser(state));

    const ts = moment().valueOf();
    const newMsgItem = new nsModels.ucInbox.MessageItem({
      displayName: user.displayName,
      gravatar: user.gravatar,
      initials: user.initials,
      id: `${user.userId}_${ts}`,
      isSelf: true,
      content: payload,
      ts,
    });

    const socket = yield select((state) => selectors.selectSocket(state));
    socket.emit('ucinbox_item', newMsgItem);
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: createUcItem';
      event.addMetadata('payload', payload);
    });
  }
}
