import { select, put } from 'redux-saga/effects';

import nsApi from '@netsapiens/netsapiens-js/dist/api';
import * as selectors from '../../selectors';
import * as actions from '../../actions';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* endAudioBridge({ payload }) {
  try {
    const audioSession = yield select((state) => selectors.selectAudioSession(state));
    const myAttendee = yield select((state) => selectors.selectMyAttendee(state));
    const meeting = yield select((state) => selectors.selectMeeting(state));
    const user = yield select(selectors.selectUser);

    const conferenceMatch = `sip:${meeting.id}.video.bridge@conference-bridge`;
    const participant = payload.participantMatch || '';

    if (audioSession && !audioSession.requested_termination) {
      localStorage.setItem('nsVideoCallLive', false);
      audioSession.bye({ reason_phrase: `AudioId in use by ${payload.participantMatch}` });
      audioSession.requested_termination = true;
      // #TODO need to add logic here.
      yield put(actions.setRemoteAudio(true));
      yield put(actions.setParticipantMatch(participant));
      yield put(actions.snackBarMessage('REMOTE_AUDIO_CONNECTED'));
      if (meeting.my_audio_id || myAttendee.audio_id) {
        const toUpdate = {
          user: user.userId,
          domain: user.domain,
          participant, // audio_participant_match
          conference_match: conferenceMatch,
          audioId: meeting.my_audio_id || myAttendee.audio_id,
        };
        yield nsApi.post({
          object: 'participant',
          action: 'update', // delete instead of update no audio id
        }, toUpdate);
      }
    }
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: endAudioBridge';
      event.addMetadata('payload', payload);
    });
  }
}
