import PropTypes from 'prop-types';

const shape = {
  contactId: PropTypes.string,
  direction: PropTypes.string,
  displayName: PropTypes.string,
  domain: PropTypes.string,
  email: PropTypes.arrayOf(PropTypes.string),
  gravatar: PropTypes.string,
  id: PropTypes.string,
  initials: PropTypes.string,
  isOnHold: PropTypes.bool,
  isSelf: PropTypes.bool,
  profile: PropTypes.string,
  show: PropTypes.bool,
  status: PropTypes.string,
  type: PropTypes.string,
  uid: PropTypes.string,
  userId: PropTypes.string,
  videoTrack: PropTypes.shape({}),
  videoTrackMuted: PropTypes.bool,
  videoStat: PropTypes.bool,
  audioId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

const propTypesShape = PropTypes.shape(shape);

export default {
  shape,
  propTypesShape,
  arrayOfShapes: PropTypes.arrayOf(propTypesShape),
};
