import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import Linkify from 'react-linkify';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import GetApp from '@material-ui/icons/GetApp';
import DeleteMessageModal from './DeleteMessageModal';
import * as selectors from '../../../../../selectors';
import gravatarSessionId from '../../../../../utils/gravatarSessionId';

const useStyles = makeStyles((theme) => ({
  avatarContainer: {
    position: 'relative',
    display: 'inline-block',
  },
  bubble: {
    borderRadius: theme.spacing(0, 1, 1, 1),
    backgroundColor: '#FFFFFF',
    display: 'inline-block',
    padding: theme.spacing(1),
  },
  hostBubble: {
    maxWidth: '240px',
  },
  img: {
    maxWidth: '100%',
    maxHeight: '200px',
  },
  vid: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  container: {
    textAlign: 'left',
    maxWidth: '85%',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    position: 'relative',
  },
  listFiller: {
    marginTop: -14,
    marginLeft: -11,
    fontSize: '2.8em',
    backgroundColor: '#F0F0F0',
    fill: '#bdbdbd',
  },
  listGravatar: {
    width: 36,
    height: 36,
    opacity: 1,
    marginRight: 4,
    float: 'left',
    top: 0,
    position: 'absolute',
  },
  listInitials: {
    display: 'flex',
    width: '36px',
    height: '36px',
    opacity: 1,
    marginRight: 4,
    borderRadius: '50%',
    color: '#fff',
    lineHeight: '38px',
    whiteSpace: 'nowrap',
    backgroundColor: '#b9b9b9',
    textAlign: 'center',
  },
  initialsText: {
    width: '100%',
    fontSize: '0.9em',
  },
  root: { margin: theme.spacing(1, 0, 1, 0) },
  speaking: {
    opacity: 1,
    boxShadow: '0 0 2px 0 rgba(0,0,0,0.5), 0 0 4px 2px #1A73E8',
    transition: 'box-shadow 0.3s ease-in-out',
  },
  fileToDownload: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  downloadableFileIcon: {
    position: 'relative',
    bottom: '-4.5px',
    fontSize: '1rem',
  },
  triangleTopLeft: {
    position: 'absolute',
    top: '5px',
    left: '-4px',
    height: '0px',
    width: '0px',
    borderTop: '16px solid white',
    borderLeft: '16px solid transparent',
    margin: '-5px',
  },
  deleteContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  innerContainer: {
    display: 'flex',
  },
}));

const BubbleInbound = ({
  gravatar,
  initials,
  lastInChain,
  text,
  timestamp,
  remotepath,
  mediatype,
  fromName,
  scrollToBottom,
  deleted,
  id,
  sessionId,
}) => {
  const classes = useStyles();
  const myAttendee = useSelector(selectors.selectMyAttendee);
  const showDelete = myAttendee && (myAttendee.role === 'host');

  // hover hide/show DeleteMessageModal
  const [isShown, setIsShown] = useState(false);

  // create up initials
  let userInitials = '';
  if (initials) {
    userInitials = initials;
    userInitials = userInitials.slice(0, 3);
  } else if (fromName && fromName.trim()) {
    const userNameSplit = fromName.match(/\b\w/g);
    if (userNameSplit) {
      userInitials = userNameSplit.join('');
    }
    userInitials = userInitials.slice(0, 3);
  } else {
    userInitials = <AccountCircle className={classes.listFiller} />;
  }
  const attendeeInitials = userInitials;

  const newDate = new Date(`${timestamp}+00:00`);
  newDate.setSeconds(newDate.getSeconds() - 30);

  // open hyper links in new tab
  const componentDecorator = (href, textLink, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {textLink}
    </a>
  );

  // error on faulty attachment
  const onLoadImg = () => {
    scrollToBottom();
  };

  if (deleted) {
    return (
      <>
      </>
    );
  }

  return (
    <Grid
      className={classes.root}
      container
      justifyContent="flex-start"
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >
      <div className={classes.avatarContainer}>
        <span className={classes.listInitials}>
          <span className={classes.initialsText}>
            {attendeeInitials}
          </span>
        </span>
        {gravatar !== null && (
          <Avatar
            className={classes.listGravatar}
            src={`https://www.gravatar.com/avatar/${gravatar}?s=42&d=blank&hash=${gravatarSessionId}`}
          />
        )}
      </div>
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div className={classes.triangleTopLeft} />
          {!remotepath && (
            <div className={classnames(classes.bubble, { [classes.hostBubble]: showDelete })}>
              <Linkify componentDecorator={componentDecorator}>
                {text}
              </Linkify>
            </div>
          )}
          {(remotepath && mediatype.includes('image')) && (
            <div className={classnames(classes.bubble, { [classes.hostBubble]: showDelete })}>
              <a href={remotepath} target="_blank" rel="noopener noreferrer">
                <img className={classes.img} src={remotepath} alt="Failed to Load" onLoad={onLoadImg} />
              </a>
            </div>
          )}
          {(remotepath && mediatype.includes('audio')) && (
            <div className={classnames(classes.bubble, { [classes.hostBubble]: showDelete })}>
              <audio
                controls
                className={classes.vid}
                src={remotepath}
              >
                <track kind="captions" />
                Your browser does not support the audio element.
              </audio>
            </div>
          )}
          {(remotepath && mediatype.includes('video')) && (
            <div className={classnames(classes.bubble, { [classes.hostBubble]: showDelete })}>
              <video className={classes.vid} controls>
                <track kind="captions" />
                <source
                  src={remotepath}
                  type={mediatype}
                />
                Sorry, your browser does not support embedded videos.
              </video>
            </div>
          )}
          {(remotepath
            && !mediatype.includes('image')
            && !mediatype.includes('audio')
            && !mediatype.includes('video')
          ) && (
            <div className={classnames(classes.bubble, { [classes.hostBubble]: showDelete })}>
              <a className={classes.fileToDownload} href={remotepath} download={text}>
                <GetApp className={classes.downloadableFileIcon} />
                {text}
              </a>
            </div>
          )}
          {(showDelete && isShown) && (
            <div className={classes.deleteContainer}>
              <DeleteMessageModal
                text={text}
                id={id}
                sessionId={sessionId}
                fromName={fromName}
              />
            </div>
          )}
        </div>
        {lastInChain && (
          <div title={newDate}>
            <Typography variant="caption">
              {fromName}
              &nbsp;-&nbsp;
              <Moment fromNow>{newDate}</Moment>
            </Typography>
          </div>
        )}
      </div>
    </Grid>
  );
};

BubbleInbound.defaultProps = {
  gravatar: null,
  initials: null,
  remotepath: null,
  mediatype: null,
  fromName: '',
  deleted: false,
};

BubbleInbound.propTypes = {
  gravatar: PropTypes.string,
  initials: PropTypes.string,
  lastInChain: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  remotepath: PropTypes.string,
  mediatype: PropTypes.string,
  fromName: PropTypes.string,
  scrollToBottom: PropTypes.func.isRequired,
  deleted: PropTypes.bool,
  id: PropTypes.string.isRequired,
  sessionId: PropTypes.string.isRequired,
};

export default React.memo(BubbleInbound);
