import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';
import { select } from 'redux-saga/effects';
import * as selectors from '../../selectors';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* endCall() {
  try {
    // remove the url id query pram
    yield nsBrowser.removeQuery('id');
    yield nsBrowser.removeQuery('user');
    const audioSession = yield select((state) => selectors.selectAudioSession(state));
    localStorage.setItem('nsVideoCallLive', false);
    if (audioSession && audioSession.state && audioSession.state !== 'Terminated') {
      audioSession.bye();
    }

    window.location.reload();
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: endCall';
    });
  }
}
