import { put, select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';

import * as actions from '../../actions';
import { userAuthenticated, subscriberSelect, showSsoEnrollPage } from '../../events';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* ssoLoginUser({ payload }) {
  try {
    yield put(actions.showLoaderPage());

    const apiHash = yield select((state) => state.configs.apiHash);

    const params = {
      idtoken: payload.idtoken,
      vendor: payload.vendor,
      username: payload.username || null,
      scope: 'video',
      apiHash,
    };

    yield nsApi.ssoLogin(params);
    yield put(actions.loginFormErrorMsg(null));
    yield put(userAuthenticated());
  } catch (err) {
    if (err === 'NO_SUBSCRIBER') {
      yield put(actions.loginFormErrorMsg(null));
      yield put(showSsoEnrollPage(
        payload.idtoken,
        payload.vendor,
      ));
    } else if (err.reason === 'SELECT_SUBSCRIBER') {
      yield put(actions.loginFormErrorMsg(null));
      yield put(subscriberSelect({
        idtoken: payload.idtoken,
        vendor: payload.vendor,
        subscriberList: err.subscriber_list,
      }));
    } else {
      yield put(actions.loginFormErrorMsg('LOGIN_FAILED'));
      yield put(actions.showLoginFormPage());

      console.error(err);
      bugsnagClient.notify(err, (event) => {
        // eslint-disable-next-line no-param-reassign
        event.context = 'saga: ssoLoginUser';
        event.addMetadata('payload', payload);
      });
    }
  }
}
