import nsApi from '@netsapiens/netsapiens-js/dist/api';

export default ({
  registrationId,
  name,
  email,
}) => nsApi.post({
  object: 'meeting',
  action: 'register',
  registration_id: registrationId,
  name,
  email,
}).then((res) => {
  if (!res[0]) {
    // eslint-disable-next-line no-throw-literal
    throw 'NO_MEETING';
  }

  return res[0];
});
