import _ from 'lodash';
import { put, select } from 'redux-saga/effects';

import * as actions from '../../actions';
import * as selectors from '../../selectors';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* updateAudioStatus({ payload: audioMuted }) {
  try {
    const userMediaStatus = yield select(selectors.selectUserMediaStatus);
    const newStatus = { ...userMediaStatus, audioMuted };

    /**
     * Update the mediaStatus store
     */
    yield put(actions.updateMediaStatus(newStatus));

    /**
     * Used to reset the muted notification if the user unmutes then mutes again
     */
    if (newStatus.audioMuted) {
      // if it is muted, set to true
      yield put(actions.setMutedNotify(true));
    } else {
      // if it is unmuted, set to false
      yield put(actions.setMutedNotify(false));
    }

    /**
     * Emit to netsapiens video room
     */
    const socket = yield select(selectors.selectSocket);
    const meeting = yield select(selectors.selectMeeting);

    const metaData = { meeting_id: meeting.id, domain: meeting.domain };
    const status = _.pick(newStatus, [
      'audioMuted',
      'hasCamDevice',
      'hasCamPermissions',
      'hasMicDevice',
      'hasMicPermissions',
      'screenShareMuted',
      'userId',
      'videoAspectRatio',
      'videoMuted',
    ]);
    socket.emit(
      'video_status',
      { ...metaData, ...status },
    );
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: toggleAudioMute';
    });
  }
}
