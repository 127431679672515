import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/index';

const useStyles = makeStyles((theme) => ({
  normal: {
    backgroundColor: '#fff',
    border: '1px solid',
    borderColor: 'rgba(0, 0, 0, .1)',
    borderRadius: 4,
    padding: theme.spacing(1),
  },
  premeet: {
    backgroundColor: '#fff',
    padding: theme.spacing(1),
  },
}));

const SettingsContainer = ({ children, variant }) => {
  const classes = useStyles();

  return (
    <div className={classes[variant]}>
      {children}
    </div>
  );
};

SettingsContainer.defaultProps = {
  variant: 'normal',
};

SettingsContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({}),
  ]).isRequired,
  variant: PropTypes.string,
};

export default SettingsContainer;
