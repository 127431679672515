import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';
import bugsnagClient from '../bugsnag';

let resolutions169;
let resolutions43;

console.debug('Device Supported Constraints');

if (window.location && !navigator.mediaDevices && window.location.protocol !== 'https:') {
  window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
}
const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
console.debug(supportedConstraints);

console.debug(`Browser Name:${nsBrowser.name}`);
switch (nsBrowser.name) {
  case 'Firefox':
    resolutions169 = [
      // 16:9
      { frameRate: 15, width: { exact: 1280 }, height: { exact: 720 } },
      { frameRate: 15, width: { exact: 640 }, height: { exact: 360 } },
      { frameRate: 15, width: { exact: 320 }, height: { exact: 180 } },
    ];
    resolutions43 = [
      // 4:3
      { frameRate: 15, width: { exact: 800 }, height: { exact: 600 } },
      { frameRate: 15, width: { exact: 640 }, height: { exact: 480 } },
      { frameRate: 15, width: { exact: 352 }, height: { exact: 288 } },
      { frameRate: 15, width: { exact: 320 }, height: { exact: 240 } },
      { frameRate: 15, width: { exact: 176 }, height: { exact: 144 } },
      { frameRate: 15, width: { exact: 160 }, height: { exact: 120 } },
    ];
    break;
  case 'Safari':
    resolutions169 = [
    // 16:9
      { frameRate: { ideal: 24 }, width: { exact: 960 }, height: { exact: 540 } },
      { frameRate: { ideal: 24 }, width: { exact: 640 }, height: { exact: 360 } },
      { frameRate: { ideal: 24 }, width: { exact: 320 }, height: { exact: 180 } },
    ];
    resolutions43 = [
    // 4:3
      { frameRate: { ideal: 24 }, width: { exact: 800 }, height: { exact: 600 } },
      { frameRate: { ideal: 24 }, width: { exact: 640 }, height: { exact: 480 } },
      { frameRate: { ideal: 24 }, width: { exact: 352 }, height: { exact: 288 } },
    ];
    break;
  default:
    resolutions169 = [
      // 16:9
      { frameRate: { ideal: 24 }, width: { exact: 1280 }, height: { exact: 720 } },
      { frameRate: { ideal: 24 }, width: { exact: 640 }, height: { exact: 360 } },
      { frameRate: { ideal: 24 }, width: { exact: 320 }, height: { exact: 180 } },
    ];
    resolutions43 = [
      // 4:3
      { frameRate: { ideal: 24 }, width: { exact: 800 }, height: { exact: 600 } },
      { frameRate: { ideal: 24 }, width: { exact: 640 }, height: { exact: 480 } },
      { frameRate: { ideal: 24 }, width: { exact: 352 }, height: { exact: 288 } },
      { frameRate: { exact: 15 }, width: { exact: 320 }, height: { exact: 240 } },
      { frameRate: { exact: 15 }, width: { exact: 176 }, height: { exact: 144 } },
      { frameRate: { exact: 15 }, width: { exact: 160 }, height: { exact: 120 } },
    ];
}

// return stream for best resolution
export default async (deviceId, xBest = 1) => {
  let stream;
  let passed = 0;
  if (window.location && !navigator.mediaDevices && window.location.protocol !== 'https:') {
    window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
  }
  for (let i = 0; i < resolutions169.length; i += 1) {
    try {
      if (supportedConstraints.resizeMode) resolutions169[i].resizeMode = 'none';
      // eslint-disable-next-line
      stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        aspectRatio: { exact: 1.7777777778 },
        video: {
          deviceId: deviceId ? { exact: deviceId } : undefined,
          ...resolutions169[i],
        },
      });
      passed += 1;
      if (passed >= xBest) {
        console.debug('user media resolution match');
        console.debug(resolutions169[i]);
        const trackSettings = stream.getVideoTracks()[0].getSettings();
        console.debug('Stream Track Settings');
        console.debug(trackSettings);
        break;
      }
    } catch (e) {
      console.debug('user media device id', deviceId);
      console.debug('user media resolution', resolutions169[i]);
      console.debug('user media error', e);
    }
  }

  if (passed === 0) {
    for (let j = 0; j < resolutions43.length; j += 1) {
      try {
        if (supportedConstraints.resizeMode) resolutions43[j].resizeMode = 'none';
        // eslint-disable-next-line
        stream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          aspectRatio: { exact: 1.33333333333 },
          video: {
            deviceId: deviceId ? { exact: deviceId } : undefined,
            ...resolutions43[j],
          },
        });
        passed += 1;
        if (passed >= xBest) {
          console.debug('user media resolution match', resolutions43[j]);
          console.debug(resolutions43[j]);
          const trackSettings = stream.getVideoTracks()[0].getSettings();
          console.debug('Stream Track Settings');
          console.debug(trackSettings);
          break;
        }
      } catch (e) {
        console.debug('user media device id', deviceId);
        console.debug('user media resolution', resolutions43[j]);
        console.debug('user media error', e);
      }
    }
  }

  console.debug(stream);

  if (!stream) {
    bugsnagClient.notify(Error('getBestMediaResolution failed to get stream'));
  }

  return stream;
};
