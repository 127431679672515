export const SHOW_MEETINGS_SCROLL_PAGE = 'events/SHOW_MEETINGS_SCROLL_PAGE';

/**
 * @param tense
 * @param noRender
 * @param meetingId
 * @param instanceId
 * @return {{type: string}}
 */
export const showMeetingsScrollPage = (tense, noRender, meetingId, instanceId) => ({
  type: SHOW_MEETINGS_SCROLL_PAGE,
  payload: {
    tense,
    noRender,
    meetingId,
    instanceId,
  },
});
