import nsApi from '@netsapiens/netsapiens-js/dist/api';

export default ({
  meetingId,
  attendeeId,
}) => nsApi.post({
  object: 'meeting',
  action: 'emailHosts',
  id: meetingId,
  attendee_id: attendeeId,
}).then((res) => res);
