export default (contacts, str) => {
  if (!contacts || !str) {
    return null;
  }

  if (!contacts.ids || !contacts.ids.length) {
    return null;
  }

  let searchString = `${str}`;
  searchString = searchString.toLowerCase();

  // search by name
  if (contacts.names && contacts.names[searchString]) {
    return contacts.entities[contacts.names[searchString]];
  }

  // match by user
  if (contacts.users && contacts.users[searchString]) {
    return contacts.entities[contacts.users[searchString]];
  }

  // search by phone number
  searchString = str.replace(/\D/g, '');
  if (searchString.length > 6) {
    // remove leading 1 from phone number
    searchString = str.replace(/^1+/, '');
  }
  if (contacts.phones && contacts.phones[searchString]) {
    return contacts.entities[contacts.phones[searchString]];
  }

  return null;
};
