export const UPDATE_PARTICIPANT = 'events/UPDATE_PARTICIPANT';

/**
 *
 * @param participant
 * @return {{type: string, payload: *}}
 */
export const updateParticipant = (participant) => ({
  type: UPDATE_PARTICIPANT,
  payload: participant,
});
