import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';

import { makeStyles } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

import * as actions from '../../actions';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const messages = defineMessages({
  MEETING_STARTED: {
    id: 'MEETING_STARTED',
    defaultMessage: 'Meeting started.',
  },
  MESSAGE_NOT_SENT: {
    id: 'MESSAGE_NOT_SENT',
    defaultMessage: 'Message size limit is 5mb.',
  },
  HOST_STARTED_RECORD: {
    id: 'HOST_STARTED_RECORD',
    defaultMessage: 'The room is now being recorded.',
  },
  HOST_STOPPED_RECORD: {
    id: 'HOST_STOPPED_RECORD',
    defaultMessage: 'The room is now NOT being recorded.',
  },
  HOST_LOCKED_MEETING: {
    id: 'HOST_LOCKED_MEETING',
    defaultMessage: 'The room is now locked.',
  },
  HOST_UNLOCKED_MEETING: {
    id: 'HOST_UNLOCKED_MEETING',
    defaultMessage: 'The room is now unlocked.',
  },
  NEW_ROOM_ADDED: {
    id: 'NEW_ROOM_ADDED',
    defaultMessage: 'New meeting room added.',
  },
  TOAST_MESSAGE_NOW_SHARING: {
    id: 'TOAST_MESSAGE_NOW_SHARING',
    defaultMessage: 'Now sharing screen...',
  },
  TOAST_MESSAGE_INFO_COPIED: {
    id: 'TOAST_MESSAGE_INFO_COPIED',
    defaultMessage: 'Copied',
  },
  COPIED_TO_CLIPBOARD: {
    id: 'COPIED_TO_CLIPBOARD',
    defaultMessage: 'Copied to clipboard',
  },
  JOIN_CONF_ERR: {
    id: 'JOIN_CONF_ERR',
    defaultMessage: 'An error occurred when trying to join the requested meeting.',
  },
  WEBINAR_MAX_VIDEO_MESSAGE: {
    id: 'WEBINAR_MAX_VIDEO_MESSAGE',
    defaultMessage: 'Webinars are limited to 6 video attendees. Your camera has been disabled.',
  },
  NETWORK_LOST_CONNECTION_TOAST: {
    id: 'NETWORK_LOST_CONNECTION_TOAST',
    defaultMessage: 'Lost connection. Trying to reconnect...',
  },
  NETWORK_NO_CONNECTION_TOAST: {
    id: 'NETWORK_NO_CONNECTION_TOAST',
    defaultMessage: 'No connection. Trying to reconnect...',
  },
  UNABLE_TO_CONFIG: {
    id: 'UNABLE_TO_CONFIG',
    defaultMessage: 'Failed to open meeting configuration.',
  },
  SAVE_FAILED: {
    id: 'SAVE_FAILED',
    defaultMessage: 'Save did not complete successfully.',
  },
  DELETE_FAILED: {
    id: 'DELETE_FAILED',
    defaultMessage: 'Delete did not complete successfully.',
  },
  DELETE_FAILED_MEETING_ACTIVE: {
    id: 'DELETE_FAILED_MEETING_ACTIVE',
    defaultMessage: 'Delete failed due to active attendees in meeting.',
  },
  START_FAILED: {
    id: 'START_FAILED',
    defaultMessage: 'Meeting was not able to start successfully.',
  },
  HOST_MUTED_AUDIO: {
    id: 'HOST_MUTED_AUDIO',
    defaultMessage: 'Your microphone was disabled by a host. You won\'t be able to speak until a host enables your microphone again.',
  },
  HOST_UNMUTED_AUDIO: {
    id: 'HOST_UNMUTED_AUDIO',
    defaultMessage: 'Your microphone was enabled by a host.',
  },
  HOST_MUTED_VIDEO: {
    id: 'HOST_MUTED_VIDEO',
    defaultMessage: 'Your camera was disabled by a host. You won\'t be able to share video until a host enables your camera again.',
  },
  HOST_UNMUTED_VIDEO: {
    id: 'HOST_UNMUTED_VIDEO',
    defaultMessage: 'Your camera was enabled by a host.',
  },
  HOST_MUTED_SCREEN_SHARE: {
    id: 'HOST_MUTED_SCREEN_SHARE',
    defaultMessage: 'Your screen sharing was disabled by a host. You won\'t be able to share your screen until a host enables your screen sharing again.',
  },
  HOST_UNMUTED_SCREEN_SHARE: {
    id: 'HOST_UNMUTED_SCREEN_SHARE',
    defaultMessage: 'Your screen sharing was enabled by a host.',
  },
  REMOTE_AUDIO_CONNECTED: {
    id: 'REMOTE_AUDIO_CONNECTED',
    defaultMessage: '"Dial In" call connected.',
  },
  ACTIVE_SPEAKER_OFF: {
    id: 'ACTIVE_SPEAKER_OFF',
    defaultMessage: 'Automatic speaker movement turned off.',
  },
  ACTIVE_SPEAKER_ON: {
    id: 'ACTIVE_SPEAKER_ON',
    defaultMessage: 'Automatic speaker movement turned on.',
  },
  TURN_ON: {
    id: 'TURN_ON',
    defaultMessage: 'Turn on',
  },
  ACCESS_RESTRICTED: {
    id: 'ACCESS_RESTRICTED',
    defaultMessage: 'Access to this meeting has been restricted.',
  },
  FAILED_TO_READ_MEETING_DATA: {
    id: 'FAILED_TO_READ_MEETING_DATA',
    defaultMessage: 'Failed to read meeting data.',
  },
  COULD_NOT_LOAD_PAGE: {
    id: 'COULD_NOT_LOAD_PAGE',
    defaultMessage: 'Could not load the requested page.',
  },
  MIC_TURN_OFF: {
    id: 'MIC_TURN_OFF',
    defaultMessage: 'Microphone turned off.',
  },
  MIC_TURN_ON: {
    id: 'MIC_TURN_ON',
    defaultMessage: 'Microphone turned on.',
  },
  MUTE_HINT: {
    id: 'MUTE_HINT',
    defaultMessage: 'Hold spacebar to temporarily mute or unmute. Double tap spacebar to toggle mute.',
  },
  DO_NOT_SHOW_AGAIN: {
    id: 'DO_NOT_SHOW_AGAIN',
    defaultMessage: 'DO NOT SHOW AGAIN',
  },
  SCREEN_SHARE_PERMISSION_FAILED: {
    id: 'SCREEN_SHARE_PERMISSION_FAILED',
    defaultMessage: 'Screen Share was denied by the Operating System.',
  },
  TOAST_SELF_HIDDEN: {
    id: 'TOAST_SELF_HIDDEN',
    defaultMessage: 'You are now hidden from view but others can still see you.',
  },
});

const useStyles = makeStyles(() => ({
  default: {

  },
  error: {
    backgroundColor: '#d32f2f !important',
  },
  warning: {
    backgroundColor: '#ffa000 !important',
  },
  info: {
    backgroundColor: '#1976d2 !important',
  },
  success: {
    backgroundColor: '#43a047 !important',
  },
}));

const SnackBar = ({
  snackBarData,
  snackBarMessageEvent,
}) => {
  const classes = useStyles();

  const snackMessage = snackBarData && snackBarData.message;
  const snackType = snackBarData && snackBarData.type;
  const snackAction = snackBarData && snackBarData.action;

  const variant = snackType || 'default';
  const Icon = variant !== 'default' ? variantIcon[variant] : null;

  let horizontalOrigin = 'left';

  // if is a message error, then shift to the right side
  if (snackMessage === 'MESSAGE_NOT_SENT') {
    horizontalOrigin = 'right';
  }

  // if action is set, create a component
  const snackActionComponent = snackAction ? (
    <Button color="secondary" size="small" onClick={snackAction.action}>
      <FormattedMessage {...messages[snackAction.label]} />
    </Button>
  ) : '';

  let autoHideDurationSec = 4000;

  // extend for the mute hint
  if (snackMessage === 'MUTE_HINT') {
    autoHideDurationSec = 5000;
  }

  return (
    <Snackbar
      id="snackBarComponent"
      open={!!snackMessage}
      anchorOrigin={{ vertical: 'bottom', horizontal: horizontalOrigin }}
      autoHideDuration={autoHideDurationSec}
      onClick={() => snackBarMessageEvent(null)}
      onClose={() => snackBarMessageEvent(null)}
    >
      <SnackbarContent
        className={classes[variant]}
        action={snackActionComponent}
        message={(
          <span>
            {variant !== 'default' && <Icon style={{ float: 'left', marginRight: 12 }} />}
            {snackMessage && <FormattedMessage {...messages[snackMessage]} />}
          </span>
        )}
      />
    </Snackbar>
  );
};

SnackBar.defaultProps = {
  snackBarData: null,
};

SnackBar.propTypes = {
  snackBarData: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string,
    action: PropTypes.shape({}),
  }),
  snackBarMessageEvent: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  snackBarData: state.notices.snackBar,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  snackBarMessageEvent: actions.snackBarMessage,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SnackBar);
