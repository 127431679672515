import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import Linkify from 'react-linkify';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import GetApp from '@material-ui/icons/GetApp';
import CircularProgress from '@material-ui/core/CircularProgress';

import DeleteMessageModal from './DeleteMessageModal';
import * as selectors from '../../../../../selectors';

const useStyles = makeStyles((theme) => ({
  bubble: {
    borderRadius: theme.spacing(1),
    backgroundColor: '#E1F4FE',
    display: 'inline-block',
    padding: theme.spacing(1),
    position: 'relative',
    textAlign: 'left',
  },
  hostBubble: {
    maxWidth: '85%',
  },
  img: {
    maxWidth: '100%',
    maxHeight: '200px',
  },
  vid: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  fileToDownload: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  downloadableFileIcon: {
    position: 'relative',
    bottom: '-4.5px',
    fontSize: '1rem',
  },
  container: {
    textAlign: 'right',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  root: { margin: theme.spacing(1, 0, 1, 0) },
  loadingMoreSpinner: {
    position: 'absolute',
    top: '45%',
    right: '45%',
    zIndex: 1,
    height: '0px',
  },
  loadingMoreSpinnerNonImage: {
    position: 'absolute',
    top: '30%',
    right: '45%',
    zIndex: 1,
    height: '0px',
  },
  circularProgress: {
    color: 'orange',
  },
  fadedImage: {
    filter: 'brightness(80%)',
  },
  deleteContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  dateTitle: {
    display: 'flex',
    width: '100%',
    textAlign: 'right',
    flexDirection: 'column',
  },
}));

const BubbleOutbound = ({
  lastInChain,
  text,
  timestamp,
  remotepath,
  mediatype,
  scrollToBottom,
  sending,
  errorSending,
  deleted,
  id,
  sessionId,
}) => {
  const classes = useStyles();
  const newDate = new Date(`${timestamp}+00:00`);
  newDate.setSeconds(newDate.getSeconds() - 10);

  const youString = 'You';

  // to check if user is a host, to hide DeleteMessageModal
  const myAttendee = useSelector(selectors.selectMyAttendee);
  const showDelete = myAttendee && (myAttendee.role === 'host');

  // hover hide/show DeleteMessageModal
  const [isShown, setIsShown] = useState(false);

  // open hyper links in new tab
  const componentDecorator = (href, textLink, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {textLink}
    </a>
  );

  // if an image finishes loading, should scroll to the bottom
  // (else on render the image will push down scroll)
  const onLoadScroll = () => {
    scrollToBottom();
  };

  if (deleted) {
    return (
      <>
      </>
    );
  }

  return (
    <Grid
      className={classes.root}
      container
      justifyContent="flex-end"
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >
      <div className={classes.container}>
        {(showDelete && !sending && isShown) && (
          <div className={classes.deleteContainer}>
            <DeleteMessageModal
              text={text}
              id={id}
              sessionId={sessionId}
              fromName={youString}
            />
          </div>
        )}
        {!remotepath && (
          <div className={classnames(classes.bubble, { [classes.hostBubble]: showDelete })}>
            <Linkify componentDecorator={componentDecorator}>
              {text}
            </Linkify>
          </div>
        )}
        {(remotepath && mediatype.includes('image')) && (
          <div className={classnames(classes.bubble, { [classes.hostBubble]: showDelete })}>
            {
                sending
                  ? (
                    <div className={classes.loadingMoreSpinner}>
                      <CircularProgress
                        className={classes.circularProgress}
                        size={35}
                        thickness={5}
                      />
                    </div>
                  )
                  : null
            }
            <a href={remotepath} target="_blank" rel="noopener noreferrer">
              <img className={classnames(classes.img, { [classes.fadedImage]: sending })} src={remotepath} alt="Failed to Load" onLoad={onLoadScroll} />
            </a>
          </div>
        )}
        {(remotepath && mediatype.includes('audio')) && (
          <div className={classnames(classes.bubble, { [classes.hostBubble]: showDelete })}>
            {
                sending
                  ? (
                    <div className={classes.loadingMoreSpinnerNonImage}>
                      <CircularProgress
                        className={classes.circularProgress}
                        size={35}
                        thickness={5}
                      />
                    </div>
                  )
                  : null
            }
            <audio
              controls
              className={classnames(classes.vid, { [classes.fadedImage]: sending })}
              src={remotepath}
            >
              <track kind="captions" />
              Your browser does not support the audio element.
            </audio>
          </div>
        )}
        {(remotepath && mediatype.includes('video')) && (
          <div className={classnames(classes.bubble, { [classes.hostBubble]: showDelete })}>
            {
                sending
                  ? (
                    <div className={classes.loadingMoreSpinner}>
                      <CircularProgress
                        className={classes.circularProgress}
                        size={24}
                        thickness={3}
                      />
                    </div>
                  )
                  : null
            }
            <video className={classnames(classes.vid, { [classes.fadedImage]: sending })} controls>
              <source
                src={remotepath}
                type={mediatype}
                download={text}
              />
              <track kind="captions" />
              Sorry, your browser does not support embedded videos.
            </video>
          </div>
        )}
        {(remotepath
          && !mediatype.includes('image')
          && !mediatype.includes('audio')
          && !mediatype.includes('video')
        ) && (
          <div className={classnames(classes.bubble, { [classes.hostBubble]: showDelete })}>
            <a
              className={classnames(classes.fileToDownload, { [classes.fadedImage]: sending })}
              href={remotepath}
              download={text}
            >
              <GetApp className={classes.downloadableFileIcon} />
              {text}
            </a>
          </div>
        )}
      </div>
      {(lastInChain && !sending && !errorSending) && (
        <div title={newDate} className={classnames(classes.dateTitle)}>
          <Typography variant="caption">
            <Moment fromNow>{newDate}</Moment>
          </Typography>
        </div>
      )}
      {sending && (
        <div title={newDate} className={classnames(classes.dateTitle)}>
          <Typography variant="caption">
            Sending...
          </Typography>
        </div>
      )}
      {errorSending && (
        <div title={newDate} className={classnames(classes.dateTitle)}>
          <Typography variant="caption" color="error">
            Message failed to send.
          </Typography>
        </div>
      )}
    </Grid>
  );
};

BubbleOutbound.defaultProps = {
  remotepath: null,
  mediatype: null,
  sending: false,
  errorSending: false,
  deleted: false,
};

BubbleOutbound.propTypes = {
  lastInChain: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  remotepath: PropTypes.string,
  mediatype: PropTypes.string,
  scrollToBottom: PropTypes.func.isRequired,
  sending: PropTypes.bool,
  errorSending: PropTypes.bool,
  deleted: PropTypes.bool,
  id: PropTypes.string.isRequired,
  sessionId: PropTypes.string.isRequired,
};

export default React.memo(BubbleOutbound);
