import { put, select } from 'redux-saga/effects';

import * as actions from '../../actions';
import * as selectors from '../../selectors';
import * as services from '../../services';
import store from '../../store';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* readMoreMeetings({ payload }) {
  try {
    const { tense, offset } = payload;

    const user = yield select((state) => selectors.selectUser(state));
    const meetings = yield select((state) => selectors.selectMeetings(state));

    yield put(actions.meetingsLoading(true));

    if (tense === 'future') {
      const res = yield services.getMeetings({
        user: user.userId,
        domain: user.domain,
        limit: 20,
        offset,
      });

      store.dispatch(actions.setMeetings(meetings.concat(res)));
      store.dispatch(actions.meetingsLoading(false));
    } else if (tense === 'past') {
      const res = yield services.getMeetingEvents({
        user: user.userId,
        domain: user.domain,
        group_by_instance: 1,
        limit: 20,
        offset,
      });

      store.dispatch(actions.setMeetings(meetings.concat(res)));
      store.dispatch(actions.meetingsLoading(false));
    }
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.severity = 'warning';
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: readMoreMeetings';
      event.addMetadata('payload', payload);
    });
  }
}
