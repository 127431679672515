import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import ArrowUpward from '@material-ui/icons/ArrowUpward';

import ControlItem from './ControlItem';

import { LAYOUT_TYPE_CONVERSATION } from '../../../../constants';

import { toggleVideoItemFocus } from '../../../../events';
import * as selectors from '../../../../selectors';

import participantShape from '../../../../shapes/participant';

const messages = defineMessages({
  MOVE_UP: {
    id: 'MOVE_UP',
    defaultMessage: 'Move Up',
  },
});

const MoveUpControl = ({
  type,
  variant,
  participant,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const layout = useSelector(selectors.selectVideoLayout);
  const room = useSelector(selectors.selectVideoRoom);

  const firstGridItemId = useSelector((state) => _.get(selectors.selectVideoGridIds(state), '0'));
  const isPartFirst = participant.id === firstGridItemId;

  const secondGridItemId = useSelector((state) => _.get(selectors.selectVideoGridIds(state), '1'));
  const isPartSecond = participant.id === secondGridItemId;

  // only show control for conversation orbiters
  if (layout !== LAYOUT_TYPE_CONVERSATION || isPartFirst || isPartSecond) {
    return '';
  }

  const findConsumerId = () => {
    const filterConsumerId = [...room.consumers.keys()].filter((consumerId) => {
      const consumer = room.consumers.get(consumerId);
      if (!consumer) { return null; }
      if (consumer.appData.share) {
        return (participant.id === `screenShare_${consumer.appData.user.userId}`);
      }
      return (participant.id === `video_${consumer.appData.user.userId}`);
    });

    return filterConsumerId[0];
  };

  const onToggle = () => {
    if (room) {
      const consumerId = findConsumerId();
      room.requestKeyFrame(consumerId);
      room.notifyFocus(consumerId);
    }
    dispatch(toggleVideoItemFocus(participant.id, participant.type, false));
  };

  return (
    <ControlItem
      type={type}
      variant={variant}
      onClick={onToggle}
      enabledIcon={<ArrowUpward />}
      enabledTooltip={intl.formatMessage(messages.MOVE_UP)}
    />
  );
};

MoveUpControl.propTypes = {
  type: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  participant: participantShape.propTypesShape.isRequired,
};

export default MoveUpControl;
