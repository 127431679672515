export const DELETE_CHAT_SESSION = 'events/DELETE_CHAT_SESSION';

/**
 * @param sessionId
 * @return {{type: string, payload: *}}
 */
export const deleteChatSession = (sessionId) => ({
  type: DELETE_CHAT_SESSION,
  payload: sessionId,
});
