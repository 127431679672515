import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { selectVideoRoom } from '../../../../selectors';
import bugsnagClient from '../../../../bugsnag';

const useStyles = makeStyles(() => ({
  stats: {
    fontSize: 11,
    lineHeight: 0.5,
    padding: '4px 8px',
  },
}));

const messages = defineMessages({
  PART_ID: {
    id: 'PART_ID',
    defaultMessage: 'Participant Id: {participantId}',
  },
  ATTD_ID: {
    id: 'ATTD_ID',
    defaultMessage: 'Attendee Id: {attendeeId}',
  },
  AUD_ID: {
    id: 'AUD_ID',
    defaultMessage: 'Audio id: {audioId}',
  },
  HEADER: {
    id: 'HEADER',
    defaultMessage: 'Participant   | Attendee | Audio',
  },
  HEADER_IDS: {
    id: 'HEADER_IDS',
    defaultMessage: '{participantId} | {attendeeId}    | {audioId}',
  },
  IS_SELF: {
    id: 'IS_SELF',
    defaultMessage: 'Producing Stats | {hostname}',
  },
  NOT_SELF: {
    id: 'NOT_SELF',
    defaultMessage: 'Consuming Stats',
  },
  STAT_TEMP: {
    id: 'STAT_TEMP',
    defaultMessage: '{label}: {stat}',
  },
});

const StatsOverlay = ({
  attendeeId,
  attendeeAudioId,
  participantId,
  isSelf,
  pollRate,
  room,
}) => {
  if (!room) { return null; }

  const classes = useStyles();

  const [localStatMap, setlocalStatMap] = useState(new Map());
  const blocklistFilter = ['certificate', 'remote-candidate', 'local-candidate', 'transport'];
  // const whitelistFilter = ['codec', 'track', 'outbound-rtp', inbound-rtp, 'candidate-pair'];
  const filterStats = (res) => {
    if (res) {
      // eslint-disable-next-line no-unused-vars, array-callback-return
      Array.from(res.values()).map((statline, idx) => {
        if (!statline) {
          return;
        }
        if (!blocklistFilter.includes(statline.type)) {
          // console.log(statline.id, statline.type, statline);

          if (statline.type === 'codec' && statline.mimeType) {
            setlocalStatMap(new Map(localStatMap.set(statline.type, statline.mimeType)));
          }

          if (statline.type === 'track') {
            if (statline.frameWidth || statline.frameHeight) {
              setlocalStatMap(new Map(localStatMap.set('Frame Size', `${statline.frameWidth || '###'}x${statline.frameHeight || '###'}`)));
            }
            // if (statline.trackIdentifier) {
            //   setlocalStatMap(new Map(localStatMap.set('Track Id', statline.trackIdentifier)));
            // }
            // if (statline.framesSent) {
            //   setlocalStatMap(new Map(localStatMap.set('Frames Sent', statline.framesSent)));
            // }
          }

          if (isSelf && statline.type === 'candidate-pair') {
            if (statline.availableOutgoingBitrate) {
              setlocalStatMap(new Map(localStatMap.set('availableOutgoingBitrate',
                new Intl.NumberFormat().format(statline.availableOutgoingBitrate))));
            }
          }

          if (isSelf && statline.type === 'outbound-rtp') {
            // if (statline.ssrc) {
            //   setlocalStatMap(new Map(localStatMap.set('SSRC', statline.ssrc)));
            // }
            // if (statline.bytesSent) {
            //   setlocalStatMap(new Map(localStatMap.set('Bytes Sent', statline.bytesSent)));
            // }
            if (statline.qualityLimitationReason !== 'none') {
              setlocalStatMap(new Map(localStatMap.set('Quality Limiter', statline.qualityLimitationReason)));
            } else if (localStatMap.delete('Quality Limiter')) {
              setlocalStatMap(new Map(localStatMap));
            }
          }

          if (statline.type === 'inbound-rtp') {
            if (statline.framesDecoded) {
              setlocalStatMap(new Map(localStatMap.set('Frames Decoded (total)', new Intl.NumberFormat().format(statline.framesDecoded))));
            }

            if (statline.bitrate) {
              setlocalStatMap(new Map(localStatMap.set('Bitrate', new Intl.NumberFormat().format(statline.bitrate))));
            }

            // packet lost = (packetsLost+packetsDiscarded)/packetCount

            if (statline.score) {
              setlocalStatMap(new Map(localStatMap.set(`${isSelf ? 'Producer' : 'Consumer'} Score`, statline.score)));
            }
          }

          if (!isSelf && statline.type === 'outbound-rtp') {
            if (statline.score) {
              setlocalStatMap(new Map(localStatMap.set('Producer Score', statline.score)));
            }
          }
        }
      });
    }
  };

  useEffect(() => {
    const fetchStats = async () => {
      if (!room) { return; }
      if (isSelf) {
        if (participantId.includes('screenShare')) {
          room.getShareScreenLocalStats().then((pls) => {
            filterStats(pls);
          }).catch((e) => {
            console.error('getShareScreenLocalStats failed', e);
            bugsnagClient.notify(e);
          });

          room.getShareScreenRemoteStats().then((prs) => {
            // console.log('prs', Array.from(prs.values()));
            filterStats(prs);
          }).catch((e) => {
            console.error('getShareScreenRemoteStats failed', e);
            bugsnagClient.notify(e);
          });
        } else {
          room.getProducerLocalStats().then((pls) => {
            // console.log('pls', Array.from(pls.values()));
            filterStats(pls);
          }).catch((e) => {
            console.error('getProducerLocalStats failed', e);
            bugsnagClient.notify(e);
          });

          room.getProducerRemoteStats().then((prs) => {
            // console.log('prs', Array.from(newprs.values()));
            if (prs) filterStats(prs.sort((a, b) => a.bitrate - b.bitrate));
          }).catch((e) => {
            console.error('getProducerRemoteStats failed', e);
            bugsnagClient.notify(e);
          });
        }
      } else {
        room.consumers.forEach((consumer, consumerId) => {
          if (participantId.slice(participantId.indexOf('_') + 1) === consumer.appData.user.userId) {
            if (consumer.appData.share === participantId.includes('screenShare')) {
              const preferedSpatial = consumer.appData.PreferedLayers ? consumer.appData.PreferedLayers.spatialLayers : '-1';
              const actualSpatial = consumer.appData.ActualLayers || { spatialLayer: 'null' };
              room.getConsumerLocalStats(consumerId).then((cls) => {
                // console.log('cls', Array.from(cls.values()));
                filterStats(cls);
                setlocalStatMap(new Map(localStatMap.set('Layers', `P:${preferedSpatial} A:${actualSpatial.spatialLayer}`)));
              }).catch((e) => {
                console.error('getConsumerLocalStats failed', e);
                console.log('Failed Consumer User:', consumer.appData.user);
                bugsnagClient.notify(e);
              });
              room.getConsumerRemoteStats(consumerId).then((crs) => {
                // console.log('crs', Array.from(crs.values()));
                filterStats(crs);
              }).catch((e) => {
                console.error('getConsumerRemoteStats failed', e);
                console.log('Failed Consumer User:', consumer.appData.user);
                bugsnagClient.notify(e);
              });
            }
          }
        });
      }
    };

    const statsInterval = setInterval(() => {
      fetchStats();
    }, pollRate);

    return () => {
      clearInterval(statsInterval);
    };
  }, []);

  return (
    <List>
      <ListItem className={classes.stats}>
        {isSelf
          ? <FormattedMessage {...messages.IS_SELF} values={{ hostname: room.snaphdHostName }} />
          : <FormattedMessage {...messages.NOT_SELF} />}
      </ListItem>
      <ListItem className={classes.stats}>
        <FormattedMessage {...messages.HEADER} />
      </ListItem>
      <ListItem className={classes.stats}>
        <FormattedMessage
          {...messages.HEADER_IDS}
          values={{ participantId, attendeeId, audioId: attendeeAudioId }}
        />
      </ListItem>
      {localStatMap && [...localStatMap.keys()].map((stat) => (
        <Fragment key={stat}>
          <ListItem className={classes.stats}>
            <FormattedMessage
              {...messages.STAT_TEMP}
              values={{ label: stat, stat: localStatMap.get(stat) }}
            />
          </ListItem>
        </Fragment>
      ))}
    </List>
  );
};

React.memo(StatsOverlay);

StatsOverlay.defaultProps = {
  isSelf: false,
  pollRate: 3000,
  attendeeId: '',
  attendeeAudioId: '',
};

StatsOverlay.propTypes = {
  attendeeId: PropTypes.string,
  attendeeAudioId: PropTypes.string,
  participantId: PropTypes.string.isRequired,
  isSelf: PropTypes.bool,
  pollRate: PropTypes.number,
  room: PropTypes.elementType.isRequired,
};

const mapStateToProps = (state) => ({
  room: selectVideoRoom(state),
});

export default connect(mapStateToProps)(StatsOverlay);
