export const AUTHENTICATE_USER = 'events/AUTHENTICATE_USER';

/**
 * @param username
 * @param password
 * @return {{type: string, payload: {username: *, password: *}}}
 */
export const authenticateUser = (username, password) => ({
  type: AUTHENTICATE_USER,
  payload: { username, password },
});
