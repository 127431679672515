import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import classnames from 'classnames';
import attendeeShape from '../../shapes/attendees';
import gravatarSessionId from '../../utils/gravatarSessionId';

const useStyles = makeStyles(() => ({
  avatarContainer: {
    position: 'relative',
    display: 'inline-block',
  },
  listGravatar: {
    opacity: 1,
    marginRight: 4,
    float: 'left',
  },
  speaking: {
    opacity: 1,
    boxShadow: '0 0 2px 0 rgba(0,0,0,0.5), 0 0 4px 2px #1A73E8',
    transition: 'box-shadow 0.3s ease-in-out',
  },
  listInitials: {
    display: 'block',
    width: 40,
    height: 40,
    opacity: 1,
    marginRight: 4,
    borderRadius: 50,
    color: '#fff',
    lineHeight: '40px',
    whiteSpace: 'nowrap',
    backgroundColor: '#b9b9b9',
    position: 'absolute',
    textAlign: 'center',
    overflow: 'hidden',
  },
  listFiller: {
    marginTop: 7,
  },
}));

const AttendeeAvatar = ({ attendee, isActive }) => {
  const classes = useStyles();

  let userInitials = '';
  if (attendee.initials) {
    userInitials = attendee.initials;
    userInitials = userInitials.slice(0, 3);
  } else if (attendee.name && attendee.name.trim()) {
    const userNameSplit = attendee.name.match(/\b\w/g);
    if (userNameSplit) {
      userInitials = userNameSplit.join('');
    }
    userInitials = userInitials.slice(0, 3);
  } else {
    userInitials = <AccountCircle className={classes.listFiller} />;
  }

  return (
    <div className={classnames('animate__animated animate__fadeIn', classes.avatarContainer)}>
      <span className={classnames(isActive && classes.speaking, classes.listInitials)}>
        {userInitials}
      </span>

      <Avatar
        className={classnames(isActive && classes.speaking, classes.listGravatar)}
        src={`https://www.gravatar.com/avatar/${attendee.gravatar}?s=42&d=blank&hash=${gravatarSessionId}`}
      />
    </div>
  );
};

AttendeeAvatar.defaultProps = {
  isActive: false,
};

AttendeeAvatar.propTypes = {
  attendee: attendeeShape.propTypesShape.isRequired,
  isActive: PropTypes.bool,
};

export default AttendeeAvatar;
