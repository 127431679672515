import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import VolumeUp from '@material-ui/icons/VolumeUp';
import _ from 'lodash';

import {
  selectConnectedAttendees,
  selectAllSpeakerActivity,
  selectVideoMuteHide,
  selectMediaStatus,
  selectVideoGridIds,
  selectParticipants,
  selectVideoHideSelf,
} from '../../../../selectors';

const useStyles = makeStyles(() => ({
  rectangle2: {
    minHeight: '32px',
    borderRadius: '100px',
    backgroundColor: 'rgba(0,0,0,0.54) !important',
    display: 'inline-flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  name: {
    padding: '5px 5px 5px 10px',
    maxWidth: '180px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  volume: {
    height: '27px',
    width: '27px',
    top: '1px',
    right: '1px',
    position: 'relative',
  },
  speaking: {
    borderRadius: '50%',
    backgroundColor: '#FAFAFA',
  },
  volumeUp: {
    color: '#1A73E8',
  },
  container: {
    right: '1%',
    position: 'relative',
    zIndex: 2,
    maxWidth: '250px',
  },
  outerContainer: {
    position: 'relative',
    width: '100%',
    textAlign: 'right',
  },
  group3: {
    height: '32px',
    display: 'inline-flex',
    padding: '5px',
  },
}));

const SpeakerBubbles = () => {
  const classes = useStyles();
  const speakerActivity = useSelector(selectAllSpeakerActivity);
  const mediaStatuses = useSelector(selectMediaStatus);
  const attendees = useSelector(selectConnectedAttendees);
  const hideMuted = useSelector(selectVideoMuteHide);
  const hideSelf = useSelector(selectVideoHideSelf);
  const videoGridIds = useSelector(selectVideoGridIds);
  const participants = useSelector(selectParticipants);

  // if the participants sidebar tab is open, return empty
  if (attendees === undefined) {
    return null;
  }

  return (
    <div className={classnames(classes.outerContainer)}>
      <div className={classnames(classes.container)}>
        {attendees.filter((attendee) => !!(_.get(speakerActivity, [_.get(attendee, 'attendee_id'), 'isActive'])
            && ((hideMuted && _.get(mediaStatuses, [_.get(attendee, 'id'), 'videoMuted']))
            // eslint-disable-next-line max-len
              || _.isUndefined(_.find(videoGridIds, (id) => _.toString(_.get(participants, [id, 'audioId'])) === _.toString(attendee.audio_id)))
              || (attendee.isSelf && hideSelf)
            )
        )).map((attendee) => (
          <div key={attendee.attendee_id} className={classnames('animate__animated animate__fadeIn', classes.group3)}>
            <div className={classnames(classes.rectangle2)}>
              {!attendee.isSelf && (
              <div className={classnames(classes.name)}>
                {attendee.name}
              </div>
              )}
              {attendee.isSelf && (
              <div className={classnames(classes.name)}>You</div>
              )}
              <div className={classnames(classes.speaking)}>
                <div className={classnames(classes.volume)}>
                  <VolumeUp
                    className={classes.volumeUp}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(SpeakerBubbles);
