import { put, select } from 'redux-saga/effects';

import * as actions from '../../actions';
import * as selectors from '../../selectors';
import * as services from '../../services';
import { setMeeting, logoutUser } from '../../events';
import { setRoute } from './setRoute';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* showMeetingHubPage({ payload }) {
  try {
    let { type } = payload;
    if (!type) {
      type = 'meeting';
    }

    yield put(actions.showLoaderPage());
    yield put(setMeeting(null));
    yield setRoute({ title: null, path: '' });

    const user = yield select((state) => selectors.selectUser(state));

    localStorage.setItem('nsVideoCallLive', false);
    // guests can never get to the hub
    if (!user) {
      window.location.reload();
      return;
    }

    const audioSession = yield select((state) => selectors.selectAudioSession(state));
    if (audioSession) {
      audioSession.bye({ reason_phrase: 'Sent to Meeting Hub' });
      window.location.reload();
      return;
    }

    if (user.scope === 'Meeting Guest') {
      yield put(logoutUser());
      return;
    }

    yield services.loadHubData({
      user: user.userId,
      domain: user.domain,
    });

    yield put(actions.showMeetingHubPage(type));
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: showMeetingHubPage';
      event.addMetadata('payload', payload);
    });
  }
}
