import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';
import useWindowSize from 'react-use/lib/useWindowSize';

import { UC_DRAWER_WIDTH, LAYOUT_ROTATE_LAND } from '../../../../../constants';
import * as selectors from '../../../../../selectors';

const useStyles = makeStyles(() => ({
  layoutContainer: {
    height: 'calc(100% - 80px)', // minus header height(48)+margins(12+12)
    position: 'relative',
    transition: 'width 0.3s ease-in-out',
  },
  mobileVertical: {
    height: '100%', // minus header height(48)+margins(12+12)
    position: 'relative',
    transition: 'width 0.3s ease-in-out',
  },
  mobileLandscape: {
    width: '100%', // minus header height(48)+margins(12+12)
    position: 'relative',
    transition: 'width 0.3s ease-in-out',
  },
  layoutContainerFull: {
    height: '100%',
  },
}));

const LayoutContainer = ({
  ucInboxIsOpen,
  children,
  isMobileView,
  orientation,
}) => {
  const classes = useStyles();
  const { width, height } = useWindowSize();

  const isFullscreen = document.fullscreenElement
    || document.mozFullScreenElement
    || document.webkitFullscreenElement
    || document.msFullscreenElement;

  if (isMobileView === 1) {
    return (
      <div
        className={classnames(
          orientation === LAYOUT_ROTATE_LAND && classes.mobileLandscape,
          orientation !== LAYOUT_ROTATE_LAND && classes.mobileVertical,
          { [classes.layoutContainerFull]: isFullscreen },
        )}
        style={{
          width,
          height: `calc(${height} - 8%)`,
          display: 'flex',
        }}
      >
        {children}
      </div>
    );
  }

  return (
    <div
      className={classnames(
        classes.layoutContainer,
        { [classes.layoutContainerFull]: isFullscreen },
      )}
      style={{ width: (ucInboxIsOpen) ? width - UC_DRAWER_WIDTH : width }}
    >
      {children}
    </div>
  );
};

LayoutContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.array,
  ]).isRequired,
  ucInboxIsOpen: PropTypes.bool.isRequired,
  isMobileView: PropTypes.number.isRequired,
  orientation: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  ucInboxIsOpen: selectors.selectUcIsOpen(state),
  isMobileView: selectors.selectMIV(state),
  orientation: selectors.selectOrientaion(state),
});

export default connect(mapStateToProps)(LayoutContainer);
