import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import useMediaDevices from 'react-use/lib/useMediaDevices';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { updateMediaStatus } from '../../../../actions';
import getBestMediaResolution from '../../../../services/getBestMediaResolution';
import mediaStatusShape from '../../../../shapes/mediaStatus';
import getAspectRatio from '../../../../utils/getAspectRatio';
import {
  getDefaultVideo,
  getFilteredVideo,
  stopStream,
} from '../../../../utils/devices';
import {
  selectUserMediaStatus,
} from '../../../../selectors';
import bugsnagClient from '../../../../bugsnag';

const messages = defineMessages({
  CAM_INPUT_LABEL: {
    id: 'CAM_INPUT_LABEL',
    defaultMessage: 'Camera',
  },
  NO_DEVICES: {
    id: 'NO_DEVICES',
    defaultMessage: 'No devices detected',
  },
  NO_LABEL_MESSAGE: {
    id: 'NO_LABEL_MESSAGE',
    defaultMessage: 'Default cam device',
  },
  BLOCKED_BY_BROWSER: {
    id: 'BLOCKED_BY_BROWSER',
    defaultMessage: 'Blocked by browser',
  },
  CAMERA_DISABLED_TOOLTIP: {
    id: 'CAMERA_DISABLED_TOOLTIP',
    defaultMessage: 'Your camera is blocked by your browser. Click the blocked media icon in your browser\'s address bar to allow camera use and then refresh',
  },
});

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth: 240,
  },
}));

const CamDevice = ({
  mediaStatus,
  updateMediaStatusAction,
}) => {
  const { devices } = useMediaDevices();
  const classes = useStyles();

  const {
    hasCamDevice,
    hasCamPermissions,
    videoDevice,
    videoStream,
    videoMuted,
  } = mediaStatus;

  const [defaultVideo, setDefaultVideo] = useState(null);
  const [videoDevices, setVideoDevices] = useState([]);

  const setSelectedVideo = (selectedDevice) => {
    updateMediaStatusAction({
      userId: mediaStatus.userId,
      videoDevice: selectedDevice,
    });
  };

  // update videoInput
  useEffect(() => {
    const myLastVideoInput = localStorage.getItem('myLastVideoInput');
    if (videoDevice && myLastVideoInput !== _.get(videoDevice, 'deviceId')) {
      localStorage.setItem('myLastVideoInput', _.get(videoDevice, 'deviceId'));

      if (videoStream) {
        stopStream(videoStream);
        updateMediaStatusAction({
          userId: mediaStatus.userId,
          videoStream: null,
        });
      }

      if (!videoMuted) {
        console.debug('FormCamDevice getBestMediaResolution line 75');
        getBestMediaResolution(_.get(videoDevice, 'deviceId'))
          .then((stream) => {
            updateMediaStatusAction({
              userId: mediaStatus.userId,
              videoStream: stream,
              videoAspectRatio: getAspectRatio(stream),
            });
          }).catch((err) => {
            console.error(err);
            bugsnagClient.notify(err);
          });
      }
    }
  }, [videoDevice]);

  // initialize devices
  useEffect(() => {
    if (devices) {
      const filteredDefaultVideo = getDefaultVideo(devices);
      const filteredVideoDevices = getFilteredVideo(devices);
      setDefaultVideo(filteredDefaultVideo);
      setVideoDevices(filteredVideoDevices);
      setSelectedVideo(filteredDefaultVideo);
    }
  }, [devices]);

  // check if selected video device is still connected
  useEffect(() => {
    if (videoDevice) {
      const filter = {};
      if (videoDevice.groupId) {
        filter.groupId = videoDevice.groupId;
      } else {
        filter.deviceId = videoDevice.deviceId;
      }

      const stillExists = !!_.filter(videoDevices, filter).length;

      if (!stillExists) {
        setSelectedVideo(null);
      }
    }
  }, [videoDevices]);

  const handleDeviceChange = (e) => {
    const id = e.target.value;
    const match = _.find(getFilteredVideo(videoDevices), { groupId: id });
    if (match) {
      setSelectedVideo(match);
    } else {
      setSelectedVideo(_.find(getFilteredVideo(videoDevices), { deviceId: id }));
    }
  };

  const selectedDevice = _.get(videoDevice, 'groupId')
    || _.get(videoDevice, 'deviceId')
    || _.get(defaultVideo, 'groupId')
    || _.get(defaultVideo, 'deviceId')
    || '';
  const [openTT, setOpen] = useState(false);
  const handleClick = () => { setOpen((prev) => !prev); };
  const handleClickAway = () => { setOpen(false); };

  const hasDeviceLabel = videoDevices.find((device) => device.label !== '') !== undefined;

  return (
    <>
      <InputLabel id="cam">
        <FormattedMessage {...messages.CAM_INPUT_LABEL} />
      </InputLabel>
      {hasCamDevice && hasCamPermissions !== false && hasDeviceLabel && (
        <TextField
          select
          id="DeviceSettingsCam"
          labelid="cam"
          variant="filled"
          margin="dense"
          value={selectedDevice}
          onChange={handleDeviceChange}
          disabled={!videoDevice && !!defaultVideo}
          fullWidth
        >
          {videoDevices.map((device) => (
            <MenuItem
              key={device.groupId || device.deviceId}
              value={device.groupId || device.deviceId}
            >
              {device.label}
            </MenuItem>
          ))}
        </TextField>
      )}
      {(!hasCamDevice || hasCamPermissions === false || !hasDeviceLabel) && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Tooltip
            title={<FormattedMessage {...messages.CAMERA_DISABLED_TOOLTIP} />}
            classes={{ tooltip: classes.customWidth }}
            open={openTT}
          >
            <TextField
              select
              onClick={handleClick}
              labelid="cam"
              variant="filled"
              margin="dense"
              value={1}
              fullWidth
              disabled
            >
              {!hasCamDevice && (
              <MenuItem value={1}>
                <FormattedMessage {...messages.NO_DEVICES} />
              </MenuItem>
              )}
              {!hasCamPermissions && (
              <MenuItem value={1}>
                <FormattedMessage {...messages.BLOCKED_BY_BROWSER} />
              </MenuItem>
              )}
              {!hasDeviceLabel && (
              <MenuItem value={1}>
                <FormattedMessage {...messages.NO_LABEL_MESSAGE} />
              </MenuItem>
              )}
            </TextField>
          </Tooltip>
        </ClickAwayListener>
      )}
    </>
  );
};

CamDevice.propTypes = {
  mediaStatus: mediaStatusShape.propTypesShape.isRequired,
  updateMediaStatusAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  mediaStatus: selectUserMediaStatus(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateMediaStatusAction: updateMediaStatus,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CamDevice);
