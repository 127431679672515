export const SSO_ENROLL_SUBMIT = 'events/SSO_ENROLL_SUBMIT';

/**
 * @param username
 * @param password
 * @param idtoken
 * @param vendor
 * @return {{type: string, payload: {username: *,password: *,idtoken: *,vendor: *}}}
 */
export const ssoEnrollSubmit = (username, password, idtoken, vendor) => ({
  type: SSO_ENROLL_SUBMIT,
  payload: {
    username, password, idtoken, vendor,
  },
});
