import nsToken from '@netsapiens/netsapiens-js/dist/token';
import { put, select } from 'redux-saga/effects';
import crypto from 'crypto-browserify';

import * as actions from '../../actions';
import * as selectors from '../../selectors';
import bugsnagClient from '../../bugsnag';

export default function* nsSocketSubscribe() {
  try {
    const { confId, videoBridgeId } = yield select((state) => state.configs);
    const userMediaStatus = yield select((state) => selectors.selectUserMediaStatus(state));
    const socket = yield select((state) => selectors.selectSocket(state));
    const token = nsToken.get();
    const user = yield select((state) => selectors.selectUser(state));
    const speechDetectionToggle = yield select((state) => selectors.selectConfig(state, 'videoAllowSpeechDetection'));
    const meeting = yield select((state) => selectors.selectMeeting(state));
    const myAttendee = yield select((state) => selectors.selectMyAttendee(state));
    const isMiv = yield select((state) => selectors.selectMIV(state));

    if (isMiv !== 1) {
      socket.subscriptions.push({
        type: 'ucinbox',
        domain: user.domain,
        userId: user.userId,
        filter: confId,
        bearer: token,
      });

      // subscribe to the ALL messages to the group
      socket.subscriptions.push({
        type: 'uc-mesg',
        domain: user.domain,
        filter: meeting.chat_sessionid,
        bearer: token,
      });

      // subscribe to private sessions
      socket.subscriptions.push({
        type: 'uc-mesg',
        domain: user.domain,
        filter: meeting.attendee_id,
        bearer: token,
        userPrivate: user.userId,
        attendeeId: meeting.attendee_id,
      });

      // subscribe to the host chat if it is the case
      if (myAttendee && (myAttendee.role === 'host' || myAttendee.role === 'presenter')) {
        socket.subscriptions.push({
          type: 'uc-mesg',
          domain: user.domain,
          filter: `hosts${crypto.createHash('md5').update(meeting.chat_sessionid).digest('hex')}`,
          meeting_id: meeting.id,
          attendeeId: meeting.attendee_id,
          bearer: token,
        });
      }

      // subscribe to 1 on 1 chats stemming from this meeting
      socket.subscriptions.push({
        type: 'uc-mesg',
        domain: user.domain,
        filter: `video-${user.userId}`,
        bearer: token,
      });
    }

    socket.subscriptions.push({
      ...userMediaStatus,
      type: 'video',
      domain: user.domain,
      filter: confId,
      bearer: token,
    });

    // listen for meeting_event
    socket.subscriptions.push({
      type: 'meeting_event',
      domain: user.domain,
      filter: meeting.id,
      bearer: token,
    });

    // TODO need to put knob here
    if (speechDetectionToggle === 'yes') {
      socket.subscriptions.push({
        type: 'energy',
        domain: user.domain,
        filter: videoBridgeId,
        bearer: token,
      });
    }

    socket.subscriptions.push({
      type: 'meeting',
      domain: user.domain,
      filter: confId,
      bearer: token,
    });

    socket.subscriptions.forEach((sub) => socket.emit('subscribe', sub));

    yield put(actions.setSocket(socket));

    return Promise.resolve();
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: nsSocketSubscribe';
    });
    return Promise.reject(err);
  }
}
