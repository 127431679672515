import React, { useMemo } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';

import LayoutContainer from './components/LayoutContainer';
import ParticipantsGrid from './components/ParticipantsGrid';
import Tray from '../video-tray/VideoTray';
import {
  selectVideoGridIds,
  selectVideoTrayOpen,
  selectParticipants,
  selectVideoMuteHide,
  selectVideoHideSelf,
} from '../../../../selectors';
import { LAYOUT_TYPE_SPOTLIGHT } from '../../../../constants';

const useStyles = makeStyles(() => ({
  closedHeight: {
    height: 'calc(100vh - 72px)',
  },
  openHeight: {
    marginTop: 8,
    height: 'calc(100vh - 230px)',
  },
  closedHeightFull: {
    height: 'calc(100vh)',
  },
  openHeightFull: {
    marginTop: 8,
    height: 'calc(100vh - 158px)',
  },
}));

const SpotlightLayout = () => {
  const classes = useStyles();
  const videoGridIds = useSelector(selectVideoGridIds);
  const videoTrayOpen = useSelector(selectVideoTrayOpen);
  const participants = useSelector(selectParticipants);
  const muteHide = useSelector(selectVideoMuteHide);
  const hideSelf = useSelector(selectVideoHideSelf);

  const participantArr = useMemo(() => {
    let filtered = videoGridIds;

    if (muteHide) {
      filtered = filtered.filter(
        (id) => _.get(participants, [id, 'videoTrackMuted']) !== true,
      );
    }

    if (hideSelf) {
      filtered = filtered.filter(
        (id) => _.get(participants, [id, 'isSelf']) !== true,
      );
    }

    if (filtered.length) {
      return [filtered[0]];
    }
    return [];
  }, [videoGridIds, muteHide, hideSelf]);

  const isFullscreen = document.fullscreenElement
    || document.mozFullScreenElement
    || document.webkitFullscreenElement
    || document.msFullscreenElement;

  let containerClass;
  if (videoTrayOpen && videoGridIds.length > 1) {
    if (isFullscreen) {
      containerClass = classes.openHeightFull;
    } else {
      containerClass = classes.openHeight;
    }
  } else {
    containerClass = isFullscreen
      ? classes.closedHeightFull
      : containerClass = classes.closedHeight;
  }

  return (
    <LayoutContainer>
      <div className={containerClass}>
        <ParticipantsGrid participantIds={participantArr} layout={LAYOUT_TYPE_SPOTLIGHT} />
      </div>
      <Tray />
    </LayoutContainer>
  );
};

export default SpotlightLayout;
