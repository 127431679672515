import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { defineMessages, FormattedMessage } from 'react-intl';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { toggleUcInbox } from '../../../../events';

const messages = defineMessages({
  CLOSE: {
    id: 'CLOSE',
    defaultMessage: 'Close',
  },
});

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: '#FAFAFA',
    padding: theme.spacing(0.5),
  },
}));

const SideTrayHeader = () => {
  const classes = useStyle();
  const dispatch = useDispatch();

  const handleToggle = () => {
    dispatch(toggleUcInbox());
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item>
          <Tooltip
            title={<FormattedMessage {...messages.CLOSE} />}
            placement="bottom-start"
          >
            <IconButton
              id="SideTrayClose"
              size="small"
              onClick={handleToggle}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(SideTrayHeader);
