import { put, select } from 'redux-saga/effects';
// import _ from 'lodash';
import * as actions from '../../actions';
import * as events from '../../events';
import * as selectors from '../../selectors';
import getAspectRatio from '../../utils/getAspectRatio';
import getBestMediaResolution from '../../services/getBestMediaResolution';
import { getMediaDevices, getMIVVideo, stopStream } from '../../utils/devices';
import bugsnagClient from '../../bugsnag';

// import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* cycleVideoDevices({ payload }) {
  try {
    const {
      videoStream,
      userId,
    } = yield select(selectors.selectUserMediaStatus);

    const { facingMode } = payload;

    const devices = yield getMediaDevices();
    const deviceIdMap = yield select((state) => selectors.selectCameraMap(state));
    const map = facingMode === 'user' ? deviceIdMap.user : deviceIdMap.environment;
    const videoDevice = getMIVVideo(devices, map);

    if (videoStream) {
      stopStream(videoStream);
      yield put(actions.updateMediaStatus({
        userId,
        videoStream: null,
      }));
    }

    const stream = yield getBestMediaResolution(videoDevice.deviceId);
    yield put(actions.updateMediaStatus({
      userId,
      videoStreamSelect: stream,
      videoAspectRatioSelect: getAspectRatio(stream),
    }));

    yield put(events.saveSelectedDevices());
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: cycleVideoDevices';
      event.addMetadata('payload', payload);
    });
  }
}
