export const SHOW_MEETING_CONFIG = 'events/SHOW_MEETING_CONFIG';

/**
 * @param configMode
 * @param configType
 * @param meeting
 * @return {{type: string}}
 */
export const showMeetingConfig = (configMode, configType, meeting, skipConfig) => ({
  type: SHOW_MEETING_CONFIG,
  payload: {
    configMode, configType, meeting, skipConfig,
  },
});
