import nsConfig from '@netsapiens/netsapiens-js/dist/config';
import { select } from 'redux-saga/effects';

import * as selectors from '../../selectors';

export default function* configureSdk() {
  const sdkConfig = yield select((state) => ({
    apiURL: selectors.selectConfig(state, 'apiURL'),
    appName: selectors.selectConfig(state, 'appName'),
    portalURL: selectors.selectConfig(state, 'portalURL'),
    tokenStorageUserId: true,
  }));
  const userId = yield select((state) => selectors.selectConfig(state, 'userId'));
  if (userId) { sdkConfig.userId = userId; }
  nsConfig.configure(sdkConfig);
}
