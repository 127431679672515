import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import * as actions from '../../actions';
import * as events from '../../events';
import * as selectors from '../../selectors';

const messages = defineMessages({
  ALLOW_CAMERA: {
    id: 'ALLOW_CAMERA',
    defaultMessage: 'Allow us to use your camera',
  },
  ALLOW_MICROPHONE: {
    id: 'ALLOW_MICROPHONE',
    defaultMessage: 'Allow us to use your microphone',
  },
  BLOCKED_CAMERA_DETAILS: {
    id: 'BLOCKED_CAMERA_DETAILS',
    defaultMessage: 'Your camera is blocked by your browser. Click the blocked media icon in your browser\'s address bar to allow camera use and then refresh.',
  },
  BLOCKED_MICROPHONE_DETAILS: {
    id: 'BLOCKED_MICROPHONE_DETAILS',
    defaultMessage: 'Your microphone is blocked by your browser. Click the blocked media icon in your browser\'s address bar to allow microphone use and then refresh.',
  },
  BLOCKED_SCREENSHARE_DETAILS: {
    id: 'BLOCKED_SCREENSHARE_DETAILS',
    defaultMessage: 'Your screenshare is blocked by your browser. Click the blocked media icon in your browser\'s address bar to allow camera use and then refresh.',
  },
  DISMISS: {
    id: 'DISMISS',
    defaultMessage: 'DISMISS',
  },
});

const useStyles = makeStyles(() => ({
  contentText: { width: 500 },
}));

const PendingPermissionsDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => selectors.selectLayout(state).showPermissionsDialog);
  const userMedia = useSelector(selectors.selectUserMediaStatus);

  let title = '';
  let infoText = '';

  if (isOpen && userMedia) {
    if (isOpen === events.TOGGLE_VIDEO_MUTE) {
      title = <FormattedMessage {...messages.ALLOW_CAMERA} />;
      infoText = <FormattedMessage {...messages.BLOCKED_CAMERA_DETAILS} />;
    } else if (isOpen === events.TOGGLE_AUDIO_MUTE) {
      title = <FormattedMessage {...messages.ALLOW_MICROPHONE} />;
      infoText = <FormattedMessage {...messages.BLOCKED_MICROPHONE_DETAILS} />;
    } else if (isOpen === events.START_SCREENSHARE) {
      title = <FormattedMessage {...messages.ALLOW_CAMERA} />;
      infoText = <FormattedMessage {...messages.BLOCKED_SCREENSHARE_DETAILS} />;
    }
  }

  const handleDismiss = () => {
    dispatch(actions.showPermissionsDialog(false)); // close the modal
  };

  return (
    <Dialog id="PendingPermissionsDialog" open={!!isOpen}>
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.contentText}>
          {infoText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button id="PendingPermissionsDialogDismiss" onClick={handleDismiss} color="primary">
          <FormattedMessage {...messages.DISMISS} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PendingPermissionsDialog;
