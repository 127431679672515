export const TOGGLE_VIDEO_ITEM_FOCUS = 'events/TOGGLE_VIDEO_ITEM_FOCUS';

/**
 * @param id
 * @param itemType
 * @param toggleSpotlight
 * @return {{type: string}}
 */
export const toggleVideoItemFocus = (id, itemType, toggleSpotlight) => ({
  type: TOGGLE_VIDEO_ITEM_FOCUS,
  payload: {
    id,
    itemType,
    toggleSpotlight,
  },
});
