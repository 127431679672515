export const MFA_SUBMIT = 'events/MFA_SUBMIT';

/**
 * @param passcode
 * @param username
 * @param mfaType
 * @param mfaVendor
 * @return {{type: string, payload: {passcode: *,username: *,mfaType: *,mfaVendor: *}}}
 */
export const mfaSubmit = (passcode, username, mfaType, mfaVendor) => ({
  type: MFA_SUBMIT,
  payload: {
    passcode, username, mfaType, mfaVendor,
  },
});
