export const SUBMIT_REGISTRATION = 'events/SUBMIT_REGISTRATION';

/**
 * @param meeting_id
 * @param name
 * @param email
 * @return {{type: string, payload: {meeting_id: *, name: *, email:"*"}}}
 */
export const submitRegistration = (registrationId, name, email) => ({
  type: SUBMIT_REGISTRATION,
  payload: { registrationId, name, email },
});
