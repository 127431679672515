import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Settings from '@material-ui/icons/Settings';
import Lock from '@material-ui/icons/Lock';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';

import FullScreenMenuItem from '../menu-items/FullScreenMenuItem';
import GlobalStatMenuItem from '../menu-items/GlobalStatMenuItem';
import HideMutedMenuItem from '../menu-items/HideMutedMenuItem';
import HideSelf from '../menu-items/HideSelf';

import * as actions from '../../../../../actions';
import * as events from '../../../../../events';
import * as selectors from '../../../../../selectors';

import attendeesShape from '../../../../../shapes/attendees';
import meetingShape from '../../../../../shapes/meeting';
import mediaStatusShape from '../../../../../shapes/mediaStatus';

const messages = defineMessages({
  MEETING_SETTINGS: {
    id: 'MEETING_SETTINGS',
    defaultMessage: 'Meeting Settings',
  },
  DEVICE_SETTINGS: {
    id: 'DEVICE_SETTINGS',
    defaultMessage: 'Device settings',
  },
  EDIT_MEETING: {
    id: 'EDIT_MEETING',
    defaultMessage: 'Edit meeting',
  },
  LOCK_MEETING: {
    id: 'LOCK_MEETING',
    defaultMessage: 'Lock meeting',
  },
  UNLOCK_MEETING: {
    id: 'UNLOCK_MEETING',
    defaultMessage: 'Unlock meeting',
  },
  START_RECORDING: {
    id: 'START_RECORDING',
    defaultMessage: 'Start recording',
  },
  STOP_RECORDING: {
    id: 'STOP_RECORDING',
    defaultMessage: 'Stop recording',
  },
  START_A_POLL: {
    id: 'START_A_POLL',
    defaultMessage: 'Start a poll',
  },
});

const useStyles = makeStyles(() => ({
  settingsMenu: {
    color: '#555',
    marginTop: 48,
    marginRight: 48,
    '& svg': {
      marginRight: 12,
    },
  },
}));

const MeetingSettings = ({
  // inherited
  variant,
  classes,

  // stateful
  configs,
  mediaStatus,
  meeting,
  myAttendee,

  // dispatch
  openDeviceReconfigModalAction,
  saveMeeting,
  setMeeting,
  snackBarMessage,
  partialMeetingUpdateAction,
}) => {
  const localClasses = useStyles();

  const [meetingSettingsAnchorEl, setMeetingSettingsAnchorEl] = useState(null);

  const openMeetingSettingsMenu = (event) => {
    setMeetingSettingsAnchorEl(event.target);
  };

  const handleClose = () => {
    setMeetingSettingsAnchorEl(null);
  };

  const openDeviceSettings = () => {
    openDeviceReconfigModalAction(true);
    handleClose();
  };

  const lockMeeting = () => {
    const updateMeeting = meeting;
    updateMeeting.lock = Number(!meeting.lock);
    setMeeting({ ...updateMeeting });
    saveMeeting('edit', () => {
      snackBarMessage(updateMeeting.lock ? 'HOST_LOCKED_MEETING' : 'HOST_UNLOCKED_MEETING');
    }, true);
    handleClose();
  };

  const recordMeeting = () => {
    snackBarMessage(!meeting.record_enabled ? 'HOST_STARTED_RECORD' : 'HOST_STOPPED_RECORD');
    partialMeetingUpdateAction({ record_enabled: Number(!meeting.record_enabled) });
    handleClose();
  };

  return (
    <>
      <Tooltip title={<FormattedMessage {...messages.MEETING_SETTINGS} />}>
        <IconButton
          id="MeetingSettings"
          className={classnames(
            classes.icon,
            classes.btn,
            variant === 'small' && classes.btnSmall,
          )}
          onClick={openMeetingSettingsMenu}
        >
          <MoreHorizIcon />
        </IconButton>
      </Tooltip>

      <Menu
        keepMounted
        anchorEl={meetingSettingsAnchorEl}
        onClose={() => setMeetingSettingsAnchorEl(null)}
        open={Boolean(meetingSettingsAnchorEl)}
        className={localClasses.settingsMenu}
      >
        <MenuItem
          key="1"
          onClick={openDeviceSettings}
          disabled={(!mediaStatus.hasCamDevice && !mediaStatus.hasMicDevice)
          || (mediaStatus.hasCamPermissions === false && !mediaStatus.hasCamPermissions === false)}
        >
          <Settings />
          <FormattedMessage {...messages.DEVICE_SETTINGS} />
        </MenuItem>

        <HideMutedMenuItem menuItemKey="4" clickCallback={setMeetingSettingsAnchorEl} />

        <HideSelf menuItemKey="4" clickCallback={setMeetingSettingsAnchorEl} />

        <FullScreenMenuItem menuItemKey="3" clickCallback={setMeetingSettingsAnchorEl} />

        {configs.PORTAL_VIDEO_ALLOW_STAT_OVERLAY === 'yes'
          && !(meeting.type === 'presentation' && myAttendee.role === 'attendee')
          && (
            <GlobalStatMenuItem menuItemKey="4" clickCallback={setMeetingSettingsAnchorEl} />
          )}

        {myAttendee && myAttendee.role === 'host' && ([
          <hr key="5" />,
          <MenuItem key="6" onClick={lockMeeting}>
            <Lock />
            {meeting.lock
              ? <FormattedMessage {...messages.UNLOCK_MEETING} />
              : <FormattedMessage {...messages.LOCK_MEETING} />}
          </MenuItem>,
        ])}

        {myAttendee && myAttendee.role === 'host' && configs.videoAllowRecording === 'yes' ? (
          <MenuItem key="7" onClick={recordMeeting}>
            <FiberManualRecord />
            {meeting.record_enabled
              ? <FormattedMessage {...messages.STOP_RECORDING} />
              : <FormattedMessage {...messages.START_RECORDING} />}
          </MenuItem>
        ) : ''}
      </Menu>
    </>
  );
};

MeetingSettings.defaultProps = {
  variant: null,
};

MeetingSettings.propTypes = {
  variant: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  classes: PropTypes.shape({
    btn: PropTypes.string,
    btnSmall: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
  configs: PropTypes.shape({
    videoAllowRecording: PropTypes.string,
    PORTAL_VIDEO_ALLOW_STAT_OVERLAY: PropTypes.string,
  }).isRequired,
  openDeviceReconfigModalAction: PropTypes.func.isRequired,
  mediaStatus: mediaStatusShape.propTypesShape.isRequired,
  meeting: meetingShape.propTypesShape.isRequired,
  myAttendee: attendeesShape.propTypesShape.isRequired,
  saveMeeting: PropTypes.func.isRequired,
  setMeeting: PropTypes.func.isRequired,
  snackBarMessage: PropTypes.func.isRequired,
  partialMeetingUpdateAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  configs: state.configs,
  mediaStatus: selectors.selectUserMediaStatus(state),
  meeting: selectors.selectMeeting(state),
  myAttendee: selectors.selectMyAttendee(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  openDeviceReconfigModalAction: events.openDeviceReconfigModal,
  saveMeeting: events.saveMeeting,
  snackBarMessage: actions.snackBarMessage,
  setMeeting: actions.setMeeting,
  partialMeetingUpdateAction: events.partialMeetingUpdate,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MeetingSettings);
