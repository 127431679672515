import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import _ from 'lodash';
import { put, select } from 'redux-saga/effects';

import * as events from '../../events';
import * as selectors from '../../selectors';
import fetchCallParticipants from './services/fetchCallParticipants';
import { matchContact } from '../../utils';
import { ALLOWED_WEBINAR_ROLES } from '../../constants';
import bugsnagClient from '../../bugsnag';

export default function* addParticipants() {
  try {
    const user = yield select((state) => selectors.selectUser(state));
    const meeting = yield select((state) => selectors.selectMeeting(state));
    const myAttendee = yield select((state) => selectors.selectMyAttendee(state));
    const videoBridgeId = yield select((state) => selectors.selectConfig(state, 'videoBridgeId'));
    if (meeting.type === 'presentation' && _.get(myAttendee, 'role')
      && !ALLOWED_WEBINAR_ROLES.includes(_.get(myAttendee, 'role'))) {
      // Dont add own Participant here...
      return;
    }

    yield put(events.addParticipant({
      direction: 'outbound',
      displayName: user.displayName,
      email: user.email,
      id: `video_${user.userId}`,
      initials: user.initials,
      isSelf: true,
      gravatar: user.gravatar,
      type: 'video',
      status: 'active',
      uid: user.uid,
      userId: user.userId,
    }));

    const participants = yield select((state) => selectors.selectParticipants(state));
    const apiParticipants = yield fetchCallParticipants(videoBridgeId);

    for (let i = 0; i < apiParticipants.length; i += 1) {
      // check if the type of participant
      if (!apiParticipants[i].participant_match) continue; // eslint-disable-line no-continue

      const type = apiParticipants[i].participant_match.indexOf('vb') === -1 ? 'call' : 'video';

      // only handle calls
      if (type === 'video') {
        continue; // eslint-disable-line no-continue
      }

      if (apiParticipants[i].leader && apiParticipants[i].leader.includes('bridge-')) {
        continue; // eslint-disable-line no-continue
      }

      let id = apiParticipants[i].aor_user.split('-')[0];
      id = id.replace(/[^0-9+]/g, '').replace('+', '');

      if (id
        && apiParticipants[i].mode === 'active'
        && !participants[`call_${id}`]
      ) {
        // attempt to match one of the users contacts
        const contacts = yield select((state) => selectors.selectContacts(state));
        const contact = matchContact(contacts, id);

        // get the display name
        let displayName = apiParticipants[i].name;
        if (contact) {
          displayName = contact.name;
        } else if (!displayName) {
          // eslint-disable-next-line max-len
          const localizationNumber = yield select((state) => selectors.selectConfig(state, 'PORTAL_LOCALIZATION_NUMBER_FORMAT'));
          displayName = nsUtils.formatPhoneNumber(id, localizationNumber);
        }

        let email;
        if (contact && contact.email) {
          email = contact.email; // eslint-disable-line prefer-destructuring
        }

        // get the gravatar
        let gravatar = null;
        if (contact && contact.gravatar) {
          gravatar = contact.gravatar; // eslint-disable-line prefer-destructuring
        }

        let initials = null;
        if (contact && contact.initials) {
          initials = contact.initials; // eslint-disable-line prefer-destructuring
        }

        let uid = null;
        if (contact && contact.uid) {
          uid = contact.uid; // eslint-disable-line prefer-destructuring
        }

        yield put(events.addParticipant({
          direction: 'inbound',
          displayName,
          email,
          gravatar,
          id: `call_${id}`,
          initials,
          type,
          status: 'active',
          uid,
          userId: id,
        }));
      }
    }
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: addParticipant';
    });
  }
}
