import _ from 'lodash';
import nsApi from '@netsapiens/netsapiens-js/dist/api';

export default ({
  user,
  domain,
  limit,
  offset,
  id,
  instanceId,
  groupByInstance,
  getStart,
}) => {
  const params = {
    user,
    domain,
    object: 'meeting',
    action: 'readEvents',
    format: 'json',
    group_by_instance: groupByInstance || 1,
    limit: limit || 10,
    offset: offset || 0,
  };

  if (id) {
    params.meeting_id = id;
  }

  if (instanceId) {
    params.instance_id = instanceId;
  }

  if (getStart) {
    params.get_start = getStart;
  }

  return nsApi.get(params).then((res) => {
    if (typeof res.meeting_events !== 'undefined') {
      return res.meeting_events;
    }

    return res.map((meeting) => {
      const parsedMeeting = meeting;

      if (_.isString(parsedMeeting.attendees)) {
        parsedMeeting.attendees = JSON.parse(parsedMeeting.attendees);
      }

      const meetingStartEvent = meeting.events.find((event) => event.event === 'meeting_start');
      const meetingStartEventTime = meetingStartEvent && Date.parse(`${meetingStartEvent.timestamp} UTC`) / 1000;

      const meetingEndEvent = meeting.events.find((event) => event.event === 'meeting_end');
      let meetingEndEventTime;

      if (!meetingEndEvent) {
        meeting.events.forEach((event) => {
          if (event.event === 'part_depart') {
            const departTimeUnix = Date.parse(`${event.timestamp} UTC`) / 1000;

            if (departTimeUnix > (meetingEndEventTime || 0)) {
              meetingEndEventTime = departTimeUnix;
            }
          }
        });

        if (!meetingEndEvent) {
          meeting.events.forEach((event) => {
            if (event.event === 'meeting_completed') {
              meetingEndEventTime = Date.parse(`${event.timestamp} UTC`) / 1000;
            }
          });
        }
      } else {
        meetingEndEventTime = Date.parse(`${meetingEndEvent.timestamp} UTC`) / 1000;
      }

      parsedMeeting.start_time = meetingStartEventTime;
      parsedMeeting.end_time = meetingEndEventTime;

      parsedMeeting.actualDuration = meetingEndEventTime
        ? (meetingEndEventTime - meetingStartEventTime) / 60 : null;

      return parsedMeeting;
    });
  }).catch(() => []);
};
