import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import hark from 'hark';

import Grid from '@material-ui/core/Grid';
import MicOn from '@material-ui/icons/Mic';
import MicNone from '@material-ui/icons/MicNone';

import SettingsContainer from '../../../containers/SettingsContainer';
import mediaStatusShape from '../../../../shapes/mediaStatus';
import { selectUserMediaStatus } from '../../../../selectors';

const useStyles = makeStyles(() => ({
  settingContainer: {
    width: 128,
  },
  icon: {
    width: 72,
    height: 72,
  },
}));

const MicSetting = ({ mediaStatus }) => {
  const classes = useStyles();
  const [speaking, setSpeaking] = useState(false);

  useEffect(() => {
    if (mediaStatus.audioStreamSelect) {
      const speechEvents = hark(mediaStatus.audioStreamSelect);

      speechEvents.on('speaking', () => {
        setSpeaking(true);
      });

      speechEvents.on('stopped_speaking', () => {
        setSpeaking(false);
      });

      const newTrack = mediaStatus.audioStreamSelect.getTracks()[0];

      newTrack.onended = () => {
        setSpeaking(false);
        newTrack.stop();
        speechEvents.stop();
      };
    }
  }, [mediaStatus.audioStreamSelect]);

  return (
    <SettingsContainer>
      <Grid
        className={classes.settingContainer}
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Grid item>
          {speaking && (
            <MicOn
              className={classes.icon}
              style={{ opacity: (!speaking) ? 0.54 : 1 }}
              color="primary"
            />
          )}
          {!speaking && (
            <MicNone
              className={classes.icon}
              style={{ opacity: (!speaking) ? 0.54 : 1 }}
              color="inherit"
            />
          )}
        </Grid>
      </Grid>
    </SettingsContainer>
  );
};

MicSetting.propTypes = {
  mediaStatus: mediaStatusShape.propTypesShape.isRequired,
};

const mapStateToProps = (state) => ({
  mediaStatus: selectUserMediaStatus(state),
});

export default connect(mapStateToProps, undefined)(MicSetting);
