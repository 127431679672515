import { select } from 'redux-saga/effects';

import * as selectors from '../../selectors';

export default function* setTitle() {
  const title = yield select((state) => selectors.selectConfig(state, 'videoName')) || 'Video';
  const titleElem = document.createElement('title');
  titleElem.innerText = title;
  document.getElementsByTagName('head')[0].appendChild(titleElem);
}
