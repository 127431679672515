import { eventChannel } from 'redux-saga';
import { put, select, take } from 'redux-saga/effects';
import socketIO from 'socket.io-client/dist/socket.io.slim';

import * as actions from '../../actions';
import * as selectors from '../../selectors';
import bugsnagClient from '../../bugsnag';

export const SOCKET_CONNECT = 'SOCKET_CONNECT';
export const SOCKET_CONNECTION_ERROR = 'SOCKET_CONNECTION_ERROR';
export const SOCKET_DISCONNECT = 'SOCKET_DISCONNECT';
export const SOCKET_RECONNECT_ERROR = 'SOCKET_RECONNECT_ERROR';

let messageQueue = [];

// connect error
// eslint-disable-next-line no-unused-vars
const sendError = (message, opt) => {
  // eslint-disable-next-line no-unused-vars
  const { onLine } = navigator; // get network status at the time of the error

  // the loop handles offline cases
  // the error will send after the browser is back online
  const interval = setInterval(() => {
    if (navigator.onLine) {
      // bugsnagClient.notify(new Error(message), {
      //   severity: 'warning',
      //   metaData: { ...opt, onLine },
      // });
      clearInterval(interval);
    }
  }, 10000);
};

export default function* nsSocketConnect() {
  const nsSocketURLs = yield select((state) => selectors.selectConfig(state, 'nsSocketURLs'));

  try {
    const socket = socketIO.connect(
      nsSocketURLs[0], { secure: true, transports: ['websocket'], perMessageDeflate: false },
    );

    socket.subscriptions = [];

    // extend the emit function for queue messages
    const socketEmit = socket.emit;
    socket.emit = function newEmit(...args) {
      // call original socket emit method
      socketEmit.apply(this, args);

      // if sending pong then clear the queue
      // else add to queue if the message is not ping or pong
      if (args[0] === 'connect') {
        messageQueue.forEach((message) => socketEmit.apply(this, message));
        messageQueue = [];
      } else if (args[0] !== 'ping'
        && args[0] !== 'pong'
        && args[0] !== 'connect_error'
        && args[0] !== 'reconnect'
        && args[0] !== 'reconnecting'
        && args[0] !== 'reconnect_error'
        && args[0] !== 'reconnect_attempt'
        && args[0] !== 'disconnect'
        && args[0] !== 'subscribe'
        // && args[0] !== 'ucinbox_item' // this will prevent sending double chat messages
        // && args[0] !== 'video_status' // this might prevent duplicate status messages.
      ) {
        messageQueue.push(args);
      } else if (args[0] === 'video_status') {
        // Aaker: i dont really understand this messageQueue, but i know that it can build up
        // conflicting data which can cause a problem. We need to not let multiple video_status
        // objects build up.
        for (let i = 0; i < messageQueue.length; i += 1) {
          if (typeof messageQueue[i] === 'object' && typeof messageQueue[i][0] !== 'undefined') {
            if (messageQueue[i][0] === 'video_status') delete messageQueue[i];
          }
        }
        // messageQueue.push(args);
      }

      // else if (args[0] === 'video_status') {
      //   // Aaker: i dont really understand this messageQueue, but i know that it can build up
      //   // conflicting data which can cause a problem. We need to not let multiple video_status
      //   // objects build up.
      //   for (let i = 0; i < messageQueue.length; i += 1) {
      //     if (_.get(messageQueue, [i, 0]) === 'video_status') delete messageQueue[i];
      //   }
      //   messageQueue.push(args);
      // }
    };

    // create channel for callbacks
    const channel = eventChannel((emitter) => {
      socket.on('connect', () => {
        console.debug('connect');
        console.debug('subscriptions', socket.subscriptions);
        emitter(SOCKET_CONNECT);
        socket.subscriptions.forEach((sub) => socket.emit('subscribe', sub));
      });

      socket.on('disconnect', () => {
        console.debug('disconnect');
        emitter(SOCKET_DISCONNECT);
      });

      socket.on('reconnect_attempt', () => {
        console.debug('reconnect_attempt');
        emitter(SOCKET_RECONNECT_ERROR);
      });

      socket.on('error', () => {
        console.debug('error');
        emitter(SOCKET_CONNECTION_ERROR);
      });

      // unsubscribe method
      return () => {
        socket.disconnect();
      };
    });

    while (true) {
      const res = yield take(channel);

      if (res === SOCKET_DISCONNECT) {
        console.error('Socket disconnect');
        sendError('Socket disconnect', { connectionURL: nsSocketURLs[0] });
        yield put(actions.setConnected(false));

        if (navigator.onLine) {
          yield put(actions.snackBarError('NETWORK_LOST_CONNECTION_TOAST'));
        } else {
          yield put(actions.snackBarError('NETWORK_NO_CONNECTION_TOAST'));
        }
      } else if (res === SOCKET_CONNECTION_ERROR) {
        console.error('Socket connection');
        sendError('Socket connection', { connectionURL: nsSocketURLs[0] });
        yield put(actions.setConnected(false));

        if (navigator.onLine) {
          yield put(actions.snackBarError('NETWORK_LOST_CONNECTION_TOAST'));
        } else {
          yield put(actions.snackBarError('NETWORK_NO_CONNECTION_TOAST'));
        }
      } else if (res === SOCKET_RECONNECT_ERROR) {
        console.error('Initial socket connection');
        sendError('Initial socket connection', { connectionURL: nsSocketURLs[0] });
        yield put(actions.setConnected(false));
      } else {
        yield put(actions.setSocket(socket));
        yield put(actions.setConnected(true));
      }
    }
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: nsSocketConnect';
    });
    sendError('Initial socket connection', { connectionURL: nsSocketURLs[0] });
    yield put(actions.setConnected(false));
  }
}
