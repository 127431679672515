/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import useVideo from 'react-use/lib/useVideo';

import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { selectUserMediaStatus } from '../../../../selectors';

import SettingsContainer from '../../../containers/SettingsContainer';

import getAspectRatio from '../../../../utils/getAspectRatio';
import mediaStatusShape from '../../../../shapes/mediaStatus';

const useStyles = makeStyles(() => ({
  settingContainer: {
    width: 128,
  },
  displayHeight: {
    position: 'relative',
    overflow: 'hidden',
    height: 72,
  },
  video: {
    transform: 'scaleX(-1)',
    backgroundColor: '#000',
  },
  videoWidth: {
    width: 128,
  },
  videoWidthMax: {
    maxWidth: 128,
  },
  videoHeight: {
    height: 72,
  },
}));

const CamPreview = ({
  mediaStatus,
}) => {
  const classes = useStyles();

  const {
    videoStreamSelect,
  } = mediaStatus;

  // eslint-disable-next-line
  const [video, , , ref] = useVideo(
    <video className={classes.videoWidthMax} autoPlay />,
  );

  // initialize video
  useEffect(() => {
    if (videoStreamSelect) {
      const track = videoStreamSelect && videoStreamSelect.getVideoTracks()[0];

      if (track && ref.current) {
        let videoClass = classes.videoHeight;
        if (getAspectRatio(videoStreamSelect) === '4:3') {
          videoClass = classes.videoWidth;
        }

        ref.current.className = classnames(classes.video, videoClass, classes.videoWidthMax);
        ref.current.srcObject = videoStreamSelect;
      }
    }
  }, [videoStreamSelect, ref.current]);

  return (
    <SettingsContainer>
      <Grid
        className={classes.settingContainer}
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Grid item className={classes.displayHeight}>
          {video}
        </Grid>
      </Grid>
    </SettingsContainer>
  );
};

CamPreview.propTypes = {
  mediaStatus: mediaStatusShape.propTypesShape.isRequired,
};

const mapStateToProps = (state) => ({
  mediaStatus: selectUserMediaStatus(state),
});

export default connect(mapStateToProps)(CamPreview);
