import _ from 'lodash';

export default (messages, newMesg) => {
  const messagesClone = _.cloneDeep(messages);

  for (let i = 0; i < messagesClone.length; i += 1) {
    if (typeof messagesClone[i].id !== 'undefined' && typeof newMesg.id !== 'undefined'
        && newMesg.id !== messagesClone[i].id) {
      continue; // eslint-disable-line no-continue
    } else if (messagesClone[i].timestamp === newMesg.timestamp) {
      messagesClone[i].sending = false;
      if (typeof messagesClone[i].id === 'undefined' && typeof newMesg.id !== 'undefined') {
        messagesClone[i].id = newMesg.id;
      }
      if (typeof messagesClone[i].session_id === 'undefined' && typeof newMesg.session_id !== 'undefined') {
        messagesClone[i].session_id = newMesg.session_id;
      }

      return messagesClone;
    }
  }

  if (!messages || !newMesg) {
    return false;
  }

  return false;
};
