import { put, select } from 'redux-saga/effects';

import * as actions from '../../actions';
import * as selectors from '../../selectors';

// eslint-disable-next-line import/prefer-default-export
export function* toggleUcInbox() {
  const isOpen = yield select((state) => selectors.selectUcIsOpen(state));
  yield put(actions.setUcIsOpen(!isOpen));
}
