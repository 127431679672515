import { select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import * as selectors from '../../selectors';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* postVideoStatus({ payload }) {
  const user = yield select((state) => selectors.selectUser(state));
  const meeting = yield select((state) => selectors.selectMeeting(state));
  const myAttendee = yield select((state) => selectors.selectMyAttendee(state));
  let attendeeId = meeting.attendee_id;
  if (!attendeeId && myAttendee) attendeeId = myAttendee.attendee_id;

  if (attendeeId) {
    yield nsApi.post({
      attendee_id: attendeeId,
      meeting_id: meeting.id,
      user: user.userId || '',
      domain: user.domain,
      object: 'attendee',
      action: 'update',
      status_video: payload,
    }).catch((err) => {
      console.error(err);
      bugsnagClient.notify(err, (event) => {
        // eslint-disable-next-line no-param-reassign
        event.context = 'saga: postVideoStatus';
        event.addMetadata('payload', payload);
      });
    });
  }
}
