import nsToken from '@netsapiens/netsapiens-js/dist/token';
import { select } from 'redux-saga/effects';
import { selectConfig, selectSocket } from '../../selectors';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* emitVideoInvite({ payload }) {
  try {
    const decodedToken = nsToken.getDecoded();

    const confId = yield select((state) => selectConfig(state, 'confId'));

    const data = {
      type: 'invite',
      sender: decodedToken.user,
      receiver: payload,
      video_id: confId,
      domain: decodedToken.domain,
      displayName: decodedToken.displayName,
    };

    const socket = yield select((state) => selectSocket(state));
    socket.emit('video_invite', data);
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: emitVideoInvite';
      event.addMetadata('payload', payload);
    });
  }
}
