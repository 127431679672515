import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* updateSessionToRead({ payload }) {
  const decodedToken = nsToken.getDecoded();
  const { sessionId } = payload;

  try {
    yield nsApi.post({
      object: 'messagesession',
      action: 'update',
      domain: decodedToken.domain,
      user: payload.fromAttendeeId,
      last_status: 'read',
      session_id: sessionId,
    }, {
      object: 'messagesession',
      action: 'update',
      domain: decodedToken.domain,
      user: payload.fromAttendeeId,
      last_status: 'read',
      session_id: sessionId,
    });
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: updateSessionToRead';
      event.addMetadata('payload', payload);
    });
  }
}
