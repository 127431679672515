import { alpha } from '@material-ui/core/styles';
import { darken } from '@material-ui/core';

function rgb2hex(rgb) {
  const newrgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);

  const rVal = (`0${parseInt(newrgb[1], 10).toString(16)}`).slice(-2);
  const gVal = (`0${parseInt(newrgb[2], 10).toString(16)}`).slice(-2);
  const bVal = (`0${parseInt(newrgb[3], 10).toString(16)}`).slice(-2);

  return (newrgb && newrgb.length === 4) ? `#${rVal}${gVal}${bVal}` : '';
}

/**
 * Get color (black/white) depending on bgColor so it would be clearly seen.
 * @param bgColor
 * @param getBoolean instead of returning a color value, returns 1 for light 0 for dark
 * @returns {string}
 */
export default (bgColor, getBoolean) => {
  if (!bgColor) {
    return 'initial';
  }

  let color = bgColor;

  // convert rga and rgba to hex
  if (color.indexOf('rgb') !== -1) {
    color = rgb2hex(bgColor);
  }

  if (getBoolean) {
    return (parseInt(color.replace('#', '0x'), 16) > 0xffffff / 2);
  }

  return (parseInt(color.replace('#', '0x'), 16) > 0xffffff / 2)
    ? alpha('#000', 0.87)
    : darken('#fff', 0.13);
};
