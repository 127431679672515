import _ from 'lodash';
import { put, select, call } from 'redux-saga/effects';

import { ALLOWED_WEBINAR_ROLES } from '../../constants';
import {
  updateMediaStatus,
  setChatInFocus,
  setChatSessions,
  setRoom,
  setUcUnreadCount,
  setVideoDynamicSpeaker,
} from '../../actions';
import * as selectors from '../../selectors';
import ClientRoom from '../../models/ClientRoom';
import initMessageSessions from './initMessageSessions';
import { partialMeetingUpdate } from '../../events';
import getDeviceInfo from '../../utils/getDeviceInfo';
import bugsnagClient from '../../bugsnag';

export default function* initRoom() {
  try {
    console.debug('SAGA - initRoom');

    const {
      appName, meeting, myAttendee, sfu,
    } = yield select((state) => ({
      appName: selectors.selectConfig(state, 'appName'),
      meeting: selectors.selectMeeting(state, 'meeting'),
      myAttendee: selectors.selectMyAttendee(state),
      sfu: selectors.selectConfig(state, 'sfu'),
    }));

    const mediaStatus = yield select(selectors.selectUserMediaStatus);
    const { userId } = yield select(selectors.selectUser);
    const deviceInfo = getDeviceInfo();

    let screenShareTrack;
    if (mediaStatus.screenShareStream) {
      if (!meeting.presenter) {
        ([screenShareTrack] = mediaStatus.screenShareStream.getTracks());
        yield put(partialMeetingUpdate({ presenter: myAttendee.attendee_id }));
      } else {
        const userMediaStatus = yield select(selectors.selectUserMediaStatus);
        const socket = yield select((state) => selectors.selectSocket(state));

        yield put(updateMediaStatus({
          ...userMediaStatus,
          screenShareStream: null,
          screenShareMuted: true,
        }));

        const status = _.pick(userMediaStatus, [
          'audioMuted',
          'hasCamDevice',
          'hasCamPermissions',
          'hasMicDevice',
          'hasMicPermissions',
          'screenShareMuted',
          'userId',
          'videoAspectRatio',
          'videoMuted',
        ]);
        socket.emit(
          'video_status',
          {
            meeting_id: meeting.id,
            domain: meeting.domain,
            ...status,
            screenShareMuted: true,
          },
        );
      }
    }

    // get messages for this video session (All)
    // compile everything into a nested array of message sessions and their messages
    if (!deviceInfo.isMiv) {
      const allMessages = yield call(initMessageSessions);
      if (allMessages) {
      // set the default session
        const defaultSession = _.find(allMessages, { type: 'meeting' }) || _.find(allMessages, { type: 'host' });
        const sessionType = _.get(defaultSession, 'type');
        if (sessionType === 'meeting' || sessionType === 'host') {
          yield put(setChatInFocus(defaultSession.id));
        }

        yield put(setChatSessions(allMessages));

        // initialize count of unread at beginning of session
        let unreadInitCount = 0;
        Object.keys(allMessages).forEach((key) => {
          if (typeof allMessages[key].unreadMessageCount !== 'undefined') {
            unreadInitCount += allMessages[key].unreadMessageCount;
          }
        });
        yield put(setUcUnreadCount(unreadInitCount));
      }
    }

    // user role for host or !host
    if (meeting.status === 'Wait for host') {
      if (!myAttendee || !ALLOWED_WEBINAR_ROLES.includes(myAttendee.role)) {
        console.log('Wait for Host to start meeting');
        return;
      }
    }

    const pendingStart = meeting.status !== 'started' && meeting.wait_for_host === 1 && !ALLOWED_WEBINAR_ROLES.includes(myAttendee.role);
    let isVideoPermitted = true;
    switch (meeting.video_share) {
      case 'host':
        isVideoPermitted = myAttendee && myAttendee.role === 'host';
        break;
      case 'presenters':
        isVideoPermitted = myAttendee && ALLOWED_WEBINAR_ROLES.includes(myAttendee.role);
        break;
      default:
        break;
    }

    const isVideoRestricted = !myAttendee || !isVideoPermitted || myAttendee.video_restricted;

    const produce = (!mediaStatus.videoMuted || mediaStatus.screenShareStream)
      && !isVideoRestricted
      && !pendingStart;

    const roomArgs = {
      accessToken: sfu.access_token,
      appName,
      displayName: sfu.room_id,
      device: mediaStatus.videoDevice,
      domain: sfu.hostname,
      peerId: sfu.peerName,
      port: sfu.port,
      roomId: sfu.room_id,
      instanceId: meeting.instance_id,
      screenShareTrack,
      userId,
      produce,
      consume: !pendingStart,
      datachannel: true,
      useSimulcast: true,
      // handler,
      // useSharingSimulcast: true,
      // forceTcp,
      // svc,
      // externalVideo,
    };

    if (deviceInfo.isMiv || deviceInfo.isMobile || deviceInfo.isTouchScreen) {
      if (!deviceInfo.isMiv && deviceInfo.name === 'Safari') {
        const browserVersion = deviceInfo.version.split('.');
        roomArgs.handler = browserVersion[0] && browserVersion[0] < 12
          ? 'Safari11' : 'Safari12';
      }
      if (deviceInfo.isMiv) {
        roomArgs.handler = 'Safari12';
      }

      roomArgs.useSimulcast = false;
      yield put(setVideoDynamicSpeaker(true));
    }

    const room = new ClientRoom(roomArgs);

    yield put(setRoom(room));
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: initRoom';
    });
  }
}
