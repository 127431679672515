import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import Person from '@material-ui/icons/Person';
import PersonOff from '@material-ui/icons/PersonAddDisabled';

import ControlItem from './ControlItem';

import * as selectors from '../../../../selectors';
import * as actions from '../../../../actions';

const messages = defineMessages({
  TOGGLE_VIDEO_SELF_ON: {
    id: 'TOGGLE_VIDEO_SELF_ON',
    defaultMessage: 'Hide myself from my view',
  },
  TOGGLE_VIDEO_SELF_OFF: {
    id: 'TOGGLE_VIDEO_SELF_OFF',
    defaultMessage: 'Hide Stats',
  },
});

const HideSelfControl = ({
  type,
  variant,
  videoHideSelf,
  toggleVideoHideSelfAction,
  toastSelfHidenAction,
  handleClose,
}) => {
  const intl = useIntl();

  const onToggleVideoHideSelf = () => {
    toggleVideoHideSelfAction(!videoHideSelf);
    if (!videoHideSelf) {
      toastSelfHidenAction('TOAST_SELF_HIDDEN');
    }
    handleClose();
  };

  return (
    <ControlItem
      type={type}
      variant={variant}
      onClick={onToggleVideoHideSelf}
      isToggled={videoHideSelf}
      enabledIcon={<PersonOff />}
      disabledIcon={<Person />}
      enabledTooltip={intl.formatMessage(messages.TOGGLE_VIDEO_SELF_ON)}
      disabledTooltip={intl.formatMessage(messages.TOGGLE_VIDEO_SELF_OFF)}
    />
  );
};

HideSelfControl.propTypes = {
  type: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  videoHideSelf: PropTypes.bool.isRequired,
  toggleVideoHideSelfAction: PropTypes.func.isRequired,
  toastSelfHidenAction: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  videoHideSelf: selectors.selectVideoHideSelf(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  toggleVideoHideSelfAction: actions.setVideoHideSelf,
  toastSelfHidenAction: actions.snackBarMessage,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HideSelfControl);
