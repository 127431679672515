import { call, put } from 'redux-saga/effects';

import * as actions from '../../actions';
import * as services from '../../services';
import { setMeeting } from '../../events';

// eslint-disable-next-line import/prefer-default-export
export function* submitRegistration(event) {
  yield put(actions.showLoaderPage());

  const meeting = yield call(services.registerAttendee, {
    registrationId: event.payload.registrationId,
    name: event.payload.name,
    email: event.payload.email,
  });

  yield put(setMeeting(meeting));
  yield put(actions.showRegisterSuccessPage());
}
