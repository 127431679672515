export const SHOW_MEETING_HUB_PAGE = 'events/SHOW_MEETING_HUB_PAGE';

/**
 * @param type
 * @return {{type: string}}
 */
export const showMeetingHubPage = (type) => ({
  type: SHOW_MEETING_HUB_PAGE,
  payload: { type },
});
