import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ScreenShareIcon from '@material-ui/icons/ScreenShare';

import ControlItem from './ControlItem';

import * as events from '../../../../events';
import * as selectors from '../../../../selectors';
import attendeesShape from '../../../../shapes/attendees';
import meetingShape from '../../../../shapes/meeting';

const messages = defineMessages({
  TRANSFER_WEBINAR: {
    id: 'TRANSFER_WEBINAR',
    defaultMessage: 'Transfer screenshare',
  },
});

const TransferPresentationControl = ({
  type,
  variant,
  attendee,
  myAttendee,
  meeting,
  endScreenShare,
  partialMeetingUpdate, // eslint-disable-line no-unused-vars
  handleClose,
}) => {
  if (!attendee) return null; // I have no idea how attendee is ever null here, but it is

  if (!myAttendee) return null; // myAttendee shouldn't ever be null either, but it is

  if (attendee.isSelf) return null;

  if (meeting.type !== 'presentation') return null;

  if (meeting.presenter === attendee.attendee_id) return null;

  // access restrictions based on roles
  switch (myAttendee.role) {
    case 'host':
      if (!['host', 'presenter'].includes(attendee.role)) {
        return null;
      }
      break;
    case 'presenter':
      if (!['host', 'presenter'].includes(attendee.role) || meeting.presenter !== myAttendee.attendee_id) {
        return null;
      }
      break;
    case 'attendee':
      return null;
    default:
      return null;
  }

  const intl = useIntl();

  const onToggle = () => {
    endScreenShare({
      presentation_transferred_by: myAttendee.attendee_id,
      presenter: attendee.attendee_id,
    });
    // partialMeetingUpdate({
    //   presentation_transferred_by: myAttendee.attendee_id,
    //   presenter: attendee.attendee_id,
    // });
    handleClose();
  };

  return (
    <ControlItem
      type={type}
      variant={variant}
      onClick={onToggle}
      enabledIcon={<ScreenShareIcon />}
      enabledTooltip={intl.formatMessage(messages.TRANSFER_WEBINAR)}
    />
  );
};

TransferPresentationControl.propTypes = {
  type: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  attendee: attendeesShape.propTypesShape.isRequired,
  meeting: meetingShape.propTypesShape.isRequired,
  myAttendee: attendeesShape.propTypesShape.isRequired,
  handleClose: PropTypes.func.isRequired,
  endScreenShare: PropTypes.func.isRequired,
  partialMeetingUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  meeting: selectors.selectMeeting(state),
  myAttendee: selectors.selectMyAttendee(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  endScreenShare: events.endScreenShare,
  partialMeetingUpdate: events.partialMeetingUpdate,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TransferPresentationControl);
