global.process = require('process');

/* eslint-disable import/first */
import 'animate.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import loglevel from 'loglevel';

import App from './views/App';
import bugsnagClient from './bugsnag';
import { startApp } from './events';
import store from './store';
import {
  selectUserMediaStatus, selectMyAttendee, selectUser, selectVideoRoom, selectAudioSession,
} from './selectors';

// if (process.env.NODE_ENV === 'development') {
//   // eslint-disable-next-line global-require
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React, {
//     trackAllPureComponents: false,
//   });
// }

window.logger = loglevel;

const ErrorBoundary = bugsnagClient.getPlugin('react').createErrorBoundary(React);

store.dispatch(startApp());

// listen for window close and end screenshare if the user was sharing
window.addEventListener('beforeunload', () => {
  const mediaStatus = selectUserMediaStatus(store.getState());
  const myAttendee = selectMyAttendee(store.getState());
  const user = selectUser(store.getState());
  const audioSession = selectAudioSession(store.getState());
  if (mediaStatus && !mediaStatus.screenShareMuted) {
    const { room } = store.getState().videoRoom;
    if (room) {
      room.endScreenShare();
    }
  }

  localStorage.setItem('nsVideoCallLive', false);
  if (audioSession && audioSession.state && audioSession.state !== 'Terminated') {
    audioSession.bye();
  }

  if (myAttendee && user && localStorage.ns_t) {
    navigator.sendBeacon(`/ns-api/?object=attendee&action=update&jwt=${localStorage.ns_t}&user=${user.userId}&domain=${user.domain}&attendee_id=${myAttendee.attendee_id}&meeting_id=${myAttendee.meeting_id}&status_video=disconnected`, {});
  }
});

document.addEventListener('visibilitychange', () => {
  const room = selectVideoRoom(store.getState());
  if (room) {
    if (document.hidden) {
      room.pauseAllConsumers();
    } else {
      room.resumeAllConsumers();
    }
  }
});

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root'),
);
