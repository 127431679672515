export default (events, messages) => {
  // combine the two object arrays of messages and events
  const combined = [];

  // add events
  Object.keys(events).forEach((key) => {
    combined.push(events[key]);
  });

  // add messages
  Object.keys(messages).forEach((key) => {
    combined.push(messages[key]);
  });

  // sort based on timestamps
  combined.sort((a, b) => ((a.timestamp > b.timestamp) ? 1 : -1));

  return combined;
};
