import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import crypto from 'crypto-browserify';

/**
 *
 * @param {object} contact
 * @return {*}
 * @private
 */
export const formatContact = (contact) => {
  contact.name = `${contact.first_name} ${contact.last_name}`; // eslint-disable-line no-param-reassign

  contact.initials = ''; // eslint-disable-line no-param-reassign
  if (contact.first_name) {
    contact.initials = contact.first_name[0].toUpperCase(); // eslint-disable-line no-param-reassign
  }
  if (contact.last_name) {
    contact.initials += contact.last_name[0].toUpperCase(); // eslint-disable-line no-param-reassign
  }
  // create a unique id
  contact.id = nsUtils.generateContactId({ // eslint-disable-line no-param-reassign
    firstName: contact.first_name,
    lastName: contact.last_name,
    user: contact.user,
  });

  // phone numbers
  if (contact.cell_phone) {
    contact.cell_phone = contact.cell_phone.replace(/[^0-9+]/g, ''); // eslint-disable-line no-param-reassign
  }
  if (contact.home_phone) {
    contact.home_phone = contact.home_phone.replace(/[^0-9+]/g, ''); // eslint-disable-line no-param-reassign
  }
  if (contact.work_phone) {
    contact.work_phone = contact.work_phone.replace(/[^0-9+]/g, ''); // eslint-disable-line no-param-reassign
  }
  if (contact.user) {
    contact.extension = contact.user; // eslint-disable-line no-param-reassign
    contact.extension_name = contact.name; // eslint-disable-line no-param-reassign
  }
  if (contact.email && contact.email.trim()) {
    contact.email = contact.email.trim(); // eslint-disable-line no-param-reassign
    contact.email = contact.email.toLowerCase(); // eslint-disable-line no-param-reassign
    contact.email = contact.email.split(';'); // eslint-disable-line no-param-reassign
    contact.gravatar = crypto.createHash('md5').update(contact.email[0]).digest('hex'); // eslint-disable-line no-param-reassign
  }

  return contact;
};

export default ({
  domain,
  user,
}) => new Promise((resolve) => {
  nsApi.get({
    object: 'contact',
    action: 'read',
    domain,
    user,
    format: 'json',
    includeDomain: 'yes',
  }).then((contactsArr) => {
    // format contacts
    for (let i = 0; i < contactsArr.length; i += 1) {
      contactsArr[i] = formatContact(contactsArr[i]); // eslint-disable-line no-param-reassign
    }
    resolve(contactsArr);
  });
});
