export const UPDATE_NCS_PARTICIPANT = 'events/UPDATE_NCS_PARTICIPANT';

export const updateNcsParticipant = (leader, successCallback, audioId) => ({
  type: UPDATE_NCS_PARTICIPANT,
  payload: {
    successCallback,
    leader,
    audioId,
  },
});
