import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import PersonAdd from '@material-ui/icons/PersonAdd';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import * as events from '../../../../../events';

const messages = defineMessages({
  INVITE: {
    id: 'INVITE',
    defaultMessage: 'Invite',
  },
});

const InvitePerson = ({
  // inherited
  classes,
  variant,

  // dispatch
  showAddUserAction,
}) => (
  <Tooltip title={<FormattedMessage {...messages.INVITE} />}>
    <IconButton
      id="InvitePerson"
      className={
        classnames(
          classes.icon,
          classes.btn,
          variant === 'small' && classes.btnSmall,
        )
      }
      onClick={() => showAddUserAction({ show: true, tab: 'invite' })}
    >
      <PersonAdd />
    </IconButton>
  </Tooltip>
);

InvitePerson.defaultProps = {
  variant: null,
  configs: {},
};

InvitePerson.propTypes = {
  classes: PropTypes.shape({
    btn: PropTypes.string,
    btnSmall: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
  variant: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  configs: PropTypes.shape({
    guest: PropTypes.bool,
  }),
  showAddUserAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showAddUserAction: events.showAddUser,
}, dispatch);

export default connect(null, mapDispatchToProps)(InvitePerson);
