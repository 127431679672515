import React from 'react';
import { makeStyles } from '@material-ui/core';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  sliderBtn: {
    backgroundColor: 'rgba(0, 0, 0, 0.54)',
    borderRadius: 24,
    color: '#ffffff',
    top: 48,
    right: -(48 + theme.spacing(1)),
    position: 'absolute !important',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
  },
  count: {
    fontSize: '1rem',
  },
}));

const NextArrow = ({
  currentSlide,
  displayed,
  onClick,
  slideCount,
}) => {
  const classes = useStyles();

  const count = slideCount - currentSlide - displayed;

  if (!count) {
    return null;
  }

  return (
    <IconButton
      id="VideoTrayNextArrow"
      className={classes.sliderBtn}
      onClick={onClick}
    >
      <div>
        <div>
          <ArrowRight />
        </div>
        <div className={classes.count}>
          {count}
        </div>
      </div>
    </IconButton>
  );
};

NextArrow.defaultProps = {
  currentSlide: 0,
  displayed: 0,
  onClick: () => {},
  slideCount: 0,
};

NextArrow.propTypes = {
  currentSlide: PropTypes.number,
  displayed: PropTypes.number,
  onClick: PropTypes.func,
  slideCount: PropTypes.number,
};

export default NextArrow;
