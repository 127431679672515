import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
// import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import {
  toggleVideoMute,
  cycleVideoDevices,
  forceVideoMediaStatus,
  toggleAllConsumers,
  endCall,
  endMeeting,
  setLayout,
  updateAudioStatus,
} from '../../events';

import * as actions from '../../actions';

import {
  LAYOUT_TYPE_SPOTLIGHT,
  LAYOUT_TYPE_GRID,
  LAYOUT_TYPE_CONVERSATION,
  LAYOUT_TYPE_PICTURE,
  LAYOUT_ROTATE_VERT,
  LAYOUT_ROTATE_LAND,
} from '../../constants';

import { selectVideoHideSelf } from '../../selectors';

const defineWindowEvent = (keyword, callback) => {
  useEffect(() => {
    window.addEventListener(keyword, callback);
    return () => window.removeEventListener(keyword, callback);
  }, [keyword, callback]);
};

const useToggleVideo = (callback) => defineWindowEvent('togglevideomute', callback);
const useCycleCamera = (callback) => defineWindowEvent('cyclecamera', callback);
const useCameraOff = (callback) => defineWindowEvent('cameraoff', callback);
const useCameraOn = (callback) => defineWindowEvent('cameraon', callback);
const useConsumersOff = (callback) => defineWindowEvent('pauseconsumers', callback);
const useConsumersOn = (callback) => defineWindowEvent('resumeconsumers', callback);
const useLeaveMeeting = (callback) => defineWindowEvent('leavemeeting', callback);
const useEndMeeting = (callback) => defineWindowEvent('endmeeting', callback);
const useSpotlightLayout = (callback) => defineWindowEvent('spotlightlayout', callback);
const useGridLayout = (callback) => defineWindowEvent('gridlayout', callback);
const useConversationLayout = (callback) => defineWindowEvent('conversationlayout', callback);
const usePictureLayout = (callback) => defineWindowEvent('picturelayout', callback);
const useRotateVertical = (callback) => defineWindowEvent('rotatevertical', callback);
const useRotateLandscape = (callback) => defineWindowEvent('rotatelandscape', callback);
const useToggleSelf = (callback) => defineWindowEvent('toggleself', callback);
const useAudioMute = (callback) => defineWindowEvent('audiomute', callback);
const useAudioUnMute = (callback) => defineWindowEvent('audiounmute', callback);

const useStyles = makeStyles(() => ({
  mivBtn: {
    position: 'absolute',
    bottom: '24px',
    right: '24px',
    overflow: 'hidden',
    fontSize: 10,
    borderRadius: 100,
    minHeight: 25,
    maxWidth: '20vw',
    lineHeight: '16px',
    backgroundColor: 'rgba(0,0,0,0.54) !important',
    display: 'inline-flex',
    height: '25px',
    alignItems: 'center',
    letterSpacing: '0.4px',
    fontFamily: 'Roboto',
    padding: '4px 10px 4px 10px',
    textOverflow: 'ellipsis',
    zIndex: '1',
    whiteSpace: 'nowrap',
  },
}));

const MivInterface = ({
  toggleVideoMuteEvent,
  cycleCameraEvent,
  cameraEvent,
  consumerEvent,
  leaveEvent,
  endEvent,
  layoutEvent,
  rotateAction,
  hideSelfAction,
  hideSelf,
  updateAudioEvent,
}) => {
  const classes = useStyles();

  window.nsNativeAction = (event) => {
    if (typeof event === 'string') {
      event = JSON.parse(event); // eslint-disable-line no-param-reassign
    }

    if (!event.action) {
      console.error('Invalid Action');
      return;
    }
    console.log(`nsNativeAction: ${event.action}`);
    console.log(event.data);
    switch (event.action) {
      case 'nsLeaveMeeting':
        leaveEvent();
        break;
      case 'nsEndMeeting':
        endEvent();
        break;
      case 'nsGrid':
        layoutEvent({ layout: LAYOUT_TYPE_GRID });
        break;
      case 'nsConversation':
        layoutEvent({ layout: LAYOUT_TYPE_CONVERSATION });
        break;
      case 'nsSpotlight':
        layoutEvent({ layout: LAYOUT_TYPE_SPOTLIGHT });
        break;
      case 'nsPicture':
        layoutEvent({ layout: LAYOUT_TYPE_PICTURE });
        break;
      case 'nsToggleVideoMute':
        toggleVideoMuteEvent();
        break;
      case 'nsToggleSelf':
        hideSelfAction(!hideSelf);
        break;
      case 'nsSwapCamera':
        cycleCameraEvent(event.data);
        break;
      case 'nsCameraOn':
        cameraEvent({ muteCam: true });
        break;
      case 'nsCameraOff':
        cameraEvent({ muteCam: false });
        break;
      case 'nsPauseConsumers':
        consumerEvent(true);
        break;
      case 'nsResumeConsumers':
        consumerEvent(false);
        break;
      case 'nsVertical':
        rotateAction(LAYOUT_ROTATE_VERT);
        break;
      case 'nsLandscape':
        rotateAction(LAYOUT_ROTATE_LAND);
        break;
      case 'nsMicMute':
        updateAudioEvent(true);
        break;
      case 'nsMicActive':
        updateAudioEvent(false);
        break;
      default:
        console.log(`Unknown Action:${event.action}`);
        break;
    }
  };

  window.nsToggleVideoMute = new Event('togglevideomute');
  useToggleVideo(useCallback(() => toggleVideoMuteEvent()));

  window.nsSwapCamera = new Event('cyclecamera');
  useCycleCamera(useCallback(() => cycleCameraEvent()));

  window.nsCameraOff = new Event('cameraoff');
  useCameraOff(useCallback(() => cameraEvent({ muteCam: true })));

  window.nsCameraOn = new Event('cameraon');
  useCameraOn(useCallback(() => cameraEvent({ muteCam: false })));

  window.nsPauseConsumers = new Event('pauseconsumers');
  useConsumersOff(useCallback(() => consumerEvent(true)));

  window.nsResumeConsumers = new Event('resumeconsumers');
  useConsumersOn(useCallback(() => consumerEvent(false)));

  window.nsLeaveMeeting = new Event('leavemeeting');
  useLeaveMeeting(useCallback(() => leaveEvent()));

  window.nsEndMeeting = new Event('endmeeting');
  useEndMeeting(useCallback(() => endEvent()));

  window.nsSpotlight = new Event('spotlightlayout');
  useSpotlightLayout(useCallback(() => layoutEvent({ layout: LAYOUT_TYPE_SPOTLIGHT })));

  window.nsGrid = new Event('gridlayout');
  useGridLayout(useCallback(() => layoutEvent({ layout: LAYOUT_TYPE_GRID })));

  window.nsConversation = new Event('conversationlayout');
  useConversationLayout(useCallback(() => layoutEvent({ layout: LAYOUT_TYPE_CONVERSATION })));

  window.nsPicture = new Event('picturelayout');
  usePictureLayout(useCallback(() => layoutEvent({ layout: LAYOUT_TYPE_PICTURE })));

  window.nsVertical = new Event('rotatevertical');
  useRotateVertical(useCallback(() => rotateAction(LAYOUT_ROTATE_VERT)));

  window.nsLandscape = new Event('rotatelandscape');
  useRotateLandscape(useCallback(() => rotateAction(LAYOUT_ROTATE_LAND)));

  window.nsToggleSelf = new Event('toggleself');
  useToggleSelf(useCallback(() => hideSelfAction(!hideSelf)));

  window.nsMicMute = new Event('audiomute');
  useAudioMute(useCallback(() => updateAudioEvent(true)));

  window.nsMicActive = new Event('audiounmute');
  useAudioUnMute(useCallback(() => updateAudioEvent(false)));

  return (
    <div>
      <div className={classes.mivBtn}>
        Mobile WebkitView
      </div>
    </div>
  );
};

MivInterface.defaultProps = {
};

MivInterface.propTypes = {
  toggleVideoMuteEvent: PropTypes.func.isRequired,
  cycleCameraEvent: PropTypes.func.isRequired,
  cameraEvent: PropTypes.func.isRequired,
  consumerEvent: PropTypes.func.isRequired,
  leaveEvent: PropTypes.func.isRequired,
  endEvent: PropTypes.func.isRequired,
  layoutEvent: PropTypes.func.isRequired,
  rotateAction: PropTypes.func.isRequired,
  hideSelfAction: PropTypes.func.isRequired,
  hideSelf: PropTypes.bool.isRequired,
  updateAudioEvent: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  hideSelf: selectVideoHideSelf(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  toggleVideoMuteEvent: toggleVideoMute,
  cycleCameraEvent: cycleVideoDevices,
  cameraEvent: forceVideoMediaStatus,
  consumerEvent: toggleAllConsumers,
  leaveEvent: endCall,
  endEvent: endMeeting,
  layoutEvent: setLayout,
  rotateAction: actions.setOrientation,
  hideSelfAction: actions.setVideoHideSelf,
  updateAudioEvent: updateAudioStatus,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MivInterface);
