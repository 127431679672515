import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import combinedReducers from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

// create the application store
const store = createStore(
  combinedReducers(),
  composeWithDevTools(
    applyMiddleware(sagaMiddleware),
  ),
);

sagaMiddleware.run(rootSaga);

export default store;
